import React, { useState, useEffect } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Table from '../../components/Table'
import AddContentButton from '../../components/Buttons/AddContent';
import { sortRolesByPriority } from "../../utils/roles-helper";
import { useLocation } from 'react-router-dom';
export default function GetAllUsers(props) {
    const location = useLocation();
    const isDashboardRoute = location.pathname.startsWith('/dashboard');
    const [rows, setRows] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [userStatus,setUserStatus] = useState(null);
    const columns = [
        {
            field: "userName",
        },
        {
            field: "contactNo",
        },
        {
            field: "email",
        },
        {
            field: "roles",
            cellRenderer: ({ value, data }) => {
                const row = data;
                if (shouldVisible(props, row)) {
                    return <div style={{ marginTop: "5px", marginBottom: "5px" }}>{renderMultiRoleSelect(value, row)}</div>;
                }
                else {
                    return value.map(str => str.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())).join(', ');
                }
            }
        },
        {
            field: "status",
            cellRenderer: ({ value, data }) => {
                const row = data;
                return (
                    <div style={editBudgetStyle} name="userStatus" >
                        {
                            shouldVisible(props, row) && (row.status !== "PENDING") ? <Select
                                value={userStatus?userStatus:value} style={{ fontSize: '12px', height: "40px", marginTop: "5px" }}
                                onChange={(event) => {
                                    setUserStatus(event.target.value);
                                    props.handleUserStatusChange(event.target.value, row.id)}}
                            >
                                <MenuItem key={row.id} value={row.status} >
                                    <span >{row.status}</span>
                                </MenuItem>
                                {props.userStatuses.map((status) => {
                                    if ((row.status === "PENDING")) {
                                        return true;
                                    } else {
                                        return (row.status !== status) && <MenuItem key={row.id} value={status} >
                                            <span  >{status}</span>
                                        </MenuItem>
                                    }
                                }
                                )}
                            </Select> : row.status
                        }
                        <input
                            type="checkbox"
                            onChange={(e) => {
                                props.handleUserCheck(e.target.checked, row.id, row.roles, row.status, row.userName, row.email);
                            }}
                            checked={shouldVisible(props, row)}
                        />
                    </div>
                )
            }
        },
    ]

    function createData(id, firstName, lastName, email, contactNo, roles, status) {
        const userName = firstName + "\u00a0" + lastName;
        return { id, userName, email, contactNo, roles, status, };
    }
    useEffect(() => {
        setRows(
            props.response?.map((row) =>
                createData(row.userId, row.firstName, row.lastName, row.email, row.contactNo, row.roles, row.user_status)
            )
        );
    }, [props.response]);
    function renderMultiRoleSelect(value, row) {
        return (
            <div className="mw-300" >
                {shouldVisible(props, row) && props.selectedRoles.length == 0 ?
                    props.setSelectedRoles(value) :
                    ""
                }
                <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel>Select Roles</InputLabel>
                    <Select
                        className="role-select"
                        multiple
                        value={props.selectedRoles}
                        onChange={(e) => ""}
                        label="Select Roles"
                        renderValue={(selected) => selected.join(', ')}
                        style={{ cursor: 'default', textOverflow: 'ellipsis !important', whiteSpace: 'nowrap !important', fontSize: "12px", height: "40px" }}
                    >{
                            sortRolesByPriority(props.roles).map((role, index) => (
                                <MenuItem key={index} value={role} className='menuItem'>
                                    {location.pathname !== '/dashboard' && (

                                        <input className="role-checkbox" style={{ cursor: 'pointer' }}
                                            type="checkbox"
                                            checked={props.selectedRoles.length ? props.selectedRoles.includes(role) : false}
                                            onClick={(e) => {
                                                props.handleRolesChange(e.target.checked, role, row.id);
                                            }}
                                        />
                                    )}
                                    <span style={{ fontSize: '12px' }} >{role}</span>

                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </div>
        );
    }
    function shouldVisible(props, row) {
        return props.userSelected !== null && props.userSelected == row.id
    }
    const editBudgetStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }
    return (
        <>
            <div className="inner-content">
                <div className='manage_content' >
                    <div className="content_subtitle">
                        <p>List of Users</p>
                    </div>
                    <div className="box-functional_elements">
                        <div className="functional_elements" >
                            <div style={{ display: 'flex', margin: '0 5px', flexWrap: 'wrap', alignItems: 'center' }}>
                                <input
                                    type={"text"}
                                    className="searchBar"
                                    value={searchText}
                                    onChange={(input) => { setSearchText(input.target.value) }}
                                    placeholder="Search"
                                ></input>
                                {
                                    (props.userToUpdate?.status === "PENDING") && <div style={{ margin: "0px 5px 0px 10px" }}>
                                        <button onClick={() => props.reCreateUser()}>Retry Verification</button>
                                    </div>
                                }
                                {
                                    props.userSelected && <div style={{ margin: "0px 5px 0px 10px" }}>
                                        <button onClick={() => props.updateUser()}>Update User</button>
                                    </div>
                                }
                                <div onClick={() => { props.setAddPopUp(true); props.setMode("Add"); }} style={{ marginLeft: "5px" }}>
                                    <AddContentButton />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='table-outer_content'>
                    <Table
                        paginationStyle={{
                            position: "fixed",
                            right: "20px",
                            bottom: "20px",
                            backgroundColor: "#ffffff",
                        }}
                        totalCount={props.totalCount}
                        page={props.page}
                        tableTitle={"UserTable"}
                        rowsPerPage={props.rowsPerPage}
                        handleChangePage={props.handleChangePage}
                        handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                        columns={columns}
                        rows={rows}
                        function={(row) => props.setGetUserByEmail(row.email)}
                        normalData={true}
                        search={searchText}
                        userTable={true}
                        handleUserCheck={props.handleUserCheck}
                        handleRolesChange={props.handleRolesChange}
                        roles={props.roles}
                        handleUserStatusChange={props.handleUserStatusChange}
                        userStatuses={props.userStatuses}
                        setUserSelected={props.setUserSelected}
                        userSelected={props.userSelected}
                        selectedRoles={props.selectedRoles}
                        setSelectedRoles={props.setSelectedRoles}
                        isPagination = {true}
                    />
                </div>
            </div>
        </>
    )
}
