import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/Navigation";
import "../../assets/styles/managepages.css";
import { getAllInvoiceListAPI } from "../../utils/api-helper";
import GetAllInvoice from "./getAllInvoice";
import CreateInvoice from "./createInvoice";
import FilterInvoice from "./FilterInvoice";
import SortInvoice from "./sortInvoice";
import Footer from "../../components/footer/Footer";
import Settings from "../../components/Settings";
import ViewContent from "./viewInvoice";
import DeleteInvoice from "./deleteInvoice";
import BackButton from "../../components/Buttons/backButton";
import {
    getAllClientAPI,
    getAllProjectAPI,
    getAllCountryAPI,
    getAllInvoiceType,
    getPaymentStatusAPI,
    getPaymentTermsAPI,
    getAllPaymentModeAPI,
    updateInvoicePaymentStatus,
} from "../../utils/api-helper";
import InvoiceItemsForm from "./invoiceItems";
import DeleteItemsForm from "./invoiceItems/deleteInvoiceItem";
import InvoiceAlertPopupMessage from "./InvoiceAlertPopupMessage";
import UpdateInvoiceNumber from "./UpdateInvoiceNumber";
import Loader from "../../components/Loader";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ErrorPopup from '../../components/ErrorPopup';
export var invoiceItemsDataArray = [];
export var TotalInvoiceAmount = 0;

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const resetInvoiceItems = () => {
    invoiceItemsDataArray = [];
}

export default function Manageinvoice(props) {
    const navigate = useNavigate();
    const [invoiceTotalAmount, setInvoiceTotalAmount] = useState(0);

    const [isGetAllInvoice, setIsGetAllInvoice] = useState(true);
    const [isAddInvoice, setIsAddInvoice] = useState(false);
    const [isGetInvoiceByid, setIsGetInvoiceByid] = useState(false);
    const [isEditInvoice, setIsEditInvoice] = useState(false);
    const [isMakeCopyInvoice, setIsMakeCopyInvoice] = useState(false);
    const [isFilterInvoice, setIsFilterInvoice] = useState(false);
    const [filterByInvoiceTypeData, setFilterByInvoiceTypeData] = useState("1");
    const [sortBy, setSortBy] = useState("");
    const [sortOrder, setSortOrder] = useState("DESC");
    const [isSortInvoice, setIsSortInvoice] = useState(false);
    const [isDeleteInvoice, setIsDeleteInvoice] = useState(false);
    const [isAddInvoiceItems, setIsAddInvoiceItems] = useState(false);
    const [isDeleteInvoiceItem, setIsDeleteInvoiceItem] = useState(false);
    const [isEditInvoiceItem, setIsEditInvoiceItem] = useState(false);
    const [startLoader, setStartLoader] = useState(false);
    const [alertPopupWindow, setAlertPopupWindow] = useState(false);
    const [alertPopupMessage, setAlertPopupMessage] = useState('');
    const [updateInvoiceNumberWindow, setUpdateInvoiceNumberWindow] = useState(false);
    const [getAlertReponse, setAlertReponse] = useState(false);
    const [isFilterSelected, setIsFilterSelected] = useState(false);
    const [activeFilters, setActiveFilters] = useState([]);
    const [invoiceNumber, setinvoiceNumber] = useState("");
    const [clientFirstNameFilter, setClientFirstNameFilter] = useState("");
    const [clientLastNameFilter, setClientLastNameFilter] = useState("");
    const [projectNameFilter, setProjectNameFilter] = useState("");
    const [dueDateFromFilter, setDueDateFromFilter] = useState("");
    const [dueDateToFilter, setDueDateToFilter] = useState("");
    const [invoiceDateFromFilter, setInvoiceDateFromFilter] = useState("");
    const [invoiceDateToFilter, setInvoiceDateToFilter] = useState("");
    const [paidDateFromFilter, setPaidDateFromFilter] = useState("");
    const [paidDateToFilter, setPaidDateToFilter] = useState("");
    const [isActiveFilter, setIsActiveFilter] = useState(false);
    const [isPaymentStatusFilter, setIsPaymentStatusFilter] = useState("");
    const [isPaymentStatusLabel, setIsPaymentStatusLabel] = useState("");
    const [sortInvoiceData, setsortInvoiceData] = useState("invoiceDate");

    //for updating status
    const [projectType, setProjectType] = useState("");
    const [projectID, setProjectID] = useState("");
    const [updateStatusBody, setUpdateStatusBody] = useState({});
    const [statusUpdated, setStatusUpdated] = useState(false);
    const [error, setError] = useState(null);

    const body = {
        clientFirstName: clientFirstNameFilter,
        clientLastName: clientLastNameFilter,
        projectName: projectNameFilter,
        dueDateFrom: dueDateFromFilter,
        dueDateTo: dueDateToFilter,
        invoiceDateFrom: invoiceDateFromFilter,
        invoiceDateTo: invoiceDateToFilter,
        isActive: isActiveFilter,
        paidDateFrom: paidDateFromFilter,
        paidDateTo: paidDateToFilter,
        paymentStatus: isPaymentStatusFilter,
        invoiceNumber: "",
        invoiceType: filterByInvoiceTypeData,
        projectManagerFirstName: "",

    };
    const [response, setResponse] = useState([]);
    const [getAllCLients, setGetAllCLients] = useState();
    const [getAllProjects, setGetAllProjects] = useState();
    const [getAllCountries, setGetAllCountries] = useState();
    const [getPaymentStatus, setGetPaymentStatus] = useState();
    const [getPaymentTerms, setGetPaymentTerms] = useState();
    const [getPaymentMode, setGetPaymentMode] = useState();
    const [invoiceType, setInvoiceType] = useState([]);
    const [allProjects, setAllProjects] = useState([]);
    function createData(id, value) {
        return { value: id, label: value };
    }

    useEffect(() => {
        getAllInvoices();
        
    }, []);

    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        getAllInvoiceAPICall();
    }, [filterByInvoiceTypeData, sortOrder, sortInvoiceData, page, rowsPerPage]);


    const handleChangePage = (newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (value) => {
        setRowsPerPage(value);
        setPage(0);
    };

    useEffect(() => {
        getPaymentStatusFunction();
        getAllClientFunction();
        getAllProjectFunction();
        getInvoiceTypeFunction();
        getAllCountryFunction();
        getPaymnetTermsFunction();
        getAllPaymentModeFunction();
    }, []);

    const handleFiltersApplied = (filters) => {
        setActiveFilters(filters);
    };

    const getAllClientFunction = () => {
        getAllClientAPI()
            .then((res) => {
                setGetAllCLients(
                    res?.data.map((row) =>
                        createData(row.id, row.firstName + " " + row.lastName)
                    )
                );
            })
            .catch((err) => {
                // console.log(err);
            });
    }
    const getAllProjectFunction = () => {
        getAllProjectAPI()
            .then((res) => {
                setGetAllProjects(
                    res?.data.map((row) => createData(row.id, row.projectName))
                );

                setAllProjects(res?.data);
            })
            .catch((err) => {
                // console.log(err);
            });
    }
    const getAllCountryFunction = () => {
        getAllCountryAPI()
            .then((res) => {
                setGetAllCountries(
                    res?.data.map((row) => createData(row.id, row.currencyName))
                );
            })
            .catch((err) => {
                // console.log(err);
            });
    }

    function handleInvoiceCheck(event, id) {
        let checked = event.target.checked;
        if (checked) {
            setProjectID(id);
            setUpdateStatusBody(((prevObject) => ({
                ...prevObject,
                id: id,
            })))
        } else {
            setProjectID(null);
            setUpdateStatusBody({});
        }
    }

    function handlePaymentStatusChange(value, id) {
        if (id == projectID) {
            setUpdateStatusBody(((prevObject) => ({
                ...prevObject,
                paymentStatus: value,
            })))
        }
    }

    function updateInvoiceStatus() {
        setStartLoader(true);
        const body = {
            paymentStatus: updateStatusBody.paymentStatus
        }
        updateInvoicePaymentStatus(updateStatusBody.id, body).then((res) => {
            setStatusUpdated(true);
        }).catch((err) => {
            if (error.response) {
                setError(error.response.data.message)
            } else if (error.request) {
                setError("Something went wrong, try again")
            } else {
                setError("Something went wrong, try again")
            }
        }).finally(() => {
            setStartLoader(false);
            getAllInvoiceAPICall();
            setUpdateStatusBody({});
            setProjectID("")
        })
    }

    const closeErrorPopup = () => {
        setError(null);
        setUpdateStatusBody({});
        setProjectID("");
        setStatusUpdated(false);
    };

    const getPaymentStatusFunction = () => {
        getPaymentStatusAPI()
            .then((res) => {
                setGetPaymentStatus(
                    res?.data?.map((row) => createData(row.id, row.value))
                );
            })
            .catch((err) => {
                // console.log(err);
            });
    }

    const getPaymnetTermsFunction = () => {
        getPaymentTermsAPI()
            .then((res) => {
                setGetPaymentTerms(
                    res?.data.map((row) => createData(row.id, row.value))
                );
            })
            .catch((err) => {
                // console.log(err);
            });
    }
    const getInvoiceTypeFunction = () => {
        getAllInvoiceType()
            .then((res) => {
                setInvoiceType(
                    res?.data.map((row) => createData(row.id, row.value))
                )
            }).catch((err) => {
                // console.log(err);
            });
    }

    const getAllPaymentModeFunction = () => {
        getAllPaymentModeAPI()
            .then((res) => {
                setGetPaymentMode(
                    res?.data.map((row) => createData(row.id, row.value))
                );
            })
            .catch((err) => {
                // console.log(err);
            });
    }

    const getAllInvoices = () => {
        setIsGetAllInvoice(true); // Default state to get All Invoice Data
        setIsAddInvoice(false);
        setIsGetInvoiceByid(false);
        setIsEditInvoice(false);
        setIsMakeCopyInvoice(false);
        setIsFilterInvoice(false);
        setIsSortInvoice(false);
        setIsDeleteInvoice(false);
        invoiceItemsDataArray.splice(0, invoiceItemsDataArray.length);
        getAllInvoiceAPICall()
        navigate("/invoice");
    };
    const getAllInvoiceAPICall = () => {
        setStartLoader(true);
        getAllInvoiceListAPI(body, sortInvoiceData, sortOrder, page, rowsPerPage)
            .then((res) => {
                setResponse(res?.data?.list);
                setTotalCount(res?.data?.totalCount);
                setStartLoader(false);
            })
            .catch((err) => {
                // console.log(err);
                setStartLoader(false);
            });
    }

    const addInvoice = () => {
        setIsGetAllInvoice(false);
        setIsAddInvoice(true); // Add invoice is set true When user Clicks on Add Invoice
        setIsGetInvoiceByid(false);
        setIsEditInvoice(false);
        setIsMakeCopyInvoice(false);
        setIsFilterInvoice(false);
        setIsSortInvoice(false);
        setIsDeleteInvoice(false);
        setIsAddInvoiceItems(false);
        setIsDeleteInvoiceItem(false);
        setIsEditInvoiceItem(false);
    };
    const getAddInvoiceItemsPage = () => {
        setIsGetAllInvoice(false);
        setIsAddInvoice(true);
        setIsGetInvoiceByid(false);
        setIsEditInvoice(false);
        setIsMakeCopyInvoice(false);
        setIsFilterInvoice(false);
        setIsSortInvoice(false);
        setIsDeleteInvoice(false);
        setIsAddInvoiceItems(true);
        setIsDeleteInvoiceItem(false);
        setIsEditInvoiceItem(false);
    };
    const [invoiceItemIndex, setInvoiceItemIndex] = useState("");
    const [invoiceItemDescription, setInvoiceItemDescription] = useState("");
    const getDeleteInvoiceItemPage = (index, description) => {
        setInvoiceItemIndex(index);
        setInvoiceItemDescription(description);
        setIsGetAllInvoice(false);
        setIsAddInvoice(true); // Add invoice is set true When user Clicks on Add Invoice
        setIsGetInvoiceByid(false);
        setIsEditInvoice(false);
        setIsMakeCopyInvoice(false);
        setIsFilterInvoice(false);
        setIsSortInvoice(false);
        setIsDeleteInvoice(false);
        setIsAddInvoiceItems(false);
        setIsDeleteInvoiceItem(true);
        setIsEditInvoiceItem(false);
    };
    const getEditInvoiceItemPage = (index) => {
        setInvoiceItemIndex(index);
        setIsGetAllInvoice(false);
        setIsAddInvoice(true); // Add invoice is set true When user Clicks on Add Invoice
        setIsGetInvoiceByid(false);
        setIsEditInvoice(false);
        setIsMakeCopyInvoice(false);
        setIsFilterInvoice(false);
        setIsSortInvoice(false);
        setIsDeleteInvoice(false);
        setIsAddInvoiceItems(false);
        setIsDeleteInvoiceItem(false);
        setIsEditInvoiceItem(true);
    };
    const filterInvoice = () => {
        setIsGetAllInvoice(true);
        setIsAddInvoice(false);
        setIsGetInvoiceByid(false); // popup window will show when clicked...
        setIsEditInvoice(false);
        setIsMakeCopyInvoice(false);
        setIsFilterInvoice(true);
        setIsSortInvoice(false);
        setIsDeleteInvoice(false);
        setIsAddInvoiceItems(false);
        setIsDeleteInvoiceItem(false);
        setIsEditInvoiceItem(false);
    };
    const sortInvoice = () => {
        setIsGetAllInvoice(true);
        setIsAddInvoice(false);
        setIsGetInvoiceByid(false);
        setIsEditInvoice(false);
        setIsMakeCopyInvoice(false);
        setIsFilterInvoice(false);
        setIsSortInvoice(true); // popup window will show when clicked...
        setIsDeleteInvoice(false);
        setIsAddInvoiceItems(false);
        setIsDeleteInvoiceItem(false);
        setIsEditInvoiceItem(false);
    };
    const getInvoiceById = (row) => {
        setIsGetAllInvoice(false);
        setIsAddInvoice(false);
        setIsGetInvoiceByid(true); // Get Deta of Invoice through Id, user will clicks on Row of Data table and it will set true state.
        setIsEditInvoice(false);
        setIsMakeCopyInvoice(false);
        setIsFilterInvoice(false);
        navigate(`/invoice/?id=${row.id}`);
        setIsDeleteInvoice(false);
        setIsAddInvoiceItems(false);
        setIsDeleteInvoiceItem(false);
        setIsEditInvoiceItem(false);
    };
    const editInvoice = () => {
        setIsGetAllInvoice(false);
        setIsAddInvoice(false);
        setIsGetInvoiceByid(false);
        setIsEditInvoice(true); // Edit Invoice page
        setIsMakeCopyInvoice(false);
        setIsFilterInvoice(false);
        setIsDeleteInvoice(false);
        setIsAddInvoiceItems(false);
        setIsDeleteInvoiceItem(false);
        setIsEditInvoiceItem(false);
    };
    const makeCopyInvoice = () => {
        setIsGetAllInvoice(false);
        setIsAddInvoice(false);
        setIsGetInvoiceByid(false);
        setIsEditInvoice(false);
        setIsMakeCopyInvoice(true); // copy Invoice page
        setIsFilterInvoice(false);
        setIsDeleteInvoice(false);
        setIsAddInvoiceItems(false);
        setIsDeleteInvoiceItem(false);
        setIsEditInvoiceItem(false);
    };
    const [getProjectName, setGetProjectName] = useState();
    const deleteInvoice = (projectName) => {
        setGetProjectName(projectName);
        setIsGetAllInvoice(false);
        setIsAddInvoice(false);
        setIsGetInvoiceByid(true); // View Invoice will be at background
        setIsEditInvoice(false);
        setIsMakeCopyInvoice(false);
        setIsFilterInvoice(false);
        setIsDeleteInvoice(true); // popup for delete Invoice
        setIsAddInvoiceItems(false);
        setIsDeleteInvoiceItem(false);
        setIsEditInvoiceItem(false);
    };
    const handleClose = () => {
        setStatusUpdated(false);
    }

    const checkIsUpwork = (selectedProjectId) => {
        let selectedProject = allProjects.find((item) => item.id == selectedProjectId);
        return selectedProject.isUpWorkProject ? true : false;
    }

    return (
        <>
            <div className="flex-container">
                <div>
                    <Navigation myClass="Manage Invoice" />
                </div>
                <div className="right-section">
                    <div className="title" id="title">
                        {isGetAllInvoice ? "" : <BackButton handleClick={getAllInvoices} />}
                        <div className="pageheading">
                            <p>Manage Invoice</p>
                        </div>
                        <Settings />
                    </div>
                    <div className="content" id="content">
                        {isGetAllInvoice ? (
                            <GetAllInvoice
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                page={page}
                                setPage={setPage}
                                rowsPerPage={rowsPerPage}
                                totalCount={totalCount}
                                response={response}
                                addInvoice={addInvoice}
                                getInvoiceById={getInvoiceById}
                                filterInvoice={filterInvoice}
                                activeFilters={activeFilters}
                                sortInvoice={sortInvoice}
                                setStartLoader={setStartLoader}
                                invoiceType={invoiceType}
                                filterByInvoiceTypeData={filterByInvoiceTypeData}
                                setFilterByInvoiceTypeData={setFilterByInvoiceTypeData}
                                isFilterSelected={isFilterSelected}
                                setIsFilterSelected={setIsFilterSelected}
                                setSortBy={setSortBy}
                                sortBy={sortBy}
                                sortOrder={sortOrder}
                                setsortInvoiceData={setsortInvoiceData}
                                setSortOrder={setSortOrder}
                                sortInvoiceData={sortInvoiceData}
                                isActiveFilter={isActiveFilter}
                                setIsActiveFilter={setIsActiveFilter}
                                handleInvoiceCheck={handleInvoiceCheck}
                                projectID={projectID}
                                setProjectID={setProjectID}
                                updateStatusBody={updateStatusBody}
                                setUpdateStatusBody={setUpdateStatusBody}
                                getPaymentStatus={getPaymentStatus}
                                getAllCLients={getAllCLients}
                                getAllProjects={getAllProjects}
                                handlePaymentStatusChange={handlePaymentStatusChange}
                                updateInvoiceStatus={updateInvoiceStatus}
                                clientFirstNameFilter={clientFirstNameFilter}
                                setClientFirstNameFilter={setClientFirstNameFilter}
                                setClientLastNameFilter={setClientLastNameFilter}
                                setProjectNameFilter={setProjectNameFilter}
                                setDueDateFromFilter={setDueDateFromFilter}
                                setDueDateToFilter={setDueDateToFilter}
                                setInvoiceDateFromFilter={setInvoiceDateFromFilter}
                                setInvoiceDateToFilter={setInvoiceDateToFilter}
                                setPaidDateFromFilter={setPaidDateFromFilter}
                                setPaidDateToFilter={setPaidDateToFilter}
                                setIsPaymentStatusFilter={setIsPaymentStatusFilter}
                                setIsFilterInvoice={setIsFilterInvoice}
                                getAllInvoices={getAllInvoices}
                                onFiltersApplied={handleFiltersApplied}
                            />
                        ) : isAddInvoice ? (
                            <CreateInvoice
                                isEditInvoice={false}
                                getAllInvoices={getAllInvoices}
                                getAllCLients={getAllCLients}
                                getAllProjects={getAllProjects}
                                getAllCountries={getAllCountries}
                                getPaymentStatus={getPaymentStatus}
                                getPaymentTerms={getPaymentTerms}
                                getPaymentMode={getPaymentMode}
                                getAddInvoiceItemsPage={getAddInvoiceItemsPage}
                                getDeleteInvoiceItemPage={getDeleteInvoiceItemPage}
                                getEditInvoiceItemPage={getEditInvoiceItemPage}
                                isAddInvoiceItems={isAddInvoiceItems}
                                isDeleteInvoiceItem={isDeleteInvoiceItem}
                                invoiceTotalAmount={invoiceTotalAmount}
                                setInvoiceTotalAmount={setInvoiceTotalAmount}
                                setAlertPopupWindow={setAlertPopupWindow}
                                setAlertPopupMessage={setAlertPopupMessage}
                                getAlertReponse={getAlertReponse}
                                setAlertReponse={setAlertReponse}
                                setStartLoader={setStartLoader}
                                setUpdateInvoiceNumberWindow={setUpdateInvoiceNumberWindow}
                                setProjectType={setProjectType}
                                checkIsUpwork={checkIsUpwork}
                            />
                        ) : isGetInvoiceByid ? (
                            <ViewContent
                                setinvoiceNumber={setinvoiceNumber}
                                editInvoice={editInvoice}
                                makeCopyInvoice={makeCopyInvoice}
                                deleteInvoice={deleteInvoice}
                                setStartLoader={setStartLoader}
                                setUpdateInvoiceNumberWindow={setUpdateInvoiceNumberWindow}
                                setAlertPopupWindow={setAlertPopupWindow}
                                setAlertPopupMessage={setAlertPopupMessage}
                            />
                        ) : isEditInvoice ? (
                            <CreateInvoice
                                isEditInvoice={true}
                                getAllInvoices={getAllInvoices}
                                getAllCLients={getAllCLients}
                                getAllProjects={getAllProjects}
                                getAllCountries={getAllCountries}
                                getPaymentStatus={getPaymentStatus}
                                getPaymentTerms={getPaymentTerms}
                                getPaymentMode={getPaymentMode}
                                getAddInvoiceItemsPage={getAddInvoiceItemsPage}
                                getDeleteInvoiceItemPage={getDeleteInvoiceItemPage}
                                getEditInvoiceItemPage={getEditInvoiceItemPage}
                                isAddInvoiceItems={isAddInvoiceItems}
                                isDeleteInvoiceItem={isDeleteInvoiceItem}
                                invoiceTotalAmount={invoiceTotalAmount}
                                setInvoiceTotalAmount={setInvoiceTotalAmount}
                                setAlertPopupWindow={setAlertPopupWindow}
                                setAlertPopupMessage={setAlertPopupMessage}
                                getAlertReponse={getAlertReponse}
                                setAlertReponse={setAlertReponse}
                                setStartLoader={setStartLoader}
                                setProjectType={setProjectType}
                                setUpdateInvoiceNumberWindow={setUpdateInvoiceNumberWindow}
                            />
                        ) : isMakeCopyInvoice ? (
                            <CreateInvoice
                                isMakeCopyInvoice={true}
                                getAllInvoices={getAllInvoices}
                                getAllCLients={getAllCLients}
                                getAllProjects={getAllProjects}
                                getAllCountries={getAllCountries}
                                getPaymentStatus={getPaymentStatus}
                                getPaymentTerms={getPaymentTerms}
                                getPaymentMode={getPaymentMode}
                                getAddInvoiceItemsPage={getAddInvoiceItemsPage}
                                getDeleteInvoiceItemPage={getDeleteInvoiceItemPage}
                                getEditInvoiceItemPage={getEditInvoiceItemPage}
                                isAddInvoiceItems={isAddInvoiceItems}
                                isDeleteInvoiceItem={isDeleteInvoiceItem}
                                isEditInvoiceItem={isEditInvoiceItem}
                                invoiceTotalAmount={invoiceTotalAmount}
                                setInvoiceTotalAmount={setInvoiceTotalAmount}
                                setAlertPopupWindow={setAlertPopupWindow}
                                setAlertPopupMessage={setAlertPopupMessage}
                                getAlertReponse={getAlertReponse}
                                setAlertReponse={setAlertReponse}
                                setStartLoader={setStartLoader}
                                setUpdateInvoiceNumberWindow={setUpdateInvoiceNumberWindow}
                                setProjectType={setProjectType}
                               
                            />
                        ) : (
                            <p>No Content</p>
                        )}
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
                {isFilterInvoice ? (
                    <FilterInvoice
                        getAllInvoices={getAllInvoices}
                        clientFirstNameFilter={clientFirstNameFilter}
                        setClientFirstNameFilter={setClientFirstNameFilter}
                        clientLastNameFilter={clientLastNameFilter}
                        setClientLastNameFilter={setClientLastNameFilter}
                        projectNameFilter={projectNameFilter}
                        setProjectNameFilter={setProjectNameFilter}
                        dueDateFromFilter={dueDateFromFilter}
                        setDueDateFromFilter={setDueDateFromFilter}
                        dueDateToFilter={dueDateToFilter}
                        setDueDateToFilter={setDueDateToFilter}
                        invoiceDateFromFilter={invoiceDateFromFilter}
                        setInvoiceDateFromFilter={setInvoiceDateFromFilter}
                        invoiceDateToFilter={invoiceDateToFilter}
                        setInvoiceDateToFilter={setInvoiceDateToFilter}
                        paidDateFromFilter={paidDateFromFilter}
                        onFiltersApplied={handleFiltersApplied}
                        setPaidDateFromFilter={setPaidDateFromFilter}
                        paidDateToFilter={paidDateToFilter}
                        setPaidDateToFilter={setPaidDateToFilter}
                        isActiveFilter={isActiveFilter}
                        setIsActiveFilter={setIsActiveFilter}
                        setIsPaymentStatusFilter={setIsPaymentStatusFilter}
                        isPaymentStatusFilter={isPaymentStatusFilter}
                        isPaymentStatusLabel={isPaymentStatusLabel}
                        getPaymentStatus={getPaymentStatus}
                        getAllCLients={getAllCLients}
                        getAllProjects={getAllProjects}
                        setStartLoader={setStartLoader}
                        isFilterSelected={isFilterSelected}
                        setIsFilterSelected={setIsFilterSelected}
                        setIsPaymentStatusLabel={setIsPaymentStatusLabel}
                        filterByInvoiceTypeData={filterByInvoiceTypeData}
                        setIsFilterInvoice={setIsFilterInvoice}
                        isFilterInvoice={isFilterInvoice}
                    />
                ) : (
                    <></>
                )}
                {isSortInvoice ? (
                    <SortInvoice
                        getAllInvoices={getAllInvoices}
                        sortInvoiceData={sortInvoiceData}
                        setsortInvoiceData={setsortInvoiceData}
                        setStartLoader={setStartLoader}
                        setIsSortInvoice={setIsSortInvoice}
                    />
                ) : (
                    <></>
                )}
                {isDeleteInvoice ? (
                    <DeleteInvoice
                        getInvoiceById={getInvoiceById}
                        invoiceNumber={invoiceNumber}
                        getAllInvoices={getAllInvoices}
                        getProjectName={getProjectName}
                        setStartLoader={setStartLoader}
                    />
                ) : (
                    <></>
                )}
                {isAddInvoiceItems ? (
                    <InvoiceItemsForm
                        isEditItems={false}
                        addInvoice={addInvoice}
                        invoiceItemsDataArray={invoiceItemsDataArray}
                        invoiceTotalAmount={invoiceTotalAmount}
                        setInvoiceTotalAmount={setInvoiceTotalAmount}
                        setStartLoader={setStartLoader}
                        projectType={projectType}
                    />
                ) : (
                    <></>
                )}
                {isDeleteInvoiceItem ? (
                    <DeleteItemsForm
                        invoiceItemIndex={invoiceItemIndex}
                        invoiceItemDescription={invoiceItemDescription}
                        addInvoice={addInvoice}
                        invoiceTotalAmount={invoiceTotalAmount}
                        setInvoiceTotalAmount={setInvoiceTotalAmount}
                        setStartLoader={setStartLoader}
                    />
                ) : (
                    <></>
                )}
                {isEditInvoiceItem ? (
                    <InvoiceItemsForm
                        isEditItems={true}
                        invoiceItemIndex={invoiceItemIndex}
                        addInvoice={addInvoice}
                        invoiceItemsDataArray={invoiceItemsDataArray}
                        invoiceTotalAmount={invoiceTotalAmount}
                        setInvoiceTotalAmount={setInvoiceTotalAmount}
                        setStartLoader={setStartLoader}
                        projectType={projectType}
                    />
                ) : (
                    <></>
                )}
            </div>
            {alertPopupWindow.is &&
                <InvoiceAlertPopupMessage
                    setAlertPopupWindow={setAlertPopupWindow}
                    alertPopupMessage={alertPopupMessage}
                    setAlertReponse={setAlertReponse}
                    alertPopupWindow={alertPopupWindow}
                ></InvoiceAlertPopupMessage>
            }
            {updateInvoiceNumberWindow &&
                <UpdateInvoiceNumber
                    setUpdateInvoiceNumberWindow={setUpdateInvoiceNumberWindow}
                ></UpdateInvoiceNumber>
            }
            {
                startLoader ? <Loader /> : <></>
            }
            {
                statusUpdated &&
                <Snackbar open={statusUpdated} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        Payment status updated successfully
                    </Alert>
                </Snackbar>
            }
            {error && <ErrorPopup errorMessage={error} onClose={closeErrorPopup} />}
        </>
    );
}
