import React from 'react';
import '../../assets/styles/managepages.css';
import styles from "./pendingInvoice.module.css";
import TextField from '@mui/material/TextField';

const Popup = (props) => {
  return (
    <div className="shortfall">
      <div className="popup-content">
        {props.isError ? (
          <>
            <h3>{"Error Details"}</h3>
            <div className='table-container'>
              <table className="error-table">
                <thead>
                  <tr>
                    <th>S No.</th>
                    <th>Row No.</th>
                    <th>Error</th>
                  </tr>
                </thead>
                <tbody>
                  {props.data.map((error, index) => (
                    <tr key={index} className="error-row">
                      <td>{index + 1}</td>
                      <td>{error.rowNumber}</td>
                      <td>{error.errorMessage}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button onClick={() => props.setShowPopUp(false)} className="btn">Close</button>
          </>
        ) : props.toUpdateStatus ? (
          // className="popup-content"
          <div className="popup-content">
            <h3>{"Shortfall Details"}</h3>
            <p style={{ margin: "10px -15px 10px 0px" }}>
              <span style={{color:"red"}}>{`${props.amountDue}`}</span>
              {` amount is short of the remaining amount.  If you would like to keep the status as "Fully Paid", please provide reason for the shortfall`}
              </p>
            {/* <input
              type="text"
              placeholder="Reason"
              value={props.reason}
              onChange={(e) => props.setReason(e.target.value)}
            /> */}
            <TextField
              style={{width:"90%"}}
              id="outlined-multiline-static"
              label="Reason"
              value={props.reason}
              multiline
              rows={2}
              className="custom-textfield"
              onChange={(e) => props.setReason(e.target.value)}
            />
            <div className={styles.reasonButtonBox} style={{width:"100%"}}>
              <button className={`${styles.btn}`} onClick={() => props.setShowPopUp(false)}>Cancel</button>
              <button className={`${styles.btn} ${props.reason ? props.reason !== "" ? "" : styles.disabled : styles.disabled}`} onClick={props.updateInvoiceStatusOnly} >OK</button>
            </div>

          </div>
        ) : props.deleteData ?(<>
            <h3 style={{textAlign:"center"}} >{"Delete"}</h3>
            <p style={{textAlign:"center"}} >{`Are you sure want to delete the budget?`}</p>
              <div className={styles.reasonButtonBox} style={{ width: "100%" }}>
                <button className={`${styles.btn}`} onClick={() => props.setBudgetId(null)}>Cancel</button>
                <button onClick={() => props.deleteBudget(props.budgetId)} className={styles.btn} style={{ backgroundColor: "red", border: "2px solid red" }}>Delete</button>
              </div>
          </>) :(
          <>
            <h3>{"Transaction Mapped Successful"}</h3>
            <p>{`Payment transaction is mapped and saved with invoice ${props.invoiceNumber}`}</p>
            <button onClick={() => props.setShowPopUp(false)} className="btn">Ok</button>
          </>
        )}
      </div>

    </div>
  );
};

export default Popup;
