import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import UpwardCollapseIcon from "../../assets/icons/upwardCollapseIcon.svg";
import DownwardCollapseIcon from "../../assets/icons/downwardCollapseIcon.svg";
import TransactionTable from "../../components/Tables/paymentTable";
import { getFileData, getPaymentDetailByID } from '../../utils/api-helper';
import classes from "./pendingInvoice.module.css";

const TransactionDetails = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {
            getTransactionDetails: getTransactionDetails
        }
    })

    // const classes = useStyles();

    const [currentDate, setCurrentDate] = useState(new Date());
    const [isShowTable, setIsShowTable] = useState(true);
    const [rows, setRows] = useState([]);
    const [paidDate, setPaidDate] = useState()
    const [paidAmount, setPaidAmount] = useState()
    const [paidDetails, setPaidDetails] = useState()
    const [sortBy, setSortBy] = useState('DATE')
    const [sortOrder, setSortOrder] = useState('DESC')

    function createData (id, formattedDate, amount, transactionDetails, mapped) {
        const tempDate =  formattedDate.split(",")
        const date = tempDate[0].replaceAll("-","/")
        
        // const dateObject = new Date(formattedDate);
        // // Extract day, month, and year components
        // const day = dateObject.getUTCDate().toString().padStart(2, '0');
        // const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed, so adding 1
        // const year = dateObject.getUTCFullYear().toString();
        // // Format into "ddmm yyyy" format
        // const date = `${day}/${month}/${year}`;

        return { id, date, amount, transactionDetails, mapped }
    }

    function detailsVisibilityHandler () {
        setIsShowTable(!isShowTable);
    }
    function formatDate (dateString) {
        const date = new Date(dateString);

        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);

        return formattedDate;
    }

    function getTransactionDetails () {
        props.setStartLoader(true);
        getFileData(props.fileID, sortOrder, sortBy).then((res) => {
            setRows(res.data.map((row) =>
                createData(row.id, row.date, row.amount, row.description, row.mapStatus)
            ));
        }).catch((err) => {
        }).finally(()=>{
            props.setStartLoader(false);
        })
    }
    function formatFileName(){
        const modifiedString = props.fileName?.replace(/ /g, "_");
        
        // Split the string into parts using "." as a separator
        const parts = modifiedString?.split(".");
        
        // Extract the file name without extension and date
        const fileNameWithoutExtension = parts?.[0];
        const filename = `${fileNameWithoutExtension}.xlsx`
        return filename;
    }

    useEffect(() => {
        getTransactionDetails();
    }, []);

    useEffect(() => {
        getTransactionDetails();
    }, [sortBy, sortOrder]);


    useEffect(() => {
        if (props.paymentDetailID !== null) {
            getPaymentDetailByID(props.paymentDetailID).then((res) => {
                setPaidAmount(res.data.amount);
                setPaidDate(formatDate(res.data.date));
                setPaidDetails(res.data.description);
                setIsShowTable(false);
            }).catch((err) => {
            })
        } else {
            setPaidAmount();
            setPaidDate();
            setPaidDetails();
            setIsShowTable(true);
        }
    }, [props.fileID, props.paymentDetailID])

    const columns = [{ id: "date", label: "Date", align: 'left', width: 12 },
    { id: "amount", label: "Amount", width: 12 },
    { id: "transactionDetails", label: "Transaction\u00a0Details", width: 12 },
    ];

    return (<>
        <div className={classes.detailPage}>
            <div className={classes.uploadedSection}>
                <div className={classes.flex}>
                    Uploaded File: <p>{`${currentDate.toLocaleString('default', { month: 'long' })} ${currentDate.getFullYear()} recieved ${formatFileName()}`}</p>
                </div>
                <button onClick={detailsVisibilityHandler} style={{ cursor: "pointer" }}>
                    <p id={classes["allTrxn"]}>All Transactions</p> <img src={`${isShowTable ? UpwardCollapseIcon : DownwardCollapseIcon}`} alt="icon" style={{ padding: "2px 4px" }} />
                </button>
            </div>
            {isShowTable &&
                <TransactionTable
                    style={{ backgroundColor: "#c5c5c5" }}
                    paginationStyle={{
                        position: "fixed",
                        right: "20px",
                        bottom: "20px",
                        backgroundColor: "#ffffff",
                    }}
                    setSortOrder={setSortOrder}
                    sortOrder={sortOrder}
                    setsortInvoiceData={props.setsortInvoiceData}
                    columns={columns}
                    normalData={true}
                    rows={rows}
                    title={"transactionDetails"}
                    setRows={setRows}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    sortInvoiceData={props.sortInvoiceData}
                    setHoveredRowIndex={props.setHoveredRowIndex}
                    hoveredRowIndex={props.hoveredRowIndex}
                    selectedStatus={props.selectedStatus}
                    handlePaymentDetailCheck={props.handlePaymentDetailCheck}
                    setSelectedStatus={props.setSelectedStatus}
                    paymentDetailID={props.paymentDetailID}
                />
            }


            <div className={classes.detailHeading}>
                <div className={classes.flex}>
                    Transaction: <p>{`${paidDetails ? paidDetails : "--"}`}</p>
                </div>
                <button className={classes.transactionDetailBtn} onClick={detailsVisibilityHandler}>
                    <div style={{ display: "flex" }}>
                        <p>Transaction Details</p> <img src={`${!isShowTable ? UpwardCollapseIcon : DownwardCollapseIcon}`} alt="icon" style={{ padding: "2px" }} />
                    </div>
                </button>
            </div>
            {!isShowTable && <div className={classes.transactionDetail}>
                <div id={classes["dateAmount"]}>
                    <div>
                        Date <p>{paidDate}</p>
                    </div>
                    <div>
                        Amount <p>{paidAmount}</p>
                    </div>
                </div>

                <div id={classes["transaction"]}>
                    Transaction Details <p>{paidDetails}</p>
                </div>
            </div>}
        </div>
    </>
    )
})

export default TransactionDetails;