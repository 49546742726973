import React, { useRef, useState, useEffect, useContext } from "react";
import Navigation from "../../components/Navigation";
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box, Switch } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { responseData } from "./viewClient";
import { useLocation } from "react-router-dom";
import "../../assets/styles/addcontent.css";
import 'react-phone-number-input/style.css';
import {
    addClientAPI,
    getAllCountryAPI,
    getAllStateAPI,
    getAllCitiesAPI,
    getAllPaymentModeAPI,
    updateClientAPI,
    addProjectAPI,
} from "../../utils/api-helper";
import { useNavigate } from "react-router-dom";
import {
    validateEmail,
    validateTextOnly,
    validateClientId,
    validatePhoneNumberSequence,
    validateWebsiteUrl,
    validateLongTextWithSPace,
    validatePostalCode,
    validateGstinNumber,
    validateCharacterLimitNormalTextField,
    validateCharacterLimitLongTextField,
    validateCharacterLimitDescriptionBox,
    validateText,
} from "../../utils/input-validation";
import {
    requiredClientTypeErrorMessage,
    requiredClientIDErrorMessage,
    requiredfirstNameErrorMessage,
    requiredemailErrorMessage,
    firstNameErrorMessage,
    lastNameErrorMessage,
    altNameErrorMessage,
    emailErrorMessage,
    clientIdErrorMessage,
    countryErrorMessage,
    stateErrorMessage,
    cityErrorMessage,
    addressErrorMessage,
    zipCodeErrorMessage,
    validatezipCodeErrorMessage,
    validateGstinNumberErrorMessage,
    requiredphoneErrorMessage,
    contactNumberErrorMessage,
    faxNumberMessage,
    paymentModeErrorMessage,
    websiteUrlErrorMessage,
    validateAddressErrorMessage,
    validateCharacterLimitNormalTextFieldErrorMessage,
    validateCharacterLimitLongTextFieldErrorMessage,
    validateCharacterLimitDescriptionBoxErrorMessage,
    selectCountry,
    selectState,
    selectCity,
    selectPaymentMode,
} from "../../utils/status-message-helper";
import Select from "react-select";
import { countryCodeList, selectStatus } from "../../utils/constants";

export default function CreateNewClient (props) {
    const [clientFirstName, setClientFirstName] = useState("");
    const [clientLastName, setClientLastName] = useState("");
    const [clientId, setclientId] = useState("");
    const [clientEmail, setClientEmail] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [paymentModeId, setPaymentModeId] = useState("");
    const [clientType, setClientType] = useState("");

    const [contactPersonFirstName, setContactPersonFirstName] = useState("");
    const [contactPersonLastName, setContactPersonLastName] = useState("");
    const [alternativeName, setAlternativeName] = useState("");
    const [gstin, setGstin] = useState("");
    const [faxNo, setFaxNo] = useState("");
    const [website, setWebsite] = useState("");
    const [panNo, setPanNo] = useState("");
    const [status, setStatus] = useState(true);
    // Bill To address
    const [countryId, setCountryId] = useState("");
    const [countryLabel, setCountryLabel] = useState("");
    const [billToStateId, setBillToStateId] = useState("");
    const [billToStateLabel, setBillToStateLabel] = useState("");
    const [billToCityId, setBillToCityId] = useState("");
    const [billToCityLabel, setBillToCityLabel] = useState("");
    const [billToAddress1, setBillToAddress1] = useState("");
    const [billToAddress2, setBillToAddress2] = useState("");
    const [billToZip, setBillToZip] = useState("");
    // Ship To address 
    const [isBillToShipToAddressSame, setIsBillToShipToAddressSame] = useState(false);
    const [shipToStateId, setShipToStateId] = useState("");
    const [shipToStateLabel, setShipToStateLabel] = useState("");
    const [shipToCityId, setShipToCityId] = useState("");
    const [shipToCityLabel, setShipToCityLabel] = useState("");
    const [shipToAddress1, setShipToAddress1] = useState("");
    const [shipToAddress2, setShipToAddress2] = useState("");
    const [shipToZip, setShipToZip] = useState("");

    const [getAllCountry, setGetAllCountry] = useState([]);
    const [getAllStates, setGetAllStates] = useState([]);
    const [getAllBillToCities, setGetAllBillToCities] = useState([]);
    const [getAllShipToCities, setGetAllShipToCities] = useState([]);

    const userRef = useRef();
    const navigate = useNavigate();

    const [fnameErrMsg, setFnameErrMsg] = useState("");
    const [lnameErrMsg, setLnameErrMsg] = useState("");
    const [altNameErrMsg, setAltNameErrMsg] = useState("");
    const [clientIdErrMsg, setClientIdErrMsg] = useState("");
    const [emailErrMsg, setemailErrMsg] = useState("");
    const [contactPersonfnameErrMsg, setcontactPersonfnameErrMsg] = useState("");
    const [contactPersonlnameErrMsg, setcontactPersonlnameErrMsg] = useState("");
    const [gstinErrMsg, setGstinErrMsg] = useState("");
    const [panErrMsg, setpanErrMsg] = useState("");
    const [paymentModeErrMsg, setPaymentModeErrMsg] = useState("");
    const [contactNoErrMsg, setContactNoErrMsg] = useState("");
    const [faxNoErrMsg, setFaxNoErrMsg] = useState("");
    const [websiteErrMsg, setWebsiteErrMsg] = useState("");
    const [clientTypeErrMsg, setClientTypeErrMsg] = useState("");

    const [countryErrMsg, setCountryErrMsg] = useState("");
    const [billToStateErrMsg, setBillToStateErrMsg] = useState("");
    const [billToCityErrMsg, setBillToCityErrMsg] = useState("");
    const [billToAddress1ErrMsg, setBillToAddress1ErrMsg] = useState("");
    const [billToAddress2ErrMsg, setBillToAddress2ErrMsg] = useState("");
    const [billToZipCodeErrMsg, setBillToZipCodeErrMsg] = useState("");

    const [shipToStateErrMsg, setShipToStateErrMsg] = useState("");
    const [shipToCityErrMsg, setShipToCityErrMsg] = useState("");
    const [shipToAddress1ErrMsg, setShipToAddress1ErrMsg] = useState("");
    const [shipToAddress2ErrMsg, setShipToAddress2ErrMsg] = useState("");
    const [shipToZipCodeErrMsg, setShipToZipCodeErrMsg] = useState("");
    const [success, setSuccess] = useState(false);


    useEffect(() => {
        userRef.current.focus();
    }, []);

    var search = useLocation().search;
    useEffect(() => {
        if (props.isEditClient) {
            setEditClientData();
        } else {
            document.getElementById("setActiveStatus").disabled = true;
        }
    }, [props.isEditClient]);

    const setEditClientData = () => {
        setClientFirstName(responseData.firstName);
        setClientLastName(responseData.lastName);
        setclientId(responseData.clientId);
        setClientEmail(responseData.email);
        setContactNo(responseData.contactNo);
        setPaymentModeId(responseData.paymentModeId);
        setClientType(responseData.clientTypeId);
        setContactPersonFirstName(responseData.contactPersonFirstName);
        setContactPersonLastName(responseData.contactPersonLastName);
        setAlternativeName(responseData.alternateName);
        setGstin(responseData.gstin);
        setFaxNo(responseData.faxNumber);
        setWebsite(responseData.website);
        setPanNo(responseData.panNo);
        setStatus(responseData.isActive);
        setCountryId(responseData.countryId);
        setCountryAndGetStatesData(responseData.countryId)
        setBillToAddress1(responseData.billToAddress1);
        setBillToAddress2(responseData.billToAddress2);
        setBillToCityId(responseData.billToCityId);
        setBillToStateId(responseData.billToStateId);

        setBillToStatesAndGetCitiesdata(responseData.billToStateId);
        setBillToZip(responseData.billToZip);
        setShipToAddress1(responseData.shipToAddress1);
        setShipToAddress2(responseData.shipToAddress2);
        setShipToCityId(responseData.shipToCityId);
        setShipToStateId(responseData.shipToStateId);
        setIsBillToShipToAddressSame(responseData.isBillToAndShipToAddressSame)

        setShipToStatesAndGetCitiesdata(responseData.shipToStateId);
        setShipToZip(responseData.shipToZip);
    };

    function createData (id, value) {
        return { value: id, label: value };
    }
    const setCountryAndGetStatesData = (row) => {
        props.setStartLoader(true);
        setBillToStateId(null);
        setBillToCityId(null);
        setShipToStateId(null);
        setShipToCityId(null);
        setGetAllStates([]);
        setGetAllBillToCities([]);
        setGetAllShipToCities([]);
        setCountryId(row);
        getAllStateAPI(row)
            .then((res) => {
                setGetAllStates(
                    res?.data.map((row, index) => createData(row.id, row.stateName))
                );
                props.setStartLoader(false);
            })
            .catch((err) => {
                // console.log(err);
                props.setStartLoader(false);
            });
    };
    const setBillToStatesAndGetCitiesdata = (row) => {
        props.setStartLoader(true);
        if (!props.isEditClient) {
            setBillToCityId(null);
        }
        setGetAllBillToCities([]);
        setBillToStateId(row);
        getAllCitiesAPI(row)
            .then((res) => {
                setGetAllBillToCities(
                    res?.data.map((row, index) => createData(row.id, row.cityName))
                );
                props.setStartLoader(false);
            })
            .catch((err) => {
                // console.log(err);
                props.setStartLoader(false);
            });
    };
    const setShipToStatesAndGetCitiesdata = (row) => {
        props.setStartLoader(true);
        if (!props.isEditClient) {
            setShipToCityId(null);
        }
        setGetAllShipToCities([]);
        setShipToStateId(row);
        getAllCitiesAPI(row)
            .then((res) => {
                setGetAllShipToCities(
                    res?.data.map((row, index) => createData(row.id, row.cityName))
                );
                props.setStartLoader(false);
            })
            .catch((err) => {
                props.setStartLoader(false);
                // console.log(err);
            });
    };

    const handleCreateClientSubmit = (e) => {
        e.preventDefault();
        props.setStartLoader(true);
        if (validateForm()) {
            const body = {
                "firstName": clientFirstName,
                "lastName": clientLastName,
                "contactPersonFirstName": contactPersonFirstName,
                "contactPersonLastName": contactPersonLastName,
                "alternateName": alternativeName,
                // clientId: clientId,
                "clientId": "1324-AB",
                "clientTypeId": clientType,
                "contactNo": contactNo,
                "email": clientEmail,
                "faxNumber": faxNo,
                "gstin": gstin,
                "isActive": true,
                "panNo": panNo,
                "paymentModeId": paymentModeId,
                "website": website,
                "countryId": countryId,
                "billToAddress1": billToAddress1,
                "billToAddress2": billToAddress2,
                "billToCityId": billToCityId,
                "billToStateId": billToStateId,
                "billToZip": billToZip,
                "shipToAddress1": shipToAddress1,
                "shipToAddress2": shipToAddress2,
                "shipToCityId": shipToCityId,
                "shipToStateId": shipToStateId,
                "shipToZip": shipToZip,
                "isBillToAndShipToAddressSame": isBillToShipToAddressSame
            };
            addClientAPI(body)
                .then((res) => {
                    setSuccess(true);
                    props.getAllClients();
                    props.setStartLoader(false);
                })
                .catch((err) => {
                    props.setAlertPopupMessage(err.response?.data.message);
                    props.setAlertPopupWindow({ is: true, type: 'alert' });
                    setSuccess(false);
                    props.setStartLoader(false);
                });
        } else {
            props.setStartLoader(false);
            // validateForm();
        }
    };

    const handleEditClientSubmit = (e) => {
        e.preventDefault();
        props.setStartLoader(true);
        var editClientId = new URLSearchParams(search).get("id");
        if (validateForm()) {
            const body = {
                id: editClientId,
                "alternateName": alternativeName,
                "billToAddress1": billToAddress1,
                "billToAddress2": billToAddress2,
                "billToCityId": billToCityId,
                "billToStateId": billToStateId,
                "billToZip": billToZip,
                // "clientId": clientId,
                "clientId": "1234-AB",
                "clientTypeId": clientType,
                "contactNo": contactNo,
                "contactPersonFirstName": contactPersonFirstName,
                "contactPersonLastName": contactPersonLastName,
                "countryId": countryId,
                "email": clientEmail,
                "faxNumber": faxNo,
                "firstName": clientFirstName,
                "gstin": gstin,
                "isActive": status,
                "lastName": clientLastName,
                "panNo": panNo,
                "paymentModeId": paymentModeId,
                "shipToAddress1": shipToAddress1,
                "shipToAddress2": shipToAddress2,
                "shipToCityId": shipToCityId,
                "shipToStateId": shipToStateId,
                "shipToZip": shipToZip,
                "website": website,
                "isBillToAndShipToAddressSame": isBillToShipToAddressSame
            }
            updateClientAPI(body)
                .then((res) => {
                    setSuccess(true);
                    props.getAllClients();
                    props.setStartLoader(false);
                })
                .catch((err) => {
                    props.setAlertPopupMessage(err.response?.data.message);
                    props.setAlertPopupWindow({ is: true, type: 'alert' });
                    setSuccess(false);
                    props.setStartLoader(false);
                });
        } else {
            props.setStartLoader(false);
        }
    };

    function checkFirstName () {
        if (clientFirstName) {
            if (validateText(clientFirstName)) {
                if (validateCharacterLimitNormalTextField(clientFirstName)) {
                    setFnameErrMsg("");
                    return true;
                } else {
                    setFnameErrMsg(validateCharacterLimitNormalTextFieldErrorMessage);
                    return false;
                }
            } else {
                setFnameErrMsg(firstNameErrorMessage);
                return false;
            }
        } else {
            setFnameErrMsg(requiredfirstNameErrorMessage);
            return false;
        }
    }
    function checkLastName () {
        if (clientLastName) {
            if (validateText(clientLastName)) {
                if (validateCharacterLimitNormalTextField(clientLastName)) {
                    setLnameErrMsg("");
                    return true;
                } else {
                    setLnameErrMsg(validateCharacterLimitNormalTextFieldErrorMessage);
                    return false;
                }
            } else {
                setLnameErrMsg(lastNameErrorMessage);
                return false;
            }
        } else {
            setLnameErrMsg("");
            return true;
        }
    }
    function checkAltName () {
        if (alternativeName) {
            if (validateText(alternativeName)) {
                if (validateCharacterLimitNormalTextField(alternativeName)) {
                    setAltNameErrMsg("");
                    return true;
                } else {
                    setAltNameErrMsg(validateCharacterLimitNormalTextFieldErrorMessage);
                    return false;
                }
            } else {
                setAltNameErrMsg(altNameErrorMessage);
                return false;
            }
        } else {
            setAltNameErrMsg("");
            return true;
        }
    }
    // function checkClientId() {
    //     if (clientId) {
    //         if (validateClientId(clientId)) {
    //             setClientIdErrMsg("");
    //             return true;
    //         } else {
    //             setClientIdErrMsg(clientIdErrorMessage);
    //             return false;
    //         }
    //     } else {
    //         setClientIdErrMsg(requiredClientIDErrorMessage);
    //         return false;
    //     }
    // }
    function checkClientEmail () {
        if (clientEmail) {
            if (validateEmail(clientEmail)) {
                setemailErrMsg("");
                return true;
            } else {
                setemailErrMsg(emailErrorMessage);
                return false;
            }
        } else {
            setemailErrMsg("");
            return true;
        }
    }
    function checkCountry () {
        if (countryId) {
            setCountryErrMsg("");
            return true;
        } else {
            setCountryErrMsg(countryErrorMessage);
            return false;
        }
    }

    function checkState (){
        if(true){
            setBillToStateErrMsg("");
        }else{
            setBillToStateErrMsg(stateErrorMessage);
        }
    }
    function checkBillToState() {
        if (billToStateId) {
            setBillToStateErrMsg("");
            return true;
        } else {
            setBillToStateErrMsg(stateErrorMessage);
            return false;
        }
    }
    // function checkShipToState() {
    //     if (billToStateId) {
    //         setShipToStateErrMsg("");
    //         return true;
    //     } else {
    //         setShipToStateErrMsg(stateErrorMessage);
    //         return false;
    //     }
    // }
    // function checkBillToCity() {
    //     if (billToCityId) {
    //         setBillToCityErrMsg("");
    //         return true;
    //     } else {
    //         setBillToCityErrMsg(cityErrorMessage);
    //         return false;
    //     }
    // }
    // function checkShipToCity() {
    //     if (shipToCityId) {
    //         setShipToCityErrMsg("");
    //         return true;
    //     } else {
    //         setShipToCityErrMsg(cityErrorMessage);
    //         return false;
    //     }
    // }
    function checkBillToAddress1 () {
        if (billToAddress1) {
            if (validateText(billToAddress1)) {
                if (validateCharacterLimitLongTextField(billToAddress1)) {
                    setBillToAddress1ErrMsg("");
                    return true;
                } else {
                    setBillToAddress1ErrMsg(validateCharacterLimitLongTextFieldErrorMessage);
                    return false;
                }
            } else {
                setBillToAddress1ErrMsg(validateAddressErrorMessage);
                return false;
            }
        } else {
            setBillToAddress1ErrMsg("");
            return true;
        }
    }
    function checkShipToAddress1 () {
        if (shipToAddress1) {
            if (validateText(shipToAddress1)) {
                if (validateCharacterLimitLongTextField(shipToAddress1)) {
                    setShipToAddress1ErrMsg("");
                    return true;
                } else {
                    setShipToAddress1ErrMsg(validateCharacterLimitLongTextFieldErrorMessage);
                    return false;
                }
            } else {
                setShipToAddress1ErrMsg(validateAddressErrorMessage);
                return false;
            }
        } else {
            setShipToAddress1ErrMsg("");
            return true;
        }
    }
    function checkBillToAddress2 () {
        if (billToAddress2) {
            if (validateText(billToAddress2)) {
                if (validateCharacterLimitLongTextField(billToAddress2)) {
                    setBillToAddress2ErrMsg("");
                    return true;
                } else {
                    setBillToAddress2ErrMsg(validateCharacterLimitLongTextFieldErrorMessage);
                    return false;
                }
            } else {
                setBillToAddress2ErrMsg(validateAddressErrorMessage);
                return false;
            }
        } else {
            setBillToAddress2ErrMsg("");
            return true;
        }
    }
    function checkShipToAddress2 () {
        if (shipToAddress2) {
            if (validateText(shipToAddress2)) {
                if (validateCharacterLimitLongTextField(shipToAddress2)) {
                    setShipToAddress2ErrMsg("");
                    return true;
                } else {
                    setShipToAddress2ErrMsg(validateCharacterLimitLongTextFieldErrorMessage);
                    return false;
                }
            } else {
                setShipToAddress2ErrMsg(validateAddressErrorMessage);
                return false;
            }
        } else {
            setShipToAddress2ErrMsg("");
            return true;
        }
    }
    // function checkBillToZipCode() {
    //     if (billToZip) {
    //         if (validatePostalCode(billToZip)) {
    //             setBillToZipCodeErrMsg("");
    //             return true;
    //         } else {
    //             setBillToZipCodeErrMsg(validatezipCodeErrorMessage);
    //             return false;
    //         }
    //     } else {
    //         setBillToZipCodeErrMsg("");
    //         return true;
    //     }
    // }
    // function checkShipToZipCode() {
    //     if (shipToZip) {
    //         if (validatePostalCode(shipToZip)) {
    //             setShipToZipCodeErrMsg("");
    //             return true;
    //         } else {
    //             setShipToZipCodeErrMsg(validatezipCodeErrorMessage);
    //             return false;
    //         }
    //     } else {
    //         setShipToZipCodeErrMsg("");
    //         return true;
    //     }
    // }
    function checkContactPersonFirstName () {
        if (contactPersonFirstName) {
            if (validateText(contactPersonFirstName)) {
                if (validateCharacterLimitNormalTextField(contactPersonFirstName)) {
                    setcontactPersonfnameErrMsg("");
                    return true;
                } else {
                    setcontactPersonfnameErrMsg(
                        validateCharacterLimitNormalTextFieldErrorMessage
                    );
                    return false;
                }
            } else {
                setcontactPersonfnameErrMsg(firstNameErrorMessage);
                return false;
            }
        } else {
            setcontactPersonfnameErrMsg("");
            return true;
        }
    }
    function checkContactPersonLastName () {
        if (contactPersonLastName) {
            if (validateText(contactPersonLastName)) {
                if (validateCharacterLimitNormalTextField(contactPersonLastName)) {
                    setcontactPersonlnameErrMsg("");
                    return true;
                } else {
                    setcontactPersonlnameErrMsg(
                        validateCharacterLimitNormalTextFieldErrorMessage
                    );
                    return false;
                }
            } else {
                setcontactPersonlnameErrMsg(lastNameErrorMessage);
                return false;
            }
        } else {
            setcontactPersonlnameErrMsg("");
            return true;
        }
    }
    function checkContactNumber () {
        if (contactNo) {
            if (formatPhoneNumber(contactNo)) {
                setContactNoErrMsg("");
                return true;
            } else {
                setContactNoErrMsg(contactNumberErrorMessage);
                return false;
            }
        } else {
            setContactNoErrMsg("");
            return true;
        }
    }
    function checkFaxNumber () {
        if (faxNo) {
            if (formatPhoneNumber(faxNo)) {
                setFaxNoErrMsg("");
                return true;
            } else {
                setFaxNoErrMsg(faxNumberMessage);
                return false;
            }
        } else {
            setFaxNoErrMsg("");
            return true;
        }
    }
    function checkpaymentmode () {
        if (paymentModeId) {
            setPaymentModeErrMsg("");
            return true;
        } else {
            setPaymentModeErrMsg(paymentModeErrorMessage);
            return false;
        }
    }
    function checkWebsiteUrl () {
        if (website) {
            if (validateWebsiteUrl(website)) {
                setWebsiteErrMsg("");
                return true;
            } else {
                setWebsiteErrMsg(websiteUrlErrorMessage);
                return false;
            }
        } else {
            setWebsiteErrMsg("");
            return true;
        }
    }
    function checkClientType () {
        if (clientType) {
            setClientTypeErrMsg("");
            return true;
        } else {
            setClientTypeErrMsg(requiredClientTypeErrorMessage);
            return false;
        }
    }
    function validateForm () {
        let isValid = true;
        if (
            checkFirstName() &&
            // checkLastName() &&
            // checkClientId() &&
            checkAltName() &&
            checkClientEmail() &&
            checkCountry() &&
            checkBillToState() &&
            // checkShipToState() &&
            // checkBillToCity() &&
            // checkShipToCity() &&
            checkBillToAddress1() &&
            checkShipToAddress1() &&
            checkBillToAddress2() &&
            checkShipToAddress2() &&
            checkContactPersonFirstName() &&
            checkContactPersonLastName() &&
            checkContactNumber() &&
            checkFaxNumber() &&
            checkpaymentmode() &&
            checkWebsiteUrl() &&
            checkClientType()
        ) {
            isValid = true;
        } else {
            checkFirstName();
            checkAltName();
            checkClientEmail();
            checkCountry();
            checkBillToState();
            checkBillToAddress1();
            checkShipToAddress1();
            checkBillToAddress2();
            checkShipToAddress2();
            checkContactPersonFirstName();
            checkContactPersonLastName();
            checkContactNumber();
            checkFaxNumber();
            checkpaymentmode();
            checkWebsiteUrl();
            checkClientType();
            isValid = false;
        }
        return isValid;
    }

    useEffect(() => {
        if (billToStateId) {
            ((billToStateId == shipToStateId) && (billToCityId == shipToCityId)) ? setIsBillToShipToAddressSame(true) : setIsBillToShipToAddressSame(false)
        }
        else {
            setIsBillToShipToAddressSame(false);
        };
    }, [billToStateId, billToCityId , shipToStateId , shipToCityId])

    const handleCheckbox = (e) => {
        if (e.target.checked) {
            setIsBillToShipToAddressSame(true);
            setShipToAddress1(billToAddress1);
            setShipToAddress2(billToAddress2);
            setShipToStateId(billToStateId);
            setShipToStateLabel(billToStateLabel);
            setShipToCityLabel(billToCityLabel);
            setShipToCityId(billToCityId);
            setShipToZip(billToZip);
            setGetAllShipToCities(getAllBillToCities);
        } else {
            setIsBillToShipToAddressSame(false);
            setShipToAddress1("");
            setShipToAddress2("");
            setShipToStateId("");
            setShipToCityId("");
            setShipToStateLabel("");
            setShipToCityLabel("");
            setShipToZip("");
        }
    }
    const handleAddress1Change = (event) => {
        if (isBillToShipToAddressSame) {
            setBillToAddress1(event.target.value);
            setShipToAddress1(event.target.value);
        } else {
            setBillToAddress1(event.target.value);
        }
    }
    const handleAddress2Change = (event) => {
        if (isBillToShipToAddressSame) {
            setBillToAddress2(event.target.value);
            setShipToAddress2(event.target.value);
        } else {
            setBillToAddress2(event.target.value);
        }
    }
    const handleZip2Change = (event) => {
        if (isBillToShipToAddressSame) {
            setBillToZip(event.target.value);
            setShipToZip(event.target.value);
        } else {
            setBillToZip(event.target.value);
        }
    }
    const setCountryForPhone = (countryName) => {
        let country = "";
        if(countryName){
            country = countryName.toLocaleUpperCase().substr(0, 2);
        }
        
        return country;
    }

    return (
        <div className="inner-content-formData">
            <form
                className="form"
                method="POST"
                id="AddClient"
                onSubmit={
                    props.isEditClient ? handleEditClientSubmit : handleCreateClientSubmit
                }
            >
                <div className="form-container">
                    <div className="flex-form-container">
                        {/* <div className="form_message form_message-error">
                            <p className={clientTypeErrMsg ? "errmsg" : "offscreen"} aria-live="assertive">This is an error message from backend</p>
                            </div> */}
                        {/* <div className="input-flex clientName"> */}

                        {/* <div className="clientLastName form_input right-flex">
                                <div className="inputLabel left-flex">
                                    <label>Last Name</label>
                                </div>
                                <input
                                    type="text"
                                    id="clientLastName"
                                    placeholder="Last Name"
                                    name="clientLastName"
                                    onChange={(e) => setClientLastName(e.target.value)}
                                    value={clientLastName}
                                />
                                <div className="form_message form_message-error">
                                    <p
                                        className={lnameErrMsg ? "errmsg" : "offscreen"}
                                        aria-live="assertive"
                                    >
                                        {lnameErrMsg}
                                    </p>
                                </div>
                            </div> */}
                        {/* </div> */}

                        {/* <div className="clientId form_input">
                            <div className="inputLabel left-flex">
                                <label>
                                    Client ID<span className="errmsg">*</span> :
                                </label>
                            </div>
                            <input
                                type="text"
                                id="clientId"
                                placeholder="Client ID"
                                name="clientId"
                                onChange={(e) => setclientId(e.target.value)}
                                value={clientId}
                            />
                            <div className="form_message form_message-error">
                                <p
                                    className={clientIdErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {clientIdErrMsg}
                                </p>
                            </div>
                            </div> */}
                        <div className="clientFirstName form_input">
                            <Box>
                                <label className="switchLabel" >Client Status:</label>
                                <FormControlLabel label={status ? 'Active' : 'Change Status'}
                                    control={<Switch
                                        id="setActiveStatus"
                                        onChange={(e) => {
                                            setStatus(e.target.checked);
                                        }}
                                        checked={status}
                                        value={status}
                                        defaultValue={
                                            props.isEditClient
                                                ? {
                                                    label: responseData.isActive ? "Active" : "Inactive",
                                                    value: responseData.isActive,
                                                }
                                                : {
                                                    label: "Active",
                                                    value: true,
                                                }
                                        } />} />
                            </Box>
                            {/* <div className="status form_input">
                                <div className="inputLabel left-flex">
                                    <label>Status:</label>
                                </div>
                                <Select
                                    type="text"
                                    id="setActiveStatus"
                                    styles={{ width: "100%", float: "left" }}
                                    placeholder={
                                        props.isEditClient ? (
                                            "Active"
                                        ) : (
                                            <div className="select-placeholder-text">Select Status</div>
                                        )
                                    }
                                    defaultValue={
                                        props.isEditClient
                                            ? {
                                                label: responseData.isActive ? "Active" : "Inactive",
                                                value: responseData.isActive,
                                            }
                                            : {
                                                label: "Active",
                                                value: true,
                                            }
                                    }
                                    name="status"
                                    options={selectStatus}
                                    onChange={(e) => setStatus(e.value)}
                                    isDisabled={props.isEditClient ? false : true}
                                />
                            </div> */}
                            <div className="inputLabel left-flex">
                                <label>
                                    Client Name<span className="errmsg">*</span>:
                                </label>
                            </div>
                            <input
                                type="text"
                                id="clientFirstName"
                                placeholder="First Name"
                                name="clientFirstName"
                                onChange={(e) => setClientFirstName(e.target.value)}
                                ref={userRef}
                                value={clientFirstName}
                            />
                            <div className="form_message form_message-error left-flex">
                                <p
                                    className={fnameErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {fnameErrMsg}
                                </p>
                            </div>
                        </div>

                        <div className="clientType form_input">
                            <div className="inputLabel left-flex">
                                <label>
                                    Client Type<span className="errmsg">*</span>:
                                </label>
                            </div>
                            <Select
                                type="text"
                                id="clientType"
                                styles={{ width: "100%", float: "left" }}
                                placeholder={
                                    <div className="select-placeholder-text">
                                        Select Client Type
                                    </div>
                                }
                                defaultValue={
                                    props.isEditClient
                                        ? {
                                            value: responseData.clientTypeId,
                                            label: responseData.clientTypeName,
                                        }
                                        : ""
                                }
                                name="clientType"
                                options={props.clientTypeOptions}
                                isClearable={true}
                                onChange={(e) => {
                                    if (!e) {
                                        e = { value: "" }
                                    }
                                    setClientType(e.value)
                                }}
                            />
                            <div className="form_message form_message-error">
                                <p
                                    className={clientTypeErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {clientTypeErrMsg}
                                </p>
                            </div>
                        </div>

                        <div className="clientEmail form_input">
                            <div className="inputLabel left-flex">
                                <label>
                                    Email:
                                </label>
                            </div>
                            <input
                                type="text"
                                id="clientEmail"
                                placeholder="Email"
                                name="clientEmail"
                                onChange={(e) => setClientEmail(e.target.value)}
                                value={clientEmail}
                            />
                            <div className="form_message form_message-error">
                                <p
                                    className={emailErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {emailErrMsg}
                                </p>
                            </div>
                        </div>
                        <div className="contactNo form_input">

                            {/* <div className="inputLabel left-flex">
                                <label>
                                
                                    Contact No:
                                </label>
                            </div> */}
                            <div className="inputLabel left-flex">
                                <label>
                                    Contact No:
                                </label>
                            </div>
                            <div className="inputLabel left-flex">
                                <PhoneInput
                                    // className="inputLabel left-flex"
                                    id="contactNo"
                                    placeholder="Enter Contact No."
                                    name="contactNo"
                                    value={contactNo}
                                    // countries={["AU","IN","US"]}
                                    onChange={setContactNo}
                                    country={setCountryForPhone(countryLabel)}
                                    defaultCountry={props.isEditClient ? setCountryForPhone(responseData.countryName) : "US"}
                                />
                            </div>


                            {/* <input
                                type="text"
                                id="contactNo"
                                placeholder="Contact No"
                                name="contactNo"
                                onChange={(e) => setContactNo(e.target.value)}
                                value={contactNo}
                            /> */}
                            <div className="form_message form_message-error">
                                <p
                                    className={contactNoErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {contactNoErrMsg}
                                </p>
                            </div>
                        </div>
                        <div className="faxNo form_input">
                            <div className="inputLabel left-flex website">
                                <label>Fax No:</label>
                            </div>
                            <div className="inputLabel left-flex">
                                <PhoneInput
                                    id="faxNo"
                                    placeholder="Enter Fax No."
                                    name="faxNo"
                                    value={faxNo}
                                    onChange={setFaxNo}
                                    country={setCountryForPhone(countryLabel)}
                                    defaultCountry={props.isEditClient ? setCountryForPhone(responseData.countryName) : "US"}
                                />
                            </div>
                            {/* <input
                                type="text"
                                id="faxNo"
                                placeholder="Fax No."
                                name="faxNo"
                                onChange={(e) => setFaxNo(e.target.value)}
                                value={faxNo}
                            /> */}
                            <div className="form_message form_message-error">
                                <p
                                    className={faxNoErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {faxNoErrMsg}
                                </p>
                            </div>
                        </div>
                        <div className="website form_input">
                            <div className="inputLabel left-flex website">
                                <label>Website:</label>
                            </div>
                            <input
                                type="text"
                                id="website"
                                placeholder="Website"
                                name="website"
                                onChange={(e) => setWebsite(e.target.value)}
                                value={website}
                            />
                            <div className="form_message form_message-error">
                                <p
                                    className={websiteErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {websiteErrMsg}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex-form-container client-details">
                        <div className="input-flex contactPersonName" style={{width: "100%"}}>
                            <div className="contactPersonFirstName form_input">
                                <div className="inputLabel left-flex">
                                    <label>Contact Person Name:</label>
                                </div>
                                <input
                                    type="text"
                                    id="contactPersonFirstName"
                                    placeholder="First Name"
                                    name="contactPersonFirstName"
                                    onChange={(e) => setContactPersonFirstName(e.target.value)}
                                    value={contactPersonFirstName}
                                />
                                <div className="form_message form_message-error">
                                    <p
                                        className={
                                            contactPersonfnameErrMsg ? "errmsg" : "offscreen"
                                        }
                                        aria-live="assertive"
                                    >
                                        {contactPersonfnameErrMsg}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="contactPersonLastName form_input" style={{marginTop:"15px", marginBottom:"-5px"}}>
                                <div className="inputLabel left-flex">
                                    <label>Last Name:</label>
                                </div>
                                <input
                                    type="text"
                                    id="contactPersonLastName"
                                    placeholder="Last Name"
                                    name="contactPersonLastName"
                                    onChange={(e) => setContactPersonLastName(e.target.value)}
                                    value={contactPersonLastName}
                                />
                                <div className="form_message form_message-error">
                                    <p
                                        className={
                                            contactPersonlnameErrMsg ? "errmsg" : "offscreen"
                                        }
                                        aria-live="assertive"
                                    >
                                        {contactPersonlnameErrMsg}
                                    </p>
                                </div>
                            </div>
                        <div className="alternativeName form_input">
                            <div className="inputLabel left-flex altMargin">
                                <label>Alternative Name:</label>
                            </div>
                            <input
                                type="text"
                                id="alternativeName"
                                placeholder="Alternative Name"
                                name="alternativeName"
                                onChange={(e) => setAlternativeName(e.target.value)}
                                value={alternativeName}
                            />
                            <div className="form_message form_message-error">
                                <p
                                    className={altNameErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {altNameErrMsg}
                                </p>
                            </div>
                        </div>
                        <div className="paymentMode form_input">
                            <div className="inputLabel left-flex">
                                <label>
                                    Payment Mode<span className="errmsg">*</span>:
                                </label>
                            </div>
                            <Select
                                type="text"
                                id="paymentMode"
                                styles={{ width: "100%", float: "left" }}
                                placeholder={
                                    <div className="select-placeholder-text">
                                        Select Payment Mode
                                    </div>
                                }
                                defaultValue={
                                    props.isEditClient
                                        ? {
                                            value: responseData.paymentModeId,
                                            label: responseData.paymentMode,
                                        }
                                        : ""
                                }
                                name="paymentMode"
                                options={props.paymentMode}
                                isClearable={true}
                                onChange={(e) => {
                                    if (!e) {
                                        e = { value: "" }
                                    }
                                    setPaymentModeId(e.value)
                                }}
                            />
                            <div className="form_message form_message-error">
                                <p
                                    className={paymentModeErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {paymentModeErrMsg}
                                </p>
                            </div>
                        </div>
                        <div className="gstin form_input">
                            <div className="inputLabel left-flex">
                                <label>GSTIN:</label>
                            </div>
                            <input
                                type="text"
                                id="gstin"
                                placeholder="GSTIN"
                                name="gstin"
                                onChange={(e) => setGstin(e.target.value)}
                                value={gstin}
                            />
                            <div className="form_message form_message-error">
                                <p
                                    className={gstinErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {gstinErrMsg}
                                </p>
                            </div>
                        </div>
                        <div className="panNo form_input">
                            <div className="inputLabel left-flex">
                                <label>PAN:</label>
                            </div>
                            <input
                                type="text"
                                id="panNo"
                                placeholder="PAN"
                                name="panNo"
                                onChange={(e) => setPanNo(e.target.value)}
                                value={panNo}
                            />
                            <div className="form_message form_message-error">
                                <p
                                    className={panErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {panErrMsg}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-container">
                    <div className="flex-form-container client-details">
                        <div className="formTitle">
                            <p>Bill To :</p>
                        </div>
                        <div className="country form_input">
                            <div className="inputLabel left-flex">
                                <label>
                                    Country<span className="errmsg">*</span>:
                                </label>
                            </div>
                            <Select
                                type="text"
                                id="country"
                                styles={{ width: "100%", float: "left" }}
                                placeholder={
                                    <div className="select-placeholder-text">Select Country</div>
                                }
                                defaultValue={
                                    props.isEditClient
                                        ? {
                                            value: responseData.countryId,
                                            label: responseData.countryName,
                                        }
                                        : ""
                                }
                                name="country"
                                options={props.country}
                                isClearable={true}
                                onChange={(e) => {
                                    if (!e) {
                                        e = { value: "" }
                                    }
                                    // console.log(e)
                                    setCountryId(e.value);
                                    setCountryLabel(e.label);
                                    setCountryAndGetStatesData(e.value)
                                }}
                            />
                            <div className="form_message form_message-error">
                                <p
                                    className={countryErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {countryErrMsg}
                                </p>
                            </div>
                        </div>
                        <div className="billToState form_input">
                            <div className="inputLabel left-flex">
                                <label>
                                    State<span className="errmsg">*</span>:
                                </label>
                            </div>
                            <Select
                                type="text"
                                id="state"
                                styles={{ width: "100%", float: "left" }}
                                placeholder={
                                    <div className="select-placeholder-text">Select State</div>
                                }
                                defaultValue={
                                    props.isEditClient
                                        ? {
                                            value: responseData.billToStateId,
                                            label: responseData.billToStateName,
                                        }
                                        : ""
                                }
                                name="state"
                                options={getAllStates}
                                isClearable={true}
                                onChange={(e) => {
                                    if (!e) {
                                        e = { value: "" }
                                    }
                                    setBillToStatesAndGetCitiesdata(e.value)
                                    setBillToStateLabel(e.label);
                                }}
                            />
                            <div className="form_message form_message-error left-flex">
                                <p
                                    className={billToStateErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {billToStateErrMsg}
                                </p>
                            </div>
                        </div>
                        <div className="billToCity form_input">
                            <div className="inputLabel left-flex">
                                <label>
                                    City:
                                </label>
                            </div>
                            <Select
                                type="text"
                                id="city"
                                styles={{ width: "100%", float: "left" }}
                                placeholder={
                                    <div className="select-placeholder-text">Select City</div>
                                }
                                defaultValue={
                                    props.isEditClient
                                        ? {
                                            value: responseData.billToCityId,
                                            label: responseData.billToCityName,
                                        }
                                        : ""
                                }
                                name="city"
                                options={getAllBillToCities}
                                isClearable={true}
                                onChange={(e) => {
                                    if (!e) {
                                        e = { value: "" }
                                    }
                                    setBillToCityLabel(e.label);
                                    setBillToCityId(e.value);
                                    // console.log(e)
                                }}
                            />
                            <div className="form_message form_message-error left-flex">
                                <p
                                    className={billToCityErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {billToCityErrMsg}
                                </p>
                            </div>
                        </div>
                        <div className="billToAddress1 form_input">
                            <div className="inputLabel left-flex">
                                <label>
                                    Address 1:
                                </label>
                            </div>
                            <input
                                type="text"
                                id="billToAddress1"
                                placeholder="Address 1"
                                name="billToAddress1"
                                // onChange={(e) => setBillToAddress1(e.target.value)}
                                onChange={handleAddress1Change}
                                value={billToAddress1}
                            />
                            <div className="form_message form_message-error">
                                <p
                                    className={billToAddress1ErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {billToAddress1ErrMsg}
                                </p>
                            </div>
                        </div>
                        <div className="billToAddress2 form_input">
                            <div className="inputLabel left-flex">
                                <label>Address 2:</label>
                            </div>
                            <input
                                type="text"
                                id="billToAddress2"
                                placeholder="Address 2"
                                name="billToAddress2"
                                // onChange={(e) => setBillToAddress2(e.target.value)}
                                onChange={handleAddress2Change}
                                value={billToAddress2}
                            />
                            <div className="form_message form_message-error">
                                <p
                                    className={billToAddress2ErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {billToAddress2ErrMsg}
                                </p>
                            </div>
                        </div>
                        <div className="billToZip form_input">
                            <div className="inputLabel left-flex">
                                <label>Zip Code:</label>
                            </div>
                            <input
                                type="text"
                                id="billToZip"
                                placeholder="bill to zip code"
                                name="billToZip"
                                onChange={handleZip2Change}
                                value={billToZip}
                            />
                            <div className="form_message form_message-error">
                                <p
                                    className={billToZipCodeErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {billToZipCodeErrMsg}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex-form-container shipTo">
                        <div className="formTitle" style={{marginLeft:"-10px"}}>
                            <p>Ship To :</p>
                            <div className="shipto-checkbox">
                                <FormControlLabel
                                    sx={{ marginLeft: "auto" }}
                                    control={<Checkbox sx={{ padding: "0px" }} onChange={(e) => handleCheckbox(e)} checked={isBillToShipToAddressSame} />}
                                    label="&nbsp;is ship to address same as bill to address."
                                />
                            </div>
                        </div>
                        <div className="shipToState form_input">
                            <div className="inputLabel left-flex">
                                <label>
                                    State:
                                </label>
                            </div>
                            <Select
                                type="text"
                                id="state"
                                styles={{ width: "100%", float: "left" }}
                                placeholder={
                                    <div className="select-placeholder-text">Select State</div>
                                }
                                defaultValue={
                                    props.isEditClient
                                        ? {
                                            value: responseData.shipToStateId,
                                            label: responseData.shipToStateName,
                                        } : isBillToShipToAddressSame ? {
                                            value: billToStateId,
                                            label: billToStateLabel,
                                        } : ""
                                }
                                name="state"
                                options={getAllStates}
                                value={getAllStates.filter(option => option.value === shipToStateId)}
                                isClearable={true}
                                onChange={(e) => {
                                    if (!e) {
                                        e = { value: "", label: "" }
                                    }
                                    setShipToStatesAndGetCitiesdata(e.value)
                                }}
                            />
                            <div className="form_message form_message-error left-flex">
                                <p
                                    className={shipToStateErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {shipToStateErrMsg}
                                </p>
                            </div>
                        </div>
                        <div className="shipTocity form_input">
                            <div className="inputLabel left-flex">
                                <label>
                                    City:
                                </label>
                            </div>
                            <Select
                                type="text"
                                id="city"
                                styles={{ width: "100%", float: "left" }}
                                placeholder={
                                    <div className="select-placeholder-text">Select City</div>
                                }
                                defaultValue={
                                    props.isEditClient
                                        ? {
                                            value: responseData.shipToCityId,
                                            label: responseData.shipToCityName,
                                        }

                                        : isBillToShipToAddressSame ? {
                                            value: billToCityId,
                                            label: billToCityLabel,
                                        } : ""
                                }
                                name="city"
                                value={getAllShipToCities.filter(option => option.value === shipToCityId)}
                                options={getAllShipToCities}
                                isClearable={true}
                                onChange={(e) => {
                                    if (e) {
                                        setShipToCityId(e.value)
                                        setShipToCityLabel(e.label);
                                    }
                                }}
                            />
                            <div className="form_message form_message-error left-flex">
                                <p
                                    className={shipToCityErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {shipToCityErrMsg}
                                </p>
                            </div>
                        </div>
                        <div className="shipToAddress1 form_input">
                            <div className="inputLabel left-flex">
                                <label>
                                    Address 1:
                                </label>
                            </div>
                            <input
                                type="text"
                                id="shipToAddress1"
                                placeholder="Address 1"
                                name="shipToAddress1"
                                onChange={(e) => setShipToAddress1(e.target.value)}
                                value={shipToAddress1}
                            />
                            <div className="form_message form_message-error">
                                <p
                                    className={shipToAddress1ErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {shipToAddress1ErrMsg}
                                </p>
                            </div>
                        </div>
                        <div className="shipToAddress2 form_input">
                            <div className="inputLabel left-flex">
                                <label>Address 2:</label>
                            </div>
                            <input
                                type="text"
                                id="shipToAddress2"
                                placeholder="Address 2"
                                name="shipToAddress2"
                                onChange={(e) => setShipToAddress2(e.target.value)}
                                value={shipToAddress2}
                            />
                            <div className="form_message form_message-error">
                                <p
                                    className={shipToAddress2ErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {shipToAddress2ErrMsg}
                                </p>
                            </div>
                        </div>
                        <div className="shipToZip form_input">
                            <div className="inputLabel left-flex">
                                <label>Zip Code:</label>
                            </div>
                            <input
                                type="text"
                                id="shipToZip"
                                placeholder="ship to zip code"
                                name="shipToZip"
                                onChange={(e) => setShipToZip(e.target.value)}
                                value={shipToZip}
                            />
                            <div className="form_message form_message-error">
                                <p
                                    className={shipToZipCodeErrMsg ? "errmsg" : "offscreen"}
                                    aria-live="assertive"
                                >
                                    {shipToZipCodeErrMsg}
                                </p>
                            </div>
                        </div>
                        <button type="submit" className="saveButton saveData">
                            <p>SAVE</p>
                        </button>
                    </div>
                    <div className="form_message form_message-error"></div>
                </div>
            </form>
        </div>
    );
}
