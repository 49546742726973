import React, { useState, useEffect } from "react";
import FilterContentButton from "../../components/Buttons/FilterContent";
import Table from '../../components/Table'
import FilterByInvoiceType from "../manageinvoice/FilterByInvoiceType";
import { getInvoiceTotalAmountAPI } from "../../utils/api-helper";
import DashFilterInvoice from "./DashFilterInvoice";

import Badge from "@mui/joy/Badge";
import { LuChevronUp } from "react-icons/lu";
import { LuChevronDown } from "react-icons/lu";
import { LuChevronsUpDown } from "react-icons/lu";

export default function GetDarftInvoice(props) {
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [rows, setRows] = useState([]);
  const [tableTitle, setTableTitle] = useState("listOfInvoices");
  const [isFilterInvoice, setIsFilterInvoice] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const formatHeaderName = (fieldName) => {
    // Split the field name by capital letters
    const result = fieldName.replace(/([A-Z])/g, ' $1');
    // Capitalize the first letter of the result
    const formattedName = result.charAt(0).toUpperCase() + result.slice(1);
    return formattedName;
  };
  const columns = [
    {
      field: "clientName", minWidth: 150,
      valueFormatter: (p) => {
        return p.value === "null" || p.value === null ? "-" : p.value;
      },
      headerComponentFramework: ({ column }) => {
        return (
          <div onClick={() => handleSortClick('ASC', column.colId)} style={{ display: "flex", alignItems: "center" }}>
            <span>{formatHeaderName(column.colDef.field)}</span>
            {getSortIcon(column.colId)}
          </div>
        )
      }
    },
    {
      field: "projectName", minWidth: 150,
      valueFormatter: (p) => {
        return p.value === "null" || p.value === null ? "-" : p.value;
      },
      headerComponentFramework: ({ column }) => {
        return (
          <div onClick={() => handleSortClick('ASC', column.colId)} style={{ display: "flex", alignItems: "center" }}>
            <span>{formatHeaderName(column.colDef.field)}</span>
            {getSortIcon(column.colId)}
          </div>
        )
      }
    },
    {
      field: "projectManager", minWidth: 160,
      valueFormatter: (p) => {
        return p.value === "null" || p.value === null ? "-" : p.value;
      },
      headerComponentFramework: ({ column }) => {
        return (
          <div onClick={() => handleSortClick('ASC', column.colId)} style={{ display: "flex", alignItems: "center" }}>
            <span>{formatHeaderName(column.colDef.field)}</span>
            {getSortIcon(column.colId)}
          </div>
        )
      }
    },
    {
      field: "invoiceNumber", minWidth: 150,
      valueFormatter: (p) => {
        return p.value === "null" || p.value === null ? "-" : p.value;
      },
      headerComponentFramework: ({ column }) => {
        return (
          <div onClick={() => handleSortClick('ASC', column.colId)} style={{ display: "flex", alignItems: "center" }}>
            <span>{formatHeaderName(column.colDef.field)}</span>
            {getSortIcon(column.colId)}
          </div>
        )
      }
    },
    {
      field: "invoiceDate", minWidth: 150,
      valueFormatter: (p) => {
        return p.value === "null" || p.value === null ? "-" : p.value;
      },
      headerComponentFramework: ({ column }) => {
        return (
          <div onClick={() => handleSortClick('ASC', column.colId)} style={{ display: "flex", alignItems: "center" }}>
            <span>{formatHeaderName(column.colDef.field)}</span>
            {getSortIcon(column.colId)}
          </div>
        )
      }
    },
    {
      field: "dueDate", minWidth: 150,
      valueFormatter: (p) => {
        return p.value === "null" || p.value === null ? "-" : p.value;
      },
      headerComponentFramework: ({ column }) => {
        return (
          <div onClick={() => handleSortClick('ASC', column.colId)} style={{ display: "flex", alignItems: "center" }}>
            <span>{formatHeaderName(column.colDef.field)}</span>
            {getSortIcon(column.colId)}
          </div>
        )
      }
    },
    ...(props.selectedInvoiceType === "all" ? [{
      field: "paymentStatus", minWidth: 150,
      valueFormatter: (p) => {
        return p.value === "null" || p.value === null ? "-" : p.value;
      }
    }] : []),
    {
      field: "amountPaid", minWidth: 150,
      valueFormatter: (p) => {
        return p.value === "null" || p.value === null ? "-" : p.value;
      },
      headerComponentFramework: ({ column }) => {
        return (
          <div onClick={() => handleSortClick('ASC', column.colId)} style={{ display: "flex", alignItems: "center" }}>
            <span>{formatHeaderName(column.colDef.field)}</span>
            {getSortIcon(column.colId)}
          </div>
        )
      }
    },
    {
      field: "amountDue", minWidth: 150,
      valueFormatter: (p) => {
        return p.value === "null" || p.value === null ? "-" : p.value;
      },
      headerComponentFramework: ({ column }) => {
        return (
          <div onClick={() => handleSortClick('ASC', column.colId)} style={{ display: "flex", alignItems: "center" }}>
            <span>{formatHeaderName(column.colDef.field)}</span>
            {getSortIcon(column.colId)}
          </div>
        )
      }
    },
    {
      field: "grandTotal", minWidth: 150,
      valueFormatter: (p) => {
        return p.value === "null" || p.value === null ? "-" : p.value;
      },
      headerComponentFramework: ({ column }) => {
        return (
          <div onClick={() => handleSortClick('ASC', column.colId)} style={{ display: "flex", alignItems: "center" }}>
            <span>{formatHeaderName(column.colDef.field)}</span>
            {getSortIcon(column.colId)}
          </div>
        )
      }
    },
    {
      field: "invoiceTotalAmountUSD", minWidth: 150,
      valueFormatter: (p) => {
        return p.value === "null" || p.value === null ? "-" : p.value;
      }
    },
  ]
  function createData(
    id,
    firstName,
    lastName,
    projectName,
    invoiceNumber,
    invoiceDate,
    dueDate,
    paymentStatus,
    grandTotal,
    amountPaid,
    amountDue,
    projectManager,
    invoiceTotalAmountUSD
  ) {
    const clientName = firstName + "\u00a0" + lastName;
    return {
      id,
      clientName,
      projectName,
      invoiceNumber,
      grandTotal,
      invoiceDate,
      dueDate,
      ...(props.selectedInvoiceType === "all" ? { paymentStatus } : {}),
      amountPaid,
      amountDue,
      projectManager,
      invoiceTotalAmountUSD,
    };
  }

  useEffect(() => {
    setRows(
      props.response?.map((row) =>
        createData(
          row.id,
          row.clientFirstName,
          row.clientLastName,
          row.projectName,
          row.invoiceNumber,
          row.invoiceDate,
          row.dueDate,
          row.paymentStatus,
          row.grandTotal,
          row.amountPaid,
          row.amountDue,
          row.projectManagerName,
          row.invoiceTotalAmountUSD
        )
      )
    );
  }, [props.response]);


  const getInvoiceTypeName = (selectedInvoiceType) => {
    switch (selectedInvoiceType) {
      case "draft":
        return "Draft Invoices";
      case "pending":
        return "Pending Invoices";
      case "unpaid":
        return "Unpaid Invoices";
      case "fullyPaid":
        return "Fully Paid Invoices";
      case "partiallyPaid":
        return "Partially Paid Invoices";
      case "void":
        return "Void Invoices";
      default:
        return "All Invoices";
    }
  };

  const handleFilterChange = (filterName, filterValue) => {
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: filterValue,
    }));
  };

  const handleClearAllFilters = () => {
    props.setClientFirstNameFilter("");
    props.setClientLastNameFilter("");
    props.setProjectNameFilter("");
    props.setDueDateFromFilter("");
    props.setDueDateToFilter("");
    props.setPaidDateFromFilter("");
    props.setPaidDateToFilter("");
    props.setIsActiveFilter(true);
    props.setIsPaymentStatusFilter("");
    props.setIsFilterInvoice(false);
    props.setIsFilterSelected(false);
    props.getAllInvoices();
    props.onFiltersApplied({});
  };

  const checkFilter = () => {
    let check = false;
    if (
      props.clientFirstNameFilter !== "" ||
      props.clientLastNameFilter !== "" ||
      props.projectNameFilter !== "" ||
      props.invoiceDateFromFilter !== "" ||
      props.dueDateFromFilter !== "" ||
      props.paidDateFromFilter !== "" ||
      props.isActiveFilter !== true ||
      props.isPaymentStatusFilter !== ""
    ) {
      props.setIsFilterSelected(false);
      check = true;
    } else {
      check = false;
    }
    return check;
  };
  const handleSortClick = (order, columnId) => {
    if (order === props.sortOrder) {
      props.setsortInvoiceData(columnId);
      props.setSortOrder(order == 'ASC' ? 'DESC' : order == 'DESC' ? null : 'ASC');
    } else {
      props.setsortInvoiceData(columnId);
      props.setSortOrder(order);
    }
  };

  const getSortIcon = (column) => {
    if (column === props.sortInvoiceData && props.sortOrder === 'ASC') {
      return <LuChevronUp style={{ fontSize: "16px" }} />
    } else if (column === props.sortInvoiceData && props.sortOrder === 'DESC') {
      return <LuChevronDown style={{ fontSize: "16px" }} />
    } else {
      return <LuChevronsUpDown style={{ fontSize: "16px" }} />;
    }
  };
  return (
    <>
      <div>
        <div className="dash_table-outer_content">
          <div className="manage_content" style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
            <div className="content_subtitle">
              <p>List of {getInvoiceTypeName(props.selectedInvoiceType)}</p>
            </div>
            <div className="box-functional_elements">
              <div >
                <button
                  onClick={() => {
                    handleClearAllFilters();
                    props.handleClose();
                  }}
                >
                  Close
                </button>
              </div>

              <div className="functional_elements">
                <div
                  onClick={() => {
                    setIsFilterInvoice(true);
                    props.setPage(0);
                  }
                  }
                  className="filterDiv"
                >
                  <Badge invisible={true} size="lg">
                    <FilterContentButton />
                  </Badge>
                </div>
              </div>
            </div>
          </div>
          <div className="dash_table">
            <Table
              paginationStyle={{
                position: "fixed",
                right: "20px",
                bottom: "20px",
                backgroundColor: "#ffffff",
              }}
              totalCount={props?.totalCount}
              page={props.page}
              rowsPerPage={props.rowsPerPage}
              handleChangePage={props.handleChangePage}
              handleChangeRowsPerPage={props.handleChangeRowsPerPage}
              columns={columns}
              rows={rows}
              function={props.getInvoiceById}
              normalData={true}
              setSortOrder={props.setSortOrder}
              sortOrder={props.sortOrder}
              setsortInvoiceData={props.setsortInvoiceData}
              tableTitle={tableTitle}
              sortBy={props.sortBy}
              setSortBy={props.setSortBy}
              sortInvoiceData={props.sortInvoiceData}
              handleInvoiceCheck={props.handleInvoiceCheck}
              projectID={props.projectID}
              getPaymentStatus={props.getPaymentStatus}
              handlePaymentStatusChange={props.handlePaymentStatusChange}
              response={props.response}
              addInvoice={props.addInvoice}
              getInvoiceById={props.getInvoiceById}
              filterInvoice={props.filterInvoice}
              activeFilters={props.activeFilters}
              sortInvoice={props.sortInvoice}
              setStartLoader={props.setStartLoader}
              invoiceType={props.invoiceType}
              filterByInvoiceTypeData={props.filterByInvoiceTypeData}
              setFilterByInvoiceTypeData={props.setFilterByInvoiceTypeData}
              isFilterSelected={props.isFilterSelected}
              setIsFilterSelected={props.setIsFilterSelected}
              isActiveFilter={props.isActiveFilter}
              setIsActiveFilter={props.setIsActiveFilter}
              getAllCLients={props.getAllCLients}
              getAllProjects={props.getAllProjects}
              updateInvoiceStatus={props.updateInvoiceStatus}
              setClientFirstNameFilter={props.setClientFirstNameFilter}
              setClientLastNameFilter={props.setClientLastNameFilter}
              setProjectNameFilter={props.setProjectNameFilter}
              setDueDateFromFilter={props.setDueDateFromFilter}
              setDueDateToFilter={props.setDueDateToFilter}
              setInvoiceDateFromFilter={props.setInvoiceDateFromFilter}
              setInvoiceDateToFilter={props.setInvoiceDateToFilter}
              setPaidDateFromFilter={props.setPaidDateFromFilter}
              setPaidDateToFilter={props.setPaidDateToFilter}
              setIsPaymentStatusFilter={props.setIsPaymentStatusFilter}
              setIsFilterInvoice={props.setIsFilterInvoice}
              getAllInvoices={props.getAllInvoices}
              onFiltersApplied={props.handleFiltersApplied}
              totalAmountUSD={props.totalAmountUSD}
              isPagination = {true}
            />
          </div>
        </div>
      </div>
      {isFilterInvoice && (
        <DashFilterInvoice
          setSelectedFilter={props.setSelectedFilter}
          selectedFilter={props.selectedFilter}
          getAllInvoices={props.getAllInvoices}
          clientFirstNameFilter={props.clientFirstNameFilter}
          setClientFirstNameFilter={props.setClientFirstNameFilter}
          clientLastNameFilter={props.clientLastNameFilter}
          setClientLastNameFilter={props.setClientLastNameFilter}
          projectNameFilter={props.projectNameFilter}
          setProjectNameFilter={props.setProjectNameFilter}
          dueDateFromFilter={props.dueDateFromFilter}
          setDueDateFromFilter={props.setDueDateFromFilter}
          dueDateToFilter={props.dueDateToFilter}
          setDueDateToFilter={props.setDueDateToFilter}
          invoiceDateFromFilter={props.invoiceDateFromFilter}
          setInvoiceDateFromFilter={props.setInvoiceDateFromFilter}
          invoiceDateToFilter={props.invoiceDateToFilter}
          setInvoiceDateToFilter={props.setInvoiceDateToFilter}
          paidDateFromFilter={props.paidDateFromFilter}
          onFiltersApplied={props.onFiltersApplied}
          setPaidDateFromFilter={props.setPaidDateFromFilter}
          paidDateToFilter={props.paidDateToFilter}
          setPaidDateToFilter={props.setPaidDateToFilter}
          isActiveFilter={props.isActiveFilter}
          setIsActiveFilter={props.setIsActiveFilter}
          setIsPaymentStatusFilter={props.setIsPaymentStatusFilter}
          isPaymentStatusFilter={props.isPaymentStatusFilter}
          isPaymentStatusLabel={props.isPaymentStatusLabel}
          isClientFirstNameLabel={props.sClientFirstNameLabel}
          getPaymentStatus={props.getPaymentStatus}
          getAllCLients={props.getAllCLients}
          getAllProjects={props.getAllProjects}
          getAllProjectManagers={props.getAllProjectManagers}
          setStartLoader={props.setStartLoader}
          isFilterSelected={props.isFilterSelected}
          setIsFilterSelected={props.setIsFilterSelected}
          setIsPaymentStatusLabel={props.setIsPaymentStatusLabel}
          setIsClientFirstNameLabel={props.setIsClientFirstNameLabel}
          filterByInvoiceTypeData={props.filterByInvoiceTypeData}
          setIsFilterInvoice={setIsFilterInvoice}
          isFilterInvoice={isFilterInvoice}
          selectedPaymentStatus={props.selectedPaymentStatus}
          startDateFromFilter={props.startDateFromFilter}
          setStartDateFromFilter={props.setStartDateFromFilter}
          startDateToFilter={props.startDateToFilter}
          setStartDateToFilter={props.setStartDateToFilter}
          endDateFromFilter={props.endDateFromFilter}
          setEndDateFromFilter={props.setEndDateFromFilter}
          endDateToFilter={props.endDateToFilter}
          setEndDateToFilter={props.setEndDateToFilter}
          projectManagerFirstNameFilter={props.projectManagerFirstNameFilter}
          setProjectManagerFirstNameFilter={
            props.setProjectManagerFirstNameFilter
          }
          selectedInvoiceType={props.selectedInvoiceType}
        />
      )}
    </>
  );
}
