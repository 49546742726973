import React, { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/FilterInvoice.css";


export default function FilterBudget(props) {
    function _onFocus(e) {
        e.currentTarget.type = "date";
    }
    function _onBlur(e) {
        e.currentTarget.type = "text";
        e.currentTarget.placeholder = "Enter a Date";
    }
    const [isDeliveryManagersFurstName, setIsDeliveryManagersFirstName] = useState(false);
    const [isDeliveryManagersLastName, setIsDeliveryManagersLastName] = useState(false);
    
    const userRef = useRef();

    const checkFilter = () => {
        let check = false;
        if (props.deliveryManagerLastNameFilter != "" || props.deliveryManagerFirstNameFilter != "") {
            props.setIsFilterSelected(false)
            check = true;
        } else {
            check = false;
        }
        return check;
    }

    const handleClickOnApply = () => {
        props.setFilterBudget(false);
        props.getAllMonthlyBudget();
    }
    const filterDeliveryManagerFirstName = () => {
        clearAll();
        props.setIsFilterSelected(true);
        setIsDeliveryManagersFirstName(true);
        setIsDeliveryManagersLastName(false);
    };
    const filterDeliveryManagerLastName = () => {
        props.setIsFilterSelected(true);
        clearAll();
        setIsDeliveryManagersFirstName(false);
        setIsDeliveryManagersLastName(true);
    };


    const clearAll = () => {
        props.setIsFilterSelected(false);
        props.setDeliveryManagerFirstNameFilter('');
        props.setDeliveryManagerLastNameFilter('');
    };
    const cancel = () => {
        clearAll();
        props.setFilterBudget(false);
    }

    return (
        <>
            <div className="flexfilter">
                <div className="filterBox">
                    <div className="filterContent">
                        <div className="filterTitle">
                            <div className="heading">Filter Budget</div>
                            <div className="crossButtonHover" onClick={cancel}>
                                <div className="crossButton"></div>
                            </div>
                            <button onClick={clearAll}>Clear All</button>
                        </div>
                        <div className="filterElements">
                            {/* <form onsubmi></form> */}
                            <div className="flex">
                                <div className="filterOptionsList">
                                    <div
                                        className={
                                            isDeliveryManagersFurstName
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterDeliveryManagerFirstName}
                                    >
                                        <p>Delivery Manager's First Name</p>
                                    </div>
                                    <div
                                        className={
                                            isDeliveryManagersLastName
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterDeliveryManagerLastName}
                                    >
                                        <p>Delivery Manager's Last Name</p>
                                    </div>
                                </div>
                                <div className="filterOptionsData">
                                    {isDeliveryManagersFurstName ? (
                                        <div className="filterOptionInput">
                                            <input
                                                type="text"
                                                id="setContactPersonFirstNameFilter"
                                                placeholder="Delivery Manager's First Name"
                                                name="setContactPersonFirstNameFilter"
                                                onChange={(e) => props.setDeliveryManagerFirstNameFilter(e.target.value)}
                                                value={props.deliveryManagerFirstNameFilter}
                                            />
                                        </div>
                                    ) : isDeliveryManagersLastName ? (
                                        <div className="filterOptionInput">
                                            <input
                                                type="text"
                                                id="setContactPersonLastNameFilter"
                                                placeholder="Delivery Manager's Last Name"
                                                name="setContactPersonLastNameFilter"
                                                onChange={(e) => props.setDeliveryManagerLastNameFilter(e.target.value)}
                                                value={props.deliveryManagerLastNameFilter}
                                            />
                                        </div>
                                    ): (
                                        <p style={{ textAlign:"center", marginTop:"50px"}}>No Content</p>
                                    )}
                                </div>
                            </div>
                            <div className="filterFooter">
                                <div className="floatright">
                                    <div className="applyButton">
                                        <button onClick={handleClickOnApply}>Apply</button>
                                    </div>
                                    <div className="cancleButton">
                                        <button onClick={cancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
