import React from 'react'
import './popupStyles.css'
export default function index(props) {
    return (
        <>
            <div className="flexpopUp" >
                <div className="popUpBox">
                    <div className="popUpContent">
                        <div className="popUpTitle">
                            <div className="heading">Alert</div>
                            {props.alertPopupWindow.type === "check" && <div className="crossButtonHover" onClick={() => { props.setAlertPopupWindow({ is: false, type: null }); props.setAlertReponse(false) }}><div className="crossButton"></div></div>}
                        </div>
                        <div className="popUpElements">
                            <div className="popUpOptionsData">
                                <p>{props.alertPopupMessage}</p>
                            </div>
                            <div className="popUpFooter">
                                <div className="floatright">
                                    {props.alertPopupWindow.type === "check" ?
                                        <div className="applyButton">
                                            <button onClick={() => props.setAlertReponse(true)}>Okay</button>
                                        </div> :
                                        <div className="applyButton">
                                            <button onClick={() => props.setAlertPopupWindow({ is: false, type: null })}>Okay</button>
                                        </div>
                                    }
                                    {props.alertPopupWindow.type === "check" && <div className="cancleButton">
                                        <button onClick={() => { props.setAlertPopupWindow({ is: false, type: null }); props.setAlertReponse(false) }}>Cancel</button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
