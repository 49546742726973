import React, { useState, useEffect, useCallback, useRef } from 'react';
import { addCitiesAPI, getCityByIdAPI, editCitiesAPI } from '../../utils/api-helper';
import { validateLongTextWithSPace, validateCharacterLimitNormalTextField } from '../../utils/input-validation';
import { cityNameErrorMessage, requiredcityNameErrorMessage, validateCharacterLimitNormalTextFieldErrorMessage } from '../../utils/status-message-helper'
import './addContent.css';

export default function DeleteCountryData(props) {

    const userRef = useRef();
    useEffect(() => {
        userRef.current.focus();
    }, []);
    const [city, setCity] = useState();
    const [errMsg, setErrMsg] = useState();

    const [createCitiesForm, setCreateCitiesForm] = useState(true);

    useEffect(() => {
        if (props.iseditCityData) {
            setCreateCitiesForm(false);
            setEditCitiesData(props.selectedCityid);
        }
    }, [props.iseditCityData]);

    const setEditCitiesData = (id) => {
        getCityByIdAPI(id).then(res => {
            setCity(res?.data?.cityName);
        }).catch((err) => {
            // console.log(err);
        })
    }

    const addCity = (e) => {
        e.preventDefault();
        if (validateCityName()) {
            const body = {
                "cityName": city,
                "stateId": props.selectedStateid
            }
            addCitiesAPI(body).then(res => {
                props.selectStatesGetCities({ "id": props.selectedStateid,"name": props.selectedStateName });
            }).catch((err) => {
                setErrMsg(err.response?.data?.message);
                // console.log(err);
            })
        }
    }
    const editCity = (e) => {
        e.preventDefault();
        if (validateCityName()) {
            const body = {
                "cityName": city,
                "stateId": props.selectedStateid
            }
            editCitiesAPI(props.selectedCityid, body).then(res => {
                props.selectStatesGetCities({ "id": props.selectedStateid,"name": props.selectedStateName });
            }).catch((err) => {
                // console.log(err);
                setErrMsg(err.response?.data?.message);
            })
        }
    }
    function validateCityName() {
        if (city) {
            if (validateLongTextWithSPace(city)) {
                if (validateCharacterLimitNormalTextField(city)) {
                    setErrMsg("");
                    return true;
                } else {
                    setErrMsg(validateCharacterLimitNormalTextFieldErrorMessage);
                    return false;
                }
            } else {
                setErrMsg(cityNameErrorMessage);
                return false;
            }
        } else {
            setErrMsg(requiredcityNameErrorMessage);
            return false;
        }
    }

    return (
        <div className="flexadd" >
            <div className="addBox">
                <div className="addContent">
                    <div className="addTitle">
                        <div className="heading">{createCitiesForm ? <>Add City</> : <>Edit City</>}</div>
                        <div className="crossButtonHover" onClick={props.initialState}><div className="crossButton"></div></div>
                    </div>
                    <form onSubmit={createCitiesForm ? addCity : editCity}>
                        <div className="addElements">
                            <div className="addOptionsData">
                                <div className="country form_input">
                                    <label>Country Name:</label>
                                    <input
                                        type="text"
                                        id="country"
                                        placeholder={props.selectedCountryName}
                                        name="country"
                                        disabled
                                    />
                                </div>
                                <div className="states form_input">
                                    <label>State Name:</label>
                                    <input
                                        type="text"
                                        id="state"
                                        placeholder={props.selectedStateName}
                                        name="state"
                                        disabled
                                    />
                                </div>
                                <div className="cities form_input">
                                    <label>City Name:</label>
                                    <input
                                        type="text"
                                        id="city"
                                        placeholder="Enter City Name"
                                        name="city"
                                        onChange={(e) => setCity(e.target.value)}
                                        value={city}
                                        ref={userRef}
                                    />
                                    <div className="form_message form_message-error">
                                        <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="addFooter">
                                <div className="floatright">
                                    <div className="applyButton">
                                        <button onClick={createCitiesForm ? addCity : editCity}>
                                        {createCitiesForm ? <>ADD</> : <>UPDATE</>}
                                        </button>
                                    </div>
                                    <div className="cancleButton">
                                        <button onClick={props.initialState}>CANCEL</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
