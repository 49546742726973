import React from 'react';
import '../../assets/styles/managepages.css';
import styles from "../managePendingInvoices/pendingInvoice.module.css";

const BudgetAlert = (props) => {
    const alertStyle = {
        color: 'red',
        display: 'flex',
        justifyContent: 'center'
    }

    const messageStyle = {
        display: 'flex',
        justifyContent: 'center'
    }

        
    function getAllData(){
        props.setShowAlert(false);
        props.setViewAllData(true);
    }
    return (
        <div className="popup">
            <div className="popup-content">
                <h3 style={alertStyle}>{props.isLogoutPage ? props.heading : "Alert"}</h3>
                <p style={messageStyle}>{props.isLogoutPage ? props.message : `No data found, do you want to create budget for ${props.month} ${props.year}`}</p>
                {
                    props.heading === "Logout" ?
                    <div className={styles.reasonButtonBox} style={{ width: "100%" }}>
                        <button className={`${styles.btn}`} onClick={() => props.cancelLogout()}>Cancel</button>
                        <button className={`${styles.btn}`} onClick={() => props.confirmLogout()} >OK</button>
                    </div> :
                    <div className={styles.reasonButtonBox} style={{ width: "100%" }}>
                        <button className={`${styles.btn}`} onClick={() => props.setShowAlert(false)}>Cancel</button>
                        <button className={`${styles.btn}`} onClick={() => getAllData()} >OK</button>
                    </div>
                }
            </div>
        </div>
    );
};

export default BudgetAlert;
