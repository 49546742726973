import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import '../../assets/styles/deleteContent.css';
import { removeClientAPI } from '../../utils/api-helper';
import qs from 'qs';
import { useNavigate } from "react-router-dom";

export default function DeleteClient(props) {
    const [errorMessage, setErrorMessage] = useState("");
    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id');

    const navigate = useNavigate();

    const deleteClient = async (e) => {
        e.preventDefault();
        props.setStartLoader(true);
        const body = qs.stringify({
            "id": id
        });
        removeClientAPI(body).then(res => {
            props.getAllClients();
            props.setStartLoader(false);
            // navigate('/client');
        }).catch((err) => {
            // console.log(err);
            setErrorMessage(err.response?.data.message);
            props.setStartLoader(false);
        })
    }
    return (
        <>
            <div className="flexdelete" >
                <div className="deleteBox">
                    <div className="deleteContent">
                        <div className="deleteTitle">
                            <div className="heading">Delete Client</div>
                            <div className="crossButtonHover" onClick={() => { props.getClientById({ id: id }) }}><div className="crossButton"></div></div>
                        </div>
                        <div className="deleteElements">
                            <div className="deleteOptionsData">
                                <p>Are you sure you want to delete</p>
                                <p>Client Id <span>: {props.clientId}</span></p>
                                <div className="form_message-error">{errorMessage ? errorMessage : ""}</div>
                            </div>
                            <div className="deleteFooter">
                                <div className="floatright">
                                    <div className="applyButton">
                                        <button onClick={deleteClient} className={`${ errorMessage ? "hidden" : "" }`}>Delete</button>
                                    </div>
                                    <div className="cancleButton">
                                        <button onClick={() => { props.getClientById({ id: id }) }}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
