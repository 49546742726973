import React from "react";
import "../../assets/styles/managepages.css";

const VoidInvoices = (props) => {
  return (
    <>
      <div className="dashboard-tile">
        <h3>{"Void Invoices"}</h3>
        <p className="description">Cancelled transaction</p>
        <div className="dashboard-content left">
          <h5>{"Total Count :"}</h5>
          <p>{`${props.voidTotalValue.totalCount ? props.voidTotalValue.totalCount : "--"}`}</p>
        </div>
        <div className="dashboard-content right">
          <h5>{"Total Amount :"}</h5>
          <p>{`${props.voidTotalValue.totalAmount ? props.voidTotalValue.totalAmount : "--"}`}</p>
        </div>
      </div>
    </>
  );
};

export default VoidInvoices;