import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import '../../assets/styles/SortingInvoice.css';
import { sortingInvoices } from '../../utils/constants';
export default function SortInvoice(props) {
    
    const cancel = () => {
        props.setIsSortInvoice(false);
        props.setsortInvoiceData('')
        // props.getAllInvoices();
    }
    return (
        <>
            <div className="flexsort" >
                <div className="sortBox">
                    <div className="sortContent">
                        <div className="sortTitle">
                            <div className="heading">Sort Invoice</div>
                            <div className="crossButtonHover" onClick={cancel}><div className="crossButton"></div></div>
                            <button onClick={() => { props.setsortInvoiceData('') }}>Clear All</button>
                        </div>
                        <div className="sortElements">
                            <div className="sortOptionsData">
                                <div className="selectOptions">
                                    <Select
                                        type="text"
                                        id="sortInvoice"
                                        placeholder={<div className="select-placeholder-text">Please select</div>}
                                        defaultValue={props.sortInvoiceData ? { value: props.sortInvoiceData, label: props.sortInvoiceData } : ""}
                                        name="countryCode"
                                        options={sortingInvoices}
                                        onChange={(e) => props.setsortInvoiceData(e.value)}
                                    />
                                </div>
                            </div>
                            <div className="sortFooter">
                                <div className="floatright">
                                    <div className="applyButton">
                                        <button onClick={() => {props.getAllInvoices()}}>Apply</button>
                                    </div>
                                    <div className="cancleButton">
                                        <button onClick={cancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
