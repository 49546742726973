import React from "react";
import "./auth.css";
// import SuccessCheck from "../../assets/images/successCheck.png"

const Success = (props) =>{
    
    return (<>
        <div className="modal-wrapper">
            <div className="pop-up">
            <div>
                <div className="imageContainer">
                    <img id="checkSuccess" src={require("../../assets/images/successCheck.png")} alt="logo" className="image"/>
                </div>
                <span> {"Sign up successful."}</span>
            </div>
            {/* <button className={'verify-otp'} onClick={handleClose}>{""}</button> */}
            <button className={'verify-otp'} onClick={props.handleBackToLoginScreen}>{"Proceed To Login"}</button>
            </div>
        </div>
    </>)
}

export default Success;