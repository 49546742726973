import React, { useState, useEffect } from 'react';
import TransactionTable from "../../components/Tables/paymentTable";
import { getUploadedFileList } from '../../utils/api-helper';
import classes from "./pendingInvoice.module.css";


function FileLists (props) {
    const [rows, setRows] = useState([]);
    const columns = [{ id: "dateUploaded", label: "Uploaded\u00a0Date", align: 'left'},
    { id: "nameFile", label: "File\u00a0Name" }
    ];

    function getFileList () {
        props.setStartLoader(true);
        getUploadedFileList().then((res) => {
            setRows(res.data.map((row) =>
                createData(row.id, row.fileName)
            ));
        }).catch((err) => {
            // console.log(err);
        }).finally(()=>{
            props.setStartLoader(false);
        })
    }


    useEffect(() => {
        getFileList();
    }, []);

    function createData(id, fileName) {
        // Replace all spaces with underscores
        const modifiedString = fileName.replace(/ /g, "_");
        
        // Split the string into parts using "." as a separator
        const parts = modifiedString.split(".");
        
        // Extract the file name without extension and date
        const fileNameWithoutExtension = parts[0];
        const datePart = parts[1] || ""; // If no date is present, set it to an empty string
        
        // Extract the date portion (assuming it's in ISO 8601 format)
        const dateTimeMatch = datePart.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/);
        const dateUploaded = dateTimeMatch ? dateTimeMatch[0].replace('T', ' at ') : "";
      
        // Combine the file name without extension and ".xlsx"
        const nameFile = `${fileNameWithoutExtension}.xlsx`;
        return {id, dateUploaded, nameFile };
      }

    

    return <>
        <div className={classes.detailPage}>
        <div className={classes.uploadedSection}>
                <div className={classes.flex}>
                    Previously Uploaded File Details:
                </div>
            </div>
            <TransactionTable
                paginationStyle={{
                    position: "fixed",
                    right: "20px",
                    bottom: "20px",
                    backgroundColor: "#ffffff",
                }}
                setSortOrder={props.setSortOrder}
                sortOrder={props.sortOrder}
                setsortInvoiceData={props.setsortInvoiceData}
                columns={columns}
                normalData={true}
                rows={rows}
                setRows={setRows}
                title={"files"}
                sortBy={props.sortBy}
                setSortBy={props.setSortBy}
                sortInvoiceData={props.sortInvoiceData}
                setHoveredRowIndex={props.setHoveredRowIndex}
                hoveredRowIndex={props.hoveredRowIndex}
                selectedStatus={props.selectedStatus}
                handlePaymentDetailCheck={props.handlePaymentDetailCheck}
                setSelectedStatus={props.setSelectedStatus}
                paymentDetailID={props.paymentDetailID}
                handleHistoryTableClick={props.handleHistoryTableClick}
            />
        </div>

    </>
}

export default FileLists;