import React, { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/FilterInvoice.css";
import Select from "react-select";
import { countryCodeList } from "../../utils/constants";

export default function FilterInvoice(props) {
    function _onFocus(e) {
        e.currentTarget.type = "date";
    }
    function _onBlur(e) {
        e.currentTarget.type = "text";
        e.currentTarget.placeholder = "Enter a Date";
    }
    const [selectedFilter, setSelectedFilter] = useState('clientFirstName');
    const [isClientFirstName, setIsClientFirstName] = useState(true);
    const [isClientLastName, setIsClientLastName] = useState(false);
    const [isProjectName, setIsProjectName] = useState(false);
    const [isInvoiceDate, setIsInvoiceDate] = useState(false);
    const [isDueDate, setIsDueDate] = useState(false);
    const [isPaidDate, setIsPaidDate] = useState(false);
    const [isStatus, setIsStatus] = useState(false);
    const [isPaymentStatus, setIsPaymentStatus] = useState(false);
    const [isPaymentStatusValue, setIsPaymentStatusValue] = useState("");
    const [isPaymentStatusLabel, setIsPaymentStatusLabel] = useState("");


    const userRef = useRef();
    const checkFilter = () => {
        let check = false;
        if (
            props.clientFirstNameFilter !== "" ||
            props.clientLastNameFilter !== "" ||
            props.projectNameFilter !== "" ||
            props.invoiceDateFromFilter !== "" ||
            props.dueDateFromFilter !== "" ||
            props.paidDateFromFilter !== "" ||
            props.isActiveFilter !== true ||
            props.isPaymentStatusFilter !== ""
        ) {
            props.setIsFilterSelected(false);
            check = true;
        } else {
            check = false;
        }
        return check;
    };

    useEffect(() => {
        checkSelectedFilter();
    }, [props]);

    const checkSelectedFilter = () => {
        setIsClientFirstName(props.clientFirstNameFilter !== "");
        setIsClientLastName(props.clientLastNameFilter !== "");
        setIsProjectName(props.projectNameFilter !== "");
        setIsInvoiceDate(props.invoiceDateFromFilter !== "");
        setIsDueDate(props.dueDateFromFilter !== "");
        setIsPaidDate(props.paidDateFromFilter !== "");
        setIsPaymentStatus(props.isPaymentStatusFilter !== "");
    };

    const handleClickOnApply = () => {
        props.setIsFilterInvoice(props.isFilterInvoice);
        const isVisible = checkFilter();
        props.setIsActiveFilter(isVisible);

        if (isVisible) {
            props.setIsFilterSelected(true);
        } else {
            if (props.filterByInvoiceTypeData !== '1') {
                props.setIsFilterSelected(true);
            } else {
                props.setIsFilterSelected(isVisible);
            }
        }
        props.getAllInvoices();
        // const activeFilters = getActiveFiltersSummary();
        // props.onFiltersApplied(activeFilters);
    };

    const filterClientFirstName = () => {
        if (selectedFilter !== 'clientFirstName') {
            setSelectedFilter('clientFirstName');
            setIsClientFirstName(true);
            setIsClientLastName(false);
            setIsProjectName(false);
            setIsInvoiceDate(false);
            setIsDueDate(false);
            setIsPaidDate(false);
            setIsStatus(false);
            setIsPaymentStatus(false);
        }
    };

    const filterProjectName = () => {
        if (selectedFilter !== 'projectName') {
            setSelectedFilter('projectName');
            setIsClientFirstName(false);
            setIsClientLastName(false);
            setIsProjectName(true);
            setIsInvoiceDate(false);
            setIsDueDate(false);
            setIsPaidDate(false);
            setIsStatus(false);
            setIsPaymentStatus(false);
        }
    };

    const filterInvoiceDate = () => {
        if (selectedFilter !== 'invoiceDate') {
            setSelectedFilter('invoiceDate');
            setIsClientFirstName(false);
            setIsClientLastName(false);
            setIsProjectName(false);
            setIsInvoiceDate(true);
            setIsDueDate(false);
            setIsPaidDate(false);
            setIsStatus(false);
            setIsPaymentStatus(false);
        }
    };

    const filterDueDate = () => {
        if (selectedFilter !== 'dueDate') {
            setSelectedFilter('dueDate');
            setIsClientFirstName(false);
            setIsClientLastName(false);
            setIsProjectName(false);
            setIsInvoiceDate(false);
            setIsDueDate(true);
            setIsPaidDate(false);
            setIsStatus(false);
            setIsPaymentStatus(false);
        }
    };

    const filterPaidDate = () => {
        if (selectedFilter !== 'paidDate') {
            setSelectedFilter('paidDate');
            setIsClientFirstName(false);
            setIsClientLastName(false);
            setIsProjectName(false);
            setIsInvoiceDate(false);
            setIsDueDate(false);
            setIsPaidDate(true);
            setIsStatus(false);
            setIsPaymentStatus(false);
        }
    };

    const filterStatus = () => {
        if (selectedFilter !== 'status') {
            setSelectedFilter('status');
            setIsClientFirstName(false);
            setIsClientLastName(false);
            setIsProjectName(false);
            setIsInvoiceDate(false);
            setIsDueDate(false);
            setIsPaidDate(false);
            setIsStatus(true);
            setIsPaymentStatus(false);
        }
    };

    const filterPaymentStatus = () => {
        if (selectedFilter !== 'paymentStatus') {
            setSelectedFilter('paymentStatus');
            setIsClientFirstName(false);
            setIsClientLastName(false);
            setIsProjectName(false);
            setIsInvoiceDate(false);
            setIsDueDate(false);
            setIsPaidDate(false);
            setIsStatus(false);
            setIsPaymentStatus(true);
        }
    };

    const clearAll = () => {
        props.setClientFirstNameFilter("");
        props.setClientLastNameFilter("");
        props.setProjectNameFilter("");
        props.setDueDateFromFilter("");
        props.setDueDateToFilter("");
        props.setInvoiceDateFromFilter("");
        props.setInvoiceDateToFilter("");
        props.setPaidDateFromFilter("");
        props.setPaidDateToFilter("");
        props.setIsActiveFilter(true);
        props.setIsPaymentStatusFilter("");
    };

    const cancel = () => {
        if (checkFilter()) {
            props.setClientFirstNameFilter("");
            props.setClientLastNameFilter("");
            props.setProjectNameFilter("");
            props.setDueDateFromFilter("");
            props.setDueDateToFilter("");
            props.setInvoiceDateFromFilter("");
            props.setInvoiceDateToFilter("");
            props.setPaidDateFromFilter("");
            props.setPaidDateToFilter("");
            props.setIsActiveFilter(true);
            setIsClientFirstName(false);
            setIsClientLastName(false);
            setIsProjectName(false);
            setIsInvoiceDate(false);
            setIsDueDate(false);
            setIsPaidDate(false);
            setIsStatus(false);
            setIsPaymentStatus(false);
            setIsClientFirstName(false);
            props.setIsFilterInvoice(false);
        } else {
            clearAll();
            props.setIsFilterInvoice(false);
        }
    };

    const close = () => {
        props.setIsFilterInvoice(false);
    };



    return (
        <>
            <div className="flexfilter">
                <div className="filterBox">
                    <div className="filterContent">
                        <div className="filterTitle">
                            <div className="heading">Filter Invoice</div>
                            <div className="crossButtonHover" onClick={close}>
                                <div className="crossButton"></div>
                            </div>
                            <button onClick={clearAll}>Clear All</button>
                        </div>
                        <div className="filterElements">
                            <div className="flex">
                                <div className="filterOptionsList">
                                    <div
                                        className={
                                            isClientFirstName
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterClientFirstName}
                                    >
                                        <p>Client's Name</p>
                                    </div>
                                    <div
                                        className={
                                            isProjectName
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterProjectName}
                                    >
                                        <p>Project Name</p>
                                    </div>
                                    <div
                                        className={
                                            isInvoiceDate
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterInvoiceDate}
                                    >
                                        <p>Invoice Date</p>
                                    </div>
                                    <div
                                        className={
                                            isDueDate
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterDueDate}
                                    >
                                        <p>Due Date</p>
                                    </div>
                                    <div
                                        className={
                                            isPaidDate
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterPaidDate}
                                    >
                                        <p>Paid Date</p>
                                    </div>
                                    <div
                                        className={isPaymentStatus ? "filterOption optionBackground" : "filterOption transparentBackground"}
                                        onClick={filterPaymentStatus}
                                    >
                                        <p>Invoice Payment Status</p>
                                    </div>
                                    <div
                                        className={isStatus ? "filterOption optionBackground" : "filterOption transparentBackground"}
                                        onClick={filterStatus}
                                    >
                                        <p>Invoice Status</p>
                                    </div>
                                </div>
                                <div className="filterOptionsData">
                                    {selectedFilter === 'clientFirstName' && (
                                        <div className="filterOptionInput">
                                            <Select
                                                type="text"
                                                styles={{ width: "100%", float: "left" }}
                                                placeholder={<div className="select-placeholder-text">Select Client First Name</div>}
                                                options={props.getAllCLients}
                                                isClearable={true}
                                                value={
                                                    props.clientFirstNameFilter
                                                        ? { label: props.clientFirstNameFilter, value: props.clientFirstNameFilter }
                                                        : null
                                                }
                                                onChange={(selectedOption) => {
                                                    if (selectedOption) {
                                                        props.setClientFirstNameFilter(selectedOption.label.trim());
                                                    } else {
                                                        props.setClientFirstNameFilter("");
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                    {selectedFilter === 'projectName' && (
                                        <div className="filterOptionInput">
                                            <Select
                                                type="text"
                                                styles={{ width: "100%", float: "left" }}
                                                placeholder={<div className="select-placeholder-text">Select Project Name</div>}
                                                options={props.getAllProjects}
                                                isClearable={true}
                                                value={
                                                    props.projectNameFilter
                                                        ? { label: props.projectNameFilter, value: props.projectNameFilter }
                                                        : null
                                                }
                                                onChange={(selectedOption) => {
                                                    if (selectedOption) {
                                                        props.setProjectNameFilter(selectedOption.label.trim());
                                                    } else {
                                                        props.setProjectNameFilter("");
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                    {selectedFilter === 'invoiceDate' && (
                                        <div className="filterOptionInput">
                                            <label>From</label>
                                            <input
                                                type="date"
                                                id="invoiceDateFilter"
                                                placeholder="Enter Invoice Date"
                                                name="invoiceDateFilter"
                                                onChange={(e) => props.setInvoiceDateFromFilter(e.target.value)}
                                                ref={userRef}
                                                value={props.invoiceDateFromFilter}
                                            />
                                            <label>To</label>
                                            <input
                                                type="date"
                                                disabled={!props.invoiceDateFromFilter}
                                                id="invoiceDateFilter"
                                                placeholder="Enter Invoice Date"
                                                name="invoiceDateFilter"
                                                onChange={(e) => props.setInvoiceDateToFilter(e.target.value)}
                                                ref={userRef}
                                                value={props.invoiceDateToFilter}
                                            />
                                        </div>
                                    )}
                                    {selectedFilter === 'dueDate' && (
                                        <div className="filterOptionInput">
                                            <label>From</label>
                                            <input
                                                type="date"
                                                id="dueDateFilter"
                                                placeholder="Enter Due Date"
                                                name="dueDateFilter"
                                                onChange={(e) => props.setDueDateFromFilter(e.target.value)}
                                                ref={userRef}
                                                value={props.dueDateFromFilter}
                                            />
                                            <label>To</label>
                                            <input
                                                type="date"
                                                disabled={!props.dueDateFromFilter}
                                                id="dueDateFilter"
                                                placeholder="Enter Due Date"
                                                name="dueDateFilter"
                                                onChange={(e) => props.setDueDateToFilter(e.target.value)}
                                                ref={userRef}
                                                value={props.dueDateToFilter}
                                            />
                                        </div>
                                    )}
                                    {selectedFilter === 'paidDate' && (
                                        <div className="filterOptionInput">
                                            <label>From</label>
                                            <input
                                                type="date"
                                                id="paidDateFilter"
                                                placeholder="Enter Paid Date"
                                                name="paidDateFilter"
                                                onChange={(e) => props.setPaidDateFromFilter(e.target.value)}
                                                ref={userRef}
                                                value={props.paidDateFromFilter}
                                            />
                                            <label>To</label>
                                            <input
                                                type="date"
                                                disabled={!props.paidDateFromFilter}
                                                id="paidDateFilter"
                                                placeholder="Enter Paid Date"
                                                name="paidDateFilter"
                                                onChange={(e) => props.setPaidDateToFilter(e.target.value)}
                                                ref={userRef}
                                                value={props.paidDateToFilter}
                                            />
                                        </div>
                                    )}
                                    {selectedFilter === 'paymentStatus' && (
                                        <div className="filterOptionInput">
                                            <Select
                                                type="text"
                                                styles={{ width: "100%", float: "left" }}
                                                placeholder={<div className="select-placeholder-text">Select Payment Status</div>}
                                                options={props.getPaymentStatus}
                                                isClearable={true}
                                                value={
                                                    props.isPaymentStatusFilter
                                                        ? { value: props.isPaymentStatusFilter, label: props.isPaymentStatusLabel }
                                                        : null
                                                }
                                                onChange={(selectedOption) => {
                                                    if (selectedOption) {
                                                        props.setIsPaymentStatusFilter(selectedOption.value);
                                                        props.setIsPaymentStatusLabel(selectedOption.label);
                                                    } else {
                                                        props.setIsPaymentStatusFilter("");
                                                        props.setIsPaymentStatusLabel("");
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                    {selectedFilter === 'status' && (
                                        <div className="filterOptionInput">
                                            <select
                                                type="select"
                                                className="statusFilter"
                                                placeholder="Enter Status"
                                                name="statusFilter"
                                                onChange={(e) => {
                                                    props.setIsActiveFilter(e.target.value);
                                                }}
                                                ref={userRef}
                                                value={props.isActiveFilter}
                                            >
                                                <option className="custom-select">Enter Status</option>
                                                <option className="custom-options" value="true">
                                                    Active
                                                </option>
                                                <option className="custom-options" value="false">
                                                    Inactive
                                                </option>
                                            </select>
                                        </div>
                                    )}
                                    {!selectedFilter && (
                                        <div style={{ padding: "90px", color: "red" }}>
                                            Please select a filter
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="filterFooter">
                                <div className="floatright">
                                    <div className="applyButton">
                                        <button type="submit" onClick={handleClickOnApply}>
                                            Apply
                                        </button>
                                    </div>
                                    <div className="cancleButton">
                                        <button onClick={cancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
    





    // const getActiveFiltersSummary = () => {
    //     let activeFilters = [];

    //     if (props.clientFirstNameFilter) {
    //         activeFilters.push(`Client First Name: ${props.clientFirstNameFilter}`);
    //     }
    //     if (props.clientLastNameFilter) {
    //         activeFilters.push(`Client Last Name: ${props.clientLastNameFilter}`);
    //     }
    //     if (props.projectNameFilter) {
    //         activeFilters.push(`Project Name: ${props.projectNameFilter}`);
    //     }
    //     if (props.invoiceDateFromFilter) {
    //         activeFilters.push(`Invoice Date From: ${props.invoiceDateFromFilter}`);
    //     }
    //     if (props.invoiceDateToFilter) {
    //         activeFilters.push(`Invoice Date To: ${props.invoiceDateToFilter}`);
    //     }
    //     if (props.dueDateFromFilter) {
    //         activeFilters.push(`Due Date From: ${props.dueDateFromFilter}`);
    //     }
    //     if (props.dueDateToFilter) {
    //         activeFilters.push(`Due Date To: ${props.dueDateToFilter}`);
    //     }
    //     if (props.paidDateFromFilter) {
    //         activeFilters.push(`Paid Date From: ${props.paidDateFromFilter}`);
    //     }
    //     if (props.paidDateToFilter) {
    //         activeFilters.push(`Paid Date To: ${props.paidDateToFilter}`);
    //     }
    //     if (props.isActiveFilter !== true) {
    //         activeFilters.push(`Status: ${props.isActiveFilter ? 'Active' : 'Inactive'}`);
    //     }
    //     if (props.isPaymentStatusFilter) {
    //         activeFilters.push(`Payment Status: ${props.isPaymentStatusLabel}`);
    //     }

    //     return activeFilters;
    // };