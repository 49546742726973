import React from "react";
import "../../assets/styles/managepages.css";

const PartiallyPaidInvoices = (props) => {
  return (
    <>
      <div className="dashboard-tile">
        <h3>{"Partially Paid Invoices"}</h3>
        <p className="description">Partially settled</p>
        <div className="dashboard-content left">
          <h5>{"Total Count :"}</h5>
          <p>{`${props.partiallyPaidTotalValue.totalCount ? props.partiallyPaidTotalValue.totalCount : "--"}`}</p>
        </div>
        <div className="dashboard-content right">
          <h5>{"Total Amount :"}</h5>
          <p>{`${props.partiallyPaidTotalValue.totalAmount ? props.partiallyPaidTotalValue.totalAmount : "--"}`}</p>
        </div>
      </div>
    </>
  );
};

export default PartiallyPaidInvoices;