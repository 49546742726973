export function validateEmail(email) {
    var pattern = new RegExp(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})$/);
    return pattern.test(email);
}
export function validatePassword(password) {
    var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*])[A-Za-z\d#?!@$%^&*]{8,24}$/);
    return pattern.test(password);
  }
export function validatePasswordRange(password) {
    var pattern = new RegExp(/^.{8,24}$/);
    return pattern.test(password);
}
export function validateTextOnly(inputText) {
    var pattern = /^\w+$/;
    return pattern.test(inputText);
}
export function validateText(inputText) {
    var pattern = new RegExp(/[\w]{1,}[\W]{0,}/);
    return pattern.test(inputText);
}
export function validateCharacterLimitNormalTextField(inputText) {
    var pattern = new RegExp(/^[\w\W]{0,80}$/);
    return pattern.test(inputText);
}
export function validateNumber(inputNumber){
    var pattern = new RegExp(/^[0-9]+$/);
    if(pattern.test(inputNumber)){
      //  console.log(Number(inputNumber));
        return Number(inputNumber);
    }
    
}
export function validateCharacterLimitLongTextField(inputText) {
    var pattern = new RegExp(/^[\w\W]{0,255}$/);
    return pattern.test(inputText);
}
export function validateCharacterLimitDescriptionBox(inputText) {
    var pattern = new RegExp(/^[\w\W]{0,1000}$/);
    return pattern.test(inputText);
}
export function validateLongTextWithSPace(inputText) {
    var pattern = new RegExp(/^[0-9A-Za-z]{0,}[\s]?[\.]?[\,]?.([0-9A-Za-z]{0,}[\s]?[\.]?[\,]?){0,}?[.]?$/gm);
    return pattern.test(inputText);
}
export function validateCurrencyCode(inputText) {
    var pattern = new RegExp(/^[A-Z]{3}$/);
    return pattern.test(inputText);
}
export function validatePostalCode(inputText) {
    var pattern = new RegExp(/^\w{5,10}$/gs);
    return pattern.test(inputText);
}
export function validateGstinNumber(inputText) {
    var pattern = new RegExp(/^\w{15}$/gs);
    return pattern.test(inputText);
}
export function validateDigitOnly(inputText) {
    var pattern = new RegExp(/^[0-9]{1,}$/);
    return pattern.test(inputText);
}
export function validateCountryCode(inputText) {
    var pattern = new RegExp(/^(\+[\d]{0,2}\-?\d{1,3})$/);
    return pattern.test(inputText);
}
export function validatePhoneNumberSequence(inputText) {
    var pattern = new RegExp(/^(\+(?!0+$)\d{1,3})[\s](?!0+$)\d{10,15}$/);  
    return pattern.test(inputText);
}
export function validatePhoneNumber(inputText) {
    var pattern = new RegExp(/^(?!0+$)\d{10,15}$/);  
    return pattern.test(inputText);
}
export function validateEqualInputs(input1, input2) {
    return input1 === input2;
}

// manage website input
export function validateWebsiteUrl(inputText) {
    var pattern = new RegExp(/((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?/gs);  
    return pattern.test(inputText);
}
// Manage Client Form
export function validateClientId(id) {
    var pattern = new RegExp(/[0-9]{4}[/-][A-Z]{2}$/);
    return pattern.test(id);
}