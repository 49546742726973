export const requiredFieldErrorMessage = "This is required field."
export const requiredfirstNameErrorMessage = "Please enter first name.";
export const requiredlastNameErrorMessage = "Please enter last name.";
export const requiredaltNameErrorMessage = "Please enter alternative name.";

export const firstNameErrorMessage = "Please enter valid first name.";
export const lastNameErrorMessage = "Please enter valid last name.";
export const altNameErrorMessage = "Please enter valid alternative name.";

export const requiredemailErrorMessage = "Please enter email address.";
export const requiredphoneErrorMessage = "Please enter contact number.";
export const requiredCountryCodeErrorMessage = "Please select contry code.";
export const requiredUserRoleErrorMessage = "Please select user role.";

export const emailErrorMessage = "Please enter valid email address.";
export const phoneErrorMessage = "Please enter valid contact number.";

export const passwordErrorMessage = `Please follow the above mentioned requirements.`;
export const passwordDoesNotMatch = "Passwords does not match."
export const requiredPasswordErrorMessage = "Please enter password."

export const clientIdErrorMessage = "Please enter valid Client Id. (e.g. 1234-AA)";
export const websiteUrlErrorMessage = "Please enter valid website url. (e.g. https://www.example.com)";
export const countryErrorMessage = "Please select country.";
export const stateErrorMessage = "Please select state.";
export const cityErrorMessage = "Please select city.";
export const paymentModeErrorMessage = "Please select payment mode.";

export const addressErrorMessage = "Please enter Address here.";
export const validateAddressErrorMessage = "Address field must be in alphanumeric and not blank.";
export const zipCodeErrorMessage = "Please enter zip code here.";
export const validatezipCodeErrorMessage = "Please enter valid zip code here.";
export const validateGstinNumberErrorMessage = "Please enter valid GSTIN number of 15 digits here.";
export const contactNumberErrorMessage = "Please enter valid contact number. (e.g. +91 9876543210)";
export const faxNumberMessage = "Please enter valid Fax number. (e.g. +91 9876543210)";

export const selectCountry = "Select Country";
export const selectState = "Select State";
export const selectCity = "Select City";
export const selectPaymentMode = "Select Payment Mode";

export const requiredclientNameErrorMessage = "Please select Client.";
export const requiredProjectMangerErrorMessage = "Please select project manager.";
export const requiredDeliveryMangerErrorMessage = "Please select delivery manager.";
export const requiredProjectNameErrorMessage = "Please enter project name.";
export const requiredProjectStartDateErrorMessage = "Please enter project start date.";
export const requiredIsUpworkErrMsg = 'Please select if the Project is from Upwork'

export const requiredProjectBudgetErrorMessage = "Please enter project budget.";
export const requiredProjectTypeErrorMessage = "Please select project type.";

export const ProjectNameErrorMessage = `Please enter valid project name.
                                        (Text only field, name of project starts with 
                                        uppercase and avoid space at the start and end)`;

export const currencyCodeErrorMessage = "Please enter valid currency code. (e.g. INR)"
export const currencyNameErrorMessage = `Please enter valid currency name.
                                        (Text only field, name of currency starts with 
                                        uppercase and avoid space at the start and end)`
export const contryCodeErrorMessage = "Please enter valid country code. (e.g. +91, +1-234)"
export const countryNameErrorMessage = `Please enter valid country name.
                                        (Text only field, name of country starts with 
                                        uppercase and avoid space at the start and end)`
export const stateNameErrorMessage = "Please enter valid state name. (Text only field, name of state starts with uppercase and avoid space at the start and end)"
export const cityNameErrorMessage = "Please enter valid city name. (Text only field, name of city starts with uppercase and avoid space at the start and end)"

export const requiredcurrencyCodeErrorMessage = "Please enter currency code."
export const requiredcurrencyNameErrorMessage = "Please enter currency name."
export const requiredcontryCodeErrorMessage = "Please enter country code."
export const requiredcountryNameErrorMessage = "Please enter country name."
export const requiredstateNameErrorMessage = "Please enter state name."
export const requiredcityNameErrorMessage = "Please enter city name."
export const requiredClientIDErrorMessage = "Please enter Client ID."
export const requiredClientTypeErrorMessage = "Please select Client Type."

export const requiredClientNameSelectedErrorMessage = "Please select client name."
export const requiredProjectNameSelectedErrorMessage = "Please select project name."
export const requiredInvoiceDateErrorMessage = "Please select invoice date."
export const requiredPaymentStatusErrorMessage = "Please select payment status."
export const requiredPaymentTermsErrorMessage = "Please select payment terms."
export const requiredCurrencyErrorMessage = "Please select currency."
export const requiredPaymentModeMessage = "Please select payment mode."
export const validatedDateRangeErrorMessage = "Please enter valid date range. (from lower to higher)"
export const mandatoryDateErrorMessage = "Please select start and end dates"
export const requireInvoiceNumber="Please enter invoice number"

export const validateCharacterLimitNormalTextFieldErrorMessage = "Character limit exceeds. Only 80 characters allowed."
export const validateCharacterLimitLongTextFieldErrorMessage = "Character limit exceeds. Only 255 characters allowed."
export const validateCharacterLimitDescriptionBoxErrorMessage = "Character limit exceeds. Only 1000 characters allowed."

export const validateInvoiceItemDescription = "Please enter item description."
export const validateInvoiceItemRate = "Please enter correct rate amount."
export const validateInvoiceItemHours = "Please enter correct hours time."
export const validateInvoiceItemQuantity = "Please enter number of qunatity."
export const validateInvoiceItemAmount = "Amount should not be zero or negative."