import React from "react";
import "../../assets/styles/managepages.css";

const UnpaidInvoices = (props) =>{

    return (
        <>
            <div className="dashboard-tile">
                    <h3>{"Unpaid Invoices"}</h3>
                    <p className="description">Billed to Client</p>
                    <div className="dashboard-content left">
                        <h5>{"Total Count :"}</h5>
                        <p>{`${props.unpaidTotalValue.totalCount ? props.unpaidTotalValue.totalCount : "--"}`}</p>
                    </div>
                    <div className="dashboard-content right">
                        <h5>{"Total Amount :"}</h5>
                        <p>{`${props.unpaidTotalValue.totalAmount ? props.unpaidTotalValue.totalAmount : "--"}`}</p>
                    </div>
            </div>
        </>
    )
}

export default UnpaidInvoices;