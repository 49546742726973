import React, { useState, useEffect } from "react";
import "../../assets/styles/addcontent.css";
import {
  addInvoiceAPI,
  updateInvoiceAPI,
  getProjectByIdAPI,
  getProjectPurchaseOrdersAPI,
} from "../../utils/api-helper";
import { useNavigate, useLocation } from "react-router-dom";
import { selectStatus } from "../../utils/constants";
import Select from "react-select";
import {
  requiredClientNameSelectedErrorMessage,
  requiredProjectNameSelectedErrorMessage,
  requiredInvoiceDateErrorMessage,
  requiredPaymentStatusErrorMessage,
  requiredPaymentTermsErrorMessage,
  requiredCurrencyErrorMessage,
  requiredPaymentModeMessage,
  validatedDateRangeErrorMessage,
  validateCharacterLimitNormalTextFieldErrorMessage,
  validateCharacterLimitDescriptionBoxErrorMessage,
  mandatoryDateErrorMessage,
  requireInvoiceNumber,
} from "../../utils/status-message-helper";
import {
  validateCharacterLimitNormalTextField,
  validateCharacterLimitDescriptionBox,
} from "../../utils/input-validation";
import DataTable from "../../components/Tables/staticTable";
import AddContentButton from "../../components/Buttons/AddContent";
import ModifyContentButton from "../../components/Buttons/ModifyContent";
import DeleteContentButton from "../../components/Buttons/DeleteContent";
import { invoiceItemsDataArray, TotalInvoiceAmount } from "./index";
import { responseData } from "./viewInvoice";
import DatePicker from "../../components/DatePicker";
import {
  currentDate,
  addDateByPaymentDtatus,
} from "../../components/DatePicker/dateFormater";
import Checkbox from "@mui/material/Checkbox";
import EditContentButton from "../../components/Buttons/ModifyContent";
import { Box } from "@mui/system";
import { FormControlLabel, Switch } from "@mui/material";
import { sortRolesByPriority } from "../../utils/roles-helper";
import { getUserData } from "../../utils/api-helper";

export default function CreateInvoice(props) {
  const [clientId, setClientId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [invoiceDescription, setInvoiceDescription] = useState();
  const [invoiceSummary, setInvoiceSummary] = useState("");
  const [invoiceDate, setInvoiceDate] = useState(currentDate);
  const [countryId, setCountryId] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("6");
  const [primaryPaymentStatus, setPrimaryPaymentStatus] = useState("6");
  const [paymentTerm, setPaymentTerms] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [paidDate, setPaidDate] = useState("");
  const [invoiceStartDate, setInvoiceStartDate] = useState("");
  const [invoiceEndDate, setInvoiceEndDate] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [shippingMethod, setShippingMethod] = useState("");
  const [shippingTerm, setShippingTerm] = useState("");
  const [paymentMode, setPaymentMode] = useState("1");
  const [note, setNote] = useState("");
  const [sequenceInvoiceNumber, setSequenceInvoiceNumber] = useState();
  const [
    isCheckedReadyToBilledPrimaryState,
    setIsCheckedReadyToBilledPrimaryState,
  ] = useState(false);
  const [isCheckedReadyToBilled, setIsCheckedReadyToBilled] = useState(false);

  const [invoiceItemsErrMsg, setInvoiceItemsErrMsg] = useState(
    "No Invoice Items added."
  );
  const [invoiceItemsCheck, setInvoiceItemsCheck] = useState(true);
  const [projectIdErrMsg, setProjectIdErrMsg] = useState("");
  const [clientIdErrMsg, setClientIdErrMsg] = useState("");
  const [invoiceDateErrMsg, setInvoiceDateErrMsg] = useState("");
  const [countryIdErrMsg, setCountryIdErrMsg] = useState("");
  const [invoiceTotalAmountErrMsg, setInvoiceTotalAmountErrMsg] = useState("");
  const [paymentStatusErrMsg, setPaymentStatusErrMsg] = useState("");
  const [paymentTermErrMsg, setPaymentTermErrMsg] = useState("");
  const [dueDateErrMsg, setDueDateErrMsg] = useState("");
  const [paidDateErrMsg, setPaidDateErrMsg] = useState("");
  const [invoicePeriodErrMsg, setInvoicePeriodErrMsg] = useState("");
  const [isActiveErrMsg, setIsActiveErrMsg] = useState("");
  const [paymentModeErrMsg, setPaymentModeErrMsg] = useState("");
  const [invoiceDescriptionErrMsg, setInvoiceDescriptionErrMsg] = useState("");
  const [invoiceSummaryErrMsg, setInvoiceSummaryErrMsg] = useState("");
  const [noteErrMsg, setNoteErrMsg] = useState("");
  const [shippingMethodErrMsg, setShippingMethodErrMsg] = useState("");
  const [shippingTermErrMsg, setShippingTermErrMsg] = useState("");
  const [allowEditInvoiceNumber, setAllowEditInvoiceNumber] = useState(false);
  const [invoiceNumError, setInvoiceNumError] = useState(null);
  const [productOrders, setProductOrders] = useState([]);
  const [selectedProductOrderId, setSelectedProductOrderId] = useState(null);

  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);

  function _onFocus(e) {
    e.currentTarget.type = "date";
  }
  function _onBlur(e) {
    e.currentTarget.type = "text";
    e.currentTarget.placeholder = "Enter a Date";
  }
  const [createInvoiceForm, setCreateInvoiceForm] = useState(true);
  useEffect(() => {
    if (props.isEditInvoice) {
      setCreateInvoiceForm(false);
      setEditInvoiceData();
      if (getUserData().roles[0] === "ADMIN") {
        setAllowEditInvoiceNumber(true);
      }
    }
  }, [props.isEditInvoice]);

  useEffect(() => {
    if (props.isMakeCopyInvoice) {
      setCreateInvoiceForm(true);
      setMakeCopyInvoiceData();
    }
  }, [props.isMakeCopyInvoice]);

  useEffect(() => {
    setDueDate(addDateByPaymentDtatus(invoiceDate, paymentTerm));
  }, [invoiceDate, paymentTerm]);

  useEffect(() => {
    getProjectByIdAPI(projectId)
      .then((res) => {
        props.setProjectType(res.data.projectType);
      })
      .catch((err) => {
      });
  }, [projectId]);

  useEffect(() => {
    let result = 0;
    if (invoiceItemsDataArray.length > 0) {
      for (let i = 0; i < invoiceItemsDataArray.length; i++) {
        result = parseFloat(result) + parseFloat(invoiceItemsDataArray[i].itemAmount);
      }
    } else {
      props.setInvoiceTotalAmount(0);
    }
    props.setInvoiceTotalAmount(result.toFixed(2));
  });

  const setEditInvoiceData = () => {
    setProjectId(responseData.projectId);
    setInvoiceDescription(responseData.invoiceDescription);
    setInvoiceSummary(responseData.invoiceSummary);
    setInvoiceDate(responseData.invoiceDate);
    setCountryId(responseData.countryId);
    props.setInvoiceTotalAmount(responseData.invoiceTotalAmount);
    setPaymentStatus(responseData.paymentStatusId);
    setPrimaryPaymentStatus(responseData.paymentStatusId);
    setPaymentTerms(responseData.paymentTermId);
    setDueDate(responseData.dueDate);
    setPaidDate(responseData.paidDate);
    setInvoiceStartDate(responseData.invoiceStartDate);
    setInvoiceEndDate(responseData.invoiceEndDate);
    setIsActive(responseData.isActive);
    setShippingMethod(responseData.shippingMethod);
    setShippingTerm(responseData.shippingTerm);
    setPaymentMode(responseData.paymentModeId);
    setNote(responseData.note);
    setIsCheckedReadyToBilled(responseData.readyToBilledToClient);
    setIsCheckedReadyToBilledPrimaryState(responseData.readyToBilledToClient);
    setSequenceInvoiceNumber(responseData.invoiceNumber);
  };
  const setMakeCopyInvoiceData = () => {
    setProjectId(responseData.projectId);
    setInvoiceDescription(responseData.invoiceDescription);
    setInvoiceSummary(responseData.invoiceSummary);
    setCountryId(responseData.countryId);
    props.setInvoiceTotalAmount(responseData.invoiceTotalAmount);
    setPaymentTerms(responseData.paymentTermId);
    setShippingMethod(responseData.shippingMethod);
    setShippingTerm(responseData.shippingTerm);
    setPaymentMode(responseData.paymentModeId);
    setNote(responseData.note);
  };
  const handleCreateInvoice = (e) => {
    if (validateForm()) {
      const body = {
        countryId: countryId,
        dueDate: dueDate,
        invoiceDate: invoiceDate,
        invoiceDescription: invoiceDescription,
        invoiceEndDate: invoiceEndDate,
        invoiceStartDate: invoiceStartDate,
        invoiceSummary: invoiceSummary,
        invoiceTotalAmount: props.invoiceTotalAmount,
        isActive: isActive,
        note: note,
        paidDate: paidDate,
        paymentMode: paymentMode,
        paymentStatus: paymentStatus,
        paymentTerm: paymentTerm,
        projectId: projectId,
        shippingMethod: shippingMethod,
        shippingTerm: shippingTerm,
        invoiceItems: invoiceItemsDataArray,
        readyToBilledToClient: false,
        purchaseOrderId: selectedProductOrderId ? selectedProductOrderId : null,
      };
      props.setStartLoader(true);
      addInvoiceAPI(body)
        .then((res) => {
          props.getAllInvoices();
          props.setStartLoader(false);
          props.handleInvoiceSaved(res.data.id);
        })
        .catch((err) => {
          // console.log(err);

          if (typeof err.response !== "undefined") {
            setSuccess(false);
            props.setAlertPopupMessage(err.response?.data.message);
            props.setAlertPopupWindow({ is: true, type: "alert" });
            props.setStartLoader(false);
          }
        });
    }
  };
  var search = useLocation().search;
  const handleEditInvoice = (e) => {
    var editInvoiceId = new URLSearchParams(search).get("id");
    if (validateForm()) {
      const body = {
        id: editInvoiceId,
        countryId: countryId,
        dueDate: dueDate,
        invoiceDate: invoiceDate,
        invoiceDescription: invoiceDescription,
        invoiceEndDate: invoiceEndDate,
        invoiceStartDate: invoiceStartDate,
        invoiceSummary: invoiceSummary,
        invoiceTotalAmount: props.invoiceTotalAmount,
        isActive: isActive,
        note: note,
        paidDate: paidDate,
        paymentMode: paymentMode,
        paymentStatus: paymentStatus,
        paymentTerm: paymentTerm,
        projectId: projectId,
        shippingMethod: shippingMethod,
        shippingTerm: shippingTerm,
        invoiceItems: invoiceItemsDataArray,
        readyToBilledToClient: isCheckedReadyToBilled,
        invoiceNumber: sequenceInvoiceNumber,
        purchaseOrderId: selectedProductOrderId ? selectedProductOrderId : null,
      };
      props.setStartLoader(true);
      updateInvoiceAPI(body)
        .then((res) => {
          invoiceItemsDataArray.splice(0, invoiceItemsDataArray.length);
          props.getAllInvoices();
          props.setStartLoader(false);
          props.handleInvoiceSaved(editInvoiceId);
        })
        .catch((err) => {
          if (typeof err.response !== "undefined") {
            setSuccess(false);
            props.setAlertPopupMessage(err.response?.data.message);
            props.setAlertPopupWindow({ is: true, type: "alert" });
            props.setStartLoader(false);
          }
        });
    }
  };

  function checkInvoiceItems() {
    setInvoiceItemsCheck(false);
    if (invoiceItemsDataArray.length > 0) {
      setInvoiceItemsErrMsg("No Invoice Items added.");
      return true;
    } else {
      setInvoiceItemsErrMsg("Please add Invoice Items to the list.");
      return false;
    }
  }
  function checkProjectName() {
    if (projectId) {
      setProjectIdErrMsg("");
      return true;
    } else {
      setProjectIdErrMsg(requiredProjectNameSelectedErrorMessage);
      return false;
    }
  }
  function checkInvoiceNumber() {
    if (props.isEditInvoice) {
      if (sequenceInvoiceNumber) {
        setInvoiceNumError("");
        return true;
      } else {
        setInvoiceNumError(requireInvoiceNumber);
        return false;
      }
    } else {
      return true;
    }
  }
  function checkInvoiceDate() {
    if (invoiceDate) {
      setInvoiceDateErrMsg("");
      return true;
    } else {
      setInvoiceDateErrMsg(requiredInvoiceDateErrorMessage);
      return false;
    }
  }
  function checkPaymentStatus() {
    if (paymentStatus) {
      setPaymentStatusErrMsg("");
      return true;
    } else {
      setPaymentStatusErrMsg(requiredPaymentStatusErrorMessage);
      return false;
    }
  }
  function checkPaymentTerms() {
    if (paymentTerm) {
      setPaymentTermErrMsg("");
      return true;
    } else {
      setPaymentTermErrMsg(requiredPaymentTermsErrorMessage);
      return false;
    }
  }
  function checkCurrencyName() {
    if (countryId) {
      setCountryIdErrMsg("");
      return true;
    } else {
      setCountryIdErrMsg(requiredCurrencyErrorMessage);
      return false;
    }
  }
  function checkPaymentMode() {
    if (paymentMode) {
      setPaymentModeErrMsg("");
      return true;
    } else {
      setPaymentModeErrMsg(requiredPaymentModeMessage);
      return false;
    }
  }
  function checkInvoicePeriod() {
    if (invoiceStartDate && invoiceEndDate) {
      if (invoiceStartDate <= invoiceEndDate) {
        setInvoicePeriodErrMsg("");
        return true;
      } else {
        setInvoicePeriodErrMsg(validatedDateRangeErrorMessage);
        return false;
      }
    } else {
      setInvoicePeriodErrMsg(mandatoryDateErrorMessage);
      return false;
    }
  }
  function checkInvoiceDescription() {
    if (invoiceDescription) {
      if (validateCharacterLimitDescriptionBox(invoiceDescription)) {
        setInvoiceDescriptionErrMsg("");
        return true;
      } else {
        setInvoiceDescriptionErrMsg(
          validateCharacterLimitDescriptionBoxErrorMessage
        );
        return false;
      }
    } else {
      setInvoiceDescriptionErrMsg("");
      return true;
    }
  }
  function checkInvoiceSummary() {
    if (invoiceSummary) {
      if (validateCharacterLimitDescriptionBox(invoiceSummary)) {
        setInvoiceSummaryErrMsg("");
        return true;
      } else {
        setInvoiceSummaryErrMsg(
          validateCharacterLimitDescriptionBoxErrorMessage
        );
        return false;
      }
    } else {
      setInvoiceSummaryErrMsg("");
      return true;
    }
  }
  function checkInvoiceNote() {
    if (note) {
      if (validateCharacterLimitDescriptionBox(note)) {
        setNoteErrMsg("");
        return true;
      } else {
        setNoteErrMsg(validateCharacterLimitDescriptionBoxErrorMessage);
        return false;
      }
    } else {
      setNoteErrMsg("");
      return true;
    }
  }
  function checkShippingMethod() {
    if (shippingMethod) {
      if (validateCharacterLimitNormalTextField(shippingMethod)) {
        setShippingMethodErrMsg("");
        return true;
      } else {
        setShippingMethodErrMsg(
          validateCharacterLimitNormalTextFieldErrorMessage
        );
        return false;
      }
    } else {
      setShippingMethodErrMsg("");
      return true;
    }
  }
  function checkShippingTerm() {
    if (shippingTerm) {
      if (validateCharacterLimitNormalTextField(shippingTerm)) {
        setShippingTermErrMsg("");
        return true;
      } else {
        setShippingTermErrMsg(
          validateCharacterLimitNormalTextFieldErrorMessage
        );
        return false;
      }
    } else {
      setShippingTermErrMsg("");
      return true;
    }
  }
  function validateForm() {
    let isValid = true;
    if (
      checkProjectName() &&
      checkInvoiceDate() &&
      checkPaymentStatus() &&
      checkPaymentTerms() &&
      checkCurrencyName() &&
      checkPaymentMode() &&
      checkInvoicePeriod() &&
      checkInvoiceDescription() &&
      checkInvoiceSummary() &&
      checkInvoiceNote() &&
      checkShippingMethod() &&
      checkShippingTerm() &&
      checkInvoiceItems() &&
      checkInvoiceNumber()
    ) {
      return (isValid = true);
    } else {
      checkProjectName();
      checkInvoiceDate();
      checkPaymentStatus();
      checkPaymentTerms();
      checkCurrencyName();
      checkPaymentMode();
      checkInvoicePeriod();
      checkInvoiceDescription();
      checkInvoiceSummary();
      checkInvoiceNote();
      checkShippingMethod();
      checkShippingTerm();
      checkInvoiceItems();
      checkInvoiceNumber();
      isValid = false;
    }
    return isValid;
  }
  const decimal = (value) => {
    let decimalValue = parseFloat(value);
    decimalValue.toFixed(2);
    return decimalValue.toFixed(2);
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    if (
      (primaryPaymentStatus !== paymentStatus &&
        ((primaryPaymentStatus === 6 && paymentStatus === 2) ||
          (primaryPaymentStatus === 2 && paymentStatus === 1))) ||
      (isCheckedReadyToBilled !== isCheckedReadyToBilledPrimaryState &&
        isCheckedReadyToBilled)
    ) {
      if (primaryPaymentStatus === 6 && paymentStatus === 2) {
        props.setAlertPopupMessage(
          `Invoice status will be changed to "Pending"\n
                    This action will send a notification to accounts team for further processing of this invoice, are you sure you want to continue?`
        );
      } else if (primaryPaymentStatus === 2 && paymentStatus === 1) {
        props.setAlertPopupMessage(`
                Invoice status will be changed to "Billed to client"\n
                This action will assign a permanent invoice number, are you sure you want to continue?
                `);
      } else if (
        isCheckedReadyToBilled !== isCheckedReadyToBilledPrimaryState &&
        isCheckedReadyToBilled
      ) {
        props.setAlertPopupMessage(`
                This action will notify PM that the invoice is ready to send to client\n 
                so that PM can send a final confirmation. Are you sure you want to continue?
                `);
      }
      props.setAlertPopupWindow({ is: true, type: "check" });
    } else {
      callAPIafterApproval();
    }
  };
  const callAPIafterApproval = () => {
    if (createInvoiceForm) {
      handleCreateInvoice();
    } else {
      handleEditInvoice();
    }
  };
  useEffect(() => {
    if (props.getAlertReponse) {
      callAPIafterApproval();
      props.setAlertReponse(false);
      props.setAlertPopupWindow({ is: false, type: "check" });
    }
  }, [props.getAlertReponse]);

  const handleReadyToBilledChange = (event) => {
    setIsCheckedReadyToBilled(event.target.checked);
  };

  const fetchProductOrders = () => {
    if (projectId) {
      getProjectPurchaseOrdersAPI(projectId)
        .then((res) => {
          setProductOrders(res.data);
        })
        .catch((err) => {
          if (err.data) {
            setProductOrders(err.data);
          }
        })

    } else {
      setProductOrders([]);
    }
  }
  useEffect(() => {
    if (projectId) {
      fetchProductOrders();
    }
  }, [projectId]);

  const handleProductOrderChange = (event) => {
    const selectedId = event.target.value;
    setSelectedProductOrderId(selectedId);
  };

  function getPreviousWeekMondayAndSunday() {
    const formatDate = (date) => date.toISOString().split('T')[0];
    const today = new Date();

    const currentDay = today.getDay();
    const daysUntilMonday = (currentDay + 6) % 7;
    const currentWeekMonday = new Date(today);
    currentWeekMonday.setDate(today.getDate() - daysUntilMonday);

    // Calculate the previous week's Monday
    const previousWeekMonday = new Date(currentWeekMonday);
    previousWeekMonday.setDate(currentWeekMonday.getDate() - 7);

    // Calculate the previous week's Sunday
    const previousWeekSunday = new Date(previousWeekMonday);
    previousWeekSunday.setDate(previousWeekMonday.getDate() + 6); // Add 6 days to Monday to get Sunday

    setInvoiceStartDate(previousWeekMonday);
    setInvoiceEndDate(previousWeekSunday);
    // return {
    //     previousWeekMonday: formatDate(previousWeekMonday),
    //     previousWeekSunday: formatDate(previousWeekSunday)
    // };
  }

  function resetStartEndDate() {
    setInvoiceStartDate('');
    setInvoiceEndDate('');
  }

  return (
    <>
      <div className="inner-content-formData">
        <form
          className="form"
          method="POST"
          id="AddInvoice"
          onSubmit={(e) => onHandleSubmit(e)}
        >
          <div className="form-container">
            <div className="flex-form-container">
              {props.isEditInvoice && (
                <div className="invoiceNumber form_input">
                  <div className="inputLabel left-flex">
                    <label>
                      Invoice Number<span className="errmsg">*</span>:
                    </label>
                  </div>
                  <input
                    type="text"
                    id="invoiceNumber"
                    placeholder="Invoice Number"
                    name="invoiceNumber"
                    onChange={(e) => setSequenceInvoiceNumber(e.target.value)}
                    value={sequenceInvoiceNumber}
                    disabled={!allowEditInvoiceNumber}
                  />
                  <div className="form_message form_message-error left-flex">
                    <p
                      className={invoiceNumError ? "errmsg" : "offscreen"}
                      aria-live="assertive"
                    >
                      {invoiceNumError}
                    </p>
                  </div>
                </div>
              )}
              <div className="projectId form_input">
                <div className="inputLabel left-flex">
                  <label>
                    Project Name<span className="errmsg">*</span>:
                  </label>
                </div>
                <Select
                  type="text"
                  id="projectId"
                  styles={{ width: "100%", float: "left" }}
                  placeholder={
                    <div className="select-placeholder-text">
                      Select Project
                    </div>
                  }
                  defaultValue={
                    props.isEditInvoice
                      ?   {
                        label: responseData.projectName,
                        value: responseData.projectId,
                      }
                      : props.isMakeCopyInvoice
                        ? {
                          label: responseData.projectName,
                          value: responseData.projectId,
                        }
                        : ""
                  }
                  name="projectId"
                  isDisabled={
                    props.isEditInvoice
                      ? responseData.paymentStatusId === 2 ||
                        responseData.paymentStatusId === 6
                        ? false
                        : true
                      : false
                  }
                  options={props.getAllProjects}
                  isClearable={false}
                  onChange={(e) => {
                    // console.log('pr', e)
                    if (!e) {
                      e = { value: "" };
                    }
                    setProjectId(e.value);

                    // if (!props.isEditInvoice) {
                    //   let isUpWork = props.checkIsUpwork(e.value);
                    //   console.log(isUpWork)
                    //   isUpWork ? getPreviousWeekMondayAndSunday() : resetStartEndDate();
                    // }
                  }}
                />
                <div className="form_message form_message-error left-flex">
                  <p
                    className={projectIdErrMsg ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {projectIdErrMsg}
                  </p>
                </div>
              </div>
              <div className="productOrder form_input">
                <div className="inputLabel left-flex">
                  <label>Purchase Order:</label>
                </div>
                <select
                  id="productOrder"
                  value={selectedProductOrderId}
                  onChange={handleProductOrderChange}
                  placeholder={
                    <div className="select-placeholder-text">
                      Select Purcahse Orders
                    </div>
                  }
                >
                  <option value="">None</option>
                  {productOrders.map((order) => (
                    <option key={order.id} value={order.id}>
                      {order.poNo}
                    </option>
                  ))}
                </select>
              </div>
              <div className="invoiceDateInput">
                <div className="inputLabel left-flex">
                  <label htmlFor="invoiceDateLabel">
                    Invoice Date<span className="errmsg">*</span>:
                  </label>
                </div>
                {props.isEditInvoice ? (
                  responseData.paymentStatusId === 2 ||
                    responseData.paymentStatusId === 6 ? (
                    <div className="datePickerInput">
                      <DatePicker
                        style={{ width: "37em" }}
                        date={invoiceDate}
                        setDate={setInvoiceDate}
                        disabled={false}
                      />
                    </div>
                  ) : (
                    <div className="disabled-datePickerInput">
                      <DatePicker
                        date={invoiceDate}
                        setDate={setInvoiceDate}
                        disabled={true}
                      />
                    </div>
                  )
                ) : (
                  <div className="datePickerInput">
                    <DatePicker
                      date={invoiceDate}
                      setDate={setInvoiceDate}
                      disabled={false}
                    />
                  </div>
                )}
                <div className="form_message form_message-error left-flex">
                  <p
                    className={invoiceDateErrMsg ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {invoiceDateErrMsg}
                  </p>
                </div>
              </div>
              <div className="paymentTerm form_input">
                <div className="inputLabel left-flex">
                  <label htmlFor="paymentTerm">
                    Payment Terms<span className="errmsg">*</span>:
                  </label>
                </div>
                <Select
                  type="text"
                  id="paymentTerm"
                  styles={{ width: "100%", float: "left" }}
                  placeholder={
                    <div className="select-placeholder-text">Select Terms</div>
                  }
                  defaultValue={
                    props.isEditInvoice || props.isMakeCopyInvoice
                      ? {
                        label: responseData.paymentTerm,
                        value: responseData.paymentTermId,
                      }
                      : ""
                  }
                  name="paymentTerm"
                  options={props.getPaymentTerms}
                  isClearable={true}
                  isDisabled={
                    props.isEditInvoice
                      ? responseData.paymentStatusId === 2 ||
                        responseData.paymentStatusId === 6
                        ? false
                        : true
                      : false
                  }
                  onChange={(e) => {
                    if (!e) {
                      e = { value: "" };
                    }
                    setPaymentTerms(e.value);
                  }}
                />
                <div className="form_message form_message-error left-flex">
                  <p
                    className={paymentTermErrMsg ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {paymentTermErrMsg}
                  </p>
                </div>
              </div>
              <div className="dueDate">
                <div className="inputLabel left-flex">
                  <label htmlFor="dueDate">Invoice Due Date:</label>
                </div>
                {props.isEditInvoice ? (
                  responseData.paymentStatusId === 2 ||
                    responseData.paymentStatusId === 6 ? (
                    <div className="datePickerInput" style={{ width: "40em" }}>
                      <DatePicker
                        date={dueDate}
                        setDate={setDueDate}
                        disabled={false}
                      />
                    </div>
                  ) : (
                    <div className="disabled-datePickerInput">
                      <DatePicker
                        date={dueDate}
                        setDate={setDueDate}
                        disabled={true}
                      />
                    </div>
                  )
                ) : (
                  <div className="datePickerInput" style={{ width: "40em" }}>
                    <DatePicker
                      date={dueDate}
                      setDate={setDueDate}
                      disabled={false}
                    />
                  </div>
                )}
                <div className="form_message form_message-error left-flex">
                  <p
                    className={dueDateErrMsg ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {dueDateErrMsg}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-form-container">
              <div className="paymentStatus form_input">
                <div className="inputLabel left-flex">
                  <label htmlFor="paymentStatus">
                    Invoice Payment Status<span className="errmsg">*</span>:
                  </label>
                </div>
                <Select
                  type="text"
                  id="paymentStatus"
                  styles={{ width: "100%", float: "left" }}
                  placeholder={
                    <div className="select-placeholder-text">Select Status</div>
                  }
                  defaultValue={
                    props.isEditInvoice
                      ? {
                        label: responseData.paymentStatus,
                        value: responseData.paymentStatusId,
                      }
                      : {
                        label: "DRAFT",
                        value: 6,
                      }
                  }
                  name="paymentStatus"
                  options={props.getPaymentStatus}
                  isClearable={true}
                  onChange={(e) => {
                    if (!e) {
                      e = { value: "" };
                    }
                    setPaymentStatus(e.value);
                  }}
                />
                <div className="form_message form_message-error left-flex">
                  <p
                    className={paymentStatusErrMsg ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {paymentStatusErrMsg}
                  </p>
                </div>
              </div>
              <div className="countryId form_input">
                <div className="inputLabel left-flex">
                  <label>
                    Currency Name<span className="errmsg">*</span>:
                  </label>
                </div>
                <Select
                  type="text"
                  id="countryId"
                  styles={{ width: "100%", float: "left" }}
                  placeholder={
                    <div className="select-placeholder-text">
                      Select Currency
                    </div>
                  }
                  defaultValue={
                    props.isEditInvoice
                      ? {
                        label: responseData.currencyName,
                        value: responseData.countryId,
                      }
                      : props.isMakeCopyInvoice
                        ? {
                          label: responseData.currencyName,
                          value: responseData.countryId,
                        }
                        : ""
                  }
                  name="countryId"
                  options={props.getAllCountries}
                  isDisabled={
                    props.isEditInvoice
                      ? responseData.paymentStatusId === 2 ||
                        responseData.paymentStatusId === 6
                        ? false
                        : true
                      : false
                  }
                  isClearable={true}
                  onChange={(e) => {
                    if (!e) {
                      e = { value: "" };
                    }
                    setCountryId(e.value);
                  }}
                />
                <div className="form_message form_message-error left-flex">
                  <p
                    className={countryIdErrMsg ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {countryIdErrMsg}
                  </p>
                </div>
              </div>
              <div className="paymentMode form_input">
                <div className="inputLabel left-flex">
                  <label htmlFor="paymentMode">
                    Payment Mode<span className="errmsg">*</span>:
                  </label>
                </div>
                <Select
                  type="text"
                  id="paymentMode"
                  styles={{ width: "100%", float: "left" }}
                  placeholder={
                    <div className="select-placeholder-text">
                      Select Payment Mode
                    </div>
                  }
                  defaultValue={
                    props.isEditInvoice
                      ? {
                        label: responseData.paymentMode,
                        value: responseData.paymentModeId,
                      }
                      : props.isMakeCopyInvoice
                        ? {
                          label: responseData.paymentMode,
                          value: responseData.paymentModeId,
                        }
                        : {
                          label: "Bank",
                          value: 1,
                        }
                  }
                  name="paymentMode"
                  options={props.getPaymentMode}
                  isDisabled={
                    props.isEditInvoice
                      ? responseData.paymentStatusId === 2 ||
                        responseData.paymentStatusId === 6
                        ? false
                        : true
                      : false
                  }
                  isClearable={true}
                  onChange={(e) => {
                    if (!e) {
                      e = { value: "" };
                    }
                    setPaymentMode(e.value);
                  }}
                />
                <div className="form_message form_message-error">
                  <p
                    className={paymentModeErrMsg ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {paymentModeErrMsg}
                  </p>
                </div>
              </div>
              <div className="input-flex">
                <div className="invoiceStartDate form_input left-flex">
                  <div className="inputLabel left-flex">
                    <label htmlFor="invoiceStartDate">
                      Invoice Start Date<span className="errmsg">*</span>:
                    </label>
                  </div>
                  {props.isEditInvoice ? (
                    responseData.paymentStatusId === 2 ||
                      responseData.paymentStatusId === 6 ? (
                      <div>
                        <DatePicker
                          date={invoiceStartDate}
                          setDate={setInvoiceStartDate}
                          style={{ width: "18.2em" }}
                          disabled={
                            !(
                              responseData.paymentStatusId === 2 ||
                              responseData.paymentStatusId === 6
                            )
                          }
                        />
                      </div>
                    ) : (
                      <div className="disabled-datePickerInput">
                        <DatePicker
                          date={invoiceStartDate}
                          setDate={setInvoiceStartDate}
                          disabled={true}
                          style={{ width: "18.2em" }}
                        />
                      </div>
                    )
                  ) : (
                    <div className="datePickerInput">
                      <DatePicker
                        date={invoiceStartDate}
                        setDate={setInvoiceStartDate}
                        disabled={false}
                        style={{ width: "18.2em" }}
                      />
                    </div>
                  )}
                </div>
                <div className="invoiceEndDate form_input right-flex">
                  <div className="inputLabel left-flex">
                    <label htmlFor="invoiceEndDate">
                      Invoice End Date<span className="errmsg">*</span>:
                    </label>
                  </div>
                  {props.isEditInvoice ? (
                    responseData.paymentStatusId === 2 ||
                      responseData.paymentStatusId === 6 ? (
                      <div className="datePickerInput">
                        <DatePicker
                          date={invoiceEndDate}
                          setDate={setInvoiceEndDate}
                          disabled={false}
                          minDate={invoiceStartDate}
                        />
                      </div>
                    ) : (
                      <div className="disabled-datePickerInput">
                        <DatePicker
                          date={invoiceEndDate}
                          setDate={setInvoiceEndDate}
                          disabled={true}
                          minDate={invoiceStartDate}
                        />
                      </div>
                    )
                  ) : (
                    <div className="datePickerInput">
                      <DatePicker
                        date={invoiceEndDate}
                        setDate={setInvoiceEndDate}
                        disabled={false}
                        minDate={invoiceStartDate}
                      />
                    </div>
                  )}
                </div>
                <div className="form_message form_message-error left-flex">
                  <p
                    className={invoicePeriodErrMsg ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {invoicePeriodErrMsg}
                  </p>
                </div>
              </div>

              {paymentStatus === 3 || paymentStatus === 4 ? (
                <div className="paidDate form_input">
                  <div className="inputLabel left-flex">
                    <label htmlFor="paidDate">Invoice Paid Date:</label>
                  </div>
                  <div className="datePickerInput">
                    <DatePicker
                      date={paidDate}
                      setDate={setPaidDate}
                    />
                  </div>
                  <div className="form_message form_message-error left-flex">
                    <p
                      className={paidDateErrMsg ? "errmsg" : "offscreen"}
                      aria-live="assertive"
                    >
                      {paidDateErrMsg}
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="form_message form_message-error"></div>
          </div>
          <div>
            {props.isEditInvoice ? (
              responseData.paymentStatusId === 2 ||
                responseData.paymentStatusId === 6 ? (
                <div className="itemBox-functional_elements  left-flex">
                  <div
                    className="functional_elementsForItem"
                    styles={{ padding: "12px", fontWeight: 500, fontSize: 18 }}
                  >
                    <p>Add Invoice Items</p>
                  </div>
                  <div
                    className="functional_elementsForItem"
                    onClick={props.getAddInvoiceItemsPage}
                  >
                    <AddContentButton />
                  </div>
                </div>
              ) : (
                <></>
              )
            ) : (
              <div className="itemBox-functional_elements left-flex">
                <div
                  className="functional_elementsForItem"
                  style={{ padding: "12px", fontWeight: 500, fontSize: 18 }}
                >
                  <p>Add Invoice Items</p>
                </div>
                <div
                  className="functional_elementsForItem"
                  onClick={props.getAddInvoiceItemsPage}
                >
                  <AddContentButton />
                </div>
              </div>
            )}
            <div className="flex-form-container">
              {invoiceItemsDataArray.length > 0 ? (
                <table className="flex-form-table">
                  <thead>
                    <tr>
                      <th styles={{ width: "30%" }}>Item Description</th>
                      <th style={{ width: "15%" }}>SAC Code</th>
                      <th style={{ width: "10%" }}>Qty</th>
                      <th style={{ width: "10%" }}>Hours</th>
                      <th style={{ width: "10%" }}>Rate</th>
                      <th style={{ width: "10%" }}>Amount</th>
                      {props.isEditInvoice ? (
                        responseData.paymentStatusId === 2 ||
                          responseData.paymentStatusId === 6 ? (
                          <th style={{ width: "15%" }}>Actions</th>
                        ) : (
                          <></>
                        )
                      ) : (
                        <th style={{ width: "15%" }}>Actions</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceItemsDataArray.length > 0 ? (
                      invoiceItemsDataArray.map((row, index) => (
                        <tr key={index}>
                          <td>{row.itemDescription}</td>
                          <td>{row.sacCode}</td>
                          {row.quantity == 0 ? (
                            <td>- -</td>
                          ) : (
                            <td>{row.quantity}</td>
                          )}
                          {decimal(row.hours) == 0 ? (
                            <td>- -</td>
                          ) : (
                            <td>{decimal(row.hours)}</td>
                          )}
                          {decimal(row.hourlyRate) == 0 ? (
                            <td>- -</td>
                          ) : (
                            <td>{row.hourlyRate}</td>
                          )}
                          <td>{row.itemAmount}</td>
                          {props.isEditInvoice ? (
                            responseData.paymentStatusId === 2 ||
                              responseData.paymentStatusId === 6 ? (
                              <td>
                                {props.isEditInvoice ? (
                                  responseData.paymentStatusId === 2 ||
                                    responseData.paymentStatusId === 6 ? (
                                    <>
                                      <div className="editAction">
                                        <div
                                          className="functional_elements"
                                          onClick={() => {
                                            props.getEditInvoiceItemPage(index);
                                          }}
                                        >
                                          <ModifyContentButton />
                                        </div>
                                      </div>
                                      <div className="deleteAction">
                                        <div
                                          className="functional_elements"
                                          onClick={() => {
                                            props.getDeleteInvoiceItemPage(
                                              index,
                                              row.itemDescription
                                            );
                                          }}
                                        >
                                          <DeleteContentButton />
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <>
                                    <div className="editAction">
                                      <div
                                        className="functional_elements"
                                        onClick={() => {
                                          props.getEditInvoiceItemPage(index);
                                        }}
                                      >
                                        <ModifyContentButton />
                                      </div>
                                    </div>
                                    <div className="deleteAction">
                                      <div
                                        className="functional_elements"
                                        onClick={() => {
                                          props.getDeleteInvoiceItemPage(
                                            index,
                                            row.itemDescription
                                          );
                                        }}
                                      >
                                        <DeleteContentButton />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </td>
                            ) : (
                              <></>
                            )
                          ) : (
                            <td>
                              {props.isEditInvoice ? (
                                responseData.paymentStatusId === 2 ||
                                  responseData.paymentStatusId === 6 ? (
                                  <>
                                    <div className="editAction">
                                      <div
                                        className="functional_elements"
                                        onClick={() => {
                                          props.getEditInvoiceItemPage(index);
                                        }}
                                      >
                                        <ModifyContentButton />
                                      </div>
                                    </div>
                                    <div className="deleteAction">
                                      <div
                                        className="functional_elements"
                                        onClick={() => {
                                          props.getDeleteInvoiceItemPage(
                                            index,
                                            row.itemDescription
                                          );
                                        }}
                                      >
                                        <DeleteContentButton />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )
                              ) : (
                                <>
                                  <div className="editAction">
                                    <div
                                      className="functional_elements"
                                      onClick={() => {
                                        props.getEditInvoiceItemPage(index);
                                      }}
                                    >
                                      <ModifyContentButton />
                                    </div>
                                  </div>
                                  <div className="deleteAction">
                                    <div
                                      className="functional_elements"
                                      onClick={() => {
                                        props.getDeleteInvoiceItemPage(
                                          index,
                                          row.itemDescription
                                        );
                                      }}
                                    >
                                      <DeleteContentButton />
                                    </div>
                                  </div>
                                </>
                              )}
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr className="itemTable-flex">
                        {/* {
                                                invoiceItemsCheck ?
                                                    <td>
                                                        <p>&emsp;&emsp;{invoiceItemsErrMsg}</p>
                                                    </td>
                                                    :
                                                    <td className="form_message form_message-error left-flex">
                                                        <p
                                                            className={invoiceItemsDataArray.length > 0 ? "errmsg" : "offscreen"}
                                                            aria-live="assertive"
                                                        >&emsp;&emsp;{invoiceItemsErrMsg}</p>
                                                    </td>
                                            } */}
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                <div className="itemTable-flex">
                  {invoiceItemsCheck ? (
                    <div>
                      <p>&emsp;&emsp;{invoiceItemsErrMsg}</p>
                    </div>
                  ) : (
                    <div className="form_message form_message-error left-flex">
                      <p
                        className={
                          invoiceItemsDataArray.length > 0
                            ? "errmsg"
                            : "offscreen"
                        }
                        aria-live="assertive"
                      >
                        &emsp;&emsp;{invoiceItemsErrMsg}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="form-container">
            <div className="flex-form-container">
              <div className="note form_input">
                <div className="inputLabel left-flex">
                  <label htmlFor="note">Invoice Note:</label>
                </div>
                <textarea
                  type="text"
                  id="note"
                  style={{ resize: "none" }}
                  placeholder="Invoice note"
                  name="note"
                  onChange={(e) => setNote(e.target.value)}
                  value={note}
                  disabled={
                    props.isEditInvoice
                      ? responseData.paymentStatusId === 2 ||
                        responseData.paymentStatusId === 6
                        ? false
                        : true
                      : false
                  }
                />
                <div className="form_message form_message-error left-flex">
                  <p
                    className={noteErrMsg ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {noteErrMsg}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-form-container">
              <div className="invoiceTotalAmount form_input">
                <div className="invoiceTotalAmount left-flex">
                  <label htmlFor="invoiceTotalAmount">Total Amount:</label>
                </div>

                {/* <div className="invoiceTotalAmount left-flex">
                                <div className="itemTable-flex">
                                    <p>1000</p>
                                </div>
                            </div> */}
                <input
                  type="number"
                  id="invoiceTotalAmount"
                  placeholder={props.invoiceTotalAmount}
                  name="invoiceTotalAmount"
                  onChange={(e) => props.setInvoiceTotalAmount(e.target.value)}
                  value={decimal(props.invoiceTotalAmount)}
                  disabled
                />
                <div className="form_message form_message-error left-flex">
                  <p
                    className={
                      invoiceTotalAmountErrMsg ? "errmsg" : "offscreen"
                    }
                    aria-live="assertive"
                  >
                    {invoiceTotalAmountErrMsg}
                  </p>
                </div>
              </div>
              <div className="isActive form_input">
                <div className="inputLabel left-flex">
                  <label htmlFor="isActive">Invoice Status:</label>
                </div>
                <Select
                  type="text"
                  id="projectId"
                  styles={{ width: "100%", float: "left" }}
                  placeholder={
                    <div className="select-placeholder-text">Active</div>
                  }
                  name="projectId"
                  options={selectStatus}
                  onChange={(e) => setIsActive(e.value)}
                  isDisabled={
                    props.isEditInvoice
                      ? responseData.paymentStatusId === 2 ||
                        responseData.paymentStatusId === 6
                        ? false
                        : true
                      : true
                  }
                />
              </div>
              {props.isEditInvoice && paymentStatus == "2" && (
                <div>
                  <span style={{ float: "left" }}>
                    <Checkbox
                      checked={isCheckedReadyToBilled}
                      onChange={(e) => handleReadyToBilledChange(e)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </span>
                  <label
                    htmlFor=""
                    style={{ padding: "10px", fontWeight: 500, fontSize: 16 }}
                  >
                    {responseData.readyToBilledToClient
                      ? "Already Notified to PM"
                      : "Notify PM : Ready to Bill"}
                  </label>
                </div>
              )}
              <button type="submit" className="saveButton right-flex">
                SAVE
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
