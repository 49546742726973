import React from 'react'
import '../buttons.css'
import { useNavigate } from "react-router-dom";
export default function Index(props) {

    let history = useNavigate();
    
    function handleClick() {
        history(props.to);
    }
    return (
        <>
            <div className="sortingContent-icon" onClick={handleClick} title="Sort"></div>
        </>
    )
}