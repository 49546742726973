import React from 'react';
import "../../assets/styles/components.css";
import { ReactComponent as ErrorIcon } from "../../assets/icons/errorIcon.svg";

const ErrorPopup = ({ errorMessage, onClose }) => {
  return (
    <div className="error-popup">
      <div className="error-content">
        <span>
          <ErrorIcon />
        </span>
        <h2 className="error-heading" style={{color: "red"}}>Error</h2>
        <p className="error-message">{errorMessage}</p>
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ErrorPopup;
