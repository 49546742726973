import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/Navigation";
import "../../assets/styles/managepages.css";
import GetAllClients from "./getAllClients";
import CreateNewClient from "./createNewClient";
import FilterClient from "./FilterClient";
import SortClient from "./sortClients";
import Footer from "../../components/footer/Footer";
import Settings from "../../components/Settings";
import ViewContent from "./viewClient";
import DeleteClient from "./deleteClient";
import BackButton from "../../components/Buttons/backButton";
import { getAllClientListAPI } from "../../utils/api-helper";
import {
    getAllCountryAPI,
    getAllStateAPI,
    getAllCitiesAPI,
    getAllPaymentModeAPI,
    listClientTypeListAPI,
} from "../../utils/api-helper";
import Loader from "../../components/Loader";
import InvoiceAlertPopupMessage from "../manageinvoice/InvoiceAlertPopupMessage";

export default function ManageClient (props) {
    const navigate = useNavigate();

    const [isGetAllClient, setIsGetAllClient] = useState(true);
    const [isAddClient, setIsAddClient] = useState(false);
    const [isGetClientByid, setIsGetClientByid] = useState(false);
    const [isEditClient, setIsEditClient] = useState(false);
    const [isFilterClient, setIsFilterClient] = useState(false);
    const [isSortClient, setIsSortClient] = useState(false);
    const [isDeleteClient, setIsDeleteClient] = useState(false);
    const [startLoader, setStartLoader] = useState(false);
    const [alertPopupWindow, setAlertPopupWindow] = useState(false);
    const [alertPopupMessage, setAlertPopupMessage] = useState('');
    const [getAlertReponse, setAlertReponse] = useState(false)

    const [clientId, setClientId] = useState("");

    const [clientFirstNameFilter, setClientFirstNameFilter] = useState("");
    const [clientLastNameFilter, setClientLastNameFilter] = useState("");
    const [contactPersonFirstNameFilter, setContactPersonFirstNameFilter] =
        useState("");
    const [contactPersonLastNameFilter, setContactPersonLastNameFilter] =
        useState("");
    const [emailFilter, setEmailFilter] = useState("");
    const [countryFilter, setCountryFilter] = useState("");
    const [isActiveStatus, setIsActiveStatus] = useState(true);
    const [isFilterSelected, setIsFilterSelected] = useState(false);

    const [sortClientData, setsortClientData] = useState("");

    const [errMsg, setErrMsg] = useState("");
    const [success, setSuccess] = useState(false);

    const body = {
        firstName: clientFirstNameFilter,
        lastName: clientLastNameFilter,
        contactPersonFirstName: contactPersonFirstNameFilter,
        contactPersonLastName: contactPersonLastNameFilter,
        countryName: countryFilter,
        email: emailFilter,
        isActive: isActiveStatus,
    };

    const [response, setResponse] = useState([]);
    const [country, setCountry] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [paymentMode, setPaymentMode] = useState([]);
    const [clientTypeOptions, setClientTypeOptions] = useState([]);

    function createData (id, value) {
        return { value: id, label: value };
    }

    useEffect(() => {
        getAllClients();
    }, []);

    useEffect(() => {
        getAllCountryAPI().then(res => {
            setCountry(
                res?.data.map((row) => createData(row.id, row.countryName))
            );
        }).catch((err) => {
            //console.log(err);
        })
    }, []);

    useEffect(() => {
        getAllPaymentModeAPI()
            .then((res) => {
                setPaymentMode(
                    res?.data.map((row) => createData(row.id, row.value))
                );
            })
            .catch((err) => {
                //console.log(err);
            });
    }, []);

    useEffect(() => {
        listClientTypeListAPI()
            .then((res) => {
                setClientTypeOptions(
                    res?.data.map((row) => createData(row.id, row.value))
                );
            })
            .catch((err) => {
                // console.log(err);
            });
    }, []);

    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        getAllClientAPICall();
    }, [page, rowsPerPage]);

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (value) => {
        setRowsPerPage(value);
        setPage(0);
    };

    const getAllClients = () => {
        setIsGetAllClient(true); // Default state to get All Client Data
        setIsAddClient(false);
        setIsGetClientByid(false);
        setIsEditClient(false);
        setIsFilterClient(false);
        setIsSortClient(false);
        setIsDeleteClient(false);
        getAllClientAPICall();
        navigate("/client");
    };
    const getAllClientAPICall = () => {
        setStartLoader(true);
        getAllClientListAPI(body, sortClientData, page, rowsPerPage)
            .then((res) => {
                setResponse(res?.data.list);
                setTotalCount(res?.data.totalCount);
                setSuccess(true);
                setStartLoader(false);
            })
            .catch((err) => {
                // console.log(err);
                setSuccess(false);
            }).finally(()=>{
                setStartLoader(false);
            });
    }
    const addClient = () => {
        setIsGetAllClient(false);
        setIsAddClient(true); // Add invoice is set true When user Clicks on Add Client
        setIsGetClientByid(false);
        setIsEditClient(false);
        setIsFilterClient(false);
        setIsSortClient(false);
        setIsDeleteClient(false);
    };
    const filterClient = () => {
        setIsGetAllClient(true);
        setIsAddClient(false);
        setIsGetClientByid(false); // popup window will show when clicked...
        setIsEditClient(false);
        setIsFilterClient(true);
        setIsSortClient(false);
        setIsDeleteClient(false);
    };
    const sortClient = () => {
        setIsGetAllClient(true);
        setIsAddClient(false);
        setIsGetClientByid(false);
        setIsEditClient(false);
        setIsFilterClient(false);
        setIsSortClient(true); // popup window will show when clicked...
        setIsDeleteClient(false);
    };
    const getClientById = (row) => {
        setIsGetAllClient(false);
        setIsAddClient(false);
        setIsGetClientByid(true); // Get Deta of Client through Id, user will clicks on Row of Data table and it will set true state.
        setIsEditClient(false);
        setIsFilterClient(false);
        navigate(`/client/?id=${row.id}`);
        setIsDeleteClient(false);
    };
    const editClient = () => {
        setIsGetAllClient(false);
        setIsAddClient(false);
        setIsGetClientByid(false);
        setIsEditClient(true); // Edit Client page
        setIsFilterClient(false);
        setIsDeleteClient(false);
    };
    const deleteClient = () => {
        setIsGetAllClient(false);
        setIsAddClient(false);
        setIsGetClientByid(true); // View Client will be at background
        setIsEditClient(false);
        setIsFilterClient(false);
        setIsDeleteClient(true); // popup for delete Client
    };

    return (
        <>
            <div className="flex-container">
                <div>
                    <Navigation myClass="Manage Client" />
                </div>
                <div className="right-section">
                    {/* <ClientList /> */}
                    <div className="title" id="title">
                        {isGetAllClient ? "" : <BackButton handleClick={getAllClients} />}
                        <div className="pageheading">
                            <p>
                                {isGetAllClient ? (
                                    <>Manage Client</>
                                ) : isAddClient ? (
                                    <>Add Client</>
                                ) : isEditClient ? (
                                    <>Edit Client</>
                                ) : isGetClientByid ? (
                                    <>View Client</>
                                ) : (
                                    <></>
                                )}
                            </p>
                        </div>
                        <Settings />
                    </div>
                    <div className="content" id="content">
                        {/* setinvoiceIdHere={setinvoiceIdHere()} */}
                        {isGetAllClient ? (
                            <GetAllClients
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                totalCount={totalCount}
                                response={response}
                                success={success}
                                isFilterSelected={isFilterSelected}
                                setIsFilterSelected={setIsFilterSelected}
                                addClient={addClient}
                                getClientById={getClientById}
                                filterClient={filterClient}
                                sortClient={sortClient}
                                setStartLoader={setStartLoader}
                            />
                        ) : isAddClient ? (
                            <CreateNewClient
                                isEditClient={false}
                                getAllClients={getAllClients}
                                paymentMode={paymentMode}
                                country={country}
                                clientTypeOptions={clientTypeOptions}
                                setStartLoader={setStartLoader}
                                setAlertPopupWindow={setAlertPopupWindow}
                                setAlertPopupMessage={setAlertPopupMessage}
                                getAlertReponse={getAlertReponse}
                                setAlertReponse={setAlertReponse}
                            />
                        ) : isGetClientByid ? (
                            <ViewContent
                                editClient={editClient}
                                deleteClient={deleteClient}
                                setClientId={setClientId}
                                getAllClients={getAllClients}
                                setStartLoader={setStartLoader}
                            />
                        ) : isEditClient ? (
                            <CreateNewClient
                                isEditClient={true}
                                getAllClients={getAllClients}
                                paymentMode={paymentMode}
                                country={country}
                                clientTypeOptions={clientTypeOptions}
                                setStartLoader={setStartLoader}
                                setAlertPopupWindow={setAlertPopupWindow}
                                setAlertPopupMessage={setAlertPopupMessage}
                                getAlertReponse={getAlertReponse}
                                setAlertReponse={setAlertReponse}
                            />
                        ) : (
                            <p>No Content</p>
                        )}
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
                {isFilterClient ? (
                    <FilterClient
                        getAllClients={getAllClients}
                        clientFirstNameFilter={clientFirstNameFilter}
                        clientLastNameFilter={clientLastNameFilter}
                        contactPersonFirstNameFilter={contactPersonFirstNameFilter}
                        contactPersonLastNameFilter={contactPersonLastNameFilter}
                        emailFilter={emailFilter}
                        countryFilter={countryFilter}
                        isFilterSelected={isFilterSelected}
                        setIsFilterSelected={setIsFilterSelected}
                        isActiveStatus={isActiveStatus}
                        setClientFirstNameFilter={setClientFirstNameFilter}
                        setClientLastNameFilter={setClientLastNameFilter}
                        setContactPersonFirstNameFilter={setContactPersonFirstNameFilter}
                        setContactPersonLastNameFilter={setContactPersonLastNameFilter}
                        setEmailFilter={setEmailFilter}
                        setCountryFilter={setCountryFilter}
                        setIsActiveStatus={setIsActiveStatus}
                        setIsFilterClient={setIsFilterClient}
                    />
                ) : (
                    <></>
                )}
                {isSortClient ? (
                    <SortClient
                        getAllClients={getAllClients}
                        sortClientData={sortClientData}
                        setsortClientData={setsortClientData}
                    />
                ) : (
                    <></>
                )}
                {isDeleteClient ? (
                    <DeleteClient
                        getClientById={getClientById}
                        clientId={clientId}
                        getAllClients={getAllClients}
                        setStartLoader={setStartLoader}
                    />
                ) : (
                    <></>
                )}
            </div>
            {
                startLoader ? <Loader /> : <></>
            }
            {alertPopupWindow.is &&
                <InvoiceAlertPopupMessage
                    setAlertPopupWindow={setAlertPopupWindow}
                    alertPopupMessage={alertPopupMessage}
                    setAlertReponse={setAlertReponse}
                    alertPopupWindow={alertPopupWindow}
                ></InvoiceAlertPopupMessage>
            }
        </>
    );
}
