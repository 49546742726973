import React, { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/FilterInvoice.css";
export default function FilterInvoice(props) {
    function _onFocus(e) {
        e.currentTarget.type = "date";
    }
    function _onBlur(e) {
        e.currentTarget.type = "text";
        e.currentTarget.placeholder = "Enter a Date";
    }
    const [isClientFirstName, setIsClientFirstName] = useState(true);
    const [isClientLastName, setIsClientLastName] = useState(false);
    const [isProjectName, setIsProjectName] = useState(false);
    const [isProjectManagerFirstName, setIsProjectManagerFirstName] = useState(false);
    const [isProjectManagerLastName, setIsProjectManagerLastName] = useState(false);
    const [isDate, setIsDate] = useState(false);
    const [isStatus, setIsStatus] = useState(false);

    const userRef = useRef();

    const initialState = () => {
        setIsClientFirstName(false);
        setIsClientLastName(false);
        setIsProjectName(false);
        setIsProjectManagerFirstName(false);
        setIsProjectManagerLastName(false);
        setIsDate(false);
        setIsStatus(false);
    };
    const checkFilter = () => {
        let check = false;
        if (
            props.clientFirstNameFilter != "" ||
            props.clientLastNameFilter != "" ||
            props.projectNameFilter != "" ||
            props.projectManagerFirstNameFilter != "" ||
            props.startDateFilter != "" ||
            props.statusFilter != true
        ) {
            props.setIsFilterSelected(false)
            check = true;
        } else {
            check = false;
        }
        return check;
    }

    const handleClickOnApply = () => {
        const isVisible = checkFilter();
        props.setIsFilterSelected(isVisible);
        props.getAllProjects();
    }
    const filterClientFirstName = () => {
        initialState();
        setIsClientFirstName(true);
    };
    const filterClientLastName = () => {
        initialState();
        setIsClientLastName(true);
    };
    const filterProjectName = () => {
        initialState();
        setIsProjectName(true);
    };
    const filterProjectManagerFirstName = () => {
        initialState();
        setIsProjectManagerFirstName(true);
    };
    const filterProjectManagerLastName = () => {
        initialState();
        setIsProjectManagerLastName(true);
    };
    const filterDate = () => {
        initialState();
        setIsDate(true);
    };
    const filterStatus = () => {
        initialState();
        setIsStatus(true);
    };

    const clearAll = () => {
        props.setIsFilterSelected(false)
        props.setClientFirstNameFilter("");
        props.setClientLastNameFilter("");
        props.setProjectNameFilter("");
        props.setProjectManagerFirstNameFilter("");
        props.setProjectManagerLastNameFilter("");
        props.setStartDateFilter("");
        props.setEndDateFilter("");
        props.setStatusFilter(true);
    };
    const cancel = () => {
        clearAll();
        props.setIsFilterProject(false);
    };

    return (
        <>
            <div className="flexfilter">
                <div className="filterBox">
                    <div className="filterContent">
                        <div className="filterTitle">
                            <div className="heading">Filter Projects</div>
                            <div className="crossButtonHover" onClick={cancel}>
                                <div className="crossButton"></div>
                            </div>
                            <button onClick={clearAll}>Clear All</button>
                        </div>
                        <div className="filterElements">
                            <div className="flex">
                                <div className="filterOptionsList">
                                    <div
                                        className={
                                            isClientFirstName
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterClientFirstName}
                                    >
                                        <p>Client's Name</p>
                                    </div>
                                    <div
                                        className={
                                            isProjectName
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterProjectName}
                                    >
                                        <p>Project Name</p>
                                    </div>
                                    <div
                                        className={
                                            isProjectManagerFirstName
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterProjectManagerFirstName}
                                    >
                                        <p>Project Manager's First Name</p>
                                    </div>
                                    <div
                                        className={
                                            isProjectManagerLastName
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterProjectManagerLastName}
                                    >
                                        <p>Project Manager's Last Name</p>
                                    </div>
                                    <div
                                        className={
                                            isDate
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterDate}
                                    >
                                        <p>Date</p>
                                    </div>
                                    <div
                                        className={
                                            isStatus
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterStatus}
                                    >
                                        <p>Status</p>
                                    </div>
                                </div>
                                <div className="filterOptionsData">
                                    {isClientFirstName ? (
                                        <div className="filterOptionInput">
                                            <input
                                                type="text"
                                                id="clientNameFilter"
                                                placeholder="Enter Client's Name"
                                                name="clientNameFilter"
                                                onChange={(e) => props.setClientFirstNameFilter(e.target.value)}
                                                ref={userRef}
                                                value={props.clientFirstNameFilter}
                                            />
                                        </div>
                                    ) : isProjectName ? (
                                        <div className="filterOptionInput">
                                            <input
                                                type="text"
                                                id="projectNameFilter"
                                                placeholder="Enter Project Name"
                                                name="projectNameFilter"
                                                onChange={(e) => props.setProjectNameFilter(e.target.value)}
                                                ref={userRef}
                                                value={props.projectNameFilter}
                                            />
                                        </div>
                                    ) : isProjectManagerFirstName ? (
                                        <div className="filterOptionInput">
                                            <input
                                                type="text"
                                                id="projectManagerFilter"
                                                placeholder="Enter Project Manager's First Name"
                                                name="projectManagerFilter"
                                                onChange={(e) =>
                                                    props.setProjectManagerFirstNameFilter(e.target.value)
                                                }
                                                ref={userRef}
                                                value={props.projectManagerFirstNameFilter}
                                            />
                                        </div>
                                    ) : isProjectManagerLastName ? (
                                        <div className="filterOptionInput">
                                            <input
                                                type="text"
                                                id="projectManagerFilter"
                                                placeholder="Enter Project Manager's Last Name"
                                                name="projectManagerFilter"
                                                onChange={(e) =>
                                                    props.setProjectManagerLastNameFilter(e.target.value)
                                                }
                                                ref={userRef}
                                                value={props.projectManagerLastNameFilter}
                                            />
                                        </div>
                                    ) : isDate ? (
                                        <div className="filterOptionInput">
                                            <input
                                                type="date"
                                                id="dateFilter"
                                                placeholder="Enter Date"
                                                name="dateFilter"
                                                onChange={(e) =>
                                                    props.setStartDateFilter(e.target.value)
                                                }
                                                ref={userRef}
                                                value={props.startDateFilter}
                                            />
                                            <input
                                                type="date"
                                                id="dateFilter"
                                                placeholder="Enter Date"
                                                name="dateFilter"
                                                onChange={(e) =>
                                                    props.setEndDateFilter(e.target.value)
                                                }
                                                ref={userRef}
                                                value={props.endDateFilter}
                                            />
                                        </div>
                                    ) : isStatus ? (
                                        <div className="filterOptionInput">
                                            <select
                                                type="select"
                                                className="statusFilter"
                                                placeholder="Select Status"
                                                name="statusFilter"
                                                onChange={(e) => props.setStatusFilter(e.target.value)}
                                                value={props.statusFilter}
                                            >
                                                <option className="custom-options" value="true" selected>Active</option>
                                                <option className="custom-options" value="false">Inactive</option>
                                            </select>
                                        </div>
                                    ) : (
                                        <p>No Content</p>
                                    )}
                                </div>
                            </div>
                            <div className="filterFooter">
                                <div className="floatright">
                                    <div className="applyButton">
                                        <button onClick={handleClickOnApply}>Apply</button>
                                    </div>
                                    <div className="cancleButton">
                                        <button onClick={cancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
