import React, { useState, useEffect } from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import Signin from "./pages/authentication/Signup";
import Login from './pages/authentication/Login';
import ManageInvoicePage from './pages/manageinvoice';
import ManageCountryPage from './pages/managecountry';
import ManageProjectPage from './pages/manageProjects';
import ManageClientPage from './pages/manageclients';
import ManagersDashboard from './pages/manageDashboard';
import ManageBudgetPage from './pages/manageBudget';
import UserProfilePage from './pages/profile';
import PendingInvoicesPage from './pages/managePendingInvoices';
import WeeklyInvoicePage from './pages/weekly-invoice'
import ManageUsersPage from './pages/manageUsers';
import Logout from './components/Logout';
import { useIdleTimer } from 'react-idle-timer';
import emitter from './utils/Emitter'
import { getisAuth } from './utils/api-helper';
import { clearAllLocalStorage } from "./utils/local-storage-helper";
// import ProtectedRoutes from './components/protected_routes';
// import NotProtectedRoutes from './components/not_protected_routes';
function PrivateRoute ({ children }) {
    const auth = getisAuth();
    return auth ? children : <Navigate to="/login" />;
}
function PublicRoutes ({ children }) {
    const auth = getisAuth();
    return auth ? <Navigate to="/invoice" /> : children;
}
export default function Routing() {
    return (
        <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path={`/signup`} element={<Signin />} />
            {/* <Route path="/home" component={Home} /> */}
            <Route exact path="/invoice" element={<PrivateRoute><ManageInvoicePage /></PrivateRoute>} />
            <Route exact path="/weekly-invoice" element={<PrivateRoute><WeeklyInvoicePage /></PrivateRoute>} />
            <Route exact path="/client" element={<PrivateRoute><ManageClientPage /></PrivateRoute>} />
            <Route exact path="/project" element={<PrivateRoute><ManageProjectPage /></PrivateRoute>} />
            <Route exact path="/country" element={<PrivateRoute><ManageCountryPage /></PrivateRoute>} />
            <Route exact path="/revenue" element={<PrivateRoute><ManageBudgetPage /></PrivateRoute>} />
            <Route exact path="/pendinginvoices" element={<PrivateRoute><PendingInvoicesPage /></PrivateRoute>} />
            <Route exact path="/users" element={<PrivateRoute><ManageUsersPage /></PrivateRoute>} />
            <Route exact path="/profile" element={<PrivateRoute><UserProfilePage /></PrivateRoute>} />
            <Route exact path="/dashboard" element={<PrivateRoute><ManagersDashboard /></PrivateRoute>} />


            <Route exact path="/logout" element={<PrivateRoute><Logout /></PrivateRoute>} />

            <Route path="/" element={<PrivateRoute><ManageInvoicePage /></PrivateRoute>} />
            <Route to={getisAuth() ? "/invoice" : "/login"} />
            <Route exact path="/login" element={getisAuth() ? <PrivateRoute><ManageClientPage /></PrivateRoute> : <Login />} />
            <Route path="*" element={<PrivateRoute><ManageInvoicePage /></PrivateRoute>} />
            {/* <Redirect path="*" component={PageNotFound} /> */}
        </Routes>
    );
}