import React, { useState } from "react";
import { ReactComponent as AscendingIcon } from "../../assets/icons/ascending_icon.svg";
import { ReactComponent as DescendingIcon } from "../../assets/icons/descending_icon.svg";
import { ReactComponent as DefaultIcon } from "../../assets/icons/default_icon.svg";
import "./pendingTable.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

export default function Index(props) {
  const [hoveredRow, setHoveredRow] = useState(null);
  const handleSortClick = (order, columnId) => {
    if (order === props.sortOrder) {
      props.setsortInvoiceData(columnId);
      props.setSortOrder(
        order == "ASC" ? "DESC" : order == "DESC" ? null : "ASC"
      );
    } else {
      props.setsortInvoiceData(columnId);
      props.setSortOrder(order);
    }
  };

  const getSortIcon = (column) => {
    if (column === props.sortInvoiceData && props.sortOrder === "ASC") {
      return <DescendingIcon />;
    } else if (column === props.sortInvoiceData && props.sortOrder === "DESC") {
      return <AscendingIcon />;
    } else {
      return <DefaultIcon />;
    }
  };

  function shouldVisible(props, row) {
    return props.paymentDetailID !== null
      ? props.invoiceID.find((checkbox) => checkbox.id === row.id)?.checked
      : props.statusInvoiceID === row.id;
  }
  const editBudgetStyle = {
    display: "flex",
    justifyContent: "space-between",
  };

  return (
    <>
      <Paper
        sx={{
          maxHeight: "50vh",
          width: "90%",
          overflow: "hidden",
          margin: "auto",
        }}
      >
        <TableContainer
          sx={{
            borderCollapse: "collapse",
            maxHeight: "50%",
            fontStyle: "Open Sans,",
            fontSize: "1rem",
            height: "310px",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            style={props.tableStyle ? props.tableStyle : {}}
            className="pending-table"
          >
            <TableHead>
              <TableRow>
                {props.columns.map((column, index) => (
                  <TableCell
                    key={props.normalData ? column.id : index}
                    align={column.align}
                    className="tableHeadCellStyle"
                    style={props.style}
                  >
                    {props.tableTitle == "listOfInvoices" ||
                    column.id === "date" ||
                    column.id === "amount" ? (
                      <>
                        <div className="coloumn_label">{column.label}</div>
                        <div
                          className="sorting-p-div"
                          onClick={() => handleSortClick("ASC", column.id)}
                          style={{ cursor: "pointer" }}
                        >
                          {getSortIcon(column.id)}
                        </div>
                      </>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.rows ? (
                props.rows
                  .filter((row) =>
                    Object.values(row).some(
                      (value) =>
                        value !== null &&
                        value
                          .toString()
                          .toLowerCase()
                          .includes(props.search.toLowerCase())
                    )
                  )
                  .map((row, index) => (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                      onMouseEnter={() => setHoveredRow(index)}
                      onMouseLeave={() => setHoveredRow(null)}
                      style={{ cursor: "pointer" }}
                      className="tableDataRowStyle"
                    >
                      {props.columns.map((column, ind) => {
                        let value = row[column.id];
                        return (
                          <TableCell
                            key={ind}
                            align={column.align}
                            className="tableDataCellStyle"
                          >
                            {props.normalData ? (
                              column.id === "paymentStatus" &&
                              shouldVisible(props, row) ? (
                                <Select
                                  defaultValue={value}
                                  value={
                                    props.invoiceID.find(
                                      (option) => option.id === row.id
                                    )?.paymentStatus
                                  }
                                  onChange={(event) =>
                                    props.handlePaymentStatusChange(
                                      event.target.value,
                                      row.id
                                    )
                                  }
                                >
                                  {props.paymentStatus.map(
                                    (status) =>
                                      (status.id == 3 ||
                                        status.id == 4 ||
                                        status.id == 1) && (
                                        <MenuItem
                                          key={status.id}
                                          value={status.value}
                                        >
                                          {status.value}
                                        </MenuItem>
                                      )
                                  )}
                                </Select>
                              ) : column.id === "amountDue" ? (
                                <div style={editBudgetStyle}>
                                  <span>{value ? value : "--"}</span>
                                  <div
                                    style={{
                                      opacity:
                                        shouldVisible(props, row) ||
                                        hoveredRow == index
                                          ? 1
                                          : 0,
                                      pointerEvents:
                                        shouldVisible(props, row) ||
                                        hoveredRow == index
                                          ? "auto"
                                          : "none",
                                    }}
                                  >
                                    <Checkbox
                                      onChange={(e) =>
                                        props.handleInvoiceCheck(
                                          e.target.checked,
                                          row.id,
                                          row.paymentStatus,
                                          row.amountDue
                                        )
                                      }
                                      checked={shouldVisible(props, row)}
                                    />
                                  </div>
                                </div>
                              ) : column.id === "transactionDetails" &&
                                hoveredRow == index ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>{value}</span>
                                  <Checkbox
                                    size="small"
                                    onChange={(event) =>
                                      props.handlePaymentDetailCheck(
                                        event,
                                        row.id
                                      )
                                    }
                                    checked={props.paymentDetailID === row.id}
                                  />
                                </div>
                              ) : column.format && typeof value === "number" ? (
                                column.format(value)
                              ) : value ? (
                                value
                              ) : (
                                "--"
                              )
                            ) : (
                              row.name
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={props.columns.length}>
                    NO DATA AVAILABLE
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <TablePagination
        rowsPerPageOptions={[10, 20, 100]}
        component="div"
        count={props.totalCount}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onPageChange={(event, newPage) => props.handleChangePage(newPage)}
        colSpan={3}
        onRowsPerPageChange={(event) =>
          props.handleChangeRowsPerPage(+event.target.value)
        }
        sx={{
          position: "fixed",
          right: "20px",
          bottom: "7px",
          padding: "0px",
          backgroundColor: "#ffffff",
        }}
        className="pagination"
      />
    </>
  );
}
