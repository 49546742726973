import React from "react";
import "./table.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

export default function Index(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <Paper sx={{
                fontStyle: "Open Sans,",
                fontSize: "2rem",
                maxHeight: "70vh",
                width: "100%",
                overflow: "hidden",
                margin: "auto",
            }}>
                <TableContainer sx={{
                    borderCollapse: "collapse",
                    maxHeight: "70vh",
                    fontStyle: "Open Sans,",
                    fontSize: "2rem",
                }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {props.columns.map((column, index) => (
                                    <TableCell
                                        key={index}
                                        align={column.align}
                                        style={{
                                            minWidth: column.minWidth,
                                            backgroundColor: "#c5c5c5",
                                        }}
                                        className="tableHeadCellStyle"
                                    >
                                        {column.label}{props.selectedName}
                                        {props.Component}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.rows != "" ? props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover={{ backgroundColor: "#ebebeb" }}
                                            tabIndex={-1}
                                            key={index}
                                            style={
                                                props.selectedId == row.id
                                                    ? { backgroundColor: "#ebebeb", cursor: "pointer" }
                                                    : { backgroundColor: "#ffffff", cursor: "pointer" }
                                            }
                                            className="tableDataRowStyle"
                                        >
                                            {props.columns.map((column, index) => {
                                                return (
                                                    <TableCell
                                                        onClick={() => {
                                                            props.function(row);
                                                        }}
                                                        // key={column.id}
                                                        key={index}
                                                        align={column.align}
                                                        className="tableDataCellStyle"
                                                    >
                                                        {row.name}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })
                                : <TableRow>
                                    <TableCell>No Data Available.</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}
