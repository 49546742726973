import React from 'react'
import './updateInvoiceNumber.css'
export default function index(props) {

    return (
        <>
            <div className="flexpopUp" >
                <div className="popUpBox">
                    <div className="popUpContent">
                        <div className="popUpTitle">
                            <div className="heading">Update Invoice Number</div>
                            <div className="crossButtonHover" onClick={() => props.setUpdateInvoiceNumberWindow(false)}><div className="crossButton"></div></div>
                        </div>
                        <div className="popUpElements">
                            <div className="popUpOptionsData">
                                <p>{props.alertPopupMessage}</p>
                            </div>
                            <div className="popUpFooter">
                                <div className="floatright">
                                    <div className="applyButton">
                                        <button onClick={() => { }}>Okay</button>
                                    </div>
                                    <div className="cancleButton">
                                        <button onClick={() => props.setUpdateInvoiceNumberWindow(false)}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
