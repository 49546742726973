import axios from 'axios';
import { OAUTH2_CLIENT_ID, OAUTH2_CLIENT_PASSWORD, getHostURL} from './api-helper';

const basicAuthData = "Basic " + btoa(`${OAUTH2_CLIENT_ID}:${OAUTH2_CLIENT_PASSWORD}`);
export const configHeaders = { 
    login : {
        'Content-Type': 'application/json',
        'Accept-Language': 'en',
        // 'authorization': basicAuthData,
        'device-type': "Web",
        'devicename': "Web",
        "role": "ADMIN"
    },
    requestOtp : {
        'Content-Type': 'application/json',
        'Accept-Language': 'en',
        'device-type': "Web",
        'devicename': "Web",
        "appVersion": "1",
        "deviceId": "1"
    },
    verifyOtp : {
        'Content-Type': 'application/json',
        'Accept-Language': 'en',
        'device-type': "Web",
        'devicename': "Web",
        "appVersion": "1",
        "deviceId": "1"
    },
    changePassword : {
        'Content-Type': 'application/json',
        'Accept-Language': 'en'
    }
}

export function loginAPI(body){

    return axios.post(`${getHostURL()}/api/v1/auth/login`, body, {
        headers: configHeaders.login
    });
}

export function requestOtpAPI(body){
    return axios.post(`${getHostURL()}/api/v1/admin/otp/requestOtp`, body, {
        headers: configHeaders.requestOtp
    })
}

export function verifyOtpAPI(body){
    return axios.post(`${getHostURL()}/api/v1/admin/otp/verify`, body, {
        headers: configHeaders.verifyOtp
    })
}

export function resendOtpAPI(body){
    return axios.post(`${getHostURL()}/api/v1/admin/otp/resendOtp`, body, {
        headers: configHeaders.requestOtp
    })
}

export function changePasswordAPI(body){
    return axios.post(`${getHostURL()}/api/v1/admin/reset/password`, body, {
        headers: configHeaders.changePassword
    })
}