import React, { useRef, useState, useEffect, useContext } from 'react'
import './changepassword.css'
import { changePasswordAPI } from '../../utils/api-helper';
import qs from 'qs';
import { useNavigate } from "react-router-dom";

export default function Index(props) {

    const navigate = useNavigate();

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
    const [isRevealConfPwd, setIsRevealConfPwd] = useState(false);
    const [error, setError] = useState(null)

    const changePassword = (e) => {
        e.preventDefault();
        const body = {
            "newPassword": newPassword,
            "oldPassword": oldPassword
        };
        if (newPassword === confirmNewPassword && newPassword !== "") {
            changePasswordAPI(body).then(res => {
                props.checkevent();
                props.setIsDataUpdated(true);
                props.setSuccessMessage("Password updated successfully")
            }).catch((err) => {
                if(err.response.status == 500){
                    props.setError("Actual password was incorrect")
                }
            })
            navigate('/profile');
        } else {
            if (newPassword !== "") {
                setError("Both passwords must be same");
            } else {
                setError("Please enter password");
            }

        }

    }

    return (
        <>
            <div className="flexChangePassword" >
                <div className="ChangePasswordBox">
                    {/* <div className="ChangePasswordContent"> */}
                        <div className="ChangePasswordTitle">
                            <div>Change Password</div>
                            <div onClick={props.checkevent}><div className="crossButton"></div></div>
                        </div>
                        <div className="ChangePasswordElements">
                            <div className="ChangePasswordOptionsData">
                                <form>
                                    <div className="oldPassword form_input">
                                        <input
                                            type={isRevealPwd ? "text" : "password"}
                                            id="oldPassword"
                                            placeholder="Old Password"
                                            name="oldPassword"
                                            onChange={(e) => setOldPassword(e.target.value)}
                                            value={oldPassword}
                                            required
                                        />
                                        <div
                                            title={isRevealPwd ? "Hide password" : "Show password"}
                                            className={isRevealPwd ? "hide_password" : "show_password"}
                                            onClick={() => setIsRevealPwd(prevState => !prevState)}
                                        />
                                    </div>
                                    <div className="newPassword form_input">
                                        <input
                                            type={isRevealNewPwd ? "text" : "password"}
                                            id="newPassword"
                                            placeholder="New Password"
                                            name="newPassword"
                                            onChange={(e) => {
                                                setNewPassword(e.target.value);
                                                setError(null);
                                            }}
                                            value={newPassword}
                                            required
                                        />
                                        <div
                                            title={isRevealNewPwd ? "Hide password" : "Show password"}
                                            className={isRevealNewPwd ? "hide_password" : "show_password"}
                                            onClick={() => setIsRevealNewPwd(prevState => !prevState)}
                                        />
                                    </div>
                                    <div className="confirmNewPassword form_input">
                                        <input
                                            type={isRevealConfPwd ? "text" : "password"}
                                            id="confirmNewPassword"
                                            placeholder="Confirm New Password"
                                            name="confirmNewPassword"
                                            onChange={(e) => {
                                                setConfirmNewPassword(e.target.value);
                                                setError(null);
                                            }}
                                            value={confirmNewPassword}
                                            required
                                        />
                                        <div
                                            title={isRevealConfPwd ? "Hide password" : "Show password"}
                                            className={isRevealConfPwd ? "hide_password" : "show_password"}
                                            onClick={() => setIsRevealConfPwd(prevState => !prevState)}
                                        />
                                    </div>
                                    <pre style={{ color: "red", fontSize: "12px", marginBottom:"14px" }}>{error ? error : " "}</pre>
                                </form>
                            </div>
                            <div className="ChangePasswordFooter">
                                    <div>
                                        <button onClick={props.checkevent}>CANCEL</button>
                                    </div>
                                    <div>
                                        <button onClick={changePassword}>SAVE</button>
                                    </div>
                            </div>
                        </div>
                    {/* </div> */}
                </div>
            </div>
        </>
    )
}