import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/Navigation";
import "../../assets/styles/managepages.css";
import InvoiceAlertPopupMessage from "../manageinvoice/InvoiceAlertPopupMessage/index";
import GetAllProjects from "./getAllProjects";
import CreateProject from "./createNewProject";
import FilterProject from "./FilterProject";
import SortProject from "./sortProjects";
import ViewContent from "./viewProject";
import DeleteProject from "./deleteProject";
import BackButton from "../../components/Buttons/backButton";
import Footer from "../../components/footer/Footer";
import Settings from "../../components/Settings";

import {
  getAllProjectListAPI,
  getAllUsersAPI,
  getAllClientAPI,
  getlocationInvoiceOriginatingAPI,
  getProjectTypeAPI,
} from "../../utils/api-helper";
import Loader from "../../components/Loader";

export default function Index(props) {
  const navigate = useNavigate();

  const [isGetAllProject, setIsGetAllProject] = useState(true);
  const [isAddProject, setIsAddProject] = useState(false);
  const [isGetProjectByid, setIsGetProjectByid] = useState(false);
  const [isEditProject, setIsEditProject] = useState(false);
  const [isFilterProject, setIsFilterProject] = useState(false);
  const [isSortProject, setIsSortProject] = useState(false);
  const [isDeleteProject, setIsDeleteProject] = useState(false);
  const [startLoader, setStartLoader] = useState(false);

  const [ProjectId, setProjectId] = useState("");
  const [selectedProjectName, setSelectedProjectName] = useState("");

  const [clientFirstNameFilter, setClientFirstNameFilter] = useState("");
  const [clientLastNameFilter, setClientLastNameFilter] = useState("");
  const [projectNameFilter, setProjectNameFilter] = useState("");
  const [projectManagerFirstNameFilter, setProjectManagerFirstNameFilter] =
    useState("");
  const [projectManagerLastNameFilter, setProjectManagerLastNameFilter] =
    useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState(true);
  const [projectType, setProjectType] = useState("");

  const [sortProjectData, setSortProjectData] = useState("PROJECTNAME");

  const body = {
    clientFirstName: clientFirstNameFilter,
    clientLastName: clientLastNameFilter,
    isActive: statusFilter,
    projectManagerFirstName: projectManagerFirstNameFilter,
    projectManagerLastName: projectManagerLastNameFilter,
    projectName: projectNameFilter,
    projectsStartDateFrom: startDateFilter,
    projectType: projectType,
    projectsStartDateTo: endDateFilter,
  };
  const [response, setResponse] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isFilterSelected, setIsFilterSelected] = useState(false);


  useEffect(() => {
    getAllProjAPICall();
  }, [page, rowsPerPage]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const [getSortedProjectManager, setGetSortedProjectManager] = useState([]);
  const [getSortedDeliveryManager, setGetSortedDeliveryManager] = useState([]);
  const [getInvoiceLocationOrigin, setGetInvoiceLocationOrigin] = useState([]);
  const [getAllClients, setGetAllClients] = useState();
  const [getAllClientsType, setGetAllClientsType] = useState();
  const [projectLocationStatus, setProjectLocationStatus] = useState(false);
  const [getProjectType, setGotProjectType] = useState([]);

  const [alertPopupWindow, setAlertPopupWindow] = useState(false);
  const [alertPopupMessage, setAlertPopupMessage] = useState("");
  const [getAlertReponse, setAlertReponse] = useState(false);

  useEffect(() => {
    setStartLoader(true);
    getAllUsersAPI()
      .then((res) => {
        function createProjectManageData(
          id,
          firstName,
          lastName,
          roleType,
          isActive
        ) {
          let projectManagerName = firstName + "\u00a0" + lastName;
          if (roleType?.includes("MANAGER") && isActive) {
            return { value: id, label: projectManagerName };
          }
        }
        function createDeliveryManagerData(
          id,
          firstName,
          lastName,
          roleType,
          isActive
        ) {
          let deliveryManagerName = firstName + "\u00a0" + lastName;
          if (roleType?.includes("DELIVERY_MANAGER") && isActive) {
            return { value: id, label: deliveryManagerName };
          }
        }
        const projectManagers = res?.data
          .map((row) =>
            createProjectManageData(
              row.userId,
              row.firstName,
              row.lastName,
              row.roles,
              row.isActive
            )
          )
          .filter((object) => typeof object === "object");
        const deliveryManagers = res?.data
          .map((row) =>
            createDeliveryManagerData(
              row.userId,
              row.firstName,
              row.lastName,
              row.roles,
              row.isActive
            )
          )
          .filter((object) => typeof object === "object");
        setGetSortedProjectManager(projectManagers);
        setGetSortedDeliveryManager(deliveryManagers);
        setStartLoader(false);
      })
      .catch((err) => {
        // Handle the error
        setStartLoader(false);
      });
  }, []);

  useEffect(() => {
    setStartLoader(true);
    getProjectTypeAPI()
      .then((res) => {
        function createProjectType(id, type) {
          let projectType = type;
          return { value: id, label: projectType };
        }
        const projectType = res?.data.map((row) =>
          createProjectType(row.id, row.value)
        );
        setGotProjectType(
          projectType.filter((object) => typeof object === "object")
        );
        setStartLoader(false);
      })
      .catch((err) => {
        // console.log(err);
        setStartLoader(false);
      });
  }, []);

  useEffect(() => {
    getlocationInvoiceOriginatingAPI()
      .then((res) => {
        function createData(id, area, city) {
          let location = area + " | " + city;
          return { value: id, label: location };
        }
        const location = res?.data.map((row) =>
          createData(row.id, row.area, row.city)
        );
        setGetInvoiceLocationOrigin(
          location.filter((object) => typeof object === "object")
        );
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  useEffect(() => {
    setStartLoader(true);
    getAllClientAPI()
      .then((res) => {
        function createClientData(id, firstName, lastName) {
          let clientName = firstName + "\u00a0" + lastName;
          return { value: id, label: clientName };
        }
        setGetAllClients(
          res?.data.map((row) =>
            createClientData(row.id, row.firstName, row.lastName)
          )
        );
        function createClientTypeData(id, type) {
          return { value: id, label: type };
        }
        setGetAllClientsType(
          res?.data.map((row) =>
            createClientTypeData(row.id, row.clientTypeValue)
          )
        );
        setStartLoader(false);
      })
      .catch((err) => {
        setStartLoader(false);
        // console.log(err);
      });
  }, []);
  useEffect(() => {
    getAllProjAPICall();
  }, []);

  const getAllProjects = () => {
    setIsGetAllProject(true); // Default state to get All Project Data
    setIsAddProject(false);
    setIsGetProjectByid(false);
    setIsEditProject(false);
    setIsFilterProject(false);
    setIsSortProject(false);
    setIsDeleteProject(false);
    navigate("/project");
    getAllProjAPICall();
  };
  const getAllProjAPICall = () => {
    setStartLoader(true);
    getAllProjectListAPI(body, sortProjectData, page, rowsPerPage)
      .then((res) => {
        setResponse(res?.data.list);
        setTotalCount(res?.data.totalCount);
        setSuccess(true);
        setStartLoader(false);
      })
      .catch((err) => {
        // console.log(err);
        setSuccess(false);
        setStartLoader(false);
      });
  };
  const addProject = () => {
    setIsGetAllProject(false);
    setIsAddProject(true); // Add invoice is set true When user Clicks on Add Project
    setIsGetProjectByid(false);
    setIsEditProject(false);
    setIsFilterProject(false);
    setIsSortProject(false);
    setIsDeleteProject(false);
  };
  const filterProject = () => {
    setIsGetAllProject(true);
    setIsAddProject(false);
    setIsGetProjectByid(false); // popup window will show when clicked...
    setIsEditProject(false);
    setIsFilterProject(true);
    setIsSortProject(false);
    setIsDeleteProject(false);
  };
  const sortProject = () => {
    setIsGetAllProject(true);
    setIsAddProject(false);
    setIsGetProjectByid(false);
    setIsEditProject(false);
    setIsFilterProject(false);
    setIsSortProject(true); // popup window will show when clicked...
    setIsDeleteProject(false);
  };
  const getProjectById = (row) => {
    setIsGetAllProject(false);
    setIsAddProject(false);
    setIsGetProjectByid(true); // Get Deta of Project through Id, user will clicks on Row of Data table and it will set true state.
    setIsEditProject(false);
    setIsFilterProject(false);
    navigate(`/project/?id=${row.id}`);
    setIsDeleteProject(false);
  };
  const editProject = () => {
    setIsGetAllProject(false);
    setIsAddProject(false);
    setIsGetProjectByid(false);
    setIsEditProject(true); // Edit Project page
    setIsFilterProject(false);
    setIsDeleteProject(false);
  };
  const deleteProject = () => {
    setIsGetAllProject(false);
    setIsAddProject(false);
    setIsGetProjectByid(true); // View Project will be at background
    setIsEditProject(true);
    setIsFilterProject(false);
    setIsDeleteProject(true); // popup for delete Project
  };

  return (
    <>
      <div className='flex-container'>
        <div>
          <Navigation myClass='Manage Project' />
        </div>
        <div className='right-section'>
          <div className='title' id='title'>
            {isGetAllProject ? "" : <BackButton handleClick={getAllProjects} />}
            <div className='pageheading'>
              <p>Manage Project</p>
            </div>
            <Settings />
          </div>
          <div className='content' id='content'>
            {isGetAllProject ? (
              <GetAllProjects
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                setPage={setPage}
                isFilterSelected={isFilterSelected}
                setIsFilterSelected={setIsFilterSelected}
                rowsPerPage={rowsPerPage}
                totalCount={totalCount}
                response={response}
                addProject={addProject}
                getProjectById={getProjectById}
                filterProject={filterProject}
                sortProject={sortProject}
              />
            ) : isAddProject ? (
              <CreateProject
                isEditProject={false}
                getAllProjects={getAllProjects}
                getSortedProjectManager={getSortedProjectManager}
                getSortedDeliveryManager={getSortedDeliveryManager}
                getInvoiceLocationOrigin={getInvoiceLocationOrigin}
                getAllClients={getAllClients}
                getAllClientsType={getAllClientsType}
                projectLocationStatus={projectLocationStatus}
                setProjectLocationStatus={setProjectLocationStatus}
                setStartLoader={setStartLoader}
                getProjectType={getProjectType}
                setAlertPopupWindow={setAlertPopupWindow}
                setAlertPopupMessage={setAlertPopupMessage}
                getAlertReponse={getAlertReponse}
                setAlertReponse={setAlertReponse}
              />
            ) : isGetProjectByid ? (
              <ViewContent
                editProject={editProject} 
                setProjectId={setProjectId}
                setSelectedProjectName={setSelectedProjectName}
                deleteProject={deleteProject}
                setStartLoader={setStartLoader}
              />
            ) : isEditProject ? (
              <CreateProject
                isEditProject={true}
                getAllProjects={getAllProjects}
                getSortedProjectManager={getSortedProjectManager}
                getSortedDeliveryManager={getSortedDeliveryManager}
                getInvoiceLocationOrigin={getInvoiceLocationOrigin}
                getAllClients={getAllClients}
                getAllClientsType={getAllClientsType}
                projectLocationStatus={projectLocationStatus}
                setProjectLocationStatus={setProjectLocationStatus}
                setStartLoader={setStartLoader}
                getProjectType={getProjectType}
                setAlertPopupWindow={setAlertPopupWindow}
                setAlertPopupMessage={setAlertPopupMessage}
                getAlertReponse={getAlertReponse}
                setAlertReponse={setAlertReponse}
              />
            ) : (
              <p>No Content</p>
            )}
          </div>
        </div>
        <div>
          <Footer />
        </div>
        {isFilterProject ? (
          <FilterProject
            getAllProjects={getAllProjects}
            clientFirstNameFilter={clientFirstNameFilter}
            setClientFirstNameFilter={setClientFirstNameFilter}
            clientLastNameFilter={clientLastNameFilter}
            setClientLastNameFilter={setClientLastNameFilter}
            projectNameFilter={projectNameFilter}
            setProjectNameFilter={setProjectNameFilter}
            projectManagerFirstNameFilter={projectManagerFirstNameFilter}
            setProjectManagerFirstNameFilter={setProjectManagerFirstNameFilter}
            projectManagerLastNameFilter={projectManagerLastNameFilter}
            setProjectManagerLastNameFilter={setProjectManagerLastNameFilter}
            startDateFilter={startDateFilter}
            isFilterSelected={isFilterSelected}
            setIsFilterSelected={setIsFilterSelected}
            setStartDateFilter={setStartDateFilter}
            endDateFilter={endDateFilter}
            setEndDateFilter={setEndDateFilter}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            setIsFilterProject={setIsFilterProject}
          />
        ) : (
          <></>
        )}
        {isSortProject ? (
          <SortProject
            getAllProjects={getAllProjects}
            sortProjectData={sortProjectData}
            setSortProjectData={setSortProjectData}
          />
        ) : (
          <></>
        )}
        {isDeleteProject ? (
          <DeleteProject
            getProjectById={getProjectById}
            ProjectId={ProjectId}
            selectedProjectName={selectedProjectName}
            getAllProjects={getAllProjects}
            setStartLoader={setStartLoader}
          />
        ) : (
          <></>
        )}
      </div>
      {alertPopupWindow.is && (
        <InvoiceAlertPopupMessage
          setAlertPopupWindow={setAlertPopupWindow}
          alertPopupMessage={alertPopupMessage}
          setAlertReponse={setAlertReponse}
          alertPopupWindow={alertPopupWindow}
        >
          {" "}
        </InvoiceAlertPopupMessage>
      )}

      {startLoader ? <Loader /> : <></>}
    </>
  );
}
