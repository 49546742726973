import React, { useState, useEffect, useCallback } from 'react'
import Navigation from '../../components/Navigation';
import { useNavigate } from "react-router-dom";
import './profile.css';
import '../../assets/styles/managepages.css';
import ViewProfile from './viewProfile';
import EditProfile from './editprofile';
import ChangePassword from '../../components/ChangePassword';
import Settings from '../../components/Settings';
import BackButton from '../../components/Buttons/backButton';
import Footer from '../../components/footer/Footer';
import { updateUserContactNo, getUserByID, getUserData} from '../../utils/api-helper';
import { Alert, Snackbar } from "@mui/material";
import ErrorPopup from '../../components/ErrorPopup';
import Loader from "../../components/Loader";
import { setArray } from '../../utils/local-storage-helper';


export default function Index() {

    const [isGetProfile, setIsGetProfile] = useState(true);
    const [isEditProfile, setIsEditProfile] = useState(false);
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [isChangeUserProfile, setIsChangeUserProfile] = useState(false);
    const [successMessage, setSuccessMessage] = useState("Number updated successfully.");
    const [error, setError] = useState(null);
    const [isDataUpdated, setIsDataUpdated] = useState(false);
    const [startLoader, setStartLoader] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [response, setResponse] = useState({});



    const closeErrorPopup = () => {
        setError(null);
    };
    useEffect(()=>{
        setStartLoader(true);
        let data = getUserData();
        getUserByID(data.userId).then((res)=>{
            setArray("user_data", res.data)
            setResponse(res.data);
        }).catch((err)=>{
            setError(err.message);
        }).finally(()=>{
            setStartLoader(false);
        })
    },[])

    
    function updateContactNumber(id, body){
        setStartLoader(true);
        updateUserContactNo(id, body).then((res)=>{
            setIsDataUpdated(true);
        }).catch((err)=>{
            setError(err.message);
        }).finally(()=>{
            getUserByID(id).then((res)=>{
                setArray("user_data", res.data)
                setResponse(res.data);
            }).catch((err)=>{
                setError(err.message);
            }).finally(()=>{
                setStartLoader(false);
                setIsUpdate(false);
            })
        })
    }

    const getProfile = () => {
        setIsGetProfile(true);
        setIsEditProfile(false);
        setIsChangePassword(false);
        setIsChangeUserProfile(false);
    }
    const editProfile = () => {
        setIsGetProfile(false);
        setIsEditProfile(true);
        setIsChangePassword(false);
        setIsChangeUserProfile(false);
    }
    const changePassword = () => {
        setIsGetProfile(true);
        setIsEditProfile(false);
        setIsChangePassword(true);
        setIsChangeUserProfile(false);
    }
    const changeUserProfileimage = () => {
        setIsGetProfile(true);
        setIsEditProfile(false);
        setIsChangePassword(false);
        setIsChangeUserProfile(true);
    }
    return (
        <>
            <div className="flex-container">
                <div>
                    <Navigation myClass="Profile" />
                </div>
                <div className="right-section">
                    {/* <InvoiceList /> */}
                    <div className="title" id="title">
                        {isGetProfile ? "" : <BackButton handleClick={getProfile} />}
                        <div className="pageheading"><p>Profile Page</p></div>
                        <Settings />
                    </div>
                    <div className="content" id="content">
                        {
                            isGetProfile ?
                                <ViewProfile
                                    editProfile={editProfile}
                                    changePassword={changePassword}
                                    updateUserContactNo={updateContactNumber}
                                    setIsUpdate={setIsUpdate}
                                    isUpdate={isUpdate}
                                    response={response}
                                /> :
                                isEditProfile ?
                                    <EditProfile getProfile={getProfile} changeUserProfileimage={changeUserProfileimage} /> : <></>
                        }
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
                {isChangePassword ? <ChangePassword 
                checkevent={getProfile} 
                setSuccessMessage={setSuccessMessage}
                setIsDataUpdated={setIsDataUpdated}
                setError={setError}
                /> : <></>}
                {error && <ErrorPopup errorMessage={error} onClose={closeErrorPopup} />}
                <Snackbar open={isDataUpdated} autoHideDuration={3000} onClose={() => setIsDataUpdated(false)} message={successMessage}>
                    <Alert onClose={() => setIsDataUpdated(false)} color="success" variant="filled" >
                        {successMessage}
                    </Alert>
                </Snackbar>
                {
                    startLoader ? <Loader /> : <></>
                }
            </div>
        </>
    )
}