import React, { useState } from 'react';
import '../../assets/styles/components.css'; // Import the CSS file
import { TextField, FormControl, InputLabel, Checkbox } from '@mui/material';
import styles from "../managePendingInvoices/pendingInvoice.module.css";
import { useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as InfoIcon } from "../../assets/icons/infoIcon.svg"
import Select from '@mui/material/Select';
import { getAllRolesAPI, getAllUsersAPI, createUser } from '../../utils/api-helper';
import { CheckBox, CleaningServices } from '@mui/icons-material';
import { validateTextOnly } from '../../utils/input-validation';
import { color } from '@mui/system';
import { sortRolesByPriority } from '../../utils/roles-helper';

function AddUser(props) {
  // const classes = useStyles();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")
  const [roles, setRoles] = useState([]);
  const [allUsers, setAllUsers] = useState([])

  useEffect(() => {
    getAllUsersAPI().then((res) => {
      setAllUsers(res.data)
    }).catch((err) => {
    })
    getAllRolesAPI().then((res) => {
      setRoles(sortRolesByPriority(res.data));
    }).catch((err) => {
      // console.log(err)
    })
  }, [])

  const validateEmail = (email) => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const specificDomain = /biz4solutions\.com$/;

    return regex.test(email) && specificDomain.test(email);
  }

  const validateContact = (number) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(number);
  }

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [contactNoError, setContactNoError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [showList, setShowList] = useState(false);
  const validateForm = () => {
    let isValid = true;

    if (firstName === '' || !validateTextOnly(firstName)) {
      setFirstNameError('Please enter a valid first name');
      isValid = false;
    } else {
      setFirstNameError('');
    }

    if (lastName === '' || !validateTextOnly(lastName)) {
      setLastNameError('Please enter a valid last name');
      isValid = false;
    } else {
      setLastNameError('');
    }

    if (email === '' || !validateEmail(email)) {
      setEmailError('Please enter a valid email');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (contactNo) {
      if (contactNo === '' || !validateContact(contactNo)) {
        setContactNoError('Please enter a valid contact no');
        isValid = false;
      } else {
        setContactNoError('');
      }
    }

    if (roles.length == 0) {
      setRoleError('Please select a role');
      isValid = false;
    } else {
      setRoleError('');
    }

    return isValid;
  };

  

  const handleSubmit = () => {
    const isValid = validateForm() && !emailError;
    if (isValid) {
      props.setStartLoader(true);
      const body = {
        "email": email,
        "firstName": firstName,
        "lastName": lastName,
        "roles": selectedRoles,
      };

      createUser(body)
        .then((res) => {
          props.setAddPopUp(false);
          props.setUserAdded(true);
        })
        .catch((err) => {
          props.setAddPopUp(false);
          props.setError(err?.response?.data?.message);
        }).finally(() => {
          props.setStartLoader(false);
          props.getAllUserData();
        })
    }
  };
  const handleRolesSelect = (isChecked, role) => {
    if (isChecked) {
      const newSelectedRoles = [...selectedRoles, role];
      setSelectedRoles(newSelectedRoles);
    } else {
      setSelectedRoles((selectedRoles) => selectedRoles.filter(item => item !== role))
    }
  }
  const handleButtonClick = () => {
    setShowList(!showList); // Toggle the state on each click
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <h2 style={{ margin: "12px 0px 12px 0px" }}>{` ${props.mode === "Add" ? "Add" : "Edit"} User Details`}</h2>
        <div className='add-field'>
          <div className='box'>
            <TextField
              style={{ width: "50%" }}
              label="First Name"
              type="text"
              name="firstName"
              value={firstName}
              onChange={(e) => { setFirstNameError(""); setFirstName(e.target.value) }}
              error={firstNameError !== ""}
              helperText={firstNameError ? firstNameError : ""}
              required
            />
            <TextField
              style={{ width: "50%" }}
              label="Last Name"
              type="text"
              name="lastName"
              value={lastName}
              onChange={(e) => { setLastNameError(""); setLastName(e.target.value); }}
              error={lastNameError !== ""}
              helperText={lastNameError ? lastNameError : ""}
              required
            />
          </div>
          <div className='box'>
            <TextField
              style={{ width: "50%" }}
              label="E-mail"
              type="email"
              name="email"
              value={email}
              onChange={(e) => {
                setEmailError("");
                let val = e.target.value;
                allUsers.forEach((obj) => {
                  if (obj.email.toLowerCase() === val.toLowerCase() && (obj.user_status !== "PENDING")) {
                    setEmailError("Email already exists");
                  } else {
                    setEmail(val);
                  }
                });

              }}
              error={emailError !== ""}
              helperText={emailError ? emailError : ""}
              required
            />
            <div className='roleDD' name={`${roleError !== "" ? "role" : ""}`}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>Select Roles</InputLabel>
                <Select
                  multiple
                  value={selectedRoles}
                  open={showList}
                  onClose={() => setShowList(false)}
                  onOpen={() => setShowList(true)}
                  onChange={(e) => setSelectedRoles(e.target.value)}
                  label="Select Roles"
                  renderValue={(selected) => selected.join(', ')}
                  style={{textOverflow:'ellipsis',whiteSpace:'nowrap', width:'288px', fontSize:'14px'}}
                >
                  {roles?.map((role) => (
                    <MenuItem key={role} value={role} className='menuItem'>
                      <input className="role-checkbox" style={{ cursor:'pointer'}}
                                                    type="checkbox"
                        checked={selectedRoles.includes(role)}
                        onClick={(e) => handleRolesSelect(e.target.checked, role)}
                      />
                      <span style={{ fontSize:'14px'}} >{role}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {roleError !== "" &&
                <p style={{ color: "#d32f2f", fontSize: "12px" }}>
                  {roleError}
                </p>
              }
            </div>
          </div>
        </div>
        {/* {
          emailError && <div style={{ display: "flex",alignItems: "center",justifyContent: "space-between", float:"left", fontSize:"12px", marginRight:"14%"}} >
            <InfoIcon />
            <p style={{whiteSpace:"nowrap"}}>{"If the user's status is pending, you can try resend verification link(in top right corner)."}</p>
          </div>
        } */}
        <div className={styles.reasonButtonBox} style={{ width: "97.5%", justifyContent: "space-between" }}>
          <button type="cancel" className={styles.btn} onClick={() => props.setAddPopUp(false)} style={{ width: "49%" }}>Cancel</button>
          <button type="submit" className={(emailError || firstName === '' || email === '' || lastName === '' || selectedRoles.length == 0) ? styles.disabled : styles.btn} onClick={() => handleSubmit()} style={{ width: "49%" }}>Add</button>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
