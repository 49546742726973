import React , {useState} from 'react';
import { deleteCitiesAPI } from '../../utils/api-helper';
import '../../assets/styles/deleteContent.css';

export default function DeleteCountryData(props) {
    const [errorMessage, setErrorMessage] = useState("");

    const deleteCity = (e) => {
        e.preventDefault();

        deleteCitiesAPI(props.selectedCityid).then(res => {
            props.selectStatesGetCities({ "id": props.selectedStateid,"name": props.selectedStateName });
        }).catch((err) => {
            setErrorMessage(err.response.data.message);
        })
    }

    return (
        <div className="flexdelete" >
            <div className="deleteBox">
                <div className="deleteContent">
                    <div className="deleteTitle">
                        <div className="heading">Delete State</div>
                        <div className="crossButtonHover" onClick={props.initialState}><div className="crossButton"></div></div>
                    </div>
                    <div className="deleteElements">
                        <div className="deleteOptionsData">
                            <p>Are you sure you want to delete</p>
                            <p>City name<span>: {props.selectedCityName}</span></p>
                            <div className="form_message-error">{ errorMessage ? errorMessage : ""}</div>
                        </div>
                        <div className="deleteFooter">
                            <div className="floatright">
                                <div className="applyButton" >
                                    <button onClick={deleteCity} className={`${ errorMessage ? "hidden" : "" }`} >DELETE</button>
                                </div>
                                <div className="cancleButton">
                                    <button onClick={props.initialState}>CANCEL</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
