import React, { useEffect, useState } from 'react';
import '../../assets/styles/managepages.css';
import styles from "./pendingInvoice.module.css";
import TransactionTable from "../../components/Tables/paymentTable";
import { getAllUnmappedTransactions } from '../../utils/api-helper';

const Popup = (props) => {
  const [rows, setRows] = useState([]);
  const [unmappedTransactionTable, setUnmappedTransactionTable] = useState(false);
  const [totalTransactions, setTotalTransaction] = useState("");
  useEffect(() => {
    getAllUnmappedTransactions(1).then((res) => {
      setTotalTransaction(res.data.length);
      setRows(res.data.map((row) =>
        createData(row.id, row.date, row.amount, row.description, row.mapStatus)
      ));
    }).catch((err) => {
    })
  }, []);

  function createData(id, formattedDate, amount, transactionDetails, mapped) {
    const tempDate = formattedDate.split(",")
    const date = tempDate[0].replaceAll("-", "/")

    // const dateObject = new Date(formattedDate);
    // // Extract day, month, and year components
    // const day = dateObject.getUTCDate().toString().padStart(2, '0');
    // const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed, so adding 1
    // const year = dateObject.getUTCFullYear().toString();
    // // Format into "ddmm yyyy" format
    // const date = `${day}/${month}/${year}`;

    return { id, date, amount, transactionDetails, mapped }
  }

  const columns = [{ id: "date", label: "Date", align: 'left', width: 12 },
  { id: "amount", label: "Amount", width: 12 },
  { id: "transactionDetails", label: "Transaction\u00a0Details", width: 12 },
  ];

  const deleteBtnStyles = {
    backgroundColor: "red",
    border: "2px solid red"
  };

  return (
    <div className="shortfall">
      <div className="popup-content">
        <h3 style={{ textAlign: "center" }} >{unmappedTransactionTable ? "Unmapped Transaction Details" : "Delete"}</h3>
        {
          totalTransactions > 0 ? <div>
          <p style={{ display: "flex", flexDirection: "column", textAlign: "center", whiteSpace: "nowrap" }} >{`Are you sure want to delete ${totalTransactions} transactions?`}</p>
          <p style={{ textAlign: "center" }}>or</p>
          {unmappedTransactionTable ?
            <p style={{ textDecoration: "underline", color: "blue", textAlign: "center", cursor: "pointer" }} onClick={() => { setUnmappedTransactionTable(false) }}>Hide Transactions Details</p>
            :
            <p style={{ textDecoration: "underline", color: "blue", textAlign: "center", cursor: "pointer" }} onClick={() => { setUnmappedTransactionTable(true) }}>Show Transactions Details</p>
          }
        </div> : 
        <div>
          <p style={{ display: "flex", flexDirection: "column", textAlign: "center", whiteSpace: "nowrap" }} >{`No unmapped transactions found`}</p>
        </div>
        }
        {
          unmappedTransactionTable && <div className='table-container'>
            <TransactionTable
              style={{ backgroundColor: "#c5c5c5" }}
              paginationStyle={{
                position: "fixed",
                right: "20px",
                bottom: "20px",
                backgroundColor: "#ffffff",
              }}
              setsortInvoiceData={props.setsortInvoiceData}
              columns={columns}
              normalData={true}
              rows={rows}
              title={"unmappedTransactions"}
              setRows={setRows}
              sortInvoiceData={props.sortInvoiceData}
              setHoveredRowIndex={props.setHoveredRowIndex}
              hoveredRowIndex={props.hoveredRowIndex}
              selectedStatus={props.selectedStatus}
              handlePaymentDetailCheck={props.handlePaymentDetailCheck}
              setSelectedStatus={props.setSelectedStatus}
              paymentDetailID={props.paymentDetailID}
            />
          </div>
        }

        <div className={styles.reasonButtonBox} style={{ width: "100%" }}>
          <button className={`${styles.btn}`} onClick={() => props.setShowUmappedTransactionModal(false)}>Cancel</button>
          <button onClick={() => props.deleteUnmappedTransaction()} className={`${totalTransactions > 0  ? styles.btn : styles.disabled}`} style={totalTransactions > 0  ? deleteBtnStyles : {}}>Delete</button>
        </div>

      </div>

    </div>
  );
};

export default Popup;
