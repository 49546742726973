import React, { useState, useEffect } from 'react'
import InvoiceTable from '../../components/Tables/pendingInvoiceTable';

export default function GetAllPendingInvoices (props) {
    const [rows, setRows] = useState([]);
    const [tableTitle, setTableTitle] = useState("listOfInvoices");

    const columns = [
        { id: "clientName", label: "Client\u00a0Name", align: 'left', width: 12 },
        { id: "projectName", label: "Project\u00a0Name", align: 'left', width: 12 },
        { id: "invoiceNumber", label: "Invoice\u00a0#", width: 12 },
        { id: "invoiceDate", label: "Invoice\u00a0Date", align: 'left', width: 10 },
        { id: "dueDate", label: "Due\u00a0Date", align: 'left', width: 10 },
        { id: "paymentStatus", label: "Payment\u00a0status", align: 'left', width: 12 },
        { id: "grandTotal", label: "Invoice\u00a0Amount", align: 'left', width: 12 },
        { id: "amountDue", label: "Amount\u00a0Due", align: 'left', width: 14 },
    ];

    function createData (
        id,
        firstName,
        lastName,
        projectName,
        invoiceNumber,
        invoiceDate,
        dueDate,
        paymentStatus,
        grandTotal,
        amountDue
    ) {
        const clientName = firstName + "\u00a0" + lastName;
        return { id, clientName, projectName, invoiceNumber, grandTotal, invoiceDate, dueDate, paymentStatus, amountDue }
    }

    useEffect(()=>{
        
    },[])
    useEffect(() => {
        setRows(
            props.response?.map((row) =>
                createData(row.id, row.clientFirstName, row.clientLastName, row.projectName, row.invoiceNumber, row.invoiceDate, row.dueDate, row.paymentStatus, row.grandTotal, row.amountDue)
            )
        );
    }, [props.response]);


    return (
        <>
            <div className='outer_content'>
                <InvoiceTable
                    style={{ backgroundColor: "#c5c5c5" }}
                    paginationStyle={{
                        position: "fixed",
                        right: "20px",
                        bottom: "20px",
                        backgroundColor: "#ffffff",
                    }}
                    totalCount={props?.totalCount}
                    page={props.page}
                    rowsPerPage={props.rowsPerPage}
                    handleChangePage={props.handleChangePage}
                    handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                    columns={columns}
                    rows={rows}
                    // function={props.getInvoiceById}
                    normalData={true}
                    setSortOrder={props.setSortOrder}
                    sortOrder={props.sortOrder}
                    setsortInvoiceData={props.setsortInvoiceData}
                    tableTitle={tableTitle}
                    sortBy={props.sortBy}
                    setSortBy={props.setSortBy}
                    sortInvoiceData={props.sortInvoiceData}
                    setHoveredRowIndex={props.setHoveredRowIndex}
                    hoveredRowIndex={props.hoveredRowIndex}
                    selectedStatus={props.selectedStatus}
                    setSelectedStatus={props.setSelectedStatus}
                    handleInvoiceCheck={props.handleInvoiceCheck}
                    invoiceID={props.invoiceID}
                    paymentDetailID={props.paymentDetailID}
                    handlePaymentStatusChange={props.handlePaymentStatusChange}
                    search={props.search}
                    paymentStatus={props.paymentStatus}
                    statusInvoiceID={props.statusInvoiceID}
                />
            </div>
        </>
    )
}