import './auth.css';
import React, { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import axios from 'axios';
import { countryCodeList, roles } from '../../utils/constants';
import Select from 'react-select';
import {
    validateEmail,
    validatePassword,
    validatePasswordRange,
    validateTextOnly,
    validateEqualInputs,
    validatePhoneNumber,
    validateCountryCode
} from '../../utils/input-validation';
import Footer from '../../components/footer/Footer';
import { getHostURL } from '../../utils/api-helper'
import showPwdImg from '../../assets/icons/show-password.svg';
import hidePwdImg from '../../assets/icons/hide-password.svg';
import Logo from '../../components/Logo_Biz4/Logo';
import {
    requiredFieldErrorMessage,
    firstNameErrorMessage,
    lastNameErrorMessage,
    emailErrorMessage,
    phoneErrorMessage,
    passwordErrorMessage,
    passwordDoesNotMatch,
    requiredfirstNameErrorMessage,
    requiredlastNameErrorMessage,
    requiredemailErrorMessage,
    // requiredphoneErrorMessage,
    // requiredCountryCodeErrorMessage,
    requiredUserRoleErrorMessage,
} from '../../utils/status-message-helper';
import SuccessModal from "./successModal";

const SIGNIN_URL = `${getHostURL()}/api/v1/users/signup`;

export default function Signup() {
    const [errMsg, setErrMsg] = useState('');
    const [fnameErrMsg, setFnameErrMsg] = useState('');
    const [lnameErrMsg, setLnameErrMsg] = useState('');
    const [emailErrMsg, setemailErrMsg] = useState('');
    const [userRoleErrMsg, setUserRoleErrMsg] = useState('');
    const [contCodeErrMsg, setcontCodeErrMsg] = useState('');
    const [phoneErrMsg, setphoneErrMsg] = useState('');
    const [passErrMsg, setpassErrMsg] = useState();
    const [doesPassMatchErrMsg, setDoesPassMatchErrMsg] = useState('');

    const [success, setSuccess] = useState(false);

    const [fname, setFirstName] = useState("");
    const [lname, setLastName] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [roleType, setRoleType] = useState([]);
    const [password, setPassword] = useState("");
    const [confpassword, setConfPassword] = useState("");
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isConfRevealPwd, setIsConfRevealPwd] = useState(false);
    const [responseData, setResponseData] = useState();
    const [verificationCode, setVerificationCode] = useState("");

    const userRef = useRef();

    const errRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();

    async function getUserData(code) {
        const GET_DATA_URL = `${getHostURL()}/api/v1/users/find/${code}`;
        if (code) {
            try {
                const response = await axios.get(GET_DATA_URL, {
                    method: "get",
                    headers: {
                        'Accept-Language': 'en',
                        'appVersion': '1',
                        'deviceId': '1',
                        'deviceName': 'web',
                        'deviceType': 'web',
                    }
                });
                // Assuming the response data is in JSON format, you can save it to the state:
                setResponseData(response.data);
            } catch (err) {
                // Handle error and set error message in state
                if (!err?.response) {
                    setErrMsg('Application error, Please contact system administrator.');
                } else if (err.response?.status === 400) {
                    setErrMsg(err.response.data.message);
                } else if (err.response?.status === 401) {
                    setErrMsg('Unauthorized');
                } else {
                    setErrMsg('Login Failed');
                }
                // errRef.current.focus();
            }
        }
    }
    function checkForm(){
        if(password !== "" && confpassword !== "" && phone !== "" && countryCode !== ""){
            return true;
        }else{
            return false;
        }

    }

    useEffect(() => {
        const tempVar = window.location.href;
        const code = tempVar.split("code=")
        const actualCode = atob(code[1]);
        getUserData(actualCode.replace("secretCodebiz4sol$", ""));
        setVerificationCode(actualCode.replace("secretCodebiz4sol$", ""));
        const queryParams = new URLSearchParams(location.search)
        if (queryParams.has('code')) {
            queryParams.delete('code');
            const newURL = window.location.pathname + '?' + queryParams.toString();
            // Update the browser's URL without triggering a page reload
            window.history.replaceState({}, document.title, newURL);
        }
    }, []);

    useEffect(() => {
        if (responseData) {
            setFirstName(responseData.firstName);
            setLastName(responseData.lastName);
            setEmail(responseData.email);
            setRoleType(responseData.roles);
        }
    }, [responseData]);

   
    // useEffect(() => {
    //     userRef.current.focus();
    // }, [])

    useEffect(() => {
        setErrMsg('');
    }, [fname, lname, email, countryCode, roleType, phone, password])


    const handleSubmit = async (e) => {
        e.preventDefault();
        const VERIFY_URL = `${getHostURL()}/api/v1/manageUsers/setPassword`

        if (validate()) {
            const body = {
                    "authPassword": password,
                    "confirmPassword": confpassword,
                    "contactNo": phone,
                    "countryCode": countryCode,
                    "email": email,
                    "verificationCode": verificationCode,
            }
            try {
                const response = await axios.post(VERIFY_URL, body,
                    {
                        method: "post",
                        headers: {
                            // 'Content-Type': 'application/json',
                            'Accept-Language': 'en',
                            'appVersion': '1',
                            'deviceId': '1',
                            'deviceName': 'web',
                            'deviceType': 'web',
                        }
                    }
                );
                setSuccess(true);
                localStorage.clear();
                setFirstName('');
                setLastName('');
                setCountryCode('');
                setPhone('');
                setEmail('');
                setRoleType([]);
                setPassword('');
                setConfPassword('');
            } catch (err) {
                if (!err?.response) {
                    setErrMsg('Application error, Please contact system administrator.');
                } else if (err.response?.status === 400) {
                    setErrMsg(err?.response?.data?.message);
                } else if (err.response?.status === 401) {
                    setErrMsg('Unauthorized');
                } else {
                    setErrMsg('Login Failed');
                }
            }
        }
    }

    function checkFirstName() {
        if (fname) {
            if (validateTextOnly(fname)) {
                setFnameErrMsg("");
                return true;
            } else {
                setFnameErrMsg(firstNameErrorMessage);
                return false;
            }
        } else {
            setFnameErrMsg(requiredfirstNameErrorMessage);
            return false;
        }
    }
    function checkLastName() {
        if (lname) {
            if (validateTextOnly(lname)) {
                setLnameErrMsg("");
                return true;
            } else {
                setLnameErrMsg(lastNameErrorMessage);
                return false;
            }
        } else {
            setLnameErrMsg(requiredlastNameErrorMessage);
            return false;
        }
    }
    function checkEmail() {
        if (email) {
            if (validateEmail(email)) {
                setemailErrMsg("");
                return true;
            } else {
                setemailErrMsg(emailErrorMessage);
                return false;
            }
        } else {
            setemailErrMsg(requiredemailErrorMessage);
            return false;
        }
    }
    function checkUserRole() {
        if (roleType) {
            setUserRoleErrMsg("");
            return true;
        } else {
            setUserRoleErrMsg(requiredUserRoleErrorMessage);
            return false;
        }
    }
    // function checkCountryCode() {
    //     if (countryCode) {
    //         setcontCodeErrMsg("");
    //         return true;
    //     } else {
    //         setcontCodeErrMsg(requiredCountryCodeErrorMessage);
    //         return false;
    //     }
    // }
    function checkPhone() {
        if (phone) {
            if (validatePhoneNumber(phone)) {
                setphoneErrMsg("");
                return true;
            } else {
                setphoneErrMsg(phoneErrorMessage);
                return false;
            }
        } else {
            // setphoneErrMsg(requiredphoneErrorMessage);
            return false;
        }
    }
    function checkPassword() {
        if (validatePasswordRange(password)) {
            if (validatePassword(password)) {
                setpassErrMsg("");
                return true;
            } else {
                setpassErrMsg(passwordErrorMessage);
                return false;
            }
        } else {
            setpassErrMsg(passwordErrorMessage);
            return false;
        }
    }
    function checkConfirmPassword() {
        if (confpassword) {
            if (password == confpassword) {
                setDoesPassMatchErrMsg("");
                return true;
            }
            else {
                setDoesPassMatchErrMsg(passwordDoesNotMatch);
                return false;
            }
        } else {
            setDoesPassMatchErrMsg("");
            return true;
        }
    }
    function validate() {
        if (checkFirstName() &&
            checkLastName() &&
            checkEmail() &&
            checkUserRole() &&
            // checkCountryCode() &&
            checkPhone() &&
            checkPassword() &&
            checkConfirmPassword()) {
            return true;
        } else {
            checkFirstName();
            checkLastName();
            checkEmail();
            checkUserRole();
            // checkCountryCode();
            checkPhone();
            checkPassword();
            checkConfirmPassword();
            return false;
        }
    }
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'red' : 'blue',
            padding: 20,
        }),
        control: () => ({
            // none of react-select's styles are passed to <Control />
            width: 200,
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        }
    }
    const handleBackToLoginScreen = () =>{
                navigate('/login');
                setSuccess(false);
    }
    const buttonStyle = {
        pointerEvents: checkForm() ? "auto" : "none",
        backgroundColor: checkForm() ?  "#013E7C" : "grey",
    }
    return (
        <>
            <div className="auth">
                < Logo />
                <div className="textalign">
                    <h2>WELCOME TO BIZ4SOLUTIONS</h2>
                    <p>Please enter your sign up details below</p>
                </div>
                <form className='form' method='POST' id="signup" onSubmit={handleSubmit}>
                    <div className="container">
                        <div className="input-flex">
                            <div className="name spacing form_input left-flex not-editable">
                                <input
                                    type="text"
                                    id="fname"
                                    placeholder="First Name"
                                    name="fname"
                                    onChange={(e) => setFirstName(e.target.value)}
                                    value={fname}
                                    ref={userRef}
                                />
                                <div className="form_message form_message-error">
                                    <p className={fnameErrMsg ? "errmsg" : "offscreen"} aria-live="assertive">{fnameErrMsg}</p>
                                </div>
                            </div>
                            <div className="name form_input right-flex not-editable">
                                <input
                                    type="text"
                                    id="lname"
                                    placeholder="Last Name"
                                    name="lname"
                                    onChange={(e) => setLastName(e.target.value)}
                                    value={lname}
                                />
                                <div className="form_message form_message-error">
                                    <p className={lnameErrMsg ? "errmsg" : "offscreen"} aria-live="assertive">{lnameErrMsg}</p>
                                </div>
                            </div>
                        </div>
                        <div className="email form_input not-editable">
                            <input
                                type="text"
                                id="email"
                                placeholder="Email"
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                        </div>
                        <div className="form_message form_message-error">
                            <p className={emailErrMsg ? "errmsg" : "offscreen"} aria-live="assertive">{emailErrMsg}</p>
                        </div>
                            <div className="role form_input  not-editable" >
                                <input className='roles-Input'
                                    type="text"
                                    id="userRoleType"
                                    placeholder="Roles"
                                    name="role"
                                    value={roleType.join(', ')}
                                    onChange={(e) => setRoleType(e.target.value)}
                                />
                            </div>
                            <div className="form_message form_message-error">
                                <p className={userRoleErrMsg ? "errmsg" : "offscreen"} aria-live="assertive">{userRoleErrMsg}</p>
                            </div>
                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <div className="contCode form_input left-flex" name="country_code">
                                    <Select
                                        type="text"
                                        id="countryCode"
                                        placeholder="Country Code"
                                        name="countryCode"
                                        options={countryCodeList}
                                        onChange={(e) => setCountryCode(e.value)}
                                    />
                                    <div className="form_message form_message-error">
                                        <p className={contCodeErrMsg ? "errmsg" : "offscreen"} aria-live="assertive">{contCodeErrMsg}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="phone form_input right-flex">
                                <input
                                    type="number"
                                    id="phone"
                                    placeholder="Phone"
                                    name="phone"
                                    onChange={(e) => {
                                        const input = e.target.value;
                                        const formattedPhoneNumber = input.replace(/\D/g, '');
                                        if (formattedPhoneNumber.length <= 10) {
                                          setPhone(formattedPhoneNumber);
                                        //   setError(formattedPhoneNumber.length === 10 ? '' : 'Phone number must be 10 digits');
                                        }
                                      }}
                                    value={phone}
                                    pattern="[0-9]{10,10}"
                                    required
                                    invalidMessage="Please enter a 10-digit phone number."
                                />
                                <div className="form_message form_message-error">
                                    <p className={phoneErrMsg ? "errmsg" : "offscreen"} aria-live="assertive">{phoneErrMsg}</p>
                                </div>
                            </div>
                        </div>

                        <div className="password form_input">
                            <input
                                type={isRevealPwd ? "text" : "password"}
                                id="password"
                                placeholder="Password"
                                name="password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                            <div
                                title={isRevealPwd ? "Hide password" : "Show password"}
                                className={isRevealPwd ? "hide_password" : "show_password"}
                                onClick={() => setIsRevealPwd(prevState => !prevState)}
                            />
                        </div>
                        <div className="form_message form_message-error">
                            <p className={passErrMsg ? "errmsg" : "offscreen"} aria-live="assertive">{passErrMsg}</p>
                        </div>
                        <div className="password form_input">
                            <input
                                type={isConfRevealPwd ? "text" : "password"}
                                id="confpassword"
                                placeholder="Confirm Password"
                                name="confpassword"
                                onChange={(e) => setConfPassword(e.target.value)}
                                value={confpassword}
                            />
                            <div
                                title={isConfRevealPwd ? "Hide password" : "Show password"}
                                className={isConfRevealPwd ? "hide_password" : "show_password"}
                                onClick={() => setIsConfRevealPwd(prevState => !prevState)}
                            />
                        </div>
                        <div className="form_message form_message-error">
                            <p className={doesPassMatchErrMsg ? "errmsg" : "offscreen"} aria-live="assertive">{doesPassMatchErrMsg}</p>
                        </div>
                        <button style={buttonStyle}>SIGNUP</button>
                    </div>
                    <div className="form_message form_message-error">
                        <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    </div>
                    {/* <p className="text-center">Already have an account <NavLink to="/login" id='linkLoginAccount'>Login</NavLink></p> */}
                    {/* <br /> */}
                    <p className="text-center">By signing up I agree with <NavLink to="/TnC">Terms & Conditions</NavLink> and <NavLink to="/PrivacyPolicy">Privacy Policy</NavLink></p>
                </form>
            </div>
            <Footer />
            {
                success && <SuccessModal handleBackToLoginScreen={handleBackToLoginScreen}/>
            }
        </>
    )
}