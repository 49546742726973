
/*
 * Method to set an item in local-storage
 * @param: key
 * @param: value
 * */
export function setItemLocalStorage(key, value, callback) {
    localStorage.setItem(key, value)
    if (callback) {
        callback();
    }
}

/*
 * Method to get an item from local-storage
 * @param: key
 * @param: defaultValue
 * */
export function getItemLocalStorage(key, defaultValue, callback) {
    return localStorage.getItem(key, (error, result) => {
        callback(result || defaultValue);
    });
}

/*
 * Method to set an item(of type array) in local-storage
 * @param: key
 * @param: value
 * */
export function setArray(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

/*
 * Method to get an item(of type array) from local-storage
 * @param: key
 * */
export function getArray(key) {
    if (localStorage.getItem(key)) {
        return JSON.parse(localStorage.getItem(key));
    } else {
        return [];
    }
}

/*
 * Method to set an item(of type Object) in local-storage
 * @param: key
 * @param: value
 * */
export function setObj(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

/*
 * Method to get an item(of type Object) in local-storage
 * @param: key
 * */
export function getObj(key) {
    if (localStorage.getItem(key)) {
        return JSON.parse(localStorage.getItem(key));
    } else {
        return undefined;
    }
}

/*
 * Method to remove specific data from local storage
 * @param: key
 * */
export function removeObj(key) {
    if (localStorage.getItem(key)) {
        localStorage.removeItem(key);
    }
}

/*
 * Method to remove all data from local storage
 * */
export function clearAllLocalStorage() {
    localStorage.clear();
}

/*
* Fetch cookie data saved to cookies storage
* */
export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

/*
* Set your cookie data to cookies storage with key values
* */   
// 30*24*60*60*1000 = 30 days
// 24*60*60*1000 = 1 day
// 60*1000 = 1 minute
export function setCookie(cname, cvalue) {
    const d = new Date();
    d.setTime(d.getTime() + (30*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=http://localhost:3000/login";
  }