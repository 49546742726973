import React, { useState, useEffect } from "react";
import AddContentButton from "../../components/Buttons/AddContent";
import FilterContentButton from "../../components/Buttons/FilterContent";
import SortContentButton from "../../components/Buttons/SortContent";
import Table from "../../components/Table";
import Badge from "@mui/joy/Badge";

export default function GetAllProjects(props) {
  const [rows, setRows] = useState([]);
  const columns = [
    {
      field: "clientName",
    },
    {
      field: "projectName",
    },
    {
      field: "projectManager",
    },
    {
      field: "projectStartDate",
    },
    {
      field: "isActive",
      headerName: "Status",
    },
  ];
  function createData(
    id,
    clientFirstName,
    clientLastName,
    projectName,
    projectManagerFirstName,
    projectManagerLastName,
    projectStartDate,
    isActiveCheck
  ) {
    const isActive = isActiveCheck ? "Active" : "Inactive";
    const clientName = clientFirstName + "\u00a0" + clientLastName;
    const projectManager =
      projectManagerFirstName + "\u00a0" + projectManagerLastName;
    return {
      id,
      clientName,
      projectName,
      projectManager,
      projectStartDate,
      isActive,
    };
  }
  const rows1 = props.response.map((row) =>
    createData(
      row.id,
      row.clientFirstName,
      row.clientLastName,
      row.projectName,
      row.projectManagerFirstName,
      row.projectManagerLastName,
      row.startDate,
      row.isActive
    )
  );
  useEffect(() => {
    setRows(
      props.response.map((row) =>
        createData(
          row.id,
          row.clientFirstName,
          row.clientLastName,
          row.projectName,
          row.projectManagerFirstName,
          row.projectManagerLastName,
          row.startDate,
          row.isActive
        )
      )
    );
  }, [props.response]);

  return (
    <>
      <div className="inner-content">
        <div className="manage_content">
          <div className="content_subtitle">
            <p>List of Projects</p>
          </div>
          <div className="box-functional_elements">
            <div className="functional_elements">
              <div onClick={props.addProject}>
                <AddContentButton />
              </div>
            </div>
            <div className="functional_elements">
              <div onClick= { () =>{
                props.setPage(0);
                 props.filterProject();
              }}
              className="filterDiv">
                <Badge invisible={!props.isFilterSelected} size="lg">
                  <FilterContentButton />
                </Badge>
              </div>
            </div>
            <div className="functional_elements">
              <div onClick={props.sortProject}>
                <SortContentButton />
              </div>
            </div>
          </div>
        </div>
        <div className="table-outer_content">
          <Table
            paginationStyle={{
              position: "fixed",
              right: "20px",
              bottom: "20px",
              backgroundColor: "#ffffff",
            }}
            totalCount={props?.totalCount}
            page={props.page}
            rowsPerPage={props.rowsPerPage}
            handleChangePage={props.handleChangePage}
            handleChangeRowsPerPage={props.handleChangeRowsPerPage}
            columns={columns}
            rows={rows}
            function={props.getProjectById}
            normalData={true}
            isPagination = {true}
          />
        </div>
      </div>
    </>
  );
}
