import React, { useEffect, useState } from "react";
import '../../assets/styles/addcontent.css'
import { Grid } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from "@mui/material/TextField";

const FilterByMonth = (props) => {
    const dateChangeHandler = (e) => {
        const newDate = e.$d;
        props.setDate(newDate);
        props.setMonth(newDate.toLocaleString('default', { month: 'long' }));
        props.setYear(e.$y);
    }
    return (
        <>
            <div style={{width:"10rem"}}>
                <Grid item xs={8}>
                    <div className="datePickerStyle" style={{marginTop:"-6px"}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} components={['DatePicker']} >
                            <DatePicker
                                value={props.date}
                                onChange={(e) => dateChangeHandler(e)}
                                views={['year', 'month']}
                                renderInput={(props) => {
                                    let trimmedMonth = props.inputProps.value.split(" ");
                                    let actualDate = `${trimmedMonth[0].substring(0, 3)} ${trimmedMonth[1]}`;
                                    return <TextField size="small" style={{ width: "11.2em" }} {...props}
                                        inputProps={{ ...props.inputProps, value: actualDate }}
                                    />
                                }
                                }
                            />
                        </LocalizationProvider>
                    </div>
                </Grid>
            </div>
            {/* </Grid> */}
        </>

    );
}

export default FilterByMonth;
