import React, { useState, useEffect } from 'react'
import AddContentButton from '../../components/Buttons/AddContent';
import FilterContentButton from '../../components/Buttons/FilterContent';
import SortContentButton from '../../components/Buttons/SortContent';
import Table from '../../components/Table'
import Badge from '@mui/joy/Badge';

export default function GetAllInvoice(props) {

    const [rows, setRows] = useState([]);
     const columns = [
        {
            field:"clientName"
        },
        {
            field:"contactPerson",
            valueFormatter:(p) =>{
                return /\S/.test(p.value) ?p.value:"-";
            }
        },
        {
            field:"email",
            valueFormatter:(p) =>{
                return /\S/.test(p.value) ?p.value:"-";
            }
        },
        {
            field:"countryName",
            headerName:"Country" ,
            valueFormatter:(p) =>  /\S/.test(p.value) ?p.value.toLocaleString("en-US"):"-",
        },
        {
            field:"contactNo",
            valueFormatter:(p) =>{
                return /\S/.test(p.value) ?p.value:"-";
            }
        },
        {
            field:"isActive",
            headerName:  "Status"
        },
     ]

    function createData(
        id,
        firstName,
        lastName,
        contactPersonFirstName,
        contactPersonLastName,
        email,
        countryName,
        contactNo,
        isActiveCheck
    ) {
        const clientName = firstName + "\u00a0" + lastName;
        const contactPerson =
            contactPersonFirstName + "\u00a0" + contactPersonLastName;
        const isActive = isActiveCheck == true ? "Active" : "Inactive";
        return {
            id,
            clientName,
            contactPerson,
            email,
            countryName,
            contactNo,
            isActive,
        };
    }
    useEffect(() => {
        setRows(
            props.response?.map((row) =>
                createData(
                    row.id,
                    row.firstName,
                    row.lastName,
                    row.contactPersonFirstName,
                    row.contactPersonLastName,
                    row.email,
                    row.countryName,
                    row.contactNo,
                    row.isActive
                )
            )
        );
    }, [props.response]);

    return (
        <>
            <div className="inner-content">
                <div className='manage_content' >
                    <div className="content_subtitle">
                        <p>List of Clients</p>
                    </div>
                    <div className="box-functional_elements">
                        <div className="functional_elements" >
                            <div onClick={props.addClient}>
                                <AddContentButton />
                            </div>
                        </div>
                        <div className="functional_elements">
                            <div onClick={props.filterClient} className="filterDiv">
                                <Badge
                                    invisible={!props.isFilterSelected}
                                    size="lg"
                                >
                                    <FilterContentButton />
                                </Badge>
                            </div>
                        </div>
                        <div className="functional_elements" >
                            <div onClick={props.sortClient} >
                                <SortContentButton />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='table-outer_content'>
                    <Table
                         totalCount={props.totalCount}
                         page={props.page}
                         rowsPerPage={props.rowsPerPage}
                         handleChangePage={props.handleChangePage}
                         handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                         columns={columns}
                         rows={rows}
                         function={props.getClientById}
                         normalData={true}
                         isPagination = {true}
                    />
                </div>
            </div>
        </>
    )
}
