import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import '../../assets/styles/deleteContent.css';
import { removeProjectAPI } from '../../utils/api-helper';
import qs from 'qs';
import { responseData } from "./viewProject";
import { useNavigate } from "react-router-dom";
import { cityErrorMessage } from '../../utils/status-message-helper';

export default function DeleteProject(props) {
    const [errorMessage, setErrorMessage] = useState("");
    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id');
    const navigate = useNavigate();

    const deleteProject = (e) => {
        e.preventDefault();
        const body = qs.stringify({
            "id": id
        });
        removeProjectAPI(body).then(res => {
            // console.log(res);
            props.getAllProjects();
            // setResponse(res)
            // setSuccess(true)
            navigate('/project');
        }).catch((err, res) => {
            // setSuccess(false)
            // console.log(err);
            setErrorMessage(err.response?.data.message);
        })
    }
    return (
        <>
            <div className="flexdelete" >
                <div className="deleteBox">
                    <div className="deleteContent">
                        <div className="deleteTitle">
                            <div className="heading">Delete Project</div>
                            <div className="crossButtonHover" onClick={() => { props.getProjectById(id) }}><div className="crossButton"></div></div>
                        </div>
                        <div className="deleteElements">
                            <div className="deleteOptionsData">
                                <p>Are you sure you want to delete</p>
                                <p>Project Name : <span>{props.selectedProjectName}</span></p>
                                <div className="form_message-error">{errorMessage ? errorMessage : ""}</div>
                            </div>
                            <div className="deleteFooter">
                                <div className="floatright">
                                    <div className="applyButton">
                                        <button onClick={deleteProject} className={`${ errorMessage ? "hidden" : "" }`}>Delete</button>
                                    </div>
                                    <div className="cancleButton">
                                        <button onClick={() => { props.getProjectById(id) }}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
