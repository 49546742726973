import React from 'react'
import '../buttons.css'
export default function index(props) {
    return (
        <>
            <div className="BackButtonContainer" onClick={props.handleClick}  title="Back">
                <div className="left-arrow-icon"></div>
            </div>
        </>
    )
}
