import React, { useState, useEffect, useCallback, useRef } from "react";
import { deleteCountryAPI } from '../../utils/api-helper';

export default function DeleteCountryData(props) {
    const [errorMessage, setErrorMessage] = useState("");

    const deleteCountry = (e) => {
        e.preventDefault();

        deleteCountryAPI(props.id).then(res => {
            props.getAllCountries();
        }).catch((err) => {
            setErrorMessage(err.response.data.message);
        })
    }

    return (
        <div className="flexdelete" >
            <div className="deleteBox">
                <div className="deleteContent">
                    <div className="deleteTitle">
                        <div className="heading">Delete Country</div>
                        <div className="crossButtonHover" onClick={props.initialState}><div className="crossButton"></div></div>
                    </div>
                    <div className="deleteElements">
                        <div className="deleteOptionsData">
                            <p>Are you sure you want to delete</p>
                            <p>Country name<span>: {props.name}</span></p>
                            <div className="form_message-error">{ errorMessage ? errorMessage : ""}</div>
                        </div>
                        <div className="deleteFooter">
                            <div className="floatright">
                                <div className="applyButton">
                                    <button onClick={deleteCountry} className={`${ errorMessage ? "hidden" : "" }`} >DELETE</button>
                                </div>
                                <div className="cancleButton">
                                    <button onClick={props.initialState}>CANCEL</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
