import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import BrowseHistory from '../../utils/browseHistory';
import { useNavigate } from 'react-router-dom';
import { budgetValidation } from '../../utils/api-helper';
import "../authentication/auth.css"
 
 
const BudgetValidator = ({ onValidationComplete }) => {
  const [msg, setMsg] = useState('');
  const [passCode, setPassCode] = useState('');
  const navigate = useNavigate();
  const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
  const matchPasscode = () => {
    return passCode === 'Test@1234'
  };
 
  const handleInputChange = (event) => {
    setPassCode(event.target.value);
  };
  const handleSubmit = () => {
    if (passCode !== '') {
      budgetValidation(passCode).then((response) => {
        onValidationComplete(true);
        setMsg('')
      }).catch((error) => {
        onValidationComplete(false);
        setMsg('Wrong Password, please try again')
      })
    } else {
      setMsg('Please Enter Password')
    }
  }
  const handleNavigation = () => {
 
    navigate('/dashboard')
  }
  const popupStyle = {
    padding: '20px',
    borderRadius: '10px',
    margin: '0 auto',
    textAlign: 'center',
    width: '70%'
  };
 
  const headingStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
  };
 
  const errorMessageStyle = {
    color: 'red', marginBottom: '5px'
  };
 
  const inputContainerStyle = {
    marginBottom: '15px',
  };
 
  const inputStyle = {
    width: '100%',
    padding: '13px',
    boxSizing: 'border-box',
    borderRadius: '5px',
    border: '1px solid #ccc',
    marginTop: '12px'
  };
 
  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
  };
 
  const submitButtonStyle = {
    backgroundColor: '#013E7C',
    color: 'white',
    padding: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '40%'
  };
 
  const closeButtonStyle = {
    backgroundColor: '#013E7C',
    color: 'white',
    padding: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '40%'
 
  };
 
 
  return (
    <div className='popup'>
      <div className='popup-content'>
        <div style={popupStyle}>
          <span style={headingStyle}>Budget Validation</span><br />
          <span style={errorMessageStyle}>
            {msg}
          </span><br />
          <div style={inputContainerStyle} className='inputStyles'>
 
            <input
              style={inputStyle}
              placeholder='Enter Password' type={isRevealNewPwd? 'text': 'password'}
              value={passCode}
              onChange={handleInputChange}
            />
            <div
              title={isRevealNewPwd ? "Hide password" : "Show password"}
              style={{position:'absolute',right:'36%', top:"47%"}}
              className={isRevealNewPwd ? "hide_forget_password" : "show_forget_password"}
              onClick={() => setIsRevealNewPwd(prevState => !prevState)}
            />
          </div>
          <div style={buttonContainerStyle} className='buttonStyles'>
            <button style={submitButtonStyle} onClick={handleSubmit}>
              Submit
            </button>
            <button style={closeButtonStyle} onClick={handleNavigation}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default BudgetValidator;
 
 
