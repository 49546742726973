import React, { useState } from 'react';
import '../auth.css';
import {validateEmail} from '../../../utils/input-validation';
import { emailErrorMessage } from '../../../utils/status-message-helper';
import { requestOtpAPI } from '../../../utils/authAPI';


const EnterEmail = (props) => {
    // const [props.email, setEmail] = useState(props.props.email);
    // const [props.isVerifyOtpScreen, props.setIsVerifyOtpScreen] = useState(false);
    // const [props.errorMessage, props.setErrorMessage] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        props.setStartLoader(true);
        props.setErrorMessage("");
        // const requestOtpUrl = `${getHostURL()}/api/v1/admin/otp/requestOtp`;
        if(validateEmail(props.email)){

            const body = JSON.stringify({
                "email": props.email,
                "role": "",
                "transactionId": ""
            });
            requestOtpAPI(body)
                .then((response)=>{
                    props.setIsVerifyOtpScreen(true);
                    props.setResendOtpTime(response.data.resendTimeout);
                    props.setOtpExpireTime(response.data.otpExpiredTime);
                    props.setTransactionId(response.data.transactionId);
                    props.setStartLoader(false);
                })
                .catch((err)=>{
                props.setStartLoader(false);
                props.setErrorMessage(err?.response.data.message);
                props.setStartLoader(false);
            })
        }else{
            props.setStartLoader(false);
            props.setErrorMessage(emailErrorMessage);
        }
    };

    const handleChange = (event) => {
        props.setEmail(event.target.value);
    };

    return (
        <>
            <div>
                <div className={props.isVerifyOtpScreen ? "form_hidden" : "enter-email-popup"}>
                    <h4>Enter e-mail address</h4>
                    <div className={"floating-label"}>
                            <input type="email" value={props.email} onChange={handleChange} required="required" />
                            <span>e-mail</span>
                        <div className='errorMessage'>
                            <p style={{whiteSpace:"nowrap", marginLeft:"-5px"}}>
                                {props.errorMessage ? props.errorMessage : ""}
                            </p>
                        </div>
                    </div>
                    <button onClick={(event)=>handleSubmit(event)} className='verify-otp'>Request OTP</button>
                </div>
            </div>
        </>
    );
};
export default EnterEmail;