import Footer from "../../components/footer/Footer";
import Navigation from "../../components/Navigation";
import GetAllBudgets from "./getAllBudgets";
import { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import { Alert, Snackbar } from "@mui/material";
import { getMonthlyBudget, updateMonthlyBudget, deleteBudgetId, createBudgetWithZero, getProjectionSummary ,getTotalMonthlyProjection} from "../../utils/api-helper";
import AddBudget from "./addBudget";
import ErrorPopup from '../../components/ErrorPopup';
import BudgetAlert from "./alert";
import Settings from "../../components/Settings";
import Popup from '../managePendingInvoices/confirmationPopup';
import FilterBudget from "./FilterBudget";
import BudgetValidator from "./budgetValidator";
import ProjectionSummary from "./projectionSummary";


export default function Index() {

    const currentDate = new Date();
    const currentMonthName = currentDate.toLocaleString('default', { month: 'long' });
    // const currentYear = currentDate.getFullYear()
    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const startYear = currentYear - 4;
        const endYear = currentYear;
        const options = [];

        for (let year = startYear; year <= endYear; year++) {
            options.push({ value: year, label: `${year}-${year + 1}` });
        }
        return options;
    };
    const [financialYears, setFinancialYears] = useState(generateYearOptions());
    const currentYear = new Date().getFullYear();
    const [month, setMonth] = useState(null)
    const [year, setYear] = useState(null)
    const [date, setDate] = useState(null)
    const currentFinancialYearOption = financialYears.find(
        (option) => option.value === currentYear
    );
    const [financialYear, setFinancialYear] = useState(
        currentFinancialYearOption
    );
    const [budgets, setBudgets] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(10);
    const [startLoader, setStartLoader] = useState(false);
    const [isDataUpdated, setIsDataUpdated] = useState(false);
    const [successMessage, setSuccessMessage] = useState("Data updated successfully.")
    const [addSingleBudget, setAddSingleBudget] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [viewAllData, setViewAllData] = useState(null);
    const [editable, setEditable] = useState(false);
    const [alertResult, setAlertResult] = useState(null);
    const [deleteData, setDeleteData] = useState()
    const [error, setError] = useState(null);
    const [budgetId, setBudgetId] = useState(null);
    const [filterBudget, setFilterBudget] = useState(false);
    const [isFilterSelected, setIsFilterSelected] = useState();
    const [deliveryManagerFirstNameFilter, setDeliveryManagerFirstNameFilter] = useState("");
    const [deliveryManagerLastNameFilter, setDeliveryManagerLastNameFilter] = useState("");
    const [budgetValidator, setBudgetValidator] = useState(false);
    const [isProjectionSummaryOpen, setIsProjectionSummaryOpen] = useState(false);
    const [data,setData] = useState(null);
    const [order,setOrder] = useState("DESC")
    const [totalAmount,setTotalAmount] = useState(0);
    const handleChangePage = (newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (value) => {
        setRowsPerPage(value);
        setPage(0);
    };

    const closeErrorPopup = () => {
        setError(null);
        setAddSingleBudget(false);
    };
    useEffect(() => {
        if (!budgetValidator) {
            getAllMonthlyBudget();
        }
    }, [page, rowsPerPage]);

    useEffect(() => {
        getBudgetValidator();
    }, [])
    function getBudgetValidator() {
        setBudgetValidator(true);
    }
    function handleValidationComplete(validationFlag) {
        if (validationFlag) {
            setBudgetValidator(false);
            setIsProjectionSummaryOpen(true);
            getProjectionSummaryByYear();
        } else {
        }
    }
    function getAllMonthlyBudget() {
        
        if(month == null || year == null ){
            return ;
        }
        setStartLoader(true);
        const body = {
            "deliveryManagerFirstName": deliveryManagerFirstNameFilter,
            "deliveryManagerLastName": deliveryManagerLastNameFilter,
            "isActive": true
        }
        getMonthlyBudget(year, month.toUpperCase(), body, page, rowsPerPage,order).then((res) => {
            let val = res?.data?.list.length;
            if (val < 1) {
                setShowAlert(true);
            }
            setTotalCount(res?.data?.totalCount);
            setBudgets(res.data.list);
        }).catch((err) => {
        }).finally(() => {
            setStartLoader(false);
            setAlertResult(null);
        })
        setStartLoader(true);
        getTotalMonthlyProjection(year,month.toUpperCase(),body).then((res) =>{
            setTotalAmount(res.data);
        }).catch((err) =>{
            // console.log(err);
        }).finally(()=>{
            setStartLoader(false);
        })
    }
    function getProjectionSummaryByYear(){
    
        if(financialYear == null){
            return ;
        }
        setStartLoader(true);
        getProjectionSummary(financialYear.value).then((res) =>{
            setData(res?.data)
        }).catch((err)=>{
            setStartLoader(false);
        }).finally(()=>{
            setStartLoader(false);
            setAlertResult(null);
        })
    }

    useEffect(() => {
        if (budgets.length > 0) {
            setShowAlert(false);
        }
    }, [budgets])

    function updateBudget(body) {
        setEditable(false);
        setStartLoader(true);
        updateMonthlyBudget(body).then((res) => {
            setIsDataUpdated(true);
        }).catch((err) => {
        }).finally(() => {
            setStartLoader(false);
            getAllMonthlyBudget();
            setIsDataUpdated(false);
        })
    }

    function deleteBudget(id) {
        setStartLoader(true);
        deleteBudgetId(id).then((res) => {
        }).catch((err) => {
        }).finally(() => {
            setStartLoader(false);
            setBudgetId(null);
            getAllMonthlyBudget();
        })
    }
    function getMonthFromString(mon){

        var d = Date.parse(mon + "1, 2012");
        if(!isNaN(d)){
           return new Date(d).getMonth() ;
        }
        return -1;
      }
    const handleMonthlyBudget = (row) => {
        setIsProjectionSummaryOpen(false);
        setMonth(row.month);
        const monthValue = getMonthFromString(row.month);
        if(monthValue == 0 || monthValue == 1 || monthValue == 2){
            setYear(financialYear.value + 1);
            const d = new Date(financialYear.value + 1,monthValue);
            setDate(d)
        }
        else{
            setYear(financialYear.value);
            const d = new Date(financialYear.value,monthValue);
            setDate(d)
        }
        
    }

    useEffect(() => {
        if (viewAllData) {
            createBudgetWithZero(year, month).then((res) => {
            }).catch((err) => {
            }).finally(() => {
                getAllMonthlyBudget();
                setViewAllData(null);
            })
        }
    }, [viewAllData])

    useEffect(() => {
        setPage(0);
        if (!budgetValidator && !isProjectionSummaryOpen) {
            getAllMonthlyBudget();
        }
    }, [month, year,order])

    const handleYearChange = (selectedOption) => {
        setFinancialYear(selectedOption);
    };
    const handleBackToProjectionSummary = () =>{
        setIsProjectionSummaryOpen(true);
        setMonth(null);
        setYear(null);
        setDate(null);
    }
    useEffect(()=>{
        if(isProjectionSummaryOpen){
            setDeliveryManagerFirstNameFilter("");
            setDeliveryManagerLastNameFilter("");
            setPage(0);
            getProjectionSummaryByYear();
        }

    },[financialYear,isProjectionSummaryOpen])
    return <>
        <div className="flex-container">
            <Snackbar open={isDataUpdated} autoHideDuration={3000} onClose={() => setIsDataUpdated(false)} message={successMessage}>
                <Alert onClose={() => setIsDataUpdated(false)} color="success" >
                    {successMessage}
                </Alert>
            </Snackbar>
            <div>
                <Navigation myClass="Revenue Projections" />
            </div>
            <div className="right-section">
                <div className="title" id="title">
                    <div className="pageheading">
                        <p>Revenue Projections</p>
                    </div>
                    <Settings />
                </div>
                <div className="content" id="content">
                    {
                        budgetValidator ? ' ' : isProjectionSummaryOpen ?
                            <ProjectionSummary
                                isProjectionSummaryOpen={isProjectionSummaryOpen}
                                handleMonthlyBudget={handleMonthlyBudget}
                                financialYears={financialYears}
                                financialYear={financialYear}
                                handleYearChange={handleYearChange}
                                getProjectionSummaryByYear={getProjectionSummaryByYear}
                                data = {data}
                            />
                            : <GetAllBudgets
                                budgets={budgets}
                                setBudgets={setBudgets}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                setPage = {setPage}
                                totalCount={totalCount}
                                date={date}
                                setDate={setDate}
                                updateBudget={updateBudget}
                                month={month}
                                year={year}
                                setMonth={setMonth}
                                setYear={setYear}
                                setEditable={setEditable}
                                editable={editable}
                                getAllMonthlyBudget={getAllMonthlyBudget}
                                setAddSingleBudget={setAddSingleBudget}
                                setBudgetId={setBudgetId}
                                setFilterBudget={setFilterBudget}
                                filterBudget={filterBudget}
                                isFilterSelected={isFilterSelected}
                                setIsFilterSelected={setIsFilterSelected}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                handleChangePage={handleChangePage}
                                handleBackToProjectionSummary ={handleBackToProjectionSummary}
                                setOrder={setOrder}
                                order = {order}
                                totalAmount={totalAmount}
                            />
                    }
                </div>
                <Footer />
            </div>
            {
                startLoader ? <Loader /> : <></>
            }
            {
                addSingleBudget && <AddBudget
                    month={month}
                    year={year}
                    setAddSingleBudget={setAddSingleBudget}
                    setStartLoader={setStartLoader}
                    setSuccessMessage={setSuccessMessage}
                    setIsDataUpdated={setIsDataUpdated}
                    setError={setError}
                    getAllMonthlyBudget={getAllMonthlyBudget}
                />
            }
            {
                showAlert && <BudgetAlert
                    month={month}
                    year={year}
                    setShowAlert={setShowAlert}
                    setViewAllData={setViewAllData}
                    setAlertResult={setAlertResult}
                />
            }
            {
                budgetValidator && <BudgetValidator onValidationComplete={handleValidationComplete} />
            }
            {budgetId !== null && (
                <Popup
                    deleteData={true}
                    budgetId={budgetId}
                    deleteBudget={deleteBudget}
                    setBudgetId={setBudgetId}
                />
            )}
            {error && <ErrorPopup errorMessage={error} onClose={closeErrorPopup} />}
            {
                filterBudget && <FilterBudget
                    setFilterBudget={setFilterBudget}
                    filterBudget={filterBudget}
                    isFilterSelected={isFilterSelected}
                    setIsFilterSelected={setIsFilterSelected}
                    setDeliveryManagerLastNameFilter={setDeliveryManagerLastNameFilter}
                    setDeliveryManagerFirstNameFilter={setDeliveryManagerFirstNameFilter}
                    deliveryManagerFirstNameFilter={deliveryManagerFirstNameFilter}
                    deliveryManagerLastNameFilter={deliveryManagerLastNameFilter}
                    getAllMonthlyBudget={getAllMonthlyBudget}
                />
            }
        </div>
    </>
}