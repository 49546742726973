import React, { useEffect, useState } from 'react'
import Table from '../../components/Table'
import Select from "react-select";
export default function ProjectionSummary(props) {
    const [rows, setRows] = useState(props?.data);
    const [invoiceDateFromFilter, setInvoiceDateFromFilter] = useState("");
    const [invoiceDateToFilter, setInvoiceDateToFilter] = useState("");
    const [isTableEditable, setIsTableEditable] = useState(false);
    const columns = [
        { field: "month", headerName: "Month" },
        {
            field: "totalAmount", headerName: "Monthly Revenue(Projected)",
            valueFormatter: (p) => {
                return p.value === 0 || p.value === null ? '-' : `$${Math.abs(p.value).toFixed(2).toLocaleString()}`;
            },
            cellStyle: { textAlign: 'right' },
        },
        {
            field: "invoiceRaised", headerName: "Invoices Raised",
            valueFormatter: (p) => {
                return p.value === 0 || p.value === null ? '-' : `$${Math.abs(p.value).toFixed(2).toLocaleString()}`;
            },
            cellStyle: { textAlign: 'right' },
        },
        {
            field: "totalArAmount", headerName: "Monthly Revenue(Received)",
            valueFormatter: (p) => {
                return p.value === 0 || p.value === null ? '-' : `$${Math.abs(p.value).toFixed(2).toLocaleString()}`;
            },
            cellStyle: { textAlign: 'right' },
        },
       
        {
            field: "diff", headerName: "Shortfall/Excess wrt Target",
            cellRenderer : ({value}) =>{
                if(value == 0 || value == null){
                    return '-';
                }
                return value > 0 ? <div style={{color:"green"}}>{"$" + Math.abs(value).toFixed(2).toLocaleString()}</div>:<div style={{color:"red"}}>{"($" + Math.abs(value).toFixed(2).toLocaleString()+")"}</div>;
                
            },
            cellStyle: { textAlign: 'right'},
        },
    ]

    useEffect(() => {
        setRows(props?.data);
    }, [props?.data])

    return (
        <div className="">
            <div >
                <div className="">
                    {props.isProjectionSummaryOpen && (
                        <div style={{ display: "flex", alignItems: "center", margin: "20px 20px 20px 45px", gap: "5px" }}>
                            Invoices for the financial year
                            <Select
                                options={props.financialYears}
                                value={props.financialYear}
                                onChange={props.handleYearChange}
                                placeholder="Select Financial Year"
                                classNamePrefix="custom-select"
                            />
                        </div>

                    )}
                </div>
            </div>
            <div className='table-outer_content'>
                <Table
                    paginationStyle={{
                        display: "none"
                    }}
                    rows={rows}
                    columns={columns}
                    isPagination={false}
                    function={props.handleMonthlyBudget}
                />
            </div>
        </div>
    )
}
