import React, { useState } from 'react';
import '../../assets/styles/components.css'; // Import the CSS file
import { TextField , FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import styles from "../managePendingInvoices/pendingInvoice.module.css";
import { useEffect } from 'react';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import { getAllProjectAPI, addMontlyBudget } from '../../utils/api-helper';
import { CleaningServices } from '@mui/icons-material';
import { validateText } from '../../utils/input-validation';



function AddBudget(props) {
  const [deliveryManager, setDeliveryManager] = useState('');
  const [amountRaised, setAmountRaised] = useState('');
  const [selectedProjectID, setSelectedProjectID] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [projects, setProjects] = useState([]);

  function createData (id, fname, lname, value) {
    const name = `${fname} ${lname}`
    return { value: id, label: value , deliveryManager: name };
}
  useEffect(() => {
    getAllProjectAPI().then((res) => {
        const data = res?.data.map((row) => createData(row.id, row.deliveryManagerFirstName, row.deliveryManagerLastName, row.projectName))
        setProjects(data);
    }).catch((err) => {
      // console.log(err)
    })
  }, []);
  

  const [managerError, setManagerError] = useState('');
  const [amountError, setAmountError] = useState('');
  const [projectError, setProjectError] = useState('');

  const validateForm = () => {
    let isValid = true;
    if (deliveryManager === '' || !validateText(deliveryManager)) {
      setManagerError("Please enter a valid delivery manager's name");
      isValid = false;
    } else {
      setManagerError('');
    }
    if (amountRaised === '') {
      setAmountError('Please enter a valid amount');
      isValid = false;
    } else {
      setAmountError('');
    }
    if (!selectedProjectID) {
      setProjectError('Please select a project');
      isValid = false;
    } else {
      setProjectError('');
    }
    return isValid;
  };

  useEffect(()=>{
    const project = projects.find(item => item.value === selectedProjectID);
    if (project) {
        setDeliveryManager(project.deliveryManager);
    }
  },[selectedProjectID])

  

  const handleSubmit = () => {
    const isValid = validateForm() && !error;      
    if (isValid) {
      props.setStartLoader(true);
      const body = {
        "amount": amountRaised,
        "projectId": selectedProjectID,
        "month":props.month,
        "year":props.year
      }
  
      addMontlyBudget(body)
        .then((res) => {
            props.setAddSingleBudget(false);
            props.setIsDataUpdated(true);
            props.setSuccessMessage("Budget added successfully.")
        })
        .catch((error) => {
          if (error.response) {
            props.setError(error.response.data.message)
        } else if (error.request) {
          props.setError("Something went wrong, try again")
        } else {
          props.setError("Something went wrong, try again")
        }
        }).finally(()=>{
          props.getAllMonthlyBudget();
          props.setStartLoader(false);
        })
    }
  };
  
  return (
    <div className="popup">
      <div className="popup-content">
        <h2 style={{ margin: "12px 0px 12px 0px" }}>Add Budget</h2>
        <div className='add-field'>
          <div className='box'>
            <div className="projectDD" name={`${projectError !== "" ? "selectedProjectID" : ""}`} >
              <FormControl variant="outlined">
                <InputLabel id="roleSelect" shrink={selectedProjectID !== 'Select Role'} style={{ backgroundColor: "white" }}>
                  Project*
                </InputLabel>
                <Select
                  labelId='roleSelect'
                  id="test"
                  value={selectedProjectID}
                  onChange={(event) => {
                    setProjectError("");
                    setSelectedProjectID(event.target.value);
                  }}
                  label="Project"
                >
                  <MenuItem value='Select Role'>Select Project</MenuItem>
                  {
                    projects?.map((project, index) => (
                      <MenuItem key={index}  name={project.label} value={project.value}>
                        {project.label}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
              {projectError !== "" &&
                <p style={{ color: "#d32f2f", fontSize: "12px" }}>
                  {projectError}
                </p>
              }
            </div>
          </div>
          <div className='box'>
            <TextField
              style={{width:"50%"}}
              label="Delivery Manager"
              type="text"
              name="deliveryManager"
              value={deliveryManager}
              onChange={(e) => { setManagerError(""); setDeliveryManager(e.target.value) }}
              error={managerError !== ""}
              helperText={managerError ? managerError : ""}
              required
            />
            <TextField
              style={{width:"50%"}}
              label="Amount"
              type="number"
              name="amount"
              value={amountRaised}
              onChange={(e) => { setAmountError(""); setAmountRaised(e.target.value); }}
              error={amountError !== ""}
              helperText={amountError ? amountError : ""}
              required
            />
          </div>
        </div>
        <div className={styles.reasonButtonBox} style={{ width: "97.5%", justifyContent: "space-between" }}>
          <button type="cancel" className={styles.btn} onClick={() => props.setAddSingleBudget(false)} style={{ width: "49%" }}>Cancel</button>
          <button type="submit" className={styles.btn} onClick={() => handleSubmit()} style={{ width: "50%" }}>Add</button>
        </div>
      </div>

    </div>
  );
}

export default AddBudget;
