import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/managepages.css";
import Navigation from "../../components/Navigation";
import Settings from "../../components/Settings";
import ViewContent from "../manageinvoice/viewInvoice/index";
import BackButton from "../../components/Buttons/backButton";
import { updateInvoicePaymentStatus } from "../../utils/api-helper";
import CreateInvoice from "../manageinvoice/createInvoice";
import InvoiceAlertPopupMessage from "../manageinvoice/InvoiceAlertPopupMessage";
import InvoiceItemsForm from "../manageinvoice/invoiceItems";
import DeleteItemsForm from "../manageinvoice/invoiceItems/deleteInvoiceItem";
import Select from "react-select";

import {
  getAllInvoiceListAPI,
  getAllClientAPI,
  getAllProjectAPI,
  getPaymentStatusAPI,
  getInvoiceTotalAmountAPI,
  getPaymentTermsAPI,
  getAllCountryAPI,
  getAllPaymentModeAPI,
  getAllInvoiceType,
} from "../../utils/api-helper";

import DraftInvoices from "./DraftInvoices";
import PendingInvoices from "./PendingInvoices";
import UnpaidInvoices from "./UnpaidInvoices";
import FullyPaidInvoices from "./FullyPaidInvoices";
import PartiallyPaidInvoices from "./PartiallyPaidInvoices";
import VoidInvoices from "./VoidInvoices";
import AllInvoices from "./AllInvoices";
import Loader from "../../components/Loader";
import InvoicesTable from "./InvoicesTable";
import { resetInvoiceItems } from "../manageinvoice/index";

export default function Index() {
  const navigate = useNavigate();

  const [draftTotalValue, setDraftTotalValue] = useState({
    totalCount: "",
    totalAmount: "",
  });
  const [pendingTotalValue, setPendingTotalValue] = useState({
    totalCount: "",
    totalAmount: "",
  });
  const [unpaidTotalValue, setUnpaidTotalValue] = useState({
    totalCount: "",
    totalAmount: "",
  });
  const [fullyPaidTotalValue, setFullyPaidTotalValue] = useState({
    totalCount: "",
    totalAmount: "",
  });
  const [partiallyPaidTotalValue, setPartiallyPaidTotalValue] = useState({
    totalCount: "",
    totalAmount: "",
  });
  const [voidTotalValue, setVoidTotalValue] = useState({
    totalCount: "",
    totalAmount: "",
  });
  const [allInvoicesTotalValue, setAllInvoicesTotalValue] = useState({
    totalCount: "",
    totalAmount: "",
  });
  const [startLoader, setStartLoader] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [response, setResponse] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterByInvoiceTypeData, setFilterByInvoiceTypeData] = useState("1");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [sortInvoiceData, setsortInvoiceData] = useState("invoiceDate");
  const [selectedInvoiceType, setSelectedInvoiceType] = useState(null);
  const [showAllInvoicesTable, setShowAllInvoicesTable] = useState(false);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [isFilterInvoice, setIsFilterInvoice] = useState(false);
  const [isSortInvoice, setIsSortInvoice] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("clientFirstName");
  const [clientFirstNameFilter, setClientFirstNameFilter] = useState("");
  const [clientLastNameFilter, setClientLastNameFilter] = useState("");
  const [projectNameFilter, setProjectNameFilter] = useState("");
  const [dueDateFromFilter, setDueDateFromFilter] = useState("");
  const [dueDateToFilter, setDueDateToFilter] = useState("");
  const [invoiceDateFromFilter, setInvoiceDateFromFilter] = useState("");
  const [invoiceDateToFilter, setInvoiceDateToFilter] = useState("");
  const [paidDateFromFilter, setPaidDateFromFilter] = useState("");
  const [paidDateToFilter, setPaidDateToFilter] = useState("");
  const [isPaymentStatusFilter, setIsPaymentStatusFilter] = useState("");
  const [getAllCLients, setGetAllCLients] = useState([]);
  const [getAllProjects, setGetAllProjects] = useState([]);
  const [getAllProjectManagers, setGetAllProjectManagers] = useState([]);
  const [getPaymentStatus, setGetPaymentStatus] = useState([]);
  const [isGetAllInvoice, setIsGetAllInvoice] = useState(true);
  const [isAddInvoice, setIsAddInvoice] = useState(false);
  const [isGetInvoiceByid, setIsGetInvoiceByid] = useState(false);
  const [isEditInvoice, setIsEditInvoice] = useState(false);
  const [isDeleteInvoice, setIsDeleteInvoice] = useState(false);
  const [isAddInvoiceItems, setIsAddInvoiceItems] = useState(false);
  const [isDeleteInvoiceItem, setIsDeleteInvoiceItem] = useState(false);
  const [isEditInvoiceItem, setIsEditInvoiceItem] = useState(false);
  const [isPaymentStatusLabel, setIsPaymentStatusLabel] = useState("");
  const [isClientFirstNameLabel, setIsClientFirstNameLabel] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [invoiceNumber, setinvoiceNumber] = useState("");
  const [updateInvoiceNumberWindow, setUpdateInvoiceNumberWindow] = useState(false);
  const [alertPopupWindow, setAlertPopupWindow] = useState(false);
  const [alertPopupMessage, setAlertPopupMessage] = useState("");
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [getAllCountries, setGetAllCountries] = useState();
  const [getPaymentTerms, setGetPaymentTerms] = useState();
  const [getPaymentMode, setGetPaymentMode] = useState();
  const [invoiceType, setInvoiceType] = useState([]);
  const [invoiceTotalAmount, setInvoiceTotalAmount] = useState(0);
  const [getAlertReponse, setAlertReponse] = useState(false);
  const [projectType, setProjectType] = useState("");
  const [invoiceData, setInvoiceData] = useState("");
  const [projectID, setProjectID] = useState("");
  const [updateStatusBody, setUpdateStatusBody] = useState({});
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(null);
  const [startDateFromFilter, setStartDateFromFilter] = useState("");
  const [startDateToFilter, setStartDateToFilter] = useState("");
  const [endDateFromFilter, setEndDateFromFilter] = useState("");
  const [endDateToFilter, setEndDateToFilter] = useState("");
  const [projectManagerFirstNameFilter, setProjectManagerFirstNameFilter] =
    useState("");
  const [totalAmountUSD, setTotalAmountUSD] = useState(0);
  const [isMakeCopyInvoice, setIsMakeCopyInvoice] = useState(false);
  const [invoiceItemsDataArray, setInvoiceItemsDataArray] = useState([]);
  const [invoiceItemIndex, setInvoiceItemIndex] = useState("");
  const [invoiceItemDescription, setInvoiceItemDescription] = useState("");
  const [isFinancialYearVisible, setIsFinancialYearVisible] = useState(true);

  const handleCloseTable = () => {
    setShowTable(false);
    setSelectedInvoiceType(null);
    setIsFinancialYearVisible(true);
  };

  const body  = {
    clientFirstName: clientFirstNameFilter,
    clientLastName: clientLastNameFilter,
    projectName: projectNameFilter,
    dueDateFrom: dueDateFromFilter,
    dueDateTo: dueDateToFilter,
    invoiceDateFrom: invoiceDateFromFilter,
    invoiceDateTo: invoiceDateToFilter,
    isActive: isActiveFilter,
    paidDateFrom: paidDateFromFilter,
    paidDateTo: paidDateToFilter,
    paymentStatus: isPaymentStatusFilter,
    invoiceNumber: "",
    invoiceType: filterByInvoiceTypeData,
    projectManagerFirstName: projectManagerFirstNameFilter,
    startDateFrom: startDateFromFilter,
    startDateTo: startDateToFilter,
    endDateFrom: endDateFromFilter,
    endDateTo: endDateToFilter,
  };

  useEffect(() => {
    getPaymentStatusFunction();
    getAllClientFunction();
    getAllProjectFunction();
    getInvoiceTypeFunction();
    getAllCountryFunction();
    getPaymnetTermsFunction();
    getAllPaymentModeFunction();
  }, [filterByInvoiceTypeData, sortOrder, sortInvoiceData, page, rowsPerPage]);

  function getAllTypesOfInvoicesCountAndInvoicesTotalAmountInUSD() {
    const paymentStatusArray = [
      { selectedType: "draft", paymentStatus: 6 },
      { selectedType: "pending", paymentStatus: 2 },
      { selectedType: "unpaid", paymentStatus: 1 },
      { selectedType: "fullyPaid", paymentStatus: 3 },
      { selectedType: "partiallyPaid", paymentStatus: 4 },
      { selectedType: "void", paymentStatus: 5 },
      { selectedType: "all", paymentStatus: "" },
    ];

    paymentStatusArray.forEach((element) => {
      const updatedBody = {
        ...body,
        paymentStatus: element.paymentStatus,
      };
      setSelectedPaymentStatus(element.paymentStatus);

      getInvoiceTotalAmountAPI(updatedBody)
        .then((response) => {
          setStartLoader(false);
          setInvoicesTotalUSDData(element.paymentStatus, response);
        })
        .catch((error) => {
          setStartLoader(false);
        });
    });
  }
  function setInvoicesTotalUSDData(paymentStatus, response) {
    switch (paymentStatus) {
      case 1:
        setUnpaidTotalValue({
          totalCount: response.data.invoicesCount,
          totalAmount: response.data.invoicesTotalAmountUSD,
        });
        break;
      case 2:
        setPendingTotalValue({
          totalCount: response.data.invoicesCount,
          totalAmount: response.data.invoicesTotalAmountUSD,
        });
        break;
      case 3:
        setFullyPaidTotalValue({
          totalCount: response.data.invoicesCount,
          totalAmount: response.data.invoicesTotalAmountUSD,
        });
        break;
      case 4:
        setPartiallyPaidTotalValue({
          totalCount: response.data.invoicesCount,
          totalAmount: response.data.invoicesTotalAmountUSD,
        });
        break;
      case 5:
        setVoidTotalValue({
          totalCount: response.data.invoicesCount,
          totalAmount: response.data.invoicesTotalAmountUSD,
        });
        break;
      case 6:
        setDraftTotalValue({
          totalCount: response.data.invoicesCount,
          totalAmount: response.data.invoicesTotalAmountUSD,
        });
        break;
      default:
        setAllInvoicesTotalValue({
          totalCount: response.data.invoicesCount,
          totalAmount: response.data.invoicesTotalAmountUSD,
        });
        break;
    }
  }

  const handleFiltersApplied = (filters) => {
    setActiveFilters(filters);
  };

  const sortInvoice = () => {
    setIsSortInvoice(true);
  };

  function updateInvoiceStatus() {
    setStartLoader(true);
    const body = {
      paymentStatus: updateStatusBody.paymentStatus,
    };
    updateInvoicePaymentStatus(updateStatusBody.id, body)
      .then((res) => {
        setStatusUpdated(true);
      })
      .catch((err) => {
        if (error.response) {
          setError(error.response.data.message);
        } else if (error.request) {
          setError("Something went wrong, try again");
        } else {
          setError("Something went wrong, try again");
        }
      })
      .finally(() => {
        setStartLoader(false);
        setUpdateStatusBody({});
        setProjectID("");
      });
  }

  function handleInvoiceCheck(event, id) {
    let checked = event.target.checked;
    if (checked) {
      setProjectID(id);
      setUpdateStatusBody((prevObject) => ({
        ...prevObject,
        id: id,
      }));
    } else {
      setProjectID(null);
      setUpdateStatusBody({});
    }
  }
  useEffect(() =>{
    fetchInvoicesList(selectedInvoiceType);
  },[page,sortOrder,rowsPerPage]);
  const getAllInvoices = () => {
    setStartLoader(true);
    getAllInvoiceListAPI(body, sortInvoiceData, sortOrder, page, rowsPerPage)
      .then((res) => {
        setResponse(res?.data?.list);
        setTotalCount(res?.data?.totalCount);
        setStartLoader(false);
      })
      .catch((err) => {
        setStartLoader(false);
      });

    getInvoiceTotalAmountAPI(body)
      .then((response) => {
        setStartLoader(false);
        setTotalAmountUSD(response.data.invoicesTotalAmountUSD);
      })
      .catch((error) => {
        setStartLoader(false);
      });

    setIsMakeCopyInvoice(false);

    setIsRowClicked(false);
    setIsGetInvoiceByid(false);
    setIsGetAllInvoice(true);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const fetchInvoicesList = (selectedType) => {
    setStartLoader(true);
    let paymentStatus;
    if (selectedType === "draft") {
      paymentStatus = 6;
    } else if (selectedType === "pending") {
      paymentStatus = 2;
    } else if (selectedType === "unpaid") {
      paymentStatus = 1;
    } else if (selectedType === "fullyPaid") {
      paymentStatus = 3;
    } else if (selectedType === "partiallyPaid") {
      paymentStatus = 4;
    } else if (selectedType === "void") {
      paymentStatus = 5;
    } else if (selectedType === "all") {
      paymentStatus = "";
    }
    const updatedBody = {
      ...body,
      paymentStatus: paymentStatus,
    };
    
    setSelectedPaymentStatus(paymentStatus);
    getAllInvoiceListAPI(
      updatedBody,
      sortInvoiceData,
      sortOrder,
      page,
      rowsPerPage
    )
      .then((res) => {
        setResponse(res?.data?.list);
        setTotalCount(res?.data?.totalCount);
        setStartLoader(false);
      })
      .catch((err) => {
        setStartLoader(false);
      });
    getInvoiceTotalAmountAPI(updatedBody)
      .then((response) => {
        setStartLoader(false);
        setTotalAmountUSD(response.data.invoicesTotalAmountUSD);
      })
      .catch((error) => {
        setStartLoader(false);
      });
  };

  const handleInvoiceTypeClick = (type) => {
    setShowTable(true);
    setSelectedInvoiceType(type);
    setPage(0);
    setIsFinancialYearVisible(false);
    fetchInvoicesList(type);
  };

  function createData(id, value) {
    return { value: id, label: value };
  }

  const getAllClientFunction = () => {
    getAllClientAPI()
      .then((res) => {
        setGetAllCLients(
          res?.data.map((row) =>
            createData(row.id, row.firstName + " " + row.lastName)
          )
        );
      })
      .catch((err) => {
      });
  };

  const getAllProjectFunction = () => {
    getAllProjectAPI()
      .then((res) => {
        setGetAllProjects(
          res?.data.map((row) => createData(row.id, row.projectName))
        );

        const uniqueProjectManagerFirstNames = Array.from(
          new Set(res?.data.map((row) => row.projectManagerFirstName))
        ).map((name) => createData(name, name));

        setGetAllProjectManagers(uniqueProjectManagerFirstNames);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPaymentStatusFunction = () => {
    getPaymentStatusAPI()
      .then((res) => {
        setGetPaymentStatus(
          res?.data?.map((row) => createData(row.id, row.value))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPaymnetTermsFunction = () => {
    getPaymentTermsAPI()
      .then((res) => {
        setGetPaymentTerms(
          res?.data.map((row) => createData(row.id, row.value))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getInvoiceTypeFunction = () => {
    getAllInvoiceType()
      .then((res) => {
        setInvoiceType(res?.data.map((row) => createData(row.id, row.value)));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCountryFunction = () => {
    getAllCountryAPI()
      .then((res) => {
        setGetAllCountries(
          res?.data.map((row) => createData(row.id, row.currencyName))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllPaymentModeFunction = () => {
    getAllPaymentModeAPI()
      .then((res) => {
        setGetPaymentMode(
          res?.data.map((row) => createData(row.id, row.value))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addInvoice = () => {
    setIsGetAllInvoice(false);
    setIsAddInvoice(true); // Add invoice is set true When user Clicks on Add Invoice
    setIsGetInvoiceByid(false);
    setIsFilterInvoice(false);
    setIsSortInvoice(false);
    setIsDeleteInvoice(false);
    setIsAddInvoiceItems(false);
    setIsDeleteInvoiceItem(false);
    setIsEditInvoiceItem(false);
  };

  const getInvoiceById = (row) => {
    setIsGetAllInvoice(false);
    setIsAddInvoice(false);
    setIsGetInvoiceByid(true);
    setIsEditInvoice(false);
    setIsMakeCopyInvoice(false);
    setIsFilterInvoice(false);
    navigate(`/dashboard/?id=${row.id}`);
    setIsDeleteInvoice(false);
    setIsAddInvoiceItems(false);
    setIsDeleteInvoiceItem(false);
    setIsEditInvoiceItem(false);
    setIsRowClicked(true);
    fetchInvoicesList(selectedInvoiceType); // Fetch the updated data
  };

  const editInvoice = () => {
    setIsGetAllInvoice(false);
    setIsAddInvoice(false);
    setIsGetInvoiceByid(false);
    setIsEditInvoice(true); // Edit Invoice page
    setIsMakeCopyInvoice(false);
    setIsFilterInvoice(false);
    setIsDeleteInvoice(false);
    setIsAddInvoiceItems(false);
    setIsDeleteInvoiceItem(false);
    setIsEditInvoiceItem(false);
    setInvoiceData(response);
  };
  const makeCopyInvoice = () => {
    setIsGetAllInvoice(false);
    setIsAddInvoice(false);
    setIsGetInvoiceByid(false);
    setIsEditInvoice(false);
    setIsMakeCopyInvoice(true); // copy Invoice page
    setIsFilterInvoice(false);
    setIsDeleteInvoice(false);
    setIsAddInvoiceItems(false);
    setIsDeleteInvoiceItem(false);
    setIsEditInvoiceItem(false);
  };

  const getAddInvoiceItemsPage = () => {
    setIsAddInvoiceItems(true);
  };

  const getDeleteInvoiceItemPage = (index, description) => {
    setInvoiceItemIndex(index);
    setInvoiceItemDescription(description);
    setIsDeleteInvoiceItem(true);
  };

  const getEditInvoiceItemPage = (index) => {
    setInvoiceItemIndex(index);
    setIsEditInvoiceItem(true);
  };

  const handleInvoiceSaved = (invoiceId) => {
    setIsMakeCopyInvoice(false);
    setIsEditInvoice(false);
    setIsGetAllInvoice(true);
    navigate(`/dashboard/?id=${invoiceId}`);
    fetchInvoicesList(selectedInvoiceType);
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear-4;
    const endYear = currentYear;
    const options = [];

    for (let year = startYear; year <= endYear; year++) {
      options.push({ value: year, label: `${year}-${year + 1}` });
    }

    return options;
  };
  const financialYears = generateYearOptions();

  // Find the current financial year option
  const currentYear = new Date().getFullYear();
  const currentFinancialYearOption = financialYears.find(
    (option) => option.value === currentYear
  );
  const [financialYear, setFinancialYear] = useState(
    currentFinancialYearOption
  );

  useEffect(() => {
    if (financialYear) {
      const year = financialYear.value;
      const dateFrom = `${year}-04-01`;
      const dateTo = `${year + 1}-03-31`;

      setInvoiceDateFromFilter(dateFrom);
      setInvoiceDateToFilter(dateTo);
    }
  }, [financialYear]);

  useEffect(() => {
    if (invoiceDateFromFilter && invoiceDateToFilter) {
      fetchInvoicesList(selectedInvoiceType);
      getAllTypesOfInvoicesCountAndInvoicesTotalAmountInUSD();
    }
  }, [invoiceDateFromFilter, invoiceDateToFilter, selectedInvoiceType]);

  const handleYearChange = (selectedOption) => {
    setFinancialYear(selectedOption);
  };

  return (
    <>
      <div className="flex-container">
        <div>
          <Navigation myClass="Dashboard" />
        </div>
        <div className="right-section overflow">
          <div className="title" id="title">
            {isGetAllInvoice ? (
              ""
            ) : (
              <BackButton
                handleClick={() => {
                  setIsRowClicked(false);
                  setIsGetInvoiceByid(false);
                  setIsGetAllInvoice(true);
                  setIsEditInvoice(false);
                  setIsMakeCopyInvoice(false);
                  fetchInvoicesList(selectedInvoiceType);
                  resetInvoiceItems();
                }}
              />
            )}
            <div className="pageheading">
              <p>Dashboard</p>
            </div>
            <Settings />
          </div>
          <div className="dashboard-container dash_content">
          {isFinancialYearVisible && (
            <div className="financial-year-select">
              Invoices for the financial year :
                <Select
                  options={generateYearOptions()}
                  value={financialYear}
                  onChange={handleYearChange}
                  placeholder="Select Financial Year"
                  classNamePrefix="custom-select"
                />
            </div>
            )}
            <div style={{ display: showTable ? "none" : "block" }}>
              <div
                className={`tile-container draft-invoice ${
                  selectedInvoiceType === "draft" ? "tile-highlighted" : ""
                }`}
              >
                <button
                  onClick={() => handleInvoiceTypeClick("draft")}
                  className={`draft-invoice ${
                    selectedInvoiceType === "draft" ? "tile-highlighted" : ""
                  }`}
                >
                  <DraftInvoices draftTotalValue={draftTotalValue} />
                </button>
              </div>

              <div
                className={`tile-container pending-invoice ${
                  selectedInvoiceType === "pending" ? "tile-highlighted" : ""
                }`}
              >
                <button
                  onClick={() => handleInvoiceTypeClick("pending")}
                  className={`pending-invoice ${
                    selectedInvoiceType === "pending" ? "tile-highlighted" : ""
                  }`}
                >
                  <PendingInvoices pendingTotalValue={pendingTotalValue} />
                </button>
              </div>

              <div
                className={`tile-container unpaid-invoice ${
                  selectedInvoiceType === "unpaid" ? "tile-highlighted" : ""
                }`}
              >
                <button
                  onClick={() => handleInvoiceTypeClick("unpaid")}
                  className={`unpaid-invoice ${
                    selectedInvoiceType === "unpaid" ? "tile-highlighted" : ""
                  }`}
                >
                  <UnpaidInvoices unpaidTotalValue={unpaidTotalValue} />
                </button>
              </div>

              <div
                className={`tile-container fully-paid-invoice ${
                  selectedInvoiceType === "fullyPaid" ? "tile-highlighted" : ""
                }`}
              >
                <button
                  onClick={() => handleInvoiceTypeClick("fullyPaid")}
                  className={`fully-paid-invoice ${
                    selectedInvoiceType === "fullyPaid"
                      ? "tile-highlighted"
                      : ""
                  }`}
                >
                  <FullyPaidInvoices
                    fullyPaidTotalValue={fullyPaidTotalValue}
                  />
                </button>
              </div>

              <div
                className={`tile-container partially-paid-invoice ${
                  selectedInvoiceType === "partiallyPaid"
                    ? "tile-highlighted"
                    : ""
                }`}
              >
                <button
                  onClick={() => handleInvoiceTypeClick("partiallyPaid")}
                  className={`partially-paid-invoice ${
                    selectedInvoiceType === "partiallyPaid"
                      ? "tile-highlighted"
                      : ""
                  }`}
                >
                  <PartiallyPaidInvoices
                    partiallyPaidTotalValue={partiallyPaidTotalValue}
                  />
                </button>
              </div>
              <div
                className={`tile-container void-invoice ${
                  selectedInvoiceType === "void" ? "tile-highlighted" : ""
                }`}
              >
                <button
                  onClick={() => handleInvoiceTypeClick("void")}
                  className={`void-invoice ${
                    selectedInvoiceType === "void" ? "tile-highlighted" : ""
                  }`}
                >
                  <VoidInvoices voidTotalValue={voidTotalValue} />
                </button>
              </div>
              <div className={`tile-container `}></div>

              <div
                className={`tile-container all-invoices ${
                  selectedInvoiceType === "all" ? "tile-highlighted" : ""
                }`}
              >
                <button
                  onClick={() => handleInvoiceTypeClick("all")}
                  className={`all-invoices ${
                    selectedInvoiceType === "all" ? "tile-highlighted" : ""
                  }`}
                >
                  <AllInvoices allInvoicesTotalValue={allInvoicesTotalValue} />
                </button>
              </div>
            </div>
            <div>
              {isGetInvoiceByid && (
                <ViewContent
                  setinvoiceNumber={setinvoiceNumber}
                  editInvoice={editInvoice}
                  makeCopyInvoice={makeCopyInvoice}
                  setStartLoader={setStartLoader}
                  setUpdateInvoiceNumberWindow={setUpdateInvoiceNumberWindow}
                  setAlertPopupWindow={setAlertPopupWindow}
                  setAlertPopupMessage={setAlertPopupMessage}
                  invoiceItemsDataArray={invoiceItemsDataArray}
                />
              )}
              {isMakeCopyInvoice ? (
                <CreateInvoice
                  isMakeCopyInvoice={true}
                  getAllInvoices={getAllInvoices}
                  getAllCLients={getAllCLients}
                  getAllProjects={getAllProjects}
                  getAllCountries={getAllCountries}
                  getPaymentStatus={getPaymentStatus}
                  getPaymentTerms={getPaymentTerms}
                  getPaymentMode={getPaymentMode}
                  getAddInvoiceItemsPage={getAddInvoiceItemsPage}
                  getDeleteInvoiceItemPage={getDeleteInvoiceItemPage}
                  getEditInvoiceItemPage={getEditInvoiceItemPage}
                  isAddInvoiceItems={isAddInvoiceItems}
                  isDeleteInvoiceItem={isDeleteInvoiceItem}
                  isEditInvoiceItem={isEditInvoiceItem}
                  invoiceTotalAmount={invoiceTotalAmount}
                  setInvoiceTotalAmount={setInvoiceTotalAmount}
                  setAlertPopupWindow={setAlertPopupWindow}
                  setAlertPopupMessage={setAlertPopupMessage}
                  getAlertReponse={getAlertReponse}
                  setAlertReponse={setAlertReponse}
                  setStartLoader={setStartLoader}
                  setUpdateInvoiceNumberWindow={setUpdateInvoiceNumberWindow}
                  setProjectType={setProjectType}
                  handleInvoiceSaved={handleInvoiceSaved}
                />
              ) : null}
              {isEditInvoice && (
                <CreateInvoice
                  isEditInvoice={true}
                  getAllInvoices={getAllInvoices}
                  getAllCLients={getAllCLients}
                  getAllProjects={getAllProjects}
                  getAllCountries={getAllCountries}
                  getPaymentStatus={getPaymentStatus}
                  getPaymentTerms={getPaymentTerms}
                  getPaymentMode={getPaymentMode}
                  getAddInvoiceItemsPage={getAddInvoiceItemsPage}
                  getDeleteInvoiceItemPage={getDeleteInvoiceItemPage}
                  getEditInvoiceItemPage={getEditInvoiceItemPage}
                  isAddInvoiceItems={isAddInvoiceItems}
                  isDeleteInvoiceItem={isDeleteInvoiceItem}
                  invoiceTotalAmount={invoiceTotalAmount}
                  setInvoiceTotalAmount={setInvoiceTotalAmount}
                  setAlertPopupWindow={setAlertPopupWindow}
                  setAlertPopupMessage={setAlertPopupMessage}
                  getAlertReponse={getAlertReponse}
                  setAlertReponse={setAlertReponse}
                  setStartLoader={setStartLoader}
                  setProjectType={setProjectType}
                  setUpdateInvoiceNumberWindow={setUpdateInvoiceNumberWindow}
                  handleInvoiceSaved={handleInvoiceSaved}
                  invoiceData={invoiceData}
                />
              )}
              {showTable && !isRowClicked && (
                <InvoicesTable
                  totalCount={totalCount}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  response={response}
                  addInvoice={addInvoice}
                  handleClose={handleCloseTable}
                  navigatedFromDashboard={true}
                  selectedInvoiceType={selectedInvoiceType}
                  setStartLoader={setStartLoader}
                  setSortBy={setSortBy}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  setsortInvoiceData={setsortInvoiceData}
                  setSortOrder={setSortOrder}
                  sortInvoiceData={sortInvoiceData}
                  getAllInvoices={getAllInvoices}
                  getAllCLients={getAllCLients}
                  getAllProjects={getAllProjects}
                  getAllProjectManagers={getAllProjectManagers}
                  getPaymentStatus={getPaymentStatus}
                  getInvoiceById={getInvoiceById}
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                  setClientFirstNameFilter={setClientFirstNameFilter}
                  setProjectNameFilter={setProjectNameFilter}
                  setDueDateFromFilter={setDueDateFromFilter}
                  setDueDateToFilter={setDueDateToFilter}
                  setInvoiceDateFromFilter={setInvoiceDateFromFilter}
                  setInvoiceDateToFilter={setInvoiceDateToFilter}
                  setPaidDateFromFilter={setPaidDateFromFilter}
                  setPaidDateToFilter={setPaidDateToFilter}
                  setIsPaymentStatusFilter={setIsPaymentStatusFilter}
                  setIsFilterInvoice={setIsFilterInvoice}
                  activeFilters={activeFilters}
                  sortInvoice={sortInvoice}
                  invoiceType={invoiceType}
                  filterByInvoiceTypeData={filterByInvoiceTypeData}
                  setFilterByInvoiceTypeData={setFilterByInvoiceTypeData}
                  isFilterSelected={isFilterSelected}
                  setIsFilterSelected={setIsFilterSelected}
                  isActiveFilter={isActiveFilter}
                  setIsActiveFilter={setIsActiveFilter}
                  handleInvoiceCheck={handleInvoiceCheck}
                  projectID={projectID}
                  updateInvoiceStatus={updateInvoiceStatus}
                  setClientLastNameFilter={setClientLastNameFilter}
                  setIsPaymentStatusLabel={setIsPaymentStatusLabel}
                  setIsClientFirstNameLabel={setIsClientFirstNameLabel}
                  dueDateFromFilter={dueDateFromFilter}
                  dueDateToFilter={dueDateToFilter}
                  invoiceDateFromFilter={invoiceDateFromFilter}
                  invoiceDateToFilter={invoiceDateToFilter}
                  paidDateFromFilter={paidDateFromFilter}
                  onFiltersApplied={handleFiltersApplied}
                  paidDateToFilter={paidDateToFilter}
                  isPaymentStatusFilter={isPaymentStatusFilter}
                  isPaymentStatusLabel={isPaymentStatusLabel}
                  isClientFirstNameLabel={isClientFirstNameLabel}
                  isFilterInvoice={isFilterInvoice}
                  selectedPaymentStatus={selectedPaymentStatus}
                  projectNameFilter={projectNameFilter}
                  clientFirstNameFilter={clientFirstNameFilter}
                  startDateFromFilter={startDateFromFilter}
                  setStartDateFromFilter={setStartDateFromFilter}
                  startDateToFilter={startDateToFilter}
                  setStartDateToFilter={setStartDateToFilter}
                  endDateFromFilter={endDateFromFilter}
                  setEndDateFromFilter={setEndDateFromFilter}
                  endDateToFilter={endDateToFilter}
                  setEndDateToFilter={setEndDateToFilter}
                  projectManagerFirstNameFilter={projectManagerFirstNameFilter}
                  setProjectManagerFirstNameFilter={
                    setProjectManagerFirstNameFilter
                  }
                  totalAmountUSD={totalAmountUSD}
                  isMakeCopyInvoice={isMakeCopyInvoice}
                />
              )}
            </div>
          </div>
        </div>
        {startLoader ? <Loader /> : <></>}
        {isAddInvoiceItems ? (
          <InvoiceItemsForm
            isEditItems={false}
            addInvoice={addInvoice}
            invoiceItemsDataArray={invoiceItemsDataArray}
            invoiceTotalAmount={invoiceTotalAmount}
            setInvoiceTotalAmount={setInvoiceTotalAmount}
            setStartLoader={setStartLoader}
            projectType={projectType}
          />
        ) : (
          <></>
        )}
        {isDeleteInvoiceItem ? (
          <DeleteItemsForm
            invoiceItemIndex={invoiceItemIndex}
            invoiceItemDescription={invoiceItemDescription}
            addInvoice={addInvoice}
            invoiceTotalAmount={invoiceTotalAmount}
            setInvoiceTotalAmount={setInvoiceTotalAmount}
            setStartLoader={setStartLoader}
          />
        ) : (
          <></>
        )}
        {isEditInvoiceItem ? (
          <InvoiceItemsForm
            isEditItems={true}
            invoiceItemIndex={invoiceItemIndex}
            addInvoice={addInvoice}
            invoiceItemsDataArray={invoiceItemsDataArray}
            invoiceTotalAmount={invoiceTotalAmount}
            setInvoiceTotalAmount={setInvoiceTotalAmount}
            setStartLoader={setStartLoader}
            projectType={projectType}
          />
        ) : (
          <></>
        )}
      </div>
      {alertPopupWindow.is && (
        <InvoiceAlertPopupMessage
          setAlertPopupWindow={setAlertPopupWindow}
          alertPopupMessage={alertPopupMessage}
          setAlertReponse={setAlertReponse}
          alertPopupWindow={alertPopupWindow}
        ></InvoiceAlertPopupMessage>
      )}
    </>
  );
}
