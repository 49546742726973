import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getProjectByIdAPI } from "../../../utils/api-helper";
import "./viewContent.css";
import EditContentButton from "../../../components/Buttons/ModifyContent";
import DeleteContentButton from "../../../components/Buttons/DeleteContent";

export var responseData = [];

export default function ViewContent(props) {
  const [response, setResponse] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(true);

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  useEffect(() => {
    if (id) {
      getProjectByIdAPI(id)
        .then((res) => {
          setResponse(res?.data);
          responseData = res?.data;
          props.setProjectId(res?.data?.id);
          props.setSelectedProjectName(res?.data?.projectName);
          setSuccess(true);
        })
        .catch((err) => {
          setSuccess(false);
        });
    }
  }, [id]);

  return (
    <>
      {success ? (
        <div className="inner-content">
          <div className="manage_content">
            <div className="content_subtitle">
              <p>
                Projects: {response.projectName} <span>(</span>
                {response.isActive ? <>Active</> : <>Inactive</>}
                <span>)</span>
              </p>
            </div>
            <div className="box-functional_elements">
              <div
                className="functional_elements"
                onClick={props.deleteProject}
              >
                <DeleteContentButton />
              </div>
              <div className="functional_elements" onClick={props.editProject}>
                <EditContentButton small={false} />
              </div>
            </div>
          </div>
          <div className="data-container">
            <div className="table-inner_content">
              <div className="flex-data-container">
                <div className="clientname">
                  <p className="heading">Client Name:</p>
                  <p className="data">
                    {response.clientFirstName} {response.clientLastName}
                  </p>
                </div>
                <div className="managerName">
                  <p className="heading">Project Manager:</p>
                  <p className="data">
                    {response.projectManagerFirstName}{" "}
                    {response.projectManagerLastName}
                  </p>
                </div>
                <div className="managerName">
                  <p className="heading">Delivery Manager:</p>
                  <p className="data">
                    {response.deliveryManagerFirstName}{" "}
                    {response.deliveryManagerLastName}
                  </p>
                </div>
                <div className="projectLocation">
                  <p className="heading">Project Location:</p>
                  <p className="data">
                    {response.projectArea
                      ? response.projectArea + " | " + response.projectCity
                      : " - - "}
                  </p>
                </div>
                <div className="projectType">
                  <p className="heading">Project Type:</p>
                  <p className="data">
                    {response.projectType ? response.projectType : "--"}
                  </p>
                </div>

                <div className="projectType">
                  <p className="heading">Upwork Project:</p>
                  <p className="data">
                    {(response.isUpWorkProject) ? 'Yes' : (response.isUpWorkProject == false
                       && response.isUpWorkProject != null && response.isUpWorkProject != undefined)? "No": '--'}
                  </p>
                </div>
              {response.deactivationDate && (
                  <div className="Active">
                    <p className="heading">Inactivation date:</p>
                    <p className="data">{response.deactivationDate}</p>
                  </div>
              )}
              </div>
              <div className="flex-data-container">
                <div className="projectBudget">
                  <p className="heading">Project Budget:</p>
                  <p className="data">
                    {response.projectBudget ? response.projectBudget : "--"}
                  </p>
                </div>
                <div className="projectName ">
                  <p className="heading">Project Name:</p>
                  <p className="data">{response.projectName}</p>
                </div>
                <div className="projectStartDate">
                  <p className="heading">Project Start Date:</p>
                  <p className="data">{response.startDate}</p>
                </div>
                <div className="purchaseOrder">
                  <p className="heading">Purchase Orders:</p>
                  <p className="data">
                    {response.poNumbers && response.poNumbers.length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          whiteSpace: "nowrap",
                          flexWrap: "wrap",
                        }}
                      >
                        {response.poNumbers.map((ele) => (
                          <div
                            style={{
                              padding: "8px",
                              boxShadow: "2px 2px 1px 1px",
                              borderRadius: "5px",
                              margin: "4px",
                              border: "1px solid black",
                              width: "fit-content",
                            }}
                          >
                            {ele}{" "}
                          </div>
                        ))}
                      </div>
                    ) : (
                      "--"
                    )}
                  </p>
                </div>

                <div className="description">
                  <p className="heading">Project description:</p>
                  <p className="data">
                    {response.description ? response.description : " - - "}
                  </p>
                </div>
                <div className="column">
                  <h4 className="heading">Revenue Generating:</h4>
                  <p className="data">
                    {response.revenueGenerating ? "True" : "False"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
