import React, { useState, useEffect, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import emitter from '../utils/Emitter';
import { clearAllLocalStorage } from '../utils/local-storage-helper';

const IdleTimer = () => {
    const [secondsRemaining, setSecondsRemaining] = useState(600); // Set the initial time remaining (600 seconds = 10 minutes)
    const idleTimerRef = useRef(null);

    const handleIdle = () => {
        clearAllLocalStorage();
        emitter.emit('TOKEN_EXPIRED', true);
    };

    const { reset } = useIdleTimer({
        timeout: 1000 * 60 * 10, // Set the idle timeout to 10 minutes (adjust as needed)
        onIdle: handleIdle,
        // debounce: 250,
        ref: idleTimerRef, // Pass the idle timer ref to the useIdleTimer hook
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setSecondsRemaining((prevSeconds) => prevSeconds - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const handleActivity = () => {
        if (idleTimerRef.current) {
            idleTimerRef.current.reset(); // Reset the idle timer using the ref
        }
        setSecondsRemaining(600); // Reset the seconds remaining to the initial value (600 seconds = 10 minutes)
    };

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        // Event listeners for user activity
        document.addEventListener('mousemove', handleActivity);
        document.addEventListener('keydown', handleActivity);
        document.addEventListener('click', handleActivity);

        return () => {
            document.removeEventListener('mousemove', handleActivity);
            document.removeEventListener('keydown', handleActivity);
            document.removeEventListener('click', handleActivity);
        };
    }, []);

    return (
        <div style={{color: 'red', fontSize: 'small'}}>
            {formatTime(secondsRemaining)}
        </div>
    );
};

export default IdleTimer;
