import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import '../../assets/styles/SortingInvoice.css';
import Select from 'react-select';
import { sortingClients } from '../../utils/constants';
export default function SortInvoice(props) {

    const cancel = () => {
        props.setsortClientData('')
        props.getAllClients();
    }
    return (
        <>
            <div className="flexsort" >
                <div className="sortBox">
                    <div className="sortContent">
                        <div className="sortTitle">
                            <div className="heading">Sort for Clients</div>
                            <div className="crossButtonHover" onClick={props.getAllClients}><div className="crossButton"></div></div>
                            <button onClick={() => props.setsortClientData("")}>Clear All</button>
                        </div>
                        <div className="sortElements">
                            <div className="sortOptionsData">
                                <div className="selectOptions">
                                    <Select
                                        type="text"
                                        id="sortClients"
                                        placeholder={<div className="select-placeholder-text">Please select</div>}
                                        defaultValue={props.sortClientData !== "" ? { value: props.sortClientData, label: props.sortClientData } : ""}
                                        name="countryCode"
                                        options={sortingClients}
                                        onChange={(e) => {
                                            props.setsortClientData(e.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="sortFooter">
                                <div className="floatright">
                                    <div className="applyButton">
                                        <button onClick={props.getAllClients}>Apply</button>
                                    </div>
                                    <div className="cancleButton">
                                        <button onClick={cancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
