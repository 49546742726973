// import '../../assets/styles/managepages.css';
import './navigation.css';
import {NavLink} from "react-router-dom";
import React from 'react';

export default function NavElements(props) {
    let IDandClassName = props.menu;
    let icon = IDandClassName.concat("-icon");
    let rectangle = IDandClassName.concat("-rectangle");
    let to = "/"+IDandClassName;
    return (
        <>
            <NavLink to={`${to}`}>
                <div id={`${rectangle}`} className={ (props.data == props.title) ? 'backgroundSelected' : ''}>
                    <div className="buttons-style" id={`${IDandClassName}`}>
                        <div className="text-align">
                            <div className={`${icon}`}></div>
                            <div className="text-link">{props.title}</div>
                        </div>
                    </div>
                </div>
            </NavLink>
        </>
    )
}