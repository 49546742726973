export const sortRolesByPriority = (roles) => {
    const priorityOrder = ["ADMIN", "DELIVERY_MANAGER", "MANAGER", "ACCOUNT", "USER"];
  
    const sortedRoles = [...roles];
  
    sortedRoles.sort((a, b) => {
      const indexOfA = priorityOrder.indexOf(a);
      const indexOfB = priorityOrder.indexOf(b);
  
      if (indexOfA !== -1 && indexOfB !== -1) {
        return indexOfA - indexOfB;
      } else if (indexOfA !== -1) {
        return -1;
      } else if (indexOfB !== -1) {
        return 1;
      } else {
        return 0;
      }
    });
    return sortedRoles;
  }