import React, { useRef, useState, useEffect, useCallback } from 'react';
import { sortingProjects } from '../../utils/constants';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import '../../assets/styles/SortingInvoice.css';
export default function SortInvoice(props) {

    const cancel = () => {
        props.setSortProjectData('');
        props.getAllProjects();
    }

    return (
        <>
            <div className="flexsort" >
                <div className="sortBox">
                    <div className="sortContent">
                        <div className="sortTitle">
                            <div className="heading">Sort Projects</div>
                            <div className="crossButtonHover" onClick={props.getAllProjects}><div className="crossButton"></div></div>
                            <button onClick={() => { props.setSortProjectData('') }}>Clear All</button>
                        </div>
                        <div className="sortElements">
                            <div className="sortOptionsData">
                                <div className="selectOptions">
                                    <Select
                                        type="text"
                                        id="sortProjects"
                                        placeholder={<div className="select-placeholder-text">Please select</div>}
                                        name="sortproject"
                                        options={sortingProjects}
                                        onChange={(e) => props.setSortProjectData(e.value)}
                                    />
                                </div>
                            </div>
                            <div className="sortFooter">
                                <div className="floatright">
                                    <div className="applyButton">
                                        <button onClick={props.getAllProjects}>Apply</button>
                                    </div>
                                    <div className="cancleButton">
                                        <button onClick={cancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
