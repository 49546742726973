import React, { useState, useEffect } from 'react';
import '../auth.css';
import { resendOtpAPI, verifyOtpAPI } from '../../../utils/authAPI';

const VerifyOTP = (props) => {
    const [otp, setOTP] = useState('');
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [resendTimer, setResendTimer] = useState(props.resendOtpTime);
    const [expiryTimer, setExpiryTimer] = useState(props.otpExpireTime);

    useEffect(()=>{
        setResendTimer(props.resendOtpTime) ;
        setExpiryTimer(props.otpExpireTime);
    },[props.resendOtpTime, props.otpExpireTime])
    
    useEffect(() => {
        if((resendTimer > 0) || (expiryTimer > 0) ){
            const timer = setInterval(() => {
                setResendTimer((prevTimer) => prevTimer - 1);
                // setExpiryTimer((prevTimer) => prevTimer - 1);
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [resendTimer]);

    const formattedTime =(seconds) => `${Math.floor(seconds / 60).toString().padStart(2, "0")}:${(seconds % 60).toString().padStart(2, "0")}`;

    const handleSubmit = async (e) => {
        e.preventDefault();
        props.setStartLoader(true);
        props.setErrorMessage("");

        const body = JSON.stringify({
            "transactionId": props.transactionId,
            "otp": otp,
        });
        
        verifyOtpAPI(body)
        .then((response)=>{
            setIsOtpVerified(true);
            props.setIsChangePasswordScreen(true);
            props.setStartLoader(false);
            props.setIsVerifyOtpScreen(false);
        })
        .catch((err)=>{
            props.setStartLoader(false);
            setOTP('');
            setResendTimer(0);
            props.setErrorMessage(err?.response.data.message);
        })
    };

    const handleResendOTP = async (e) => {
        props.setErrorMessage("");
        props.setIsVerifyOtpScreen(true);
        props.setStartLoader(true);

        const body = JSON.stringify({
            "email": props.email,
            "role": "",
            "transactionId": props.transactionId
        });
        resendOtpAPI(body)
        .then((response)=>{
            props.setStartLoader(false);
            props.setResendOtpTime(response.data.resendTimeout);
            props.setOtpExpireTime(response.data.otpExpiredTime);
        })
        .catch((err)=>{
            setResendTimer(0);
            props.setStartLoader(false);
            props.setErrorMessage(err?.response.data.message);
        })
    };

    return (
        <>
            <div className={"enter-email-popup"}>
                <h4>{"OTP Verification"}</h4>
                <div className='verification-message'>
                    <p style={{color:"green"}}>Verfication code sent to your registered email, code will be valid for <span>{`${formattedTime(expiryTimer)}`}</span>  minutes.</p>
                </div>
                <p>{`Please enter the code to proceed further...`}</p>
                <div className={"floating-label"}>
                        <input
                            type="text"
                            value={otp}
                            onChange={(e) => setOTP(e.target.value)}
                            required="required"
                        />
                        <span>Verification Code</span>
                    <div className={props.errorMessage ? 'errorMessage' : "form_hidden"}>
                        <p>
                         {props.errorMessage ? props.errorMessage : ""}
                        </p>
                    </div>
                    {!isOtpVerified && resendTimer > 0 && (
                        <p style={{marginLeft:"-5px"}}>{`Resend OTP in ${formattedTime(resendTimer)} secs.`}</p>
                    )}
                    {/* {
                        (expiryTimer > 0 && <p>{`OTP expires in ${formattedTime(expiryTimer)} secs.`}</p>)
                    } */}
                </div>
                {(isOtpVerified ? "" : <button 
                                            className='verify-otp' 
                                            onClick={handleSubmit}>Verify OTP
                                        </button>
                )}
                {(isOtpVerified ? "" :  <button
                                            disabled={(resendTimer > 0)}
                                            onClick={handleResendOTP}
                                            className={(resendTimer && resendTimer > 0) ? 'resend-otp inactive' : 'resend-otp'} >Resend OTP
                                        </button>
                )}
                <div>
                </div>
                {/* {
                    isChangePasswordScreen && <ChangePassword
                        email={props.email}
                        transactionId={props.transactionId}
                        setIsEnterForgetPasswordScreen={props.setIsEnterForgetPasswordScreen}
                        setStartLoader={props.setStartLoader}
                    />
                } */}
            </div>
        </>
    );
};

export default VerifyOTP;
