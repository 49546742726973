import React, { useState, useEffect, useCallback, useRef } from "react";
import {
    addStateAPI,
    getStateByIdAPI,
    updateStateAPI,
} from "../../utils/api-helper";
import { validateLongTextWithSPace, validateCharacterLimitNormalTextField } from "../../utils/input-validation";
import {
    stateNameErrorMessage,
    requiredstateNameErrorMessage,
    validateCharacterLimitNormalTextFieldErrorMessage,
} from "../../utils/status-message-helper";
import "./addContent.css";

export default function DeleteCountryData(props) {
    const userRef = useRef();
    useEffect(() => {
        userRef.current.focus();
    }, []);

    const [stateName, setStateName] = useState("");
    const [stateNameErrMsg, setStateNameErrMsg] = useState();
    const [id, setId] = useState();
    const [name, setName] = useState();
    useEffect(() => {
        setId(props.selectedCountryid);
        setName(props.selectedCountryName);
    }, []);

    const [createStatesForm, setCreateStatesForm] = useState(true);
    useEffect(() => {
        if (props.iseditStateData) {
            setCreateStatesForm(false);
            setEditStatesData(props.id);
        }
    }, [props.iseditStateData]);

    const setEditStatesData = (id) => {
        getStateByIdAPI(id)
            .then((res) => {
                setStateName(res?.data?.stateName);
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    const addState = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const body = {
                countryId: props.selectedCountryid,
                stateName: stateName,
            };
            addStateAPI(body)
                .then((res) => {
                    props.selectCountryGetStates({ "id": id, "name": name});
                })
                .catch((err) => {
                    // console.log(err);
                    setStateNameErrMsg(err.response?.data?.message);
                });
            setStateName("");
        }
    };
    const editState = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const body = {
                countryId: props.selectedCountryid,
                stateName: stateName,
            };
            updateStateAPI(props.id, body)
                .then((res) => {
                    props.selectCountryGetStates({ "id": id, "name": name});
                })
                .catch((err) => {
                    setStateNameErrMsg(err.response?.data?.message);
                    // console.log(err);
                });
            setStateName("");
        }
    };
    function validateForm() {
        if (stateName) {
            if (validateLongTextWithSPace(stateName)) {
                if (validateCharacterLimitNormalTextField(stateName)) {
                    setStateNameErrMsg("");
                    return true;
                } else {
                    setStateNameErrMsg(validateCharacterLimitNormalTextFieldErrorMessage);
                    return false;
                }
            } else {
                setStateNameErrMsg(stateNameErrorMessage);
                return false;
            }
        } else {
            setStateNameErrMsg(requiredstateNameErrorMessage);
            return false;
        }
    }

    return (
        <div className="flexadd">
            <div className="addBox">
                <div className="addContent">
                    <div className="addTitle">
                        <div className="heading">
                            {createStatesForm ? <>Add State</> : <>Edit State</>}
                        </div>
                        <div className="crossButtonHover" onClick={props.initialState}>
                            <div className="crossButton"></div>
                        </div>
                    </div>
                    <form onSubmit={createStatesForm ? addState : editState}>
                        <div className="addElements">
                            <div className="addOptionsData">
                                <div className="countryName form_input">
                                    <label>Country Name:</label>
                                    <input
                                        type="text"
                                        id="countryName"
                                        placeholder={props.selectedCountryName}
                                        name="countryName"
                                        disabled
                                    />
                                </div>
                                <div className="currencyName form_input">
                                    <label>State Name:</label>
                                    <input
                                        type="text"
                                        id="stateName"
                                        placeholder="Enter State Name"
                                        name="stateName"
                                        onChange={(e) => setStateName(e.target.value)}
                                        value={stateName}
                                        ref={userRef}
                                    />
                                    <div className="form_message form_message-error">
                                        <p
                                            className={stateNameErrMsg ? "errmsg" : "offscreen"}
                                            aria-live="assertive"
                                        >
                                            {stateNameErrMsg}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="addFooter">
                                <div className="floatright">
                                    <div className="applyButton">
                                        <button
                                            type="submit"
                                            onClick={createStatesForm ? addState : editState}
                                        >
                                            {createStatesForm ? <>ADD</> : <>UPDATE</>}
                                        </button>
                                    </div>
                                    <div className="cancleButton">
                                        <button onClick={props.initialState}>CANCEL</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
