import React, { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/FilterInvoice.css";
import { getAllCountryAPI } from '../../utils/api-helper';

export default function FilterInvoice(props) {
    function _onFocus(e) {
        e.currentTarget.type = "date";
    }
    function _onBlur(e) {
        e.currentTarget.type = "text";
        e.currentTarget.placeholder = "Enter a Date";
    }
    const [isClientFirstName, setIsClientFirstName] = useState(true);
    const [isClientLastName, setIsClientLastName] = useState(false);
    const [isContactPersonFirstName, setIsContactPersonFirstName] = useState(false);
    const [isContactPersonLastName, setIsContactPersonLastName] = useState(false);
    const [isEmail, setIsEmail] = useState(false);
    const [isCountry, setIsCountry] = useState(false);
    const [isStatus, setIsStatus] = useState(false);

    const [getAllCountries, setGetAllCountries] = useState([]);
    
    const userRef = useRef();

    const checkFilter = () => {
        let check = false;
        if (
            props.clientFirstNameFilter != "" ||
            props.clientLastNameFilter != "" ||
            props.emailFilter != "" ||
            props.countryFilter != "" ||
            props.contactPersonFirstNameFilter != "" ||
            props.contactPersonLastNameFilter != ""||
            props.isActiveStatus != true
        ) {
            props.setIsFilterSelected(false)
            check = true;
        } else {
            check = false;
        }
        return check;
    }

    const handleClickOnApply = () => {
        const isVisible = checkFilter();
        props.setIsFilterSelected(isVisible);
        props.getAllClients();
    }
    const filterClientFirstName = () => {
        clearAll();
        setIsClientFirstName(true);
        setIsClientLastName(false);
        setIsContactPersonFirstName(false);
        setIsContactPersonLastName(false);
        setIsEmail(false);
        setIsCountry(false);
        setIsStatus(false);
    };
    const filterClientLastName = () => {
        clearAll();
        setIsClientFirstName(false);
        setIsClientLastName(true);
        setIsContactPersonFirstName(false);
        setIsContactPersonLastName(false);
        setIsEmail(false);
        setIsCountry(false);
        setIsStatus(false);
    };
    const filterContactPersonFirstName = () => {
        clearAll();
        setIsClientFirstName(false);
        setIsClientLastName(false);
        setIsContactPersonFirstName(true);
        setIsContactPersonLastName(false);
        setIsEmail(false);
        setIsCountry(false);
        setIsStatus(false);
    };
    const filterContactPersonLastName = () => {
        clearAll();
        setIsClientFirstName(false);
        setIsClientLastName(false);
        setIsContactPersonFirstName(false);
        setIsContactPersonLastName(true);
        setIsEmail(false);
        setIsCountry(false);
        setIsStatus(false);
    };
    const filterEmail = () => {
        clearAll();
        setIsEmail(true);
        setIsClientFirstName(false);
        setIsClientLastName(false);
        setIsContactPersonFirstName(false);
        setIsContactPersonLastName(false);
        setIsCountry(false);
        setIsStatus(false);
    };
    const filterCountry = () => {
        getAllCountryAPI().then(res => {
            setGetAllCountries(res?.data);
        }).catch((err) => {
            // console.log(err);
        })
        clearAll();
        setIsCountry(true);
        setIsClientFirstName(false);
        setIsClientLastName(false);
        setIsContactPersonFirstName(false);
        setIsContactPersonLastName(false);
        setIsEmail(false);
        setIsStatus(false);
    };
    const filterStatus = () => {
        clearAll();
        setIsStatus(true);
        setIsClientFirstName(false);
        setIsClientLastName(false);
        setIsContactPersonFirstName(false);
        setIsContactPersonLastName(false);
        setIsEmail(false);
        setIsCountry(false);
    };

    const clearAll = () => {
        props.setIsFilterSelected(false);
        props.setClientFirstNameFilter('');
        props.setClientLastNameFilter('');
        props.setContactPersonFirstNameFilter('');
        props.setContactPersonLastNameFilter('');
        props.setEmailFilter('');
        props.setCountryFilter('');
        props.setIsActiveStatus(true);
    };
    const cancel = () => {
        clearAll();
        props.setIsFilterClient(false);
    }

    return (
        <>
            <div className="flexfilter">
                <div className="filterBox">
                    <div className="filterContent">
                        <div className="filterTitle">
                            <div className="heading">Filter Clients</div>
                            <div className="crossButtonHover" onClick={cancel}>
                                <div className="crossButton"></div>
                            </div>
                            <button onClick={clearAll}>Clear All</button>
                        </div>
                        <div className="filterElements">
                            {/* <form onsubmi></form> */}
                            <div className="flex">
                                <div className="filterOptionsList">
                                    <div
                                        className={
                                            isClientFirstName
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterClientFirstName}
                                    >
                                        <p>Client Name</p>
                                    </div>
                                    {/* <div
                                        className={
                                            isClientLastName
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterClientLastName}
                                    >
                                        <p>Client Last Name</p>
                                    </div> */}
                                    <div
                                        className={
                                            isContactPersonFirstName
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterContactPersonFirstName}
                                    >
                                        <p>Contact Person's First Name</p>
                                    </div>
                                    <div
                                        className={
                                            isContactPersonLastName
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterContactPersonLastName}
                                    >
                                        <p>Contact Person's Last Name</p>
                                    </div>
                                    <div
                                        className={
                                            isEmail
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterEmail}
                                    >
                                        <p>Email</p>
                                    </div>
                                    <div
                                        className={
                                            isCountry
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterCountry}
                                    >
                                        <p>Country</p>
                                    </div>
                                    <div
                                        className={
                                            isStatus
                                                ? "filterOption optionBackground"
                                                : "filterOption transparentBackground"
                                        }
                                        onClick={filterStatus}
                                    >
                                        <p>Status</p>
                                    </div>
                                </div>
                                <div className="filterOptionsData">
                                    {isClientFirstName ? (
                                        <div className="filterOptionInput">
                                            <input
                                                type="text"
                                                id="clientNameFilter"
                                                placeholder="Enter Client Name"
                                                name="clientNameFilter"
                                                onChange={(e) => props.setClientFirstNameFilter(e.target.value)}
                                                ref={userRef}
                                                value={props.clientFirstNameFilter}
                                            />
                                        </div>
                                    ) : isContactPersonFirstName ? (
                                        <div className="filterOptionInput">
                                            <input
                                                type="text"
                                                id="setContactPersonFirstNameFilter"
                                                placeholder="Contact Person's First Name"
                                                name="setContactPersonFirstNameFilter"
                                                onChange={(e) => props.setContactPersonFirstNameFilter(e.target.value)}
                                                value={props.contactPersonFirstNameFilter}
                                            />
                                        </div>
                                    ) : isContactPersonLastName ? (
                                        <div className="filterOptionInput">
                                            <input
                                                type="text"
                                                id="setContactPersonLastNameFilter"
                                                placeholder="Contact Person's Last Name"
                                                name="setContactPersonLastNameFilter"
                                                onChange={(e) => props.setContactPersonLastNameFilter(e.target.value)}
                                                value={props.contactPersonLastNameFilter}
                                            />
                                        </div>
                                    ) : isEmail ? (
                                        <div className="filterOptionInput">
                                            <input
                                                type="text"
                                                id="emailFilter"
                                                placeholder="Enter Email"
                                                name="emailFilter"
                                                onChange={(e) => props.setEmailFilter(e.target.value)}
                                                value={props.emailFilter}
                                            />
                                        </div>
                                    ) : isCountry ? (
                                        <div className="filterOptionInput">
                                            <select
                                                type="select"
                                                id="countryFilter"
                                                className="countryFilter"
                                                placeholder="Enter Country"
                                                name="countryFilter"
                                                // value={props.countryFilter}
                                                onChange={(e) => props.setCountryFilter((e.target.value))}
                                            >
                                                <option className="custom-select" value="" disabled selected>{props.countryFilter == "" ? "Select Country" : props.countryFilter}</option>
                                                {getAllCountries.map((row) => (
                                                    <option value={row.countryName} className="custom-options">{row.countryName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    ) : isStatus ? (
                                        <div className="filterOptionInput">
                                            <select
                                                type="select"
                                                className="statusFilter"
                                                placeholder="Enter Status"
                                                name="statusFilter"
                                                onChange={(e) => props.setIsActiveStatus(e.target.value)}
                                                value={props.statusFilter}
                                            >
                                                <option className="custom-options" value="true" selected>Active</option>
                                                <option className="custom-options" value="false">Inactive</option>
                                            </select>
                                        </div>
                                    ) : (
                                        <p>No Content</p>
                                    )}
                                </div>
                            </div>
                            <div className="filterFooter">
                                <div className="floatright">
                                    <div className="applyButton">
                                        <button onClick={handleClickOnApply}>Apply</button>
                                    </div>
                                    <div className="cancleButton">
                                        <button onClick={cancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
