import React from 'react'
import './footer.css'
function Footer() {
  return (
    <>
      <footer className="footer-box">
        <div className="footer-heading">Copyright @ 2022 Biz4solutions- All rights reserved</div>
      </footer></>
  )
}

export default Footer