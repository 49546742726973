import React, { useState } from 'react';
import '../auth.css';
import { changePasswordAPI } from '../../../utils/authAPI';
import { passwordErrorMessage } from '../../../utils/status-message-helper';
import { validatePassword } from '../../../utils/input-validation';

const ChangePassword = (props) => {

    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState("");
    const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
    const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false);
    const [isPasswordChanged, setIsPasswordChanged] = useState(false)
    const handleBackToLoginScreen = () => {
        props.setIsEnterForgetPasswordScreen(false);
        props.setIsChangePasswordScreen(false);
    }

    const handleChangePassword = async () => {
        props.setStartLoader(true);
        setErrorMessage("");
        if (validatePassword(newPassword) && validatePassword(confirmNewPassword)) {
            if (newPassword === confirmNewPassword) {
                const body = JSON.stringify({
                    "email": props.email,
                    "newPassword": newPassword,
                    "confirmPassword": confirmNewPassword,
                    "transactionId": props.transactionId
                });

                changePasswordAPI(body)
                .then((response)=>{
                    if (response.status == "success") {
                        setIsPasswordChanged(true);
                        props.setStartLoader(false);
                    }
                })
                .catch((err)=>{
                    props.setStartLoader(false);
                    setErrorMessage(err?.response.data.message);
                })
            } else {
                props.setStartLoader(false);
                setErrorMessage("Please make sure both passwords match.")
            }
        } else {
            props.setStartLoader(false);
            setErrorMessage(passwordErrorMessage);
        }
    }

    return (
        <>
            <div className={"enter-email-popup"}>
                <h3>{isPasswordChanged ? "" : `Set New Password`}</h3>
                <div className={isPasswordChanged ? "form_hidden" : "password-validation-container"}>
                    <h5>Password should contain :</h5>
                    <ul>
                        <li>{"8-24 characters."}</li>
                        <li>{"One lowercase letter (a-z)."}</li>
                        <li>{"One uppercase letter (A-Z)."}</li>
                        <li>{"One numeric character (0-9)"}</li>
                        <li>{"One special character (@,$,%,&,!,#,^,*,_,-)."}</li>
                    </ul>
                </div>
                <div className={isPasswordChanged ? "form_hidden" :"" }>
                    <div className={"floating-label"}>
                        
                            <input
                                type={isRevealNewPwd ? "text" : "password"}
                                value={newPassword}
                                onChange={(e) => {
                                    setNewPassword(e.target.value);
                                    setErrorMessage("");
                                }}
                                required="required"
                            />
                            <span id='newPassword'>New Password</span>
                            <div
                                title={isRevealNewPwd ? "Hide password" : "Show password"}
                                className={isRevealNewPwd ? "hide_forget_password" : "show_forget_password"}
                                onClick={() => setIsRevealNewPwd(prevState => !prevState)}
                            />
                    </div>
                    <div  className={"floating-label"}>
                            <input
                                type={isRevealConfirmPwd ? "text" : "password"}
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                required="required"
                            />
                            <span id='confirmPassword'>Confirm Password</span>
                        <div
                            title={isRevealConfirmPwd ? "Hide password" : "Show password"}
                            className={isRevealConfirmPwd ? "hide_forget_password" : "show_forget_password"}
                            onClick={() => setIsRevealConfirmPwd(prevState => !prevState)}
                        />
                        <div className='errorMessage'>
                            <p>
                                {errorMessage ? errorMessage : ""}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={isPasswordChanged ? "password_change_success" : "form_hidden"}>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <img id="checkSuccess" src={require("../../../assets/images/successCheck.png")} />
                    </div>
                    <span> {"Password Changed Succesfully."}</span>
                </div>
                <button className={isPasswordChanged ? 'form_hidden' : 'verify-otp'} onClick={handleChangePassword}>{"Change Password"}</button>
                <button className={isPasswordChanged ? 'verify-otp' : 'form_hidden'} onClick={handleBackToLoginScreen}>{"Back To Login"}</button>
            </div>
        </>
    )

}

export default ChangePassword;