import React, { useState, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-material.css"; // Optional Theme applied to the grid
import { TablePagination } from "@mui/material";
import { isEditable } from "@testing-library/user-event/dist/utils";
import "./table.css";
export default function Index(props) {
  const rowData = useMemo(() => props.rows, [props.rows]);
  const colDefs = useMemo(() => props.columns, [props.columns]);
  const defaultColDef = useMemo(
    () => ({
      flex: 1,
    }),
    []
  );
  const filteredData = props.search
    ? props.rows.filter((row) => {
        if (props.userTable) {
          return Object.values(row).some(
            (value) =>
              value !== null &&
              typeof value === "string" &&
              value
                .replace(/\s/g, "")
                .toLowerCase()
                .includes(props.search.replace(/\s/g, "").toLowerCase())
          );
        } else {
          return true; // Return the row as-is if props.userTable is false
        }
      })
    : null;
  return (
    <div>
      <div
        className="ag-theme-material"
        style={{
          height: "calc(100vh - 210px)",
          width: "100%",
          paddingLeft: "45px",
          paddingRight: "20px",
        }} // the grid will fill the size of the parent container
      >
        <div>
          {props.isPagination && (
            <TablePagination
              rowsPerPageOptions={[10, 20, 100]}
              component="div"
              count={props.totalCount}
              rowsPerPage={props.rowsPerPage}
              page={props.page}
              onPageChange={(event, newPage) => props.handleChangePage(newPage)}
              colSpan={3}
              onRowsPerPageChange={(event) =>
                props.handleChangeRowsPerPage(+event.target.value)
              }
              sx={{
                position: "relative",
                backgroundColor: "#ffffff",
                padding: "0px",
                margin: "0px",
                color: "#0f84fd",
              }}
            />
          )}
        </div>
        <AgGridReact
          rowData={filteredData ? filteredData : rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          onCellValueChanged={
            props.handleCellChange ? props.handleCellChange : null
          }
          onRowClicked={(event) => {
            //This is done to stopPropagation to row click when we click on edit after that cancel icon will triger row click .

            if (props.isTableEditable && props.fromPage === 'WeeklyInvoice') {
              const row = event?.node?.data;
              props.function(row);
              return;
            }else if(props.fromPage === 'WeeklyInvoice' && !props.isTableEditable){
              return;
            }

            if (props.isTableEditable) {
              return;
            }
            const row = event?.node?.data;
            // return props.function(row);
            if (
              props.tableTitle === "monthlyProjectBudget" ||
              props.navigatedFromDashboard
            ) {
              return () => {};
            } else {
              return props.function(row);
            }
          }}
        />
      </div>
    </div>
  );
}
