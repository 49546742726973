import React, { useState, useEffect, useCallback } from 'react';
import './settings.css';
import { useNavigate } from "react-router-dom";
import { logoutAdminAPI, logoutUserAPI, getUserData } from '../../utils/api-helper';
import { clearAllLocalStorage } from '../../utils/local-storage-helper';
import ChangePassword from '../ChangePassword';
import IdleTimer from '../IdleTimer';
import { sortRolesByPriority } from '../../utils/roles-helper';

export default function Index () {

    // const [isChangePassword, setIsChangePassword] = useState(false);
    // const changePassword = () => {
    //     setIsChangePassword(false)
    // }
    return (
        <>
            <div className="settings-box">
                <div className="managesettings">
                    <div className="settings-container">
                            <p className="heading">{getUserData().firstName} {getUserData().lastName}</p>
                            <p className="designation">{sortRolesByPriority(getUserData().roles)[0]}</p>
                            <IdleTimer />
                    </div>
                </div>
            </div>
            {/* {isChangePassword ? <ChangePassword checkevent={changePassword} /> : <></>} */}
        </>
    )
}
