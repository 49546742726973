import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import Navigation from '../../components/Navigation';
import AddCountryData from './addCountry';
import DeleteCountryData from './deleteCountry';
import AddStateData from './addStates';
import DeleteStateData from './deleteStates';
import AddCityData from './addCities';
import DeleteCityData from './deleteCities';
import {
    getAllCountryAPI,
    getAllStateAPI,
    getAllCitiesAPI
} from '../../utils/api-helper';
import Settings from '../../components/Settings';
import Footer from '../../components/footer/Footer';
import AddContentButton from '../../components/Buttons/AddContent';
import ModifyContentButton from '../../components/Buttons/ModifyContent';
import DeleteContenttButton from '../../components/Buttons/DeleteContent';
import Loader from "../../components/Loader";

import '../../assets/styles/managepages.css';

import DataTable from '../../components/Tables'

export function RenderComponent(props) {
    return (
        <>
            <div className="table-box-functional_elements">
                {props.isSelected ?
                    <>
                        <div className="functional_elements" >
                            <div onClick={props.delete}>
                                <DeleteContenttButton />
                            </div>
                        </div>
                        <div className="functional_elements">
                            <div onClick={props.edit}>
                                <ModifyContentButton />
                            </div>
                        </div>
                    </>
                    : <></>
                }
                <div className="functional_elements" >
                    <div onClick={props.add}>
                        <AddContentButton />
                    </div>
                </div>
            </div>
        </>
    )
}

export default function ManageCountry() {
    const navigate = useNavigate();
    
    const [isGetAllCountries, setIsGetAllCountries] = useState(true);
    const [isGetAllStates, setIsGetAllStates] = useState(true);
    const [isGetAllCities, setIsGetAllCities] = useState(true);
    const [startLoader, setStartLoader] = useState(false);
    const [isaddCountryData, setIsAddCountryData] = useState(false);
    const [isdeleteCountryData, setIsDeleteCountryData] = useState(false);
    const [iseditCountryData, setIsEditCountryData] = useState(false);
    const [isaddStateData, setIsAddStateData] = useState(false);
    const [isdeleteStateData, setIsDeleteStateData] = useState(false);
    const [iseditStateData, setIsEditStateData] = useState(false);
    const [isaddCityData, setIsAddCityData] = useState(false);
    const [isdeleteCityData, setIsDeleteCityData] = useState(false);
    const [iseditCityData, setIsEditCityData] = useState(false);

    const [countryResponse, setCountryResponse] = useState([]);
    const [stateResponse, setStateResponse] = useState([]);
    const [cityResponse, setCityResponse] = useState([]);
    const [success, setSuccess] = useState(false);

    const [selectedCountryid, setSelectedCountryid] = useState();
    const [selectedStateid, setSelectedStateid] = useState();
    const [selectedCityid, setSelectedCityid] = useState();

    const [selectedCountryName, setSelectedCountryName] = useState();
    const [selectedStateName, setSelectedStateName] = useState();
    const [selectedCityName, setSelectedCityName] = useState();

    const [isSelectedCountry, setIsSelectedCountry] = useState(false);
    const [isSelectedState, setIsSelectedState] = useState(false);
    const [isSelectedCity, setIsSelectedCity] = useState(false);


    function createData(id, name) {
        return { id, name };
    }
    const CountryColumns = [{ id: "countryName", label: "Country" },];
    const StatesColumns = [{ id: "StateName", label: "States of " },];
    const CitiesColumns = [{ id: "CityName", label: "Cities of " },];

    const CountryRows = countryResponse ? countryResponse.map((row) =>
        createData(row.id, row.countryName)
    ) : "";
    const StatesRows = stateResponse ? stateResponse.map((row) =>
        createData(row.id, row.stateName)
    ) : "";
    const CitiesRows = cityResponse ? cityResponse.map((row) =>
        createData(row.id, row.cityName)
    ) : "";

    const initialStateOfCities = () => {
        setIsAddCityData(false);
        setIsDeleteCityData(false);
        setIsEditCityData(false);
    }
    const initialStateOfStates = () => {
        setIsAddStateData(false);
        setIsDeleteStateData(false);
        setIsEditStateData(false);
        initialStateOfCities();
    }
    const initialStateOfCountries = () => {
        setIsAddCountryData(false);
        setIsDeleteCountryData(false);
        setIsEditCountryData(false);
        initialStateOfStates();
        initialStateOfCities();
    }
    const getAllCountries = () => {
        setStartLoader(true);
        initialStateOfCountries();
        setIsSelectedCountry(false);
        getAllCountryAPI().then(res => {
            setCountryResponse(res?.data);
            setStateResponse();
            setCityResponse();
            setSuccess(true);
            setStartLoader(false);
        }).catch((err) => {
            setStartLoader(false);
            setSuccess(false);
        })
    }
    const addCountries = () => {
        initialStateOfCountries();
        setIsAddCountryData(true);
    }
    const editCountries = () => {
        initialStateOfCountries();
        setIsEditCountryData(true);
    }
    const deleteCountries = () => {
        initialStateOfCountries();
        setIsDeleteCountryData(true);
    }
    const addStates = () => {
        initialStateOfStates();
        setIsAddStateData(true);
    }
    const editStates = () => {
        initialStateOfStates();
        setIsEditStateData(true);
    }
    const deleteStates = () => {
        initialStateOfStates();
        setIsDeleteStateData(true);
    }
    const addCities = () => {
        initialStateOfCities();
        setIsAddCityData(true);
    }
    const editCities = () => {
        initialStateOfCities();
        setIsEditCityData(true);
    }
    const deleteCities = () => {
        initialStateOfCities();
        setIsDeleteCityData(true);
    }

    const selectCountryGetStates = (row) => {
        setStartLoader(true);
        setIsSelectedCountry(true);
        setIsSelectedState(false);
        setIsSelectedCity(false);
        initialStateOfStates();
        setStateResponse();
        setCityResponse();
        setSelectedCountryid(row.id);
        setSelectedCountryName(row.name);
        setSelectedStateid();
        setSelectedCityid();
        getAllStateAPI(row.id).then(res => {
            setStateResponse(res?.data);
            setStartLoader(false);
        }).catch((err) => {
            setStartLoader(false);
        })
    }
    const selectStatesGetCities = (row) => {
        setStartLoader(true);
        setIsSelectedCountry(true);
        setIsSelectedState(true);
        setIsSelectedCity(false);
        setIsAddCityData(false);
        setIsDeleteCityData(false);
        setIsEditCityData(false);
        setCityResponse();
        setSelectedCityid();
        setSelectedStateid(row.id);
        setSelectedStateName(row.name)
        getAllCitiesAPI(row.id).then(res => {
            setCityResponse(res?.data);
            setStartLoader(false);
        }).catch((err) => {
            setStartLoader(false);
        })
    }
    const selectCities = (row) => {
        setIsSelectedCity(true);
        setSelectedCityid(row.id);
        setSelectedCityName(row.name);
    }
    useEffect(() => {
        if (countryResponse?.length == 0) {
            getAllCountries();
        }
    }, []);


    return (
        <>
            <div className="flex-container">
                <div>
                    <Navigation myClass="Manage Country" />
                </div>
                <div className="right-section">
                    <div className="title" id="title">
                        <div className="pageheading"><p>Manage Country</p></div>
                        <Settings />
                    </div>
                    <div className="content" id="content">
                        <div className="viewCountries">
                            <div className="locationLists">
                                <DataTable
                                    paginationStyle={{
                                        position: "fixed",
                                        right: "20px",
                                        bottom: "20px",
                                        backgroundColor: "#ffffff",
                                    }}
                                    selectedId={selectedCountryid}
                                    columns={CountryColumns}
                                    rows={CountryRows}
                                    Component={<RenderComponent
                                        isSelected={isSelectedCountry}
                                        delete={deleteCountries}
                                        edit={editCountries}
                                        add={addCountries}
                                    />}
                                    function={selectCountryGetStates}
                                    normalData={false}
                                />
                            </div>
                            {stateResponse ?
                                <div className="locationLists">
                                    <DataTable
                                        paginationStyle={{
                                            position: "fixed",
                                            right: "20px",
                                            bottom: "20px",
                                            backgroundColor: "#ffffff",
                                        }}
                                        selectedName={selectedCountryName}
                                        selectedId={selectedStateid}
                                        columns={StatesColumns}
                                        rows={StatesRows}
                                        Component={<RenderComponent
                                            isSelected={isSelectedState}
                                            delete={deleteStates}
                                            edit={editStates}
                                            add={addStates}
                                        />}
                                        function={selectStatesGetCities}
                                        normalData={false}
                                    />
                                </div>
                                : <></>
                            }
                            {cityResponse ?
                                <div className="locationLists">
                                    <DataTable
                                        paginationStyle={{
                                            position: "fixed",
                                            right: "20px",
                                            bottom: "20px",
                                            backgroundColor: "#ffffff",
                                        }}
                                        selectedName={selectedStateName}
                                        selectedId={selectedCityid}
                                        columns={CitiesColumns}
                                        rows={CitiesRows}
                                        Component={<RenderComponent
                                            isSelected={isSelectedCity}
                                            delete={deleteCities}
                                            edit={editCities}
                                            add={addCities}
                                        />}
                                        function={selectCities}
                                        normalData={false}
                                    />
                                </div>
                                : <></>
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
                {isaddCountryData ?
                    <AddCountryData
                        iseditCountryData={false}
                        initialState={initialStateOfCountries}
                        getAllCountries={getAllCountries}
                    /> : <></>}
                {isdeleteCountryData ?
                    <DeleteCountryData
                        initialState={initialStateOfCountries}
                        getAllCountries={getAllCountries}
                        id={selectedCountryid}
                        name={selectedCountryName}
                    /> : <></>}
                {iseditCountryData ?
                    <AddCountryData
                        iseditCountryData={true}
                        initialState={initialStateOfCountries}
                        getAllCountries={getAllCountries}
                        id={selectedCountryid}
                    /> : <></>}

                {isaddStateData ?
                    <AddStateData
                        iseditStateData={false}
                        initialState={initialStateOfStates}
                        selectCountryGetStates={selectCountryGetStates}
                        selectedCountryid={selectedCountryid}
                        selectedCountryName={selectedCountryName}
                    /> : <></>}
                {isdeleteStateData ?
                    <DeleteStateData
                        selectCountryGetStates={selectCountryGetStates}
                        initialState={initialStateOfStates}
                        selectedCountryid={selectedCountryid}
                        id={selectedStateid}
                        selectedCountryName={selectedCountryName}
                        selectedStateName={selectedStateName}
                    /> : <></>}
                {iseditStateData ?
                    <AddStateData
                        iseditStateData={true}
                        selectCountryGetStates={selectCountryGetStates}
                        initialState={initialStateOfStates}
                        id={selectedStateid}
                        selectedCountryid={selectedCountryid}
                        selectedCountryName={selectedCountryName}
                    /> : <></>}

                {isaddCityData ?
                    <AddCityData
                        iseditCityData={false}
                        initialState={initialStateOfCities}
                        selectStatesGetCities={selectStatesGetCities}
                        selectedStateid={selectedStateid}
                        selectedStateName={selectedStateName}
                        selectedCountryName={selectedCountryName}
                    /> : <></>}
                {isdeleteCityData ?
                    <DeleteCityData
                        selectStatesGetCities={selectStatesGetCities}
                        initialState={initialStateOfCities}
                        selectedStateid={selectedStateid}
                        selectedStateName={selectedStateName}
                        selectedCityid={selectedCityid}
                        selectedCityName={selectedCityName}
                    /> : <></>}
                {iseditCityData ?
                    <AddCityData
                        iseditCityData={true}
                        initialState={initialStateOfCities}
                        selectStatesGetCities={selectStatesGetCities}
                        selectedCityid={selectedCityid}
                        selectedStateid={selectedStateid}
                        selectedStateName={selectedStateName}
                        selectedCountryName={selectedCountryName}
                    /> : <></>}
            </div>
            {
                startLoader ? <Loader /> : <></>
            }
        </>
    )
}