import './App.css';
import RoutingPage from './Router'
import { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import emitter from './utils/Emitter';
import { useNavigate } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
// import { ThemeProvider, createTheme } from '@mui/material/styles'


// const theme = createTheme({
//   components: {
//     MuiOutlinedInput: {
//       styleOverrides: {
//         root: {
//           '&.Mui-focused': {
//             borderWidth: 1,
//           },
//           '& .MuiOutlinedInput-notchedOutline': {
//             borderWidth: 1,
//           },
//         },
//       },
//     },
//   },
// });



function App () {
  let history = createBrowserHistory();
  const navigate = useNavigate();

  useEffect(() => {
    emitter.on('TOKEN_EXPIRED', (isExpired) => {
      return navigate("/login");
    });

    return () => emitter.off('TOKEN_EXPIRED');
  }, [navigate]);
  return (<>
      {/* <ThemeProvider theme={theme}> */}
        <RoutingPage history={history} />
      {/* </ThemeProvider>; */}
    </>);
}
export default App;