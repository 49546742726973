import React, { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate } from "react-router-dom";
import './auth.css';
import Logo from '../../components/Logo_Biz4/Logo'
import qs from 'qs'
import { OAUTH2_CLIENT_ID, OAUTH2_CLIENT_PASSWORD, getHostURL, setUserData, getUserData, setAuthToken, setisAuth, getisAuth } from '../../utils/api-helper'
import { setCookie, getCookie, setItemLocalStorage } from '../../utils/local-storage-helper';
import { validateEmail } from '../../utils/input-validation';
import { emailErrorMessage, requiredemailErrorMessage, requiredPasswordErrorMessage } from '../../utils/status-message-helper';
import Footer from '../../components/footer/Footer';
import EnterEmail from './ForgetPassword/EnterEmail';
import VerifyOTP from './ForgetPassword/VerifyOtp';
import ChangePassword from './ForgetPassword/ChangePassword';
import Loader from '../../components/Loader';
import { loginAPI } from '../../utils/authAPI';
import { sortRolesByPriority } from '../../utils/roles-helper';

export default function Login() {

    const [email, setEmail] = useState(getCookie('username'));
    const [password, setPassword] = useState(getCookie('password'));
    const userRef = useRef();
    const errRef = useRef();
    const [errorMessage, setErrorMessage] = useState('');
    const [passErrMsg, setpassErrMsg] = useState();
    const [emailErrMsg, setemailErrMsg] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isEnterForgetPasswordScreen, setIsEnterForgetPasswordScreen] = useState(false);
    const [startLoader, setStartLoader] = useState(false);


    const [isVerifyOtpScreen, setIsVerifyOtpScreen] = useState(false);
    const [isChangePasswordScreen, setIsChangePasswordScreen] = useState(false);
    const [transactionId, setTransactionId] = useState("");
    const [otpExpireTime, setOtpExpireTime] = useState("");
    const [resendOtpTime, setResendOtpTime] = useState("");



    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrorMessage('');
    }, [email, password])

    let history = useNavigate();

    const setCookieData = () => {
        if (!email == "" && !password == "") {
            setCookie("username", email)
            setCookie("password", password)
        } else {
            setErrorMessage("Email and password field cannot be empty")
            document.getElementById("remember").checked = false;
        }
    }

    const handleForgotPasswordClick = () => {
        setIsEnterForgetPasswordScreen(true);
    }

    const timerSetUp = (timeStamp) => {
        const currentDate = new Date();
        const currentTimeStamp = currentDate.getTime();
        const expectedTime = new Date(timeStamp);
        const expectedTimeStamp = expectedTime.getTime();
        const timeDiff = Math.floor((expectedTimeStamp - currentTimeStamp) / 1000);


        return (timeDiff ? timeDiff : 0);
    }
      

    const handleSubmit = async (e) => {
        localStorage.clear();
        e.preventDefault();
        if (validateUserEmail()) {
          
            const requestWrapper = {
                "email" : email,
                "password" : password
            }
            //call API from authAPI.js
            loginAPI(requestWrapper)
                .then((response) => {
                    setEmail('');
                    setPassword('');
                    setisAuth(true)
                    setAuthToken(response?.access_token, response?.refresh_token)
                    setUserData(response?.data)
                    const roles = sortRolesByPriority(response?.data.roles);
                    setItemLocalStorage('userRole', roles[0]);
                    roles[0] == "USER" ? history("/invoice") : history("/dashboard");
                }).catch((err) => {
                    if (!err?.response) {
                        setErrorMessage(err + ', No server response');
                    } else if (err.response?.status === 400) {
                        setErrorMessage(err.response?.data.message);
                    } else if (err.response?.status === 401) {
                        setErrorMessage('Unauthorized');
                    } else {
                        setErrorMessage('Login Failed');
                    }
                });
        }
    }
    function checkEmail() {
        if (email) {
            if (validateEmail(email)) {
                setemailErrMsg("");
                return true;
            } else {
                setemailErrMsg(emailErrorMessage);
                return false;
            }
        } else {
            setemailErrMsg(requiredemailErrorMessage);
            return false;
        }
    }
    function checkPassword() {
        if (password) {
            setpassErrMsg("");
            return true;
        } else {
            setpassErrMsg(requiredPasswordErrorMessage);
            return false;
        }
    }
    function validateUserEmail() {
        if (checkEmail()
            && checkPassword()) {
            return true;
        } else {
            checkEmail();
            checkPassword();
            return false;
        }
    }

    return (
        <>
            {
                startLoader ? <Loader /> : <></>
            }
            <div className="auth" >
                {/* image Logo */}
                < Logo />
                <div className="textalign">
                    <h2>WELCOME TO BIZ4SOLUTIONS</h2>
                    <p>Please enter your login details below.</p>
                </div>
                {/*     Login Page      */}
                <form className={isEnterForgetPasswordScreen ? 'form_hidden' : 'form'} method='POST' id="login" onSubmit={handleSubmit} >
                    <div className="container">
                        <div className="email form_input">
                            <input
                                type="text"
                                id="email"
                                placeholder="Email"
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                                ref={userRef}
                                value={email}
                            />
                            <div className="form_message form_message-error">
                                <p className={emailErrMsg ? "errmsg" : "offscreen"} aria-live="assertive">{emailErrMsg}</p>
                            </div>
                        </div>
                        <div className="password form_input">
                            <input
                                type={isRevealPwd ? "text" : "password"}
                                id="password"
                                placeholder="Password"
                                name="password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                            <div className="form_message form_message-error">
                                <p className={passErrMsg ? "errmsg" : "offscreen"} aria-live="assertive">{passErrMsg}</p>
                            </div>
                            <div
                                title={isRevealPwd ? "Hide password" : "Show password"}
                                className={isRevealPwd ? "hide_password" : "show_password"}
                                onClick={() => setIsRevealPwd(prevState => !prevState)}
                            />
                        </div>
                        <div className="form_message form_message-error">
                            <p ref={errRef} className={errorMessage ? "errmsg" : "offscreen"} aria-live="assertive">{errorMessage}</p>
                        </div>
                        <div className="sub-container left-flex">
                            <label className="checkbox" style={{ padding: "5px" }}>
                                <input type="checkbox" name="remember" id="remember" onClick={setCookieData} />
                                Keep me Logged In.
                            </label>
                            <span className="psw" onClick={handleForgotPasswordClick}> Forgot password?</span>
                        </div>
                        <button type='submit'>LOGIN</button>
                    </div>
                    {/* <p className="text-center">Don't have an account? <NavLink to="/signup" id='linkCreatAccount'>Signup</NavLink></p> */}
                </form>
            </div>
            <Footer />
            {isEnterForgetPasswordScreen &&
                <EnterEmail
                    email={email}
                    setStartLoader={setStartLoader}
                    setErrorMessage={setErrorMessage}
                    setTransactionId={setTransactionId}
                    setEmail={setEmail}
                    errorMessage={errorMessage}
                    setIsVerifyOtpScreen={setIsVerifyOtpScreen}
                    isVerifyOtpScreen={isVerifyOtpScreen}
                    setResendOtpTime={setResendOtpTime}
                    setOtpExpireTime={setOtpExpireTime}
                />
            }
            {
                isVerifyOtpScreen && <VerifyOTP
                    email={email}
                    transactionId={transactionId}
                    setIsChangePasswordScreen={setIsChangePasswordScreen}
                    setStartLoader={setStartLoader}
                    setErrorMessage={setErrorMessage}
                    errorMessage={errorMessage}
                    setIsVerifyOtpScreen={setIsVerifyOtpScreen}
                    setResendOtpTime={setResendOtpTime}
                    setOtpExpireTime={setOtpExpireTime}
                    otpExpireTime={timerSetUp(otpExpireTime)}
                    resendOtpTime={timerSetUp(resendOtpTime)}
                />
            }
            {
                isChangePasswordScreen && <ChangePassword
                    email={email}
                    transactionId={transactionId}
                    setIsChangePasswordScreen={setIsChangePasswordScreen}
                    setIsEnterForgetPasswordScreen={setIsEnterForgetPasswordScreen}
                    setStartLoader={setStartLoader}
                />
            }

        </>
    )
}
