import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  addCountryAPI,
  getCountryByIdAPI,
  editCountryAPI,
} from "../../utils/api-helper";
import {
  validateTextOnly,
  validateCountryCode,
  validateCurrencyCode,
  validateLongTextWithSPace,
  validateCharacterLimitNormalTextField,
} from "../../utils/input-validation";
import {
  currencyCodeErrorMessage,
  currencyNameErrorMessage,
  contryCodeErrorMessage,
  countryNameErrorMessage,
  requiredcurrencyCodeErrorMessage,
  requiredcurrencyNameErrorMessage,
  requiredcontryCodeErrorMessage,
  requiredcountryNameErrorMessage,
  validateCharacterLimitNormalTextFieldErrorMessage,
} from "../../utils/status-message-helper";
import "./addContent.css";

export default function DeleteCountryData(props) {
  const userRef = useRef();
  useEffect(() => {
    userRef.current.focus();
  }, []);

  const [countryCode, setCountryCode] = useState("");
  const [countryName, setCountryName] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [currencyName, setCurrencyName] = useState("");

  const [countryCodeErrMsg, setCountryCodeErrMsg] = useState();
  const [countryNameErrMsg, setCountryNameErrMsg] = useState();
  const [currencyCodeErrMsg, setCurrencyCodeErrMsg] = useState();
  const [currencyNameErrMsg, setCurrencyNameErrMsg] = useState();

  const [createCountriesForm, setCreateCountriesForm] = useState(true);
  useEffect(() => {
    if (props.iseditCountryData) {
      setCreateCountriesForm(false);
      setEditCountryData(props.id);
    }
  }, [props.iseditCountryData]);

  const setEditCountryData = (id) => {
    getCountryByIdAPI(id)
      .then((res) => {
        setCountryCode(res?.data?.countryCode);
        setCountryName(res?.data?.countryName);
        setCurrencyCode(res?.data?.currencyCode);
        setCurrencyName(res?.data?.currencyName);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const addCountry = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const body = {
        countryCode: countryCode,
        countryName: countryName,
        currencyCode: currencyCode,
        currencyName: currencyName,
      };
      addCountryAPI(body)
        .then((res) => {
          props.getAllCountries();
        })
        .catch((err) => {
          // console.log(err);
          setCountryNameErrMsg(err.response?.data?.message);
        });
    }
  };
  const editCountry = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const body = {
        countryCode: countryCode,
        countryName: countryName,
        currencyCode: currencyCode,
        currencyName: currencyName,
      };
      editCountryAPI(props.id, body)
        .then((res) => {
          props.getAllCountries();
        })
        .catch((err) => {
          // console.log(err);
          setCountryNameErrMsg(err.response?.data?.message);
        });
    }
  };

  function checkCountryCode() {
    if (countryCode) {
      if (validateCountryCode(countryCode)) {
        setCountryCodeErrMsg("");
        return true;
      } else {
        setCountryCodeErrMsg(contryCodeErrorMessage);
        return false;
      }
    } else {
      setCountryCodeErrMsg(requiredcontryCodeErrorMessage);
      return false;
    }
  }
  function checkCountryName() {
    if (countryName) {
      if (validateLongTextWithSPace(countryName)) {
        if (validateCharacterLimitNormalTextField(countryName)) {
          setCountryNameErrMsg("");
          return true;
        } else {
          setCountryNameErrMsg(validateCharacterLimitNormalTextFieldErrorMessage);
          return false;
        }
      } else {
        setCountryNameErrMsg(countryNameErrorMessage);
        return false;
      }
    } else {
      setCountryNameErrMsg(requiredcountryNameErrorMessage);
      return false;
    }
  }
  function checkCurrencyCode() {
    if (currencyCode) {
      if (validateCurrencyCode(currencyCode)) {
        setCurrencyCodeErrMsg("");
        return true;
      } else {
        setCurrencyCodeErrMsg(currencyCodeErrorMessage);
        return false;
      }
    } else {
      setCurrencyCodeErrMsg(requiredcurrencyCodeErrorMessage);
      return false;
    }
  }
  function checkCurrencyName() {
    if (currencyName) {
      if (validateLongTextWithSPace(currencyName)) {
        if (validateCharacterLimitNormalTextField(currencyName)) {
          setCurrencyNameErrMsg("");
          return true;
        } else {
          setCurrencyNameErrMsg(validateCharacterLimitNormalTextFieldErrorMessage);
          return false;
        }
      } else {
        setCurrencyNameErrMsg(currencyNameErrorMessage);
        return false;
      }
    } else {
      setCurrencyNameErrMsg(requiredcurrencyNameErrorMessage);
      return false;
    }
  }

  function validateForm() {
    let isValid = true;
    if (
      checkCountryCode() &&
      checkCountryName() &&
      checkCurrencyCode() &&
      checkCurrencyName()
    ) {
      return (isValid = true);
    } else {
      checkCountryCode();
      checkCountryName();
      checkCurrencyCode();
      checkCurrencyName();
      isValid = false;
    }
    return isValid;
  }

  return (
    <div className="flexadd">
      <div className="addBox">
        <div className="addContent">
          <div className="addTitle">
            <div className="heading">
              {createCountriesForm ? <>Add Country</> : <>Edit Country</>}
            </div>
            <div className="crossButtonHover" onClick={props.initialState}>
              <div className="crossButton"></div>
            </div>
          </div>
          <form onSubmit={createCountriesForm ? addCountry : editCountry}>
            <div className="addElements">
              <div className="addOptionsData">
                <div className="countryCodeData form_input">
                  <label>Country Code:</label>
                  <input
                    type="text"
                    id="countryCode"
                    placeholder="Enter Country Code"
                    name="countryCode"
                    onChange={(e) => setCountryCode(e.target.value)}
                    value={countryCode}
                    ref={userRef}
                  />
                  <div className="form_message form_message-error">
                    <p
                      className={countryCodeErrMsg ? "errmsg" : "offscreen"}
                      aria-live="assertive"
                    >
                      {countryCodeErrMsg}
                    </p>
                  </div>
                </div>
                <div className="countryName form_input">
                  <label>Country Name:</label>
                  <input
                    type="text"
                    id="countryName"
                    placeholder="Enter Country Name"
                    name="countryName"
                    onChange={(e) => setCountryName(e.target.value)}
                    value={countryName}
                  />
                  <div className="form_message form_message-error">
                    <p
                      className={countryNameErrMsg ? "errmsg" : "offscreen"}
                      aria-live="assertive"
                    >
                      {countryNameErrMsg}
                    </p>
                  </div>
                </div>
                <div className="currencyCode form_input">
                  <label>Currency Code:</label>
                  <input
                    type="text"
                    id="currencyCode"
                    placeholder="Enter Currency Code"
                    name="currencyCode"
                    onChange={(e) => setCurrencyCode(e.target.value)}
                    value={currencyCode}
                  />
                  <div className="form_message form_message-error">
                    <p
                      className={currencyCodeErrMsg ? "errmsg" : "offscreen"}
                      aria-live="assertive"
                    >
                      {currencyCodeErrMsg}
                    </p>
                  </div>
                </div>
                <div className="currencyName form_input">
                  <label>Currency Name:</label>
                  <input
                    type="text"
                    id="currencyName"
                    placeholder="Enter Currency Name"
                    name="currencyName"
                    onChange={(e) => setCurrencyName(e.target.value)}
                    value={currencyName}
                  />
                  <div className="form_message form_message-error">
                    <p
                      className={currencyNameErrMsg ? "errmsg" : "offscreen"}
                      aria-live="assertive"
                    >
                      {currencyNameErrMsg}
                    </p>
                  </div>
                </div>
              </div>
              <div className="addFooter">
                <div className="floatright">
                  <div className="applyButton">
                    <button
                      type="submit"
                      onClick={createCountriesForm ? addCountry : editCountry}
                    >
                      {createCountriesForm ? <>ADD</> : <>UPDATE</>}
                    </button>
                  </div>
                  <div className="cancleButton">
                    <button type="cancel" onClick={props.initialState}>
                      CANCEL
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
