import React from "react";
import "../../assets/styles/managepages.css";


const FullyPaidInvoices = (props) => {
  return (
    <>
      <div className="dashboard-tile">
        <h3>{"Fully Paid Invoices"}</h3>
        <p className="description">Transaction complete</p>
        <div className="dashboard-content left">
          <h5>{"Total Count :"}</h5>
          <p>{`${props.fullyPaidTotalValue.totalCount ? props.fullyPaidTotalValue.totalCount : "--"}`}</p>
        </div>
        <div className="dashboard-content right">
          <h5>{"Total Amount :"}</h5>
          <p>{`${props.fullyPaidTotalValue.totalAmount ? props.fullyPaidTotalValue.totalAmount : "--"}`}</p>
        </div>
      </div>
    </>
  );
};

export default FullyPaidInvoices;