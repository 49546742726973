import React from 'react'
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function Index(props) {
    const maxDateLimit = new Date('3000-12-01');
    const handleChange = (value) => {
        if (value) {
            var date = new Date(value);
            date.toLocaleString({ timeZone: 'Asia/Calcutta', format: 'dd/MM/yyyy' })
            const yyyy = date.getFullYear();
            let mm = date.getMonth() + 1; // Months start at 0!
            let dd = date.getDate();
            if (dd < 10) dd = '0' + dd; // Adding string '0' for single digit datee
            if (mm < 10) mm = '0' + mm; // Adding string '0' for single digit month
            const formattedToday = yyyy + '-' + mm + '-' + dd;
            props.setDate(formattedToday);
        }
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                value={props.date}
                onChange={handleChange}
                style={{width:"100%", height:"50px"}}
                // onChange={(value) => { props.setDate(value) }}
                maxDate={maxDateLimit}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params}  style={{width:"100%",height:"50px"}}></TextField>}
                disabled={props.disabled}
                minDate={props.minDate}
            />
        </LocalizationProvider>
    )
}
