import React, { useRef, useState, useEffect, useContext } from 'react';
import './profile.css';
import EditContentButton from '../../components/Buttons/ModifyContent';
import { ReactComponent as EditIcon } from "../../assets/icons/EditIcon.svg";
import { getUserByID } from '../../utils/api-helper';
import { TextField } from '@mui/material';
import { validatePhoneNumber } from '../../utils/input-validation';

export default function ViewProfile(props) {

    const response = props.response;
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [phoneNum,setPhoneNum] = useState(null);
    const [error, setError] = useState(null);

    function checkPhone(phoneNum) {
        let regex = /^[0-9]{10}$/;
        if (phoneNum == null) {
            setError("Please enter a contact number");
            return false;
        }
    
        if (regex.test(phoneNum)) {
            setError(null);
            return true;
        } else {
            setError("Please enter a valid contact number");
            return false;
        }
    }
    
    useEffect(()=>{
        checkPhone(phoneNum)
    },[phoneNum])



    return (
        <>
            <div className="inner-content ">
                <div className='manage_content' >
                    <div className="box-functional_elements">
                        <div className="functional_elements" onClick={props.editProfile}>
                            {/*  */}
                        </div>
                    </div>
                </div>
                <div className="data-container">
                    <div className="flex-image-container-profile">
                        <div className="profile-image">
                            <img src={require("../../assets/images/dummyprofile.png")} alt="Profile-photo" />
                        </div>
                    </div>
                    <div className="flex-data-container-profile">
                        <div className="userName">
                            <p className="heading">Name:</p>
                            <p className="data">{response.firstName} {response.lastName}</p>
                        </div>
                        <div className="userEmail">
                            <p className="heading">Email:</p>
                            <p className="data">{response.email}</p>
                        </div>
                        <div className="userPhone">
                            <p className="heading">Phone:</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {
                                    props.isUpdate ? <div style={{display:"flex"}}>
                                        <p className="data" style={{margin: "4px 4px 0px 0px"}}>{response.countryCode}</p>
                                        <TextField
                                            type='number'
                                            variant='standard'
                                            defaultValue={`${response.contactNo}`}
                                            value={phoneNum}
                                            onChange={(e) => setPhoneNum(e.target.value)}
                                            // label={`${error ? "Error" : ""}`}
                                            error={error}
                                            helperText="Enter a valid number(9876543210)"
                                        />
                                    </div> 
                                 : <p className="data">{response.countryCode} {response.contactNo}</p>
                                }
                                <span onClick={() => props.setIsUpdate(!props.isUpdate)} style={{ margin: "0px 0px -10px 5px"}}>
                                   {!props.isUpdate && <EditContentButton small={true} />}
                                </span>
                            </div>
                            {/* {error && <p style={{color:"red"}}>{error}</p>} */}
                        </div>
                    </div>
                    <div className="flex-data-container-profile">
                        <div className="employeeID ">
                            <p className="heading">Employee ID:</p>
                            <p className="data">{response.userId}</p>
                        </div>
                        <div className="department ">
                            <p className="heading">Department:</p>
                            <p className="data">{response.role}</p>
                        </div>
                        <div className="changePassword ">
                            {
                                props.isUpdate ?
                                    <button onClick={()=>props.updateUserContactNo(response.userId,{"contactNo" : phoneNum})} className={`${error ? "disable" : ""}`} style={{backgroundColor: `${error ? "grey" :""}`}}>Update Contact</button> :
                                    <button onClick={props.changePassword}>CHANGE PASSWORD</button>
                            }
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
