import React, { useState, useEffect, useContext } from "react";
import "./invoiceItems.css";
import "../../../assets/styles/addcontent.css";
import { invoiceItemsDataArray, TotalInvoiceAmount } from "../index";
import FormControlLabel from "@mui/material/FormControlLabel";
import { alpha, styled } from "@mui/material/styles";
import DataTable from "../../../components/Tables/staticTable";
import Switch from "@mui/material/Switch";
import Select from "react-select";
import { toggleInvoiceItemForm } from "../../../utils/constants";
import {
  validateInvoiceItemDescription,
  validateInvoiceItemRate,
  validateInvoiceItemHours,
  validateInvoiceItemQuantity,
  validateInvoiceItemAmount,
} from "../../../utils/status-message-helper";
export default function Index(props) {
  const [id, setId] = useState(1);
  const [itemDescription, setItemDescription] = useState("");
  const [sacCode, setSacCode] = useState("998314");
  const [hourlyRate, setHourlyRate] = useState();
  const [hours, setHours] = useState();
  const [quantity, setQuantity] = useState();
  const [itemAmount, setItemAmount] = useState();

  const [toggleInvoiceItemFormChoice, settoggleInvoiceItemFormChoice] =
    useState("true");
  const [isInvoiceItemsData, setIsInvoiceItemsData] = useState(true);

  const [itemDescriptionErrMessage, setItemDescriptionErrMessage] =
    useState("");
  const [hourlyRateErrMessage, setHourlyRateErrMessage] = useState("");
  const [hoursErrMessage, setHoursErrMessage] = useState("");
  const [quantityErrMessage, setQuantityErrMessagee] = useState("");
  const [amountErrMessage, setAmountErrMessage] = useState("");

  const decimal = (value) => {
    let decimalValue = parseFloat(value);
    decimalValue.toFixed(2);
    return decimalValue.toFixed(2);
  };

  function invoiceItemFormChoice(e) {
    settoggleInvoiceItemFormChoice(String(e.target.value));
  }

  const calculateItemAmount = (e, check) => {
    if (toggleInvoiceItemFormChoice === "true") {
      if (check) {
        setItemAmount(e.target.value);
        setHourlyRate(0.0);
        setQuantity(0.0);
        setHours(0.0);
      } else {
        if (true) {
          let ItemAmount = parseFloat(
            (quantity ? decimal(quantity) : 0.0) *
              (hourlyRate ? decimal(hourlyRate) : 0.0)
          );
          setHours(0.0);
          setItemAmount(ItemAmount.toFixed(2));
        }
      }
    } else {
      if (check) {
        setItemAmount(e.target.value);
        setHourlyRate(0.0);
        setQuantity(0.0);
        setHours(0.0);
      } else {
        if (true) {
          let ItemAmount = parseFloat(
            (hours ? decimal(hours) : 0.0) *
              (hourlyRate ? decimal(hourlyRate) : 0.0)
          );
          setQuantity(0.0);
          setItemAmount(ItemAmount.toFixed(2));
        }
      }
    }
  };

  useEffect(() => {
    if (hourlyRate > 0.0 || hours > 0.0 || quantity > 0.0) {
      if (toggleInvoiceItemFormChoice) {
        calculateItemAmount(null, false);
      } else {
        calculateItemAmount(null, false);
      }
    }
  }, [hourlyRate, hours, quantity]);

  const invoiceItemsData = {
    hourlyRate: decimal(hourlyRate),
    hours:
      toggleInvoiceItemFormChoice === "false"
        ? decimal(hours)
        : decimal("0.00"),
    quantity: toggleInvoiceItemFormChoice === "true" ? quantity : 0,
    itemAmount: decimal(itemAmount),
    itemDescription: itemDescription,
    itemNumber: 0,
    sacCode: sacCode,
    isActive: true,
  };

  useEffect(() => {
    if (props.isEditItems) {
      setIsInvoiceItemsData(false);
      setEditInvoiceItemsData(props.invoiceItemIndex);
    }
  }, [props.isEditItems]);

  useEffect(() => {
    if (props.isEditItems) {
      let hours = "";
      let quantity = "";
      invoiceItemsDataArray.map((row, index) => {
        if (index === props.invoiceItemIndex) {
          hours = row.hours;
          quantity = row.quantity;
        }
      });

      if (hours > 0) {
        settoggleInvoiceItemFormChoice("false");
        const setSelectValue = document.querySelector("#invoiceItemType");
        setSelectValue.value = "false";
      } else {
        settoggleInvoiceItemFormChoice("true");
        const setSelectValue = document.querySelector("#invoiceItemType");
        setSelectValue.value = "true";
      }
    }
  }, [props.isEditItems]);

  const setEditInvoiceItemsData = (checkIndex) => {
    invoiceItemsDataArray.map((row, index) => {
      if (index === checkIndex) {
        setHourlyRate(row.hourlyRate);
        setHours(row.hours);
        setItemAmount(row.itemAmount);
        setItemDescription(row.itemDescription);
        setQuantity(row.quantity);
        setSacCode(row.sacCode);
      }
    });
  };
  const handleCreateItems = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // setItemNumber(itemNumber + 1);
      invoiceItemsDataArray.push(invoiceItemsData);
      // setHourlyRate();
      // setHours();
      // setItemAmount();
      // setItemDescription();
      // setQuantity();
      // setSacCode();
      // props.setInvoiceTotalAmount(getTotalAmount);
      props.addInvoice();
    }
  };
  const handleEditItems = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // setItemNumber(itemNumber + 1);
      invoiceItemsDataArray.splice(props.invoiceItemIndex, 1, invoiceItemsData);
      // props.setInvoiceTotalAmount(getTotalAmount);
      props.addInvoice();
    }
  };
  function checkItemDescription() {
    if (itemDescription) {
      setItemDescriptionErrMessage("");
      return true;
    } else {
      setItemDescriptionErrMessage(validateInvoiceItemDescription);
      return false;
    }
  }
  // function checkSacCode() {
  //     if (sacCode) {
  //         document.getElementById("sacCode").style.borderColor = "#7d7d7d";
  //         return true;
  //     } else {
  //         document.getElementById("sacCode").style.borderColor = "#CF0000";
  //         return false;
  //     }
  // }
  function checkQuantity() {
    if (
      quantity >= 0 ||
      quantity === null ||
      quantity === undefined ||
      quantity === NaN
    ) {
      setQuantityErrMessagee("");
      return true;
    } else {
      setQuantityErrMessagee(validateInvoiceItemQuantity);
      return false;
    }
  }
  function checkHours() {
    if (decimal(hours) >= 0) {
      setHoursErrMessage("");
      return true;
    } else {
      setHoursErrMessage(validateInvoiceItemHours);
      return false;
    }
  }
  function checkRate() {
    if (
      decimal(hourlyRate) >= 0.0 ||
      hourlyRate === null ||
      hourlyRate === "" ||
      hourlyRate === undefined ||
      hourlyRate === NaN
    ) {
      setHourlyRateErrMessage("");
      return true;
    } else {
      setHourlyRateErrMessage(validateInvoiceItemRate);
      return false;
    }
  }
  function checkAmount() {
    if (decimal(itemAmount) > 0.0) {
      setAmountErrMessage("");
      return true;
    } else {
      setAmountErrMessage(validateInvoiceItemAmount);
      return false;
    }
  }
  function validateForm() {
    let isValid = true;
    if (
      // checkInvoiceItem() &&
      checkItemDescription() &&
      // checkSacCode()
      checkQuantity() &&
      checkHours() &&
      checkRate() &&
      checkAmount()
    ) {
      isValid = true;
    } else {
      // checkInvoiceItem();
      checkItemDescription();
      checkQuantity();
      checkHours();
      checkRate();
      checkAmount();
      // checkSacCode();
      isValid = false;
    }
    return isValid;
  }

  useEffect(() => {
    if (
      props.projectType === "FIXED TYPE" ||
      props.projectType === "FIXED PRICE"
    ) {
      settoggleInvoiceItemFormChoice("true");
      const setSelectValue = document.querySelector("#invoiceItemType");
      setSelectValue.value = "true";
    } else {
      const setSelectValue = document.querySelector("#invoiceItemType");
      setSelectValue.value = "false";
      settoggleInvoiceItemFormChoice("false");
    }
  }, [props]);

  function renderTableColumn(fixed) {
    return fixed === "true" ? (
      <div className='quantity'>
        <p>Qty</p>
      </div>
    ) : (
      <div className='hours'>
        <p>Hours</p>
      </div>
    );
  }
  useEffect(() => {
    renderTableColumn(toggleInvoiceItemFormChoice);
  }, [toggleInvoiceItemFormChoice]);

  return (
    <>
      <div className='flexPage'>
        <div className='flexBox'>
          <div className='flexContent'>
            <div className='filterTitle'>
              <div className='crossButtonHover' onClick={props.addInvoice}>
                <div className='crossButton'></div>
              </div>
              <div className='itemsChoices'>
                <select
                  id='invoiceItemType'
                  onChange={(e) => invoiceItemFormChoice(e)}
                  value={toggleInvoiceItemFormChoice}
                >
                  <option value={"true"}>Fixed Price</option>
                  <option value={"false"}>Hourly Price</option>
                </select>
              </div>
            </div>
            <form
              onSubmit={
                isInvoiceItemsData ? handleCreateItems : handleEditItems
              }
            >
              <div className='flex-form-container left-flex'>
                <div className='itemTableHead-flex'>
                  {/* <div className="itemNumber">
                                        <p>Item no.</p>
                                    </div> */}
                  <div className='itemDescription'>
                    <p>Item Description</p>
                  </div>
                  <div className='sacCode'>
                    <p>SAC Code</p>
                  </div>
                  {renderTableColumn(toggleInvoiceItemFormChoice)}
                  <div className='rate'>
                    <p>Rate</p>
                  </div>
                  <div className='itemAmount'>
                    <p>Amount</p>
                  </div>
                </div>
                <div className='itemTable-flex'>
                  {/* <div className="itemNumber left-flex">
                                        <p>{itemNumber}</p>
                                    </div> */}
                  {/* props.setItemNumber()
                                    props.itemNumber */}
                  <div className='itemDescriptionInput left-flex'>
                    <input
                      type='text'
                      id='itemDescription'
                      placeholder='Item Description'
                      // name="invoiceDate"
                      onChange={(e) => setItemDescription(e.target.value)}
                      value={itemDescription}
                    />
                    <div className='form_message form_message-error'>
                      <p
                        className={
                          itemDescriptionErrMessage ? "errmsg" : "offscreen"
                        }
                        aria-live='assertive'
                      >
                        {itemDescriptionErrMessage}
                      </p>
                    </div>
                  </div>
                  <div className='sacCodeInput left-flex'>
                    <input
                      type='text'
                      id='sacCode'
                      placeholder='SAC'
                      // name="invoiceDate"
                      onChange={(e) => setSacCode(e.target.value)}
                      value={sacCode}
                    />
                  </div>
                  <>
                    {toggleInvoiceItemFormChoice === "true" ? (
                      <div className='quantityInput left-flex'>
                        <input
                          type='text' // Use text type to allow the use of regex
                          placeholder='0.00'
                          id='quantity'
                          onChange={(e) => {
                            const inputRegex = /^[0-9]+(\.[0-9]{0,2})?$/;
                            const inputValue = e.target.value;

                            if (
                              inputRegex.test(inputValue) ||
                              inputValue === ""
                            ) {
                              setQuantity(inputValue);
                            }
                          }}
                          value={quantity}
                        />
                        <div className='form_message form_message-error'>
                          <p
                            className={
                              quantityErrMessage ? "errmsg" : "offscreen"
                            }
                            aria-live='assertive'
                          >
                            {quantityErrMessage}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className='hoursInput left-flex'>
                        <input
                          type='number'
                          id='hours'
                          placeholder='0.00'
                          onChange={(e) => setHours(e.target.value)}
                          value={hours}
                        />
                        <div className='form_message form_message-error'>
                          <p
                            className={hoursErrMessage ? "errmsg" : "offscreen"}
                            aria-live='assertive'
                          >
                            {hoursErrMessage}
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                  <div className='rateInput left-flex'>
                    <input
                      type='number'
                      placeholder='0.00'
                      id='rate'
                      onChange={(e) => {
                        setHourlyRate(e.target.value);
                      }}
                      value={hourlyRate}
                    />
                    <div className='form_message form_message-error'>
                      <p
                        className={
                          hourlyRateErrMessage ? "errmsg" : "offscreen"
                        }
                        aria-live='assertive'
                      >
                        {hourlyRateErrMessage}
                      </p>
                    </div>
                  </div>
                  <div className='itemAmountInput'>
                    <input
                      type='number'
                      id='itemAmount'
                      placeholder='0.00'
                      onChange={(e) => calculateItemAmount(e, true)}
                      value={itemAmount}
                    />
                    <div className='form_message form_message-error'>
                      <p
                        className={amountErrMessage ? "errmsg" : "offscreen"}
                        aria-live='assertive'
                      >
                        {amountErrMessage}
                      </p>
                    </div>
                  </div>
                  {/* <div className="itemAmount">
                                        <p>{hourlyRate * hours ? hourlyRate * hours : 0}</p>
                                    </div> */}
                  {/* props.setItemAmount()
                                        props.itemAmount */}
                  {/* <div className="action"></div> */}
                </div>
                <div>
                  <button
                    type='submit'
                    onClick={
                      isInvoiceItemsData ? handleCreateItems : handleEditItems
                    }
                    className='saveButton right-flex'
                  >
                    {props.isEditItems ? "UPDATE" : "ADD TO LIST"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
