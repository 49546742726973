import React, { useEffect } from "react";

const FilterByInvoiceType = (props) => {
    const handleChangeInvoiceType = (event) => {
        if (event.target.value == '1') {
            if(props.isActiveFilter){
                props.setIsFilterSelected(true);
            } else{
                props.setIsFilterSelected(false);
            }
        }else{
            props.setIsFilterSelected(true);
        }
        props.setFilterByInvoiceTypeData(event.target.value);
    }


    return (
        <select id="invoiceType" className={props.className} onChange={handleChangeInvoiceType}>
            {props.invoiceType ? props.invoiceType.map((option, index) => (
                <option key={index} value={option.value}>
                    {(option.label == "LLC") ? option.label : option.label.substr(0, 1).toUpperCase() + option.label.substr(1).toLowerCase()}
                </option>
            )) : ""}
        </select>
    );
};

export default FilterByInvoiceType;
