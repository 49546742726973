import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/Navigation";
import { getAllUsersForAdmin, getAllRolesAPI, updateUserDetails, createUser } from '../../utils/api-helper';
import Footer from "../../components/footer/Footer";
import Settings from "../../components/Settings";
import Loader from "../../components/Loader";
import ErrorPopup from '../../components/ErrorPopup';
import GetAllUsers from './getAllUsers';
import UserPopup from './addUser';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function Index (){

    const [startLoader, setStartLoader] = useState(false);
    const [error, setError] = useState("");
    const [addPopUp, setAddPopUp] = useState(false);
    const [response, setResponse] = useState([]);
    const [userAdded, setUserAdded] = useState(false);
    const [userUpdated, setUserUpdated] = useState(false);
    const [mode, setMode] = useState("Add");
    const [getUserByEmail, setGetUserByEmail] = useState("");
    const [roles, setRoles] = useState([]);
    const [userToUpdate, setUserToUpdate] = useState({});
    const [userSelected,setUserSelected] = useState(null);
    const [selectedRoles,setSelectedRoles] = useState([]);
    const [snackbarOpen,setSnackbarOpen] = useState(false);
    const userStatuses = ["ACTIVE", "INACTIVE"];


    //pagination
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortUserData, setSortClientData] = useState("CREATEDAT");

    useEffect(() => {
        getAllUserData();
    }, [page, rowsPerPage]);

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (value) => {
        setRowsPerPage(value);
        setPage(0);
    };

    useEffect(() => {
        getAllRolesAPI().then((res) => {
        //   console.log(res)
          setRoles(res.data);
        }).catch((err) => {
        //   console.log(err)
        })
      }, [])

    useEffect(()=>{

    },[])
      
    function reCreateUser() {
        setStartLoader(true);
        let userData = userToUpdate;
        const name = userData.name.split("\u00a0");
        const body = {
            "email": userData.email,
            "firstName": name[0],
            "lastName": name[1],
            "roles": userData.roles,
        };
        createUser(body)
            .then((res) => {
                setUserToUpdate({})
                setUserSelected(null)
                setUserAdded(true);
            })
            .catch((err) => {
                setError(err?.response?.data?.message);
            }).finally(() => {
                setStartLoader(false);
                getAllUserData();
            })
    }

     

    const handleUserCheck = (isChecked, id, roles, status, name, email) =>{
        if(isChecked){
            setUserSelected(id);
            setSelectedRoles([]);
            setUserToUpdate((prevObject) => ({
                ...prevObject,
                id: id,
                roles: roles,
                status: status,
                name: name,
                email: email
            })); 

        }else{
            setUserToUpdate({});
            setUserSelected(null);
            setSelectedRoles([]);
        }
        
    }
    function removeElementFromArray(arr, elementToRemove) {
        return arr.filter(item => item !== elementToRemove);
      }

    const handleRolesChange = (isChecked, role, id ) =>{
        if(isChecked && !selectedRoles.includes(role)){
            setSelectedRoles((selectedRoles)=> [...selectedRoles,role]);
        }else {
            if(selectedRoles.length !== 1){
                const arr = removeElementFromArray(selectedRoles, role);
                setSelectedRoles(arr);
            }else{
                setSnackbarOpen(true);
            }
        }
        if(id == userSelected){
            setUserToUpdate((prevObject) => {
                return {
                  ...prevObject,
                  roles: selectedRoles,
                }})

        }
    }
    function handleUserStatusChange(status, id) {
        if (id == userSelected) {
            setUserToUpdate((prevObject) => ({
                ...prevObject,
                status: status,
            }))
        }
    }

    const updateUser = () =>{
        setStartLoader(true);
        const id = userToUpdate.id;
        const body = {
            "roles": selectedRoles,
            "user_status": userToUpdate.status,
        }
        updateUserDetails(id, body).then((res)=>{
            setUserSelected(null);
            setUserToUpdate({})
        }).catch((err)=>{
            setError("Unable to update user");
        }).finally(()=>{
            setStartLoader(false);
            getAllUserData();
        })
    }

    const getAllUserData = ()=>{
            const body ={  
                "contactNo": "",
                "email": "",
                "firstName": "",
                "lastName": "",
                "roles": {},
                "user_status": ""
            }
        setStartLoader(true);
        getAllUsersForAdmin(body, sortUserData, page, rowsPerPage).then((res)=>{
            // console.log(res);
            setResponse(res.data.list);
            setTotalCount(res?.data.totalCount);

        }).catch((err)=>{
            // console.log(err);
        }).finally(()=>{
            setStartLoader(false);
          })
    }

    useEffect(()=>{
        getAllUserData();
    },[])

    const closePopUp = () => {
        setError("");
    };
    const handleClose = () => {
        setUserAdded(false)
      }

    return (
        <>
            <div className="flex-container">
                <div>
                    <Navigation myClass="Manage Users" />
                </div>
                <div className="right-section">
                    <div className="title" id="title">
                        <div className="pageheading" style={{marginLeft:"28px"}}>
                            <p>Manage Users</p>
                        </div>
                        <Settings />
                    </div>
                    <div className="content" id="content" >
                        <GetAllUsers
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleChangePage={handleChangePage}
                            setAddPopUp={setAddPopUp}
                            setMode={setMode}
                            setGetUserByEmail={setGetUserByEmail}
                            response={response}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            totalCount={totalCount}
                            handleUserCheck={handleUserCheck}
                            handleRolesChange={handleRolesChange}
                            roles={roles}
                            handleUserStatusChange={handleUserStatusChange}
                            userStatuses={userStatuses}
                            setUserSelected={setUserSelected}
                            userSelected={userSelected}
                            updateUser={updateUser}
                            userToUpdate={userToUpdate}
                            reCreateUser={reCreateUser}
                            selectedRoles={selectedRoles}
                            setSelectedRoles={setSelectedRoles}
                        />
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
                {
                    startLoader ? <Loader /> : <></>
                }
                {error !== "" && <ErrorPopup errorMessage={error} onClose={closePopUp} />}
                {
                    addPopUp && <UserPopup
                        setAddPopUp={setAddPopUp}
                        setUserAdded={setUserAdded}
                        setStartLoader={setStartLoader}
                        setError={setError} 
                        onClose={closePopUp}
                        mode= {mode}
                        getUserByEmail={getUserByEmail}
                        getAllUserData={getAllUserData}
                    />
                }
                {
                    userAdded &&
                    <Snackbar open={userAdded} autoHideDuration={3000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            Email sent to registered user.
                        </Alert>
                    </Snackbar>
                }
                {
                    snackbarOpen &&
                    <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={snackbarOpen}
                    autoHideDuration={3000} // Adjust as needed
                    onClose={() => setSnackbarOpen(false)}
                    message="At least one role should be selected."
                  />
                }
            </div>
        </>
    );
}

