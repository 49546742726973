import Table from '../../components/Table'
import FilterByMonth from './FilterByMonth';
import Badge from '@mui/joy/Badge';
import FilterContentButton from '../../components/Buttons/FilterContent';
import AddContentButton from "../../components/Buttons/AddContent";
import { useState, useEffect, useRef } from 'react';
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import { LuChevronUp } from "react-icons/lu";
import { LuChevronDown } from "react-icons/lu";
import { LuChevronsUpDown } from "react-icons/lu";
export default function GetAllBudgets(props) {
  const [rows, setRows] = useState([]);
  const [payload, setPayLoad] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isTableEditable, setIsTableEditable] = useState(false);

  const handleSortClick = (order) => {
    if (order === props.order) {
      props.setOrder(order == 'ASC' ? 'DESC' : order == 'DESC' ? null : 'ASC');
    } else {
      props.setOrder(order);
    }
  };

  const getSortIcon = (column) => {
    if (props.order === 'ASC') {
      return <LuChevronUp style={{ fontSize: "20px" }} />
    } else if (props.order === 'DESC') {
      return <LuChevronDown style={{ fontSize: "20px" }} />
    } else {
      return <LuChevronsUpDown style={{ fontSize: "16px" }} />;
    }
  };
  const columns = [
    {
      field: "projectName",
      headerName: "Project",
      cellRenderer: ({ value, data }) => {
        const finalValue = String(value).trim() === "null" || value === null ? "-" : value;
        return (<div style={{ display: "flex", alignItems: "center", gap: "1rem", width: "100%" }} >
          <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", flex: "1" }}>
            {finalValue}
          </div>
        </div>)
      },
      headerComponentFramework: ({ column }) => {
        return (
          <div onClick={() => handleSortClick('ASC')} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div>Project Name</div>
            <div>{getSortIcon(column.colId)}</div>
          </div>
        )
      }

    },
    {
      field: "deliveryManagerFirstName",
      headerName: "Delivery Manager"

    },
    {
      field: "amount",
      headerName: "Projected Revenue",
      editable: isTableEditable,
      cellStyle: { textAlign: 'right' },
      valueFormatter: (p) => {
        return p.value === 0 || p.value === null ? '-' : `$${Math.round(p.value).toLocaleString()}`;
      }
    },
    {
      field: "invoiceAmount",
      cellStyle: { textAlign: 'right' },
      valueFormatter: (p) => {
        return p.value === 0 || p.value === null ? '-' : `$${Math.round(p.value).toLocaleString()}`;
      }

    },
    {
      field: "arAmount",
      headerName: "Received Amount",
      cellStyle: { textAlign: 'right' },
      valueFormatter: (p) => {
        return p.value === 0 || p.value === null ? '-' : `$${Math.round(p.value).toLocaleString()}`;
      },

    },
    {
      field: "updatedBy",
      headerName: "Last Action By",
      valueFormatter: (p) => {
        return p.value === "null" || p.value === null ? "-" : p.value;
      }

    },
    {
      field: "updatedAt",
      headerName: "Last Action On",
      cellRenderer: ({ value, data }) => {
        const finalValue = String(value).trim() === "null" || value === null ? "-" : value;
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "1rem", width: "100%" }} >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", flex: "1" }}>{finalValue}</div>
            <div style={{ height: "16px", width: "16px", display: "flex", alignItems: "center" }}><DeleteIcon onClick={() => { props.setBudgetId(data.id) }} /></div>
          </div>)
      },
    }
  ]
  function createData(
    id,
    projectId,
    projectName,
    deliveryManagerFName,
    deliveryManagerLName,
    amount,
    invoiceTotalAmount,
    arAmount,
    year,
    month,
    updatedBy,
    updatedAt
  ) {
    const copiedDeliveryManagerFName = deliveryManagerFName;
    const copiedDeliveryManagerLName = deliveryManagerLName;
    const deliveryManagerFirstName = `${copiedDeliveryManagerFName} ${copiedDeliveryManagerLName}`;

    return {
      id,
      projectId,
      projectName,
      deliveryManagerFirstName,
      amount,
      invoiceAmount: invoiceTotalAmount,
      arAmount,
      updatedBy,
      year,
      month,
      updatedAt,
      numericAmount: amount, // Add numeric amount for calculations
    };
  }
  useEffect(() => {
    const rowsData = props.budgets.map((row) =>
      createData(
        row.id,
        row.projectId,
        row.projectName,
        row.deliverManagerFirstName,
        row.deliverManagerLastName,
        row.amount,
        row.invoiceTotalAmount,
        row.arAmount,
        row.year,
        row.month,
        row.updatedBy,
        row.updatedAt,
      )
    );
    setRows(rowsData);
  }, [props.budgets]);

  const tableTitle = "monthlyProjectBudget";

  const inlineStyles = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  };
  const handleProjectionRevenueChange = ({ data }) => {
    let updatedAmount = data?.amount;
    const updatedData = props.budgets.map(item => {
      if (item.id == data?.id) {
        return { ...item, amount: updatedAmount }
      } else {
        return item;
      }
    });
    setPayLoad(updatedData);
    props.setBudgets(updatedData);
  }
  return (
    <>
      <div className="inner-content">
        <div className="manage_content" style={inlineStyles}>
          <div style={{ display: "flex", alignItems: "center" }} className="content_subtitle">
            <div onClick={props.handleBackToProjectionSummary} className='left-arrow-icon-1' >
            </div>

          </div>
          <div
            style={{
              display: "flex",
              marginRight: "32px",
              alignItems: "center",
              gap: "1rem"
            }}
          >
            <div style={{ display: "flex", alignItems: "center", color: "var(--navyblue1)" }}  >
              <div>Total Projected Amount: ${props.totalAmount === null ? '$0.00' : `${Math.round(props.totalAmount).toLocaleString()}`}</div>
            </div>
            <div>
              <FilterByMonth
                date={props.date}
                setDate={props.setDate}
                setMonth={props.setMonth}
                setYear={props.setYear}
                getAllMonthlyBudget={props.getAllMonthlyBudget}
                year={props.year}
                month={props.month}
              />
            </div>
            {isTableEditable ? (
              <button
                style={{
                  display: "flex",
                  padding: "10px 16px",
                  width: "max-content",
                  alignItems: "center",
                  margin: "0px 4px 4px 8px",
                }}
                onClick={() => {
                  setIsTableEditable(false);
                  props.updateBudget(payload)
                }}
              >
                SAVE
              </button>
            ) : (
              <button
                style={{
                  display: "flex",
                  padding: "10px 16px",
                  width: "max-content",
                  alignItems: "center",
                  margin: "0px 4px 4px 8px",
                }}
                onClick={() => setIsTableEditable(true)}
              >
                Edit
              </button>
            )
            }
            <div className="functional_elements">
              <div
                onClick={() => {
                  props.setFilterBudget(!props.filterBudget)
                  props.setPage(0);
                }
                }
                className="filterDiv"
              >
                <Badge invisible={!props.isFilterSelected} size="lg">
                  <FilterContentButton />
                </Badge>
              </div>
            </div>
            <div onClick={() => { props.setAddSingleBudget(true) }} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <AddContentButton />
            </div>
          </div>
        </div>

        <div className="table-outer_content">
          <Table
            tableStyle={{ maxHeight: "20vh" }}
            paginationStyle={{
              position: "fixed",
              right: "20px",
              bottom: "20px",
              backgroundColor: "#ffffff",
            }}
            totalCount={props?.totalCount}
            page={props.page}
            rowsPerPage={props.rowsPerPage}
            handleChangePage={props.handleChangePage}
            handleChangeRowsPerPage={props.handleChangeRowsPerPage}
            columns={columns}
            rows={rows}
            normalData={true}
            tableTitle={tableTitle}
            setPayLoad={setPayLoad}
            setEditable={props.setEditable}
            editable={props.editable}
            setBudgetId={props.setBudgetId}
            isPagination={true}
            handleCellChange={handleProjectionRevenueChange}
          />
        </div>
      </div>
    </>
  );
}
