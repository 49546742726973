import React from "react";
import "../../assets/styles/managepages.css";

const PendingInvoices = (props) =>{

    return (
        <>
            <div className="dashboard-tile">
                    <h3>{"Pending Invoices"}</h3>
                    <p className="description">Not Billed to Client</p>
                    <div className="dashboard-content left">
                        <h5>{"Total Count :"}</h5>
                        <p>{`${props.pendingTotalValue.totalCount ? props.pendingTotalValue.totalCount : "--"}`}</p>
                    </div>
                    <div className="dashboard-content right">
                        <h5>{"Total Amount :"}</h5>
                        <p>{`${props.pendingTotalValue.totalAmount ? props.pendingTotalValue.totalAmount : "--"}`}</p>
                    </div>
            </div>
        </>
    )
}

export default PendingInvoices;