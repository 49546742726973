import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import '../../assets/styles/deleteContent.css';
import { removeInvoiceAPI } from '../../utils/api-helper';
import qs from 'qs';
import { useNavigate } from "react-router-dom";

export default function DeleteInvoice(props) {
    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id');
    const row = { id: id }
    const navigate = useNavigate();

    return (
        <>
            {/* <div className="flexdelete" >
                <div className="deleteBox">
                    <div className="deleteContent">
                        <div className="deleteTitle">
                            <div className="heading">Delete Invoice</div>
                            <div className="crossButtonHover" onClick={() => {props.getInvoiceById({ id: id })}}><div className="crossButton"></div></div>
                        </div>
                        <div className="deleteElements">
                            <div className="deleteOptionsData">
                                <p>Are you sure you want to delete</p>
                                <p>Invoice Number :<span className="ContentId"> {props.invoiceNumber}</span></p>
                                <p>Invoice of Project Name :<span className="ContentId"> {props.getProjectName}</span></p>
                            </div>
                            <div className="deleteFooter">
                                <div className="floatright">
                                    <div className="applyButton">
                                        <button onClick={deleteInvoice}>Delete</button>
                                    </div>
                                    <div className="cancleButton">
                                        <button onClick={() => {props.getInvoiceById({ id: id })}}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}
