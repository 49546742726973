import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getClientByIdAPI } from "../../../utils/api-helper";
import "./viewClients.css";
import EditContentButton from "../../../components/Buttons/ModifyContent";
import DeleteContentButton from "../../../components/Buttons/DeleteContent";

export var responseData = [];

export default function Index(props) {
    const [response, setResponse] = useState([]);
    const [errMsg, setErrMsg] = useState("");
    const [success, setSuccess] = useState(false);

    const search = useLocation().search;
    const id = new URLSearchParams(search).get("id");

    useEffect(() => {
        if(id){
        props.setStartLoader(true);
        getClientByIdAPI(id)
            .then((res) => {
                setResponse(res?.data);
                responseData = res?.data;
                props.setClientId(res?.data?.clientId);
                setSuccess(true);
                props.setStartLoader(false);
            })
            .catch((err) => {
                // console.log(err);
                setSuccess(false);
                props.setStartLoader(false);
            });}
    }, [id]);

    return (
        <>
            {success ? (
                <div className="inner-content client">
                    <div className="manage_content">
                        <div className="content_subtitle">
                            <p>
                                Client Id: {"\u00a0\u00a0"}
                                {response.clientId}
                                {"\u00a0\u00a0"}
                                {response.delete ? "(Inactive)" : "(Active)"}
                            </p>
                        </div>
                        <div className="box-functional_elements">
                            <div className="functional_elements" onClick={props.deleteClient}>
                                <DeleteContentButton />
                            </div>
                            <div className="functional_elements" onClick={props.editClient}>
                                <EditContentButton small={false}/>
                            </div>
                        </div>
                    </div>
                    <div className="data-container">
                        <div className="flex-data-container">
                            <div className="clientname">
                                <p className="heading">Client Name:</p>
                                {response.firstName ? (
                                    <p className="data">
                                        {response.firstName} {response.lastName}
                                    </p>
                                ) : (
                                    <> - - </>
                                )}
                            </div>
                            <div className="alternativeName ">
                                <p className="heading">Alternative Name:</p>
                                {response.alternateName ? (
                                    <p className="data">{response.alternateName}</p>
                                ) : (
                                    <> - - </>
                                )}
                            </div>
                            <div className="clientEmail">
                                <p className="heading">Email:</p>
                                {response.email ? (
                                    <p className="data">{response.email}</p>
                                ) : (
                                    <> - - </>
                                )}
                            </div>
                            <div className="contactNo ">
                                <p className="heading">Contact No:</p>
                                {response.contactNo ? (
                                    <p className="data">{response.contactNo}</p>
                                ) : (
                                    <> - - </>
                                )}
                            </div>
                            <div className="website">
                                <p className="heading">Website:</p>
                                {response.website ? (
                                    <p className="data">{response.website}</p>
                                ) : (
                                    <> - -</>
                                )}
                            </div>
                            <div className="clientType ">
                                <p className="heading">Client Type:</p>
                                {response.clientTypeName ? (
                                    <p className="data">{response.clientTypeName}</p>
                                ) : (
                                    <> - - </>
                                )}
                            </div>
                        </div>
                        <div className="flex-data-container">
                            <div className="contactPerson ">
                                <p className="heading">Contact Person:</p>
                                <p className="data">
                                    {response.contactPersonFirstName
                                        ? response.contactPersonFirstName
                                        : " -"}{" "}
                                    {response.contactPersonLastName
                                        ? response.contactPersonLastName
                                        : " -"}
                                </p>
                            </div>
                            <div className="faxNo">
                                <p className="heading">Fax No:</p>
                                {response.faxNumber ? (
                                    <p className="data">{response.faxNumber}</p>
                                ) : (
                                    <> - -</>
                                )}
                            </div>
                            <div className="gstin">
                                <p className="heading">GSTIN:</p>
                                {response.gstin ? (
                                    <p className="data">{response.gstin}</p>
                                ) : (
                                    <> - -</>
                                )}
                            </div>
                            <div className="panNo">
                                <p className="heading">PAN no.:</p>
                                {response.panNo ? (
                                    <p className="data">{response.panNo}</p>
                                ) : (
                                    <> - -</>
                                )}
                            </div>
                            <div className="paymentMode">
                                <p className="heading">Payment Mode:</p>
                                {response.paymentMode ? (
                                    <p className="data">{response.paymentMode}</p>
                                ) : (
                                    <> - -</>
                                )}
                            </div>
                        </div>
                        <div className="flex-data-container">
                            <div className="clientCountry">
                                <p className="heading">Country:</p>
                                {response.countryName ? (
                                    <p className="data">{response.countryName}</p>
                                ) : (
                                    <> - - </>
                                )}
                            </div>
                            <div className="clientBillToAddress">
                                <p className="heading">Bill To Address:</p>
                                {response.billToAddress1 ? (
                                    <p className="data">{response.billToAddress1}</p>
                                ) : (
                                    <> - - </>
                                )}
                                {response.billToAddress2 ? (
                                    <p className="data">{response.billToAddress2}</p>
                                ) : (
                                    <></>
                                )}
                                <p>
                                    {response.billToCityName ? response.billToCityName : ""},&nbsp;
                                    {response.billToStateName ? response.billToStateName : ""}&nbsp;
                                    {response.billToZip ? response.billToZip : ""}
                                </p>
                            </div>
                            <div className="clientShipToAddress">
                                <p className="heading">Ship To Address:</p>
                                {response.shipToAddress1 ? (
                                    <p className="data">{response.shipToAddress1}</p>
                                ) : (
                                    <> - - </>
                                )}
                                {response.shipToAddress2 ? (
                                    <p className="data">{response.shipToAddress2}</p>
                                ) : (
                                    <></>
                                )}
                                <p>
                                    {response.shipToCityName ? response.shipToCityName : ""},&nbsp;
                                    {response.shipToStateName ? response.shipToStateName : ""}&nbsp;
                                    {response.shipToZip ? response.shipToZip : ""}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}
