import React from "react";
import "../../assets/styles/managepages.css";

const AllInvoices = (props) => {
  return (
    <>
      <div className="dashboard-tile">
        <h3>{"All Invoices"}</h3>
        <div className="dashboard-content left">
          <h5>{"Total Count :"}</h5>
          <p>{`${props.allInvoicesTotalValue.totalCount ? props.allInvoicesTotalValue.totalCount : "--"}`}</p>
        </div>
        <div className="dashboard-content right">
          <h5>{"Total Amount :"}</h5>
          <p>{`${props.allInvoicesTotalValue.totalAmount ? props.allInvoicesTotalValue.totalAmount : "--"}`}</p>
        </div>
      </div>
    </>
  );
};

export default AllInvoices;