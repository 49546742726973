import React, { useRef, useState, useEffect, useContext } from 'react';
import { countryCodeList } from '../../utils/constants';
import Select from 'react-select';
import { getUserData, updateUserProfileAPI } from '../../utils/api-helper';

export default function EditProfile(props) {

    const [fname, setFirstName] = useState(getUserData().firstName);
    const [lname, setLastName] = useState(getUserData().lastName);
    const [countryCode, setCountryCode] = useState(getUserData().countryCode);
    const [phone, setPhone] = useState(getUserData().contactNo);
    const [email, setEmail] = useState(getUserData().email);
    const [empid, setEmpid] = useState("");
    const [department, setDepartment] = useState("");

    const [success, setSuccess] = useState(false);

    const userRef = useRef();

    const updateprofilepage = (e) => {
        e.preventDefault();
        const body = {
            "firstName": fname,
            "lastName": lname,
            "countryCode": countryCode,
            "email": phone,
            "email": email,
        };

        updateUserProfileAPI(body).then(res => {
            setSuccess(true);
            setFirstName('');
            setLastName('');
            setCountryCode('');
            setPhone('');
            setEmail('');

            setEmpid('');
            setDepartment('');
            props.getProfile();
        }).catch((err) => {
            // console.log(err);
            setSuccess(false);
        })
    }

    return (
        <>
            <div className="inner-content profile">
                <div className="data-container">
                    {/* className='form' method='POST' id="signup" onSubmit={handleSubmit} */}
                    <form className='form-profile-image' >
                        <div className="flex-image-container-profile">
                            <div className="profile-image">
                                <img src={require("../../assets/images/dummyprofile.png")} alt="Profile-photo" />
                            </div>
                        </div>
                    </form>
                    {/* className='form' method='POST' id="signup" onSubmit={handleSubmit} */}
                    <form className='form-profile' >
                        <div className="flex-data-container-profile">
                            <div className="userName">
                                <div className="firstName spacing form_input">
                                    <input
                                        type="text"
                                        id="fname"
                                        placeholder="First Name"
                                        name="fname"
                                        onChange={(e) => setFirstName(e.target.value)}
                                        value={fname}
                                        ref={userRef}
                                        required />
                                </div>
                                <div className="lastName form_input">
                                    <input
                                        type="text"
                                        id="lname"
                                        placeholder="Last Name"
                                        name="lname"
                                        onChange={(e) => setLastName(e.target.value)}
                                        value={lname}
                                        required />
                                </div>
                            </div>
                            <div className="userEmail">
                                <div className="userEmail form_input">
                                    <input
                                        type="email"
                                        id="email"
                                        placeholder="Email"
                                        name="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                        readonly="readonly"
                                        required />
                                </div>
                            </div>
                            <div className="userPhone">
                                <div className="userContCode form_input">
                                    <Select
                                        type="text"
                                        id="countryCode"
                                        placeholder={<div className="select-placeholder-text">{countryCode}</div>}
                                        name="countryCode"
                                        options={countryCodeList}
                                        // value={countryCode}
                                        onChange={(e) => setCountryCode(e.value)}
                                        required
                                    />
                                </div>
                                <div className="userPhoneNumber form_input">
                                    <input
                                        type="text"
                                        id="phone"
                                        placeholder="Phone"
                                        name="phone"
                                        onChange={(e) => setPhone(e.target.value)}
                                        value={phone}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-data-container-profile">
                            <div className="employeeID ">
                                <div className="userEmpid form_input">
                                    <input
                                        type="text"
                                        id="empid"
                                        placeholder="Employee ID"
                                        name="empid"
                                        onChange={(e) => setEmpid(e.target.value)}
                                        value={empid}
                                        required />
                                </div>
                            </div>
                            <div className="department ">
                                <div className="userDepartment form_input">
                                    <input
                                        type="text"
                                        id="department"
                                        placeholder="Department"
                                        name="department"
                                        onChange={(e) => setDepartment(e.target.value)}
                                        value={department}
                                        required />
                                </div>
                            </div>
                            <div className="buttonsEvents">
                                <div className="saveProfile">
                                    <button >SAVE</button>
                                </div>
                                <div className="cancelEdit">
                                    <button onClick={props.getProfile}>CANCEL</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        </>
    )
}
