import React from "react";
import "../../assets/styles/managepages.css";

const DraftInvoices = (props) =>{

    return (
        <>
            <div className="dashboard-tile">
                <h3>{"Draft Invoices"}</h3>
                <p className="description">Not yet sent to clients</p>
                <div className="dashboard-content left">
                    <h5>{"Total Count :"}</h5>
                    <p>{`${props.draftTotalValue.totalCount ?  props.draftTotalValue.totalCount : "--"}`}</p>
                </div>
                <div className="dashboard-content right">
                    <h5>{"Total Amount :"}</h5>
                    <p>{`${props.draftTotalValue.totalAmount ? props.draftTotalValue.totalAmount : "--"}`}</p>
                </div>
            </div>
        </>
    )
}

export default DraftInvoices;
