import React, { useState, useEffect, useCallback, useRef } from "react";
import { deleteStateAPI } from '../../utils/api-helper';
import '../../assets/styles/deleteContent.css';

export default function DeleteCountryData(props) {

    const [id, setId] = useState();
    const [name, setName] = useState();
    const [errorMessage, setErrorMessage] = useState("");
    const deleteState = (e) => {
        e.preventDefault();

        deleteStateAPI(props.id).then(res => {
            props.selectCountryGetStates({ "id": id, "name": name});
        }).catch((err) => {
            setErrorMessage(err.response.data.message);
        })
    }
    useEffect(() => {
        setId(props.selectedCountryid);
        setName(props.selectedCountryName);
    }, []);

    return (
        <div className="flexdelete" >
            <div className="deleteBox">
                <div className="deleteContent">
                    <div className="deleteTitle">
                        <div className="heading">Delete State</div>
                        <div className="crossButtonHover" onClick={props.initialState}><div className="crossButton"></div></div>
                    </div>
                    <div className="deleteElements">
                        <div className="deleteOptionsData">
                            <p>Are you sure you want to delete</p>
                            <p>State name<span>: {props.selectedStateName}</span></p>
                            <div className="form_message-error">{ errorMessage ? errorMessage : ""}</div>
                        </div>
                        <div className="deleteFooter">
                            <div className="floatright">
                                <div className="applyButton">
                                    <button onClick={deleteState} className={`${ errorMessage ? "hidden" : "" }`} >DELETE</button>
                                </div>
                                <div className="cancleButton">
                                    <button onClick={props.initialState}>CANCEL</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
