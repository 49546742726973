export const currentDate = () => {
    const date = new Date();
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();
    if (dd < 10) dd = '0' + dd; // Adding string '0' for single digit date
    if (mm < 10) mm = '0' + mm; // Adding string '0' for single digit month
    const formattedToday = yyyy + '-' + mm + '-' + dd;
    return formattedToday
}
export const addDateByPaymentDtatus = (invoiceDate, paymentTerm) => {
    const date = new Date(invoiceDate);
    switch (paymentTerm) {
        case 1:
            date.setDate(date.getDate() + 3);
            break;
        case 2:
            date.setDate(date.getDate() + 5);
            break;
        case 3:
            date.setDate(date.getDate() + 15);
            break;
        case 4:
            date.setDate(date.getDate() + 30);
            break;
    }
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();
    if (dd < 10) dd = '0' + dd; // Adding string '0' for single digit date
    if (mm < 10) mm = '0' + mm; // Adding string '0' for single digit month
    const formattedDate = yyyy + '-' + mm + '-' + dd;
    return formattedDate;
}