import React from 'react'
import '../buttons.css'
import { useNavigate } from "react-router-dom";
export default function Index() {

    return (
        <>
            <div className="addNewContent-icon" title="Add"></div>  
        </>
    )
}
