import React, { useState, useEffect } from 'react';
import '../../../assets/styles/deleteContent.css';
import { invoiceItemsDataArray, TotalInvoiceAmount } from '../index'
export default function DeleteInvoiceItem(props) {

    const deleteInvoice = () => {
        invoiceItemsDataArray.splice(props.invoiceItemIndex, 1);
        props.setInvoiceTotalAmount( props.invoiceTotalAmount + 
            invoiceItemsDataArray.map((row, index) =>
                {return row.itemAmount} 
            )
        );
        props.addInvoice();
    }
    return (
        <>
            <div className="flexdelete" >
                <div className="deleteBox">
                    <div className="deleteContent">
                        <div className="deleteTitle">
                            <div className="heading">Delete Invoice</div>
                            <div className="crossButtonHover" onClick={() => {props.addInvoice()}}><div className="crossButton"></div></div>
                        </div>
                        <div className="deleteElements">
                            <div className="deleteOptionsData">
                                <p>Are you sure you want to delete</p>
                                {/* <p>Item Number :<span className="ContentId"> {props.invoiceItemIndex}</span></p> */}
                                <p>Item Description :<span className="ContentId"> {props.invoiceItemDescription}</span></p>
                            </div>
                            <div className="deleteFooter">
                                <div className="floatright">
                                    <div className="applyButton">
                                        <button onClick={deleteInvoice}>Delete</button>
                                    </div>
                                    <div className="cancleButton">
                                        <button onClick={() => {props.addInvoice()}}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
