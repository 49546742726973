import React from 'react'
import '../buttons.css'
export default function Index(props) {

    return (
        <>
            {
                props.small ?
                    <div className="ModifyContent-icon-small" title="Edit"></div>
                    :
                    <div className="ModifyContent-icon" title="Edit"></div>
            }
        </>
    )
}
