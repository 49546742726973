import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { logoutAdminAPI, logoutUserAPI, getUserData } from '../../utils/api-helper';
import { clearAllLocalStorage } from '../../utils/local-storage-helper';
import '../../assets/styles/deleteContent.css';
import Navigation from '../../components/Navigation';
import Footer from '../../components/footer/Footer';
import Alert from "../../pages/manageBudget/alert";

export default function Index() {

    const history = useNavigate();

    const logoutUser = () => {
        logoutAdminAPI().then(res => {
            history('/login');
            clearAllLocalStorage();
        }).catch((err) => {
            // console.log(err);
        })
    };

    const cancelLogout = () => {
        history(-1);
    }

    return (
        <>

            <div className="flex-container">
                <div>
                    <Navigation myClass="Logout" />
                </div>
                <div className="right-section">
                    {/* <InvoiceList /> */}
                    <div className="title" id="title">
                    </div>
                    <div className="content" id="content">

                    </div>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
            <Alert 
                cancelLogout={cancelLogout}
                confirmLogout={logoutUser}
                message={"Are you sure you want to logout?"}
                heading={"Logout"}
                isLogoutPage={true}
            />

            {/* <div className="flexdelete" >
                <div className="deleteBox">
                    <div className="deleteContent">
                        <div className="deleteTitle">
                            <div className="heading">Logout</div>
                            <div className="crossButtonHover" onClick={cancelLogout}><div className="crossButton"></div></div>
                        </div>
                        <div className="deleteElements">
                            <div className="deleteOptionsData">
                                <p></p>
                            </div>
                            <div className="deleteFooter">
                                <div className="">
                                    <div className="applyButton">
                                        <button onClick={logoutUser}>Yes</button>
                                    </div>
                                    <div className="cancleButton">
                                        <button onClick={cancelLogout}>No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}
